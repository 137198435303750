import * as React from "react";
import {
  AlignItemsEnum,
  JustifyContentEnum,
  OverflowEnum,
} from "./LayoutXenum";

type props = {
  children?: React.ReactNode;
  colGap?: string;
  crossAxisAlignment?: AlignItemsEnum;
  direction?: "row" | "column";
  fullWidth?: boolean;
  height?: string;
  mainAxisAlignment?: JustifyContentEnum;
  overflow?: OverflowEnum;
  rowGap?: string;
  className?: string;
};

export const Wrap = ({
  className,
  children,
  colGap,
  crossAxisAlignment,
  direction = "row",
  fullWidth,
  height,
  mainAxisAlignment,
  overflow = "visible",
  rowGap,
}: props) => {
  return (
    <div
      style={{
        alignItems: crossAxisAlignment,
        display: "flex",
        flexFlow: `${direction} wrap`,
        gap: `${colGap} ${rowGap}`,
        height,
        justifyContent: mainAxisAlignment,
        overflow: overflow,
        verticalAlign: "bottom",
        width: fullWidth ? "100%" : "auto",
        wordBreak: "break-word",
      }}
      className={className}
    >
      {children}
    </div>
  );
};
