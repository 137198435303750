const COOKIE_KEY = "swiperSalesId";

const getAll = () =>{
    let pairs = document.cookie.split(";").filter(x => x);
    let cookies = {};
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split("=");
        cookies[(pair[0] + '').trim()] = unescape(pair[1]);
    }
    return Object.keys(cookies).length == 0 ? null : cookies;
}

export const setSwiperSalesIdCookie = (s: string, path:string = "/") => {
    document.cookie = `${COOKIE_KEY}=${s};path=${path};`;
}

export const getSwiperSalesIdCookie = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${COOKIE_KEY}`)) {
        const result = cookie.substring(`${COOKIE_KEY}=`.length, cookie.length);
        return result;
    }
    }
    return "";
}

export const deleteSwiperSalesIdCookie = () => {
    let cookies = getAll();
  let filtered = Object.keys(cookies)
    .filter(key => key === COOKIE_KEY)
    .reduce((obj, key) => {
      obj[key] = cookies[key];
      return obj;
    }, {});
  Object.keys(filtered).forEach(key => {
    document.cookie = `${key}=;path=/;expires=${new Date(0).toUTCString()};`
  });}