import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { Button, Fab } from "ui-neumorphism";
import PropertyX_logo from "../../../assets/images/property-X.png";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import TextFieldNeu from "../../../components/TextField/TextFieldNeu";
import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { useSnackbar } from "react-simple-snackbar";
import {
  useCheckAccountCodeValidLazyQuery,
  useLinkPurchaserAccountMutation,
  useSendSignUpInvitationLazyQuery,
} from "../../../generated/graphql";
import { useNavigate, useParams } from "react-router";
import { AUTH_PATH, Invalid_Auth_Path } from "./AuthRoutes";
import SimpleDialog from "../../../components/Dialog/SimpleDialog";
import { cardChangeColor } from "../../../containers/context/CardContext";
import { buttonChangeColor } from "../../../containers/context/ButttonContext";

const Register = () => {
  const [emailValue, setEmailValue] = useState("");
  const { accountCode } = useParams();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const navigation = useNavigate();
  const [isOpenConfirmLinkAccountDialog, setIsOpenConfirmLinkAccountDialog] =
    useState(false);
  const { cardColor } = cardChangeColor();
  const { buttonColor } = buttonChangeColor();

  const RegisterForm = {
    input: {
      email: emailValue,
      accountCode: accountCode || "",
    },
  };
  const [
    sendRegisterInvitation,
    { data: registerInvitationResponse, loading: registerInvitationLoading },
  ] = useSendSignUpInvitationLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [getAccountCode, { data: accountCodeData }] =
    useCheckAccountCodeValidLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [linkAccount, { data: linkAccountData }] =
    useLinkPurchaserAccountMutation({});

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const handleIsValidEmail = (isValid: boolean) => {
    setIsValidEmail(isValid);
  };

  const handleRegister = async () => {
    if (!RegisterForm.input.email)
      return openSnackbar("You must include an email address.");

    if (!isValidEmail) return openSnackbar("Invalid email address.");

    await sendRegisterInvitation({
      variables: RegisterForm,
    });
  };

  const handleCloseConfirmLinkAccountDialog = () => {
    setIsOpenConfirmLinkAccountDialog(false);
  };

  useEffect(() => {
    getAccountCode({ variables: { accountCode: accountCode || "" } });
  }, []);

  useEffect(() => {
    //return null means register invitation is sent successfully
    if (registerInvitationResponse?.sendSignUpInvitation === null) {
      openSnackbar(
        "An email with a link to register your account has been sent to your email address. Please check your mailbox."
      );
      navigation(
        accountCode
          ? AUTH_PATH.LOGIN.replace(":accountCode", accountCode || "")
          : Invalid_Auth_Path.LOGIN
      );

      //if register invitation is a string (it is a userId), it means the user is an existing purchaser
    } else if (
      typeof registerInvitationResponse?.sendSignUpInvitation === "string"
    ) {
      setIsOpenConfirmLinkAccountDialog(true);
    }
  }, [registerInvitationResponse]);

  const handleConfirmLinkAccount = async () => {
    setIsOpenConfirmLinkAccountDialog(false);

    if (
      accountCode &&
      registerInvitationResponse?.sendSignUpInvitation &&
      RegisterForm.input.email
    ) {
      await linkAccount({
        variables: {
          accountCode: accountCode,
          loginId: RegisterForm.input.email,
          userId: registerInvitationResponse?.sendSignUpInvitation,
        },
      }).then((res) => {
        if (res.data?.linkPurchaserAccount) {
          openSnackbar(
            "Link account successfully. An email with a link to register your account has been sent to your email address. Please check your mailbox."
          );

          navigation(
            accountCode
              ? AUTH_PATH.LOGIN.replace(":accountCode", accountCode || "")
              : Invalid_Auth_Path.LOGIN
          );
        } else {
          openSnackbar("Failed to link account");
        }
      });
    }
  };

  const handleCancelLinkAccount = () => {
    setIsOpenConfirmLinkAccountDialog(false);
  };

  return (
    <>
      <div className="Auth-Container">
        <ColumnFlex gap="30px" crossAxisAlignment="center">
          <div style={{ width: "100%" }}>
            <CardNeu elavation={2} style={{ background: cardColor }}>
              <ContainerX
                padding={{
                  symmetric: { vertical: "20px", horizontal: "40px" },
                }}
                alignment="center"
              >
                <ColumnFlex crossAxisAlignment="center" gap="20px">
                  <ColumnFlex crossAxisAlignment="center" gap="0px">
                    <img
                      src={
                        accountCodeData?.checkAccountCodeValid?.purchaserAppCss
                          ?.logoUrl || PropertyX_logo
                      }
                      className="company-logo"
                    />
                    <span
                      className="color-orange text-with-shadow fs-14"
                      style={{
                        width: "100%",
                        textAlign: "end",
                        paddingRight: "10%",
                      }}
                    >
                      {accountCodeData?.checkAccountCodeValid?.purchaserAppCss
                        ?.showPurchaserAppWord
                        ? "Purchaser App"
                        : null}
                    </span>
                  </ColumnFlex>
                  <span className="fs-20 fw-700 text-with-shadow color-text">
                    Register
                  </span>
                  <span className="fs-14 fw-300">
                    Enter your email and we'll send you the register link.
                  </span>
                  <TextFieldNeu
                    placeholder="Email"
                    showWarning
                    type="email"
                    onInput={handleEmailChange}
                    value={emailValue}
                    handleIsValid={handleIsValidEmail}
                    validTest
                  />
                </ColumnFlex>
              </ContainerX>
            </CardNeu>
          </div>
          <Fab
            className={"fullwidth-with-child"}
            disabled={!isValidEmail || registerInvitationLoading || !emailValue}
            onClick={handleRegister}
            style={
              !isValidEmail || registerInvitationLoading
                ? {
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            <RowFlex crossAxisAlignment="center" gap="10px">
              {/* {registerInvitationLoading && <Spinner direction="horizontal" />} */}
              <span className="color-text-blue-gradient">
                Send Register Link
              </span>
            </RowFlex>
          </Fab>
        </ColumnFlex>
      </div>
      <SimpleDialog
        visible={isOpenConfirmLinkAccountDialog}
        onClose={handleCloseConfirmLinkAccountDialog}
      >
        <ColumnFlex gap="20px">
          <span className="color-text fs-20 fw-800 text-with-shadow">
            Account Linkage
          </span>
          <span className="fs-12">
            You are an existing puchaser, do you like to link up your user
            profile to this new account.
          </span>
          <RowFlex gap="24px" mainAxisAlignment="flex-end">
            <Button
              className={"disable-full-uppercase font-family-strict color-text"}
              onClick={handleCancelLinkAccount}
            >
              Cancel
            </Button>
            <Button
              className={"disable-full-uppercase font-family-strict color-text"}
              onClick={handleConfirmLinkAccount}
            >
              Confirm
            </Button>
          </RowFlex>
        </ColumnFlex>
      </SimpleDialog>
    </>
  );
};

export default Register;
