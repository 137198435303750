import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../GeneralPages/GeneralRoutes";
import { useEffect, useState } from "react";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Avatar, Button, IconButton } from "ui-neumorphism";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import exp from "constants";
import ContainerX from "../../../components/general/ContainerX";
import CustomizeHeader from "../../../components/Header/CustomizeHeader";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { CloseRounded } from "@mui/icons-material";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import CardNeu from "../../../components/Card/Card";
import { Chip, FormControl, InputBase, TextField } from "@mui/material";
import { EmailFormType } from "./PageDetail";
import { isValidEmail } from "../../../containers/helpers/InputValidation";
import QuillTextEditor, {
  isEditorEmpty,
} from "../../../components/TextEditor/QuillTextEditor";
import { useSendProjectBrochureEmailMutation } from "../../../generated/graphql";
import { useSnackbar } from "react-simple-snackbar";
import { isMobileWidth, useMediaQuery } from "../../../useHook/useMediaQuery";
import { headerChangeColor } from "../../context/Context";
import { useContext } from "react";

const EmailEditor = ({
  emailForm,
  setEmailForm,
  setIsOpenEmailEditor,
}: {
  emailForm: EmailFormType;
  setEmailForm: (value: any) => void;
  setIsOpenEmailEditor: (value: boolean) => void;
}) => {
  const [emailInput, setEmailInput] = useState("");
  const [emailWarning, setEmailWarning] = useState<string | null>(null);
  const [subjectWarning, setSubjectWarning] = useState<string | null>(null);
  const [contentWarning, setContentWarning] = useState<string | null>(null);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const isMobile = useMediaQuery(isMobileWidth);
  const { headerColor } = headerChangeColor();

  const [sendProjectBrochureEmail] = useSendProjectBrochureEmailMutation({
    onCompleted: (data) => {
      if (data.sendProjectBrochureEmail) {
        openSnackbar("Email sent successfully");
        setIsOpenEmailEditor(false);
      }
    },
  });

  const handleAddEmail = () => {
    if (!isValidEmail(emailInput.trim()).result) {
      setEmailInput("");
      return;
    }

    if (emailInput.trim() !== "") {
      setEmailForm({
        ...emailForm,
        toEmails: [...emailForm.toEmails, emailInput.trim()],
      });
      setEmailInput("");
    }

    if (emailWarning) {
      setEmailWarning(null);
    }
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...emailForm.toEmails];
    newEmails.splice(index, 1);
    setEmailForm({
      ...emailForm,
      toEmails: newEmails,
    });

    if (newEmails && newEmails.length <= 0) {
      setEmailWarning("Email is required");
    }
  };

  const handleSubjectChange = (e: { target: { value: any } }) => {
    setEmailForm({ ...emailForm, subject: e.target.value });

    if (e.target.value.length > 0) {
      setSubjectWarning(null);
    } else {
      setSubjectWarning("Subject is required");
    }
  };

  const handleMessageChange = (value: any) => {
    setEmailForm({ ...emailForm, message: value });

    if (isEditorEmpty()) {
      setContentWarning("Message is required");
    } else {
      setContentWarning(null);
    }
  };

  const handleSendEmail = async () => {
    if (emailForm?.toEmails.length <= 0) {
      setEmailWarning("Email is required");
      return;
    } else {
      setEmailWarning(null);
    }

    if (emailForm.subject.length <= 0) {
      setSubjectWarning("Subject is required");
      return;
    } else {
      setSubjectWarning(null);
    }

    if (isEditorEmpty()) {
      setContentWarning("Message is required");
      return;
    } else {
      setContentWarning(null);
    }

    sendProjectBrochureEmail({
      variables: {
        emailList: emailForm.toEmails,
        message: emailForm.message,
        subject: emailForm.subject,
      },
    });
  };

  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "#e5e8f7",
        width: "100%",
        transform: "translateY(-20px)",
        height: "100vh",
        overflow: "auto",
        left: 0,
        top: "20px",
        zIndex: 100,
      }}
    >
      <ContainerX
        padding={{
          only: {
            top: "80px",
            bottom: "40px",
            left: `${isMobile && "10px"}`,
            right: `${isMobile && "10px"}`,
          },
        }}
        className={`${
          !isMobile && "fullwidth-container"
        } margin-horizontal-center`}
      >
        <ColumnFlex gap="30px" crossAxisAlignment="center">
          <CustomizeHeader style={{ background: headerColor }}>
            <RowFlex crossAxisAlignment="center" gap="20px">
              <Button onClick={() => setIsOpenEmailEditor(false)}>
                <CloseRounded className="color-text-light-blue" />
              </Button>
              <span className="fs-16 fw-700 text-with-shadow">Email</span>
            </RowFlex>
          </CustomizeHeader>

          <CardNeu width="100%">
            <ContainerX
              padding={{ symmetric: { horizontal: "16px", vertical: "20px" } }}
            >
              <ColumnFlex gap="20px">
                <ColumnFlex gap="4px" className="neu-text-field-style">
                  <span className="fs-12 fw-500 color-text-2">To *</span>
                  <InputBase
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddEmail();
                      }
                    }}
                    onBlur={handleAddEmail}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {emailForm?.toEmails?.map((email, index) => (
                      <Chip
                        key={index}
                        label={email}
                        onDelete={() => handleRemoveEmail(index)}
                        color="primary"
                      />
                    ))}
                  </div>
                  {emailWarning && (
                    <span className="fs-12 fw-500 color-red">
                      {emailWarning}
                    </span>
                  )}
                </ColumnFlex>
                <ColumnFlex gap="4px" className="neu-text-field-style">
                  <span className="fs-10 fw-400 color-text-2">Subject *</span>
                  <input
                    type="text"
                    value={emailForm.subject}
                    onChange={handleSubjectChange}
                  />
                  {subjectWarning && (
                    <span className="fs-10 fw-400 color-red">
                      {subjectWarning}
                    </span>
                  )}
                </ColumnFlex>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>

          <CardNeu width="100%">
            <ContainerX
              padding={{ symmetric: { horizontal: "4px", vertical: "4px" } }}
            >
              <QuillTextEditor
                value={emailForm.message}
                onChange={handleMessageChange}
              />
              {contentWarning && (
                <span className="fs-10 fw-400 color-red p-h-15">
                  {contentWarning}
                </span>
              )}
            </ContainerX>
          </CardNeu>
          <Button
            rounded
            className={"disable-full-uppercase color-text"}
            style={{ width: "50%" }}
            onClick={handleSendEmail}
          >
            Send
          </Button>
        </ColumnFlex>
      </ContainerX>
    </div>
  );
};

export default EmailEditor;
