import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { Button, IconButton } from "ui-neumorphism";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CardNeu from "../../../../components/Card/Card";
import { useEffect, useState } from "react";
import ContainerX from "../../../../components/general/ContainerX";
import {
  useApplyLoanMasterDataLazyQuery,
  useGetSellingPriceLazyQuery,
  useLoanAddMutation,
  useLoanListingBySalesLazyQuery,
  useProjectCurrencyLazyQuery,
} from "../../../../generated/graphql";
import TextX from "../../../../components/general/TextX";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useMediaQuery } from "../../../../useHook/useMediaQuery";
import Expanded from "../../../../components/LayoutX/Expanded";
import ZoomedImageDialog from "../../../../components/Dialog/ZoomedImageDialog";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import DefaultProfileBg from "../../../../assets/images/background/profile-bg.jpg";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import TextFieldNeu from "../../../../components/TextField/TextFieldNeu";
import PersonIcon from "@mui/icons-material/Person";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { DASHBOARDS_PATH } from "../DashboardRoutes";
import { getPathCookie } from "../../../../containers/helpers/PathCookie";
import NumberFormatTextField from "../../../../components/TextField/NumberFormat";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import { useSnackbar } from "react-simple-snackbar";
import EmptyRecord from "../../../../components/general/EmptyRecord";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";
import { inputFieldChangeColor } from "../../../../containers/context/InputFieldContext";
import { buttonChangeColor } from "../../../../containers/context/ButttonContext";

import { useContext } from "react";

const LoanListing = () => {
  const [searchParams] = useSearchParams();
  const saleId = searchParams.get("saleId");
  const projectId = searchParams.get("projectId");
  const navigate = useNavigate();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();
  const { inputFieldColor } = inputFieldChangeColor();
  const { buttonColor } = buttonChangeColor();

  const populateLoanRate = 0.9;
  const rate = 1.1;

  const [getLoanListBySales, { data: loanListData, loading: loanListLoading }] =
    useLoanListingBySalesLazyQuery();
  const [addLoan] = useLoanAddMutation();
  const [
    applyLoanMasterData,
    { data: masterInfoData, loading: masterInfoLoading },
  ] = useApplyLoanMasterDataLazyQuery();
  const [getProjectCurrency, { data: projectCurrencyData }] =
    useProjectCurrencyLazyQuery();
  const [getSellingPrice, { data: sellingPriceData }] =
    useGetSellingPriceLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.getSellingPrice?.sellingPrice > 0) {
          setAmountValue(
            data?.getSellingPrice?.sellingPrice * populateLoanRate
          );
        }
      },
    });
  const [openApplyLoanDialog, setOpenApplyLoanDialog] = useState(false);
  const [amountValue, setAmountValue] = useState(0);
  const [currentFinancierId, setCurrentFinancierId] = useState("");
  const [applyLoanError, setApplyLoanError] = useState<string | null>(null);

  const isGreaterThanPropertyPrice = (amount: number) => {
    return amount > sellingPriceData?.getSellingPrice?.sellingPrice * rate;
  };

  const handleApplyAmountChange = (value: number) => {
    if (value <= 0) {
      setApplyLoanError("Loan amount is required");
    } else if (isGreaterThanPropertyPrice(value)) {
      setApplyLoanError("Input amount is greater than property price.");
    } else {
      setApplyLoanError(null);
    }

    setAmountValue(value);
  };

  useEffect(() => {
    if (!projectId || !saleId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getLoanListBySales({
        variables: {
          saleId: saleId,
          projectId: projectId,
        },
      });
    }
  }, []);

  const handleAddLoan = () => {
    if (applyLoanError) return;

    if (amountValue <= 0) {
      setApplyLoanError("Loan amount is required");
      return;
    } else if (isGreaterThanPropertyPrice(amountValue)) {
      setApplyLoanError("Input amount is greater than property price.");
      return;
    } else {
      setApplyLoanError(null);
    }

    if (!masterInfoData || !projectCurrencyData || !saleId || !projectId) {
      return;
    }

    addLoan({
      variables: {
        input: {
          project_id: projectId,
          sale_id: saleId,
          loan_type: masterInfoData?.GetAllMasterInfo?.LoanType?.COMMERCIAL,
          apply_amount: amountValue,
          financier_ids: [currentFinancierId],
        },
      },
      onCompleted: (data) => {
        if (data?.applyLoan !== true) return;
        setOpenApplyLoanDialog(false);
        openSnackbar("Loan application successfully applied.");
        window.location.reload();
      },
    });

    setAmountValue(
      sellingPriceData?.getSellingPrice?.sellingPrice * populateLoanRate
    );

    setCurrentFinancierId("");
  };

  const handleOpenApplyLoanDialog = async (financierId: string) => {
    setCurrentFinancierId(financierId);
    await getSellingPrice({
      variables: { saleId: saleId },
    });
    await applyLoanMasterData();
    await getProjectCurrency({
      variables: { projectId: projectId },
    });

    setOpenApplyLoanDialog(true);
  };

  const handleCloseApplyLoanDialog = () => {
    setCurrentFinancierId("");
    setAmountValue(0);
    setOpenApplyLoanDialog(false);
  };

  const handleJumpToLoanDetails = (financierId: string) => {
    navigate(
      `${DASHBOARDS_PATH.LOAN_DETAILS.replace(
        ":accountCode",
        getPathCookie()
      )}?saleId=${saleId}&projectId=${projectId}&financierIdId=${financierId}`
    );
  };

  const handleJumpToPurchaserDocuments = (loanId) => {
    navigate(
      `${DASHBOARDS_PATH.PURCHASER_DOCUMENTS.replace(
        ":accountCode",
        getPathCookie()
      )}?saleId=${saleId}&projectId=${projectId}&loanId=${loanId}`
    );
  };

  const calculateDocProgress = (saleLoanDocArray) => {
    const documentChecklist = {
      bookingFrom: false,
      customerIC: false,
      customerCreditReport: false,
      customerPayslips: false,
      customerEPFEABank: false,
      otherDocuments: false,
    };

    saleLoanDocArray.forEach((obj) => {
      switch (obj.refType) {
        case "BOOKING_FORM":
          documentChecklist.bookingFrom = true;
          break;
        case "BOOKING_BUYER_NRIC_ATTACHMENT":
        case "BOOKING_JOINT_BUYER_NRIC_ATTACHMENT":
        case "BOOKING_BUYER_PASSPORT_ATTACHMENT":
          documentChecklist.customerIC = true;
          break;
        case "BOOKING_CCR_ATTACHMENT":
          documentChecklist.customerCreditReport = true;
          break;
        case "LOAN_PAY_SLIP":
          documentChecklist.customerPayslips = true;
          break;
        case "LOAN_INCOME_STATEMENT":
          documentChecklist.customerEPFEABank = true;
          break;
        case "LOAN_OTHER_ATTACHMENT":
          documentChecklist.otherDocuments = true;
          break;
        default:
          break;
      }
    });

    let selectedDocumentCount = 0;
    Object.values(documentChecklist).forEach((x) => {
      if (x) selectedDocumentCount++;
    });

    return selectedDocumentCount;
  };

  if (loanListData?.loanListingBySales) {
    return (
      <>
        <ColumnFlex fullWidth gap="24px">
          <GeneralHeader
            title="Loan Listing"
            style={{ background: headerColor }}
          />
          {loanListData?.loanListingBySales.length <= 0 && <EmptyRecord />}
          {loanListData?.loanListingBySales.length > 0 && (
            <CardNeu
              padding={{ only: { top: "10px" } }}
              style={{ background: cardColor }}
            >
              {loanListData?.loanListingBySales.map((financier) => (
                <ContainerX
                  key={financier?.financierId}
                  className="divider-bottom-border"
                  padding={{
                    symmetric: { vertical: "10px", horizontal: "20px" },
                  }}
                >
                  <ColumnFlex gap="10px">
                    <img
                      src={financier?.financierLogoUrl || DefaultProfileBg}
                      width={"36px"}
                      height={"36px"}
                    />
                    <RowFlex
                      mainAxisAlignment="space-between"
                      crossAxisAlignment="center"
                    >
                      <ColumnFlex gap="4px">
                        <span className="fw-700 fs-12 color-text">
                          {financier?.financierName}
                        </span>
                        {financier?.loanId && (
                          <ColumnFlex
                            gap="4px"
                            className="color-text fw-700 fs-12"
                          >
                            <RowFlex
                              className="color-text fw-700 fs-12"
                              gap="4px"
                            >
                              <TextX
                                data={financier?.applyAmount}
                                dataType="Number"
                              />
                              <span>|</span>
                              <span className="color-text-light-blue">{`Documents: ${calculateDocProgress(
                                financier?.saleLoanDoc
                              )}/6`}</span>
                            </RowFlex>
                            <RowFlex gap="4px">
                              <PersonIcon fontSize="inherit" />
                              <span>{financier?.financierContact?.name}</span>
                              <span>|</span>
                              <SmartphoneIcon fontSize="inherit" />
                              <span>
                                {financier?.financierContact?.phoneNo}
                              </span>
                            </RowFlex>
                          </ColumnFlex>
                        )}
                      </ColumnFlex>
                      {financier?.loanId ? (
                        <ColumnFlex
                          gap="4px"
                          crossAxisAlignment="flex-end"
                          className="color-text fw-700 fs-12"
                        >
                          <TextX data={financier?.applyDate} dataType="Date" />
                          <span>{financier?.statusName}</span>
                        </ColumnFlex>
                      ) : (
                        <IconButton
                          rounded
                          size="large"
                          onClick={() =>
                            handleOpenApplyLoanDialog(financier?.financierId)
                          }
                        >
                          <ArrowForwardIosRoundedIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </RowFlex>
                    {financier?.loanId && (
                      <RowFlex gap="16px" mainAxisAlignment="flex-end">
                        <Button
                          className={"disable-full-uppercase"}
                          onClick={() =>
                            handleJumpToLoanDetails(financier?.financierId)
                          }
                          bgColor={buttonColor}
                        >
                          View
                        </Button>
                        <Button
                          className={"disable-full-uppercase"}
                          onClick={() =>
                            handleJumpToPurchaserDocuments(financier?.loanId)
                          }
                          bgColor={buttonColor}
                        >
                          Documents
                        </Button>
                      </RowFlex>
                    )}
                  </ColumnFlex>
                </ContainerX>
              ))}
            </CardNeu>
          )}
        </ColumnFlex>
        <SimpleDialog
          visible={openApplyLoanDialog}
          onClose={handleCloseApplyLoanDialog}
          background={cardColor}
        >
          <ColumnFlex gap="15px">
            <ColumnFlex gap="4px">
              <span className="fw-700 fs-16 color-text text-with-shadow">
                Apply Loan
              </span>
              <span className="fw-400 fs-12 color-text-2">{`Loan Amount (${projectCurrencyData?.getProjectById?.currency})`}</span>
            </ColumnFlex>
            <div className=" neu-text-field-style fullwidth">
              <NumberFormatTextField
                value={amountValue}
                onChange={(value) => handleApplyAmountChange(value)}
                className="fullwidth"
              />
              {applyLoanError && (
                <span className="fs-12 color-red">{applyLoanError}</span>
              )}
            </div>
            <RowFlex mainAxisAlignment="flex-end" gap="30px">
              <Button
                className={"disable-full-uppercase"}
                onClick={handleCloseApplyLoanDialog}
                bgColor={buttonColor}
              >
                Cancel
              </Button>
              <Button
                className={"disable-full-uppercase color-text"}
                disabled={applyLoanError ? true : false}
                onClick={handleAddLoan}
                bgColor={buttonColor}
              >
                Apply
              </Button>
            </RowFlex>
          </ColumnFlex>
        </SimpleDialog>
      </>
    );
  }
};

export default LoanListing;
