import { NavigateBeforeRounded } from "@mui/icons-material";
import { RowFlex } from "../LayoutX/Flex/RowFlex";
import { useNavigate } from "react-router";
import { Button } from "ui-neumorphism";

type CustomizeHeaderProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

// child element must be a div element
const CustomizeHeader: React.FC<CustomizeHeaderProps> = ({
  children,
  style,
}) => {
  const navigate = useNavigate();

  return (
    <div className="general-header" style={style}>
      {children}
    </div>
  );
};

export default CustomizeHeader;
