import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { useEffect, useState } from "react";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ContainerX from "../../../../components/general/ContainerX";

const PDPAconsent = ({ setIsOpenPDPAconsent, setProjectRequiredCheckbox }) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#e8ebf5",
          width: "100%",
          transform: "translateY(-20px)",
          height: "100vh",
          overflow: "auto",
          top: "0",
          left: "0",
          zIndex: 21,
        }}
      >
        <ContainerX className="MainContainer">
          <ColumnFlex fullWidth gap="40px">
            <RowFlex crossAxisAlignment="center" gap="20px" fullWidth>
              <Button onClick={() => setIsOpenPDPAconsent(false)}>
                <CloseRoundedIcon className="color-text-light-blue" />
              </Button>
              <span className="fs-16 fw-700 text-with-shadow">
                PDPA Consent
              </span>
            </RowFlex>
            <ContainerX
              padding={{
                only: { left: "20px", right: "20px", bottom: "100px" },
              }}
            >
              <ColumnFlex gap="15px">
                <div className="title">PDPA Consent Clause</div>
                <div className="content">
                  By submitting this Form, you hereby agree that Property365 may
                  collect, obtain, store and process your personal data that you
                  provide in this form for the purpose of receiving updates,
                  promotional and marketing mails or materials from Property365.
                </div>
                <div className="">
                  You hereby acknowledge, confirm and give your consent to
                  Property365 to:
                  <ul className="m-t-0">
                    <li>Store and process your Personal Data;</li>
                    <li>
                      Provide you with information, updates on products,
                      services, new launches, promotions or such other
                      information offered by Property365 and or its partner
                      and/or partner developers;
                    </li>
                    <li>
                      Process and complete your unit reservation transaction and
                      all other necessary transaction and communication relating
                      to your unit reservation;
                    </li>
                    <li>
                      Disclose your Personal Data to the relevant governmental
                      authorities or third parties where required by law or for
                      legal purposes.
                    </li>
                  </ul>
                </div>
                <div className="content">
                  You further acknowledge that the personal data provided is
                  made voluntarily and is accurate and complete and is necessary
                  and you hereby understand that if you do not provide the
                  personal data requested, Property365 will not be able to carry
                  out the purposes set out above.
                </div>
                <div className="content">
                  Should there be any changes to any of your personal data, you
                  shall notify Property365 immediately.
                </div>
                <div className="content">
                  Where you provide any personal data for and on behalf of
                  another, you covenant that you have obtain the consent of the
                  data provider and that the personal data is given voluntarily,
                  accurately and complete; failing which you shall indemnify the
                  company against any claims from such data provider.
                </div>
                <div className="content">
                  For the avoidance of doubt, Personal Data includes all data
                  defined within the Personal Data Protection Act 2010 including
                  all data you had disclosed to Property365 in this Form.
                </div>
                <div className="content">
                  You may request in writing for access to and to request for
                  correction of personal data in accordance with the PDPA. In
                  the event of such request or if you have an inquiry or
                  complaint in respect our handling of such personal data,
                  kindly contact us at support@property365.my.
                </div>
                <div className="content">
                  We reserve the right to update and amend this policy from time
                  to time. Any material changes will be posted on our website
                  with the updated Privacy Policy and therefore, we encourage
                  you to check or visit the same from time to time for any
                  changes on a regular basis.
                </div>
                <div className="title">Our Contact Details</div>
                <div className="content">
                  Should you have queries, concerns or complaints in relation to
                  this Privacy Policy, kindly contact us during office hours
                  between 9:00am to 6:00pm, Mondays to Fridays at the following
                  contact points:
                </div>
                <div className="m-t-20">
                  <small>Telephone: </small>+603-7886 2963
                </div>
                <div>
                  <small>Email: </small>support@property365.my
                </div>
                <div className="m-b-20">
                  <small>Address: </small>No. 19-4, Jalan PJU 1/42A, Dataran
                  Prima, 47301 Petaling Jaya, Selangor, Malaysia.
                </div>
                <i> Last update: 10 April 2017 </i>
              </ColumnFlex>
            </ContainerX>
          </ColumnFlex>
        </ContainerX>
      </div>
      <div
        style={{
          width: "100%",
          position: "fixed",
          bottom: "0px",
          height: "80px",
          backgroundColor: "rgba(232, 235, 245, 0.8)",
          zIndex: 22,
        }}
      >
        <RowFlex crossAxisAlignment="center" className="fullheight">
          <Button
            rounded
            className={"disable-full-uppercase color-text"}
            style={{
              minWidth: "200px",
              maxWidth: "500px",
              width: "50%",
              margin: "0 auto",
            }}
            onClick={() => {
              setProjectRequiredCheckbox((prev) => ({ ...prev, pdpa: true }));
              setIsOpenPDPAconsent(false);
            }}
          >
            Agree
          </Button>
        </RowFlex>
      </div>
    </>
  );
};

export default PDPAconsent;
