import CardNeu from "../../../components/Card/Card";
import { RowGrid } from "../../../components/LayoutX/Grid/RowGrid";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Icon } from "@mui/material";
import newsImage01 from "../../../assets/images/background/weatherbg.jpg";
import locationIcon from "../../../assets/images/icon/location-icon.png";
import typeIcon from "../../../assets/images/icon/type-icon.png";
import sqftIcon from "../../../assets/images/icon/sqft-icon.png";
import {
  SalesAccountQuery,
  useProjectListingQuery,
} from "../../../generated/graphql";
import TextX from "../../../components/general/TextX";
import { Button } from "ui-neumorphism";
import { DASHBOARDS_PATH } from "../Dashboards/DashboardRoutes";
import { GENERAL_PATH } from "../GeneralPages/GeneralRoutes";
import { useNavigate } from "react-router";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import { useSearchParams } from "react-router-dom";
import { getSwiperSalesIdCookie } from "../../../containers/helpers/salesIdCookie";
import { projectCardChangeColor } from "../../../containers/context/ProjectCardContext";
import { textChangeColor } from "../../../containers/context/ContainerXContext";
import { useContext } from "react";

type ProjectListingProps = {
  salesAccData: SalesAccountQuery | undefined;
};

const ProjectListing: React.FC<ProjectListingProps> = ({ salesAccData }) => {
  const {
    data: projectListData,
    loading: projectListLoading,
    error: projectListError,
  } = useProjectListingQuery();

  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const { projectCardColor } = projectCardChangeColor();
  const { textColor } = textChangeColor();
  const handleJumpToPageDetail = (projectId) => {
    const currentSwiperSaleId = getSwiperSalesIdCookie();
    const registeredSaleAcc = salesAccData?.getSalesAccount.find((saleAcc) => {
      return saleAcc.SaleID === currentSwiperSaleId;
    });

    if (registeredSaleAcc) {
      const contactId = registeredSaleAcc?.AccountID;
      navigation(
        `${DASHBOARDS_PATH.PAGE_DETAIL.replace(
          ":accountCode",
          getPathCookie()
        )}?projectId=${projectId}&contactId=${contactId}&isIntegrate=${registeredSaleAcc?.IsIntegrate}`
      );
    } else {
      navigation(
        `${DASHBOARDS_PATH.PAGE_DETAIL.replace(
          ":accountCode",
          getPathCookie()
        )}?projectId=${projectId}`
      );
    }
  };

  return (
    <ColumnFlex fullWidth gap="15px">
      <span className="fw-700 fs-12" style={{ color: textColor }}>
        You May Be Interested
      </span>
      <ColumnFlex fullWidth gap="30px">
        {projectListData?.getProjectListing.map((project) => (
          <CardNeu
            key={project.project_id}
            style={{ background: projectCardColor }}
          >
            <div
              onClick={() => handleJumpToPageDetail(project.project_id)}
              className="click-able"
            >
              <ColumnFlex
                fullWidth
                gap="20px"
                className="p-12 fs-10 fw-500 color-text-2"
                crossAxisAlignment="center"
              >
                <img
                  src={project.resource_url || newsImage01}
                  alt="projectImg"
                  className="project-listing-img"
                />
                <ColumnFlex fullWidth gap="4px">
                  <span
                    className="fw-700 fs-12 color-text"
                    style={{ color: textColor }}
                  >
                    {project.project_name || "-"}
                  </span>
                  {/* <RowGrid gridTemplateColumns="1fr 1fr 1fr" gap="5px"> */}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      columnGap: "14px",
                      rowGap: "5px",
                    }}
                  >
                    <div style={{ flex: "auto" }}>
                      <RowFlex crossAxisAlignment="flex-start" gap="4px">
                        <img src={locationIcon} alt="location_icon" />
                        <span>{project.city_name || "-"}</span>
                      </RowFlex>
                    </div>
                    <div style={{ flex: "auto" }}>
                      <RowFlex crossAxisAlignment="flex-start" gap="4px">
                        <img src={typeIcon} alt="location_icon" />
                        <span>{project.property_type_desc || "-"}</span>
                      </RowFlex>
                    </div>
                    <div style={{ flex: "auto" }}>
                      <RowFlex crossAxisAlignment="flex-start" gap="4px">
                        <img src={sqftIcon} alt="location_icon" />
                        <span>fr.{project.min_sqft || "0"} sq .ft</span>
                      </RowFlex>
                    </div>
                  </div>
                  {/* </RowGrid> */}
                  <RowFlex
                    className="color-text-light-blue"
                    crossAxisAlignment="center"
                    gap="4px"
                  >
                    <MonetizationOnIcon
                      sx={{ width: "12px" }}
                      className="color-text-light-blue"
                    />
                    <span>
                      From{" "}
                      <TextX
                        data={project.project_min_price}
                        dataType="Number"
                        className="fw-700"
                      />
                    </span>
                  </RowFlex>
                </ColumnFlex>
              </ColumnFlex>
            </div>
          </CardNeu>
        ))}
      </ColumnFlex>
    </ColumnFlex>
  );
};

const projectItems = [1, 2, 3, 4];

export default ProjectListing;
