import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Avatar, Button as Mui_Button, styled } from "@mui/material";
import CardNeu from "../../../components/Card/Card";
import {
  useProfileQuery,
  useUpdateProfileMutation,
} from "../../../generated/graphql";
import { useNavigate, useParams } from "react-router";
import TextFieldNeu from "../../../components/TextField/TextFieldNeu";
import { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, {
  Country,
  isValidPhoneNumber,
} from "react-phone-number-input";
import UploadIcon from "@mui/icons-material/Upload";
import { useSnackbar } from "react-simple-snackbar";
import { OTHERS_PATH } from "./OthersRoutes";
import { AttachmentHelper } from "../../../containers/helpers/AttachmentHelper";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import { fetchCountryCode } from "../../../containers/helpers/GetGeoIp";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import { headerChangeColor } from "../../../containers/context/Context";
import { cardChangeColor } from "../../../containers/context/CardContext";
import { inputFieldChangeColor } from "../../../containers/context/InputFieldContext";

import { useContext } from "react";

type profileFormType = {
  name: string;
  email: string;
  phoneNo: string;
  avatar?: string;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const acceptFileType = ["image/gif", "image/png", "image/jpg", "image/jpeg"];
const acceptString = acceptFileType.join(",");

const EditProfile = () => {
  const {
    data: profileData,
    loading: getProfileLoading,
    error: getProfileError,
  } = useProfileQuery();

  const [
    updateProfile,
    {
      data: updateProfileResult,
      loading: updateProfileLoading,
      error: updateProfileError,
    },
  ] = useUpdateProfileMutation({
    onCompleted: () => {
      openSnackbar("Profile Updated Successfully");
      navigation(
        OTHERS_PATH.PROFILE.replace(":accountCode", accountCode || "")
      );
    },
  });

  const [openSnackbar, closeSnackbar] = useSnackbar();
  const navigation = useNavigate();
  const { accountCode } = useParams();
  const [profileForm, setProfileForm] = useState<profileFormType>({
    name: "",
    email: "",
    phoneNo: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [profileNameHelperText, setProfileNameHelperText] = useState(null);
  const [phoneNoHelperText, setPhoneNoHelperText] = useState(null);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();
  const { inputFieldColor } = inputFieldChangeColor();

  useEffect(() => {
    fetchCountryCode().then((code) => {
      setCountryCode(code);
    });
  }, []);

  useEffect(() => {
    if (profileData && profileData?.profile) {
      setProfileForm({
        name: profileData.profile.Name,
        email: profileData.profile.Email,
        phoneNo: profileData.profile.PhoneNo,
        avatar: profileData.profile.ProfilePic,
      });
    }
  }, [profileData]);

  const handleSubmitEditProfile = async () => {
    if (
      profileForm.phoneNo === "" ||
      profileForm.phoneNo === undefined ||
      profileForm.phoneNo === null
    ) {
      openSnackbar("Phone Number is required");
      return;
    }
    if (!isValidPhoneNumber(profileForm.phoneNo)) {
      openSnackbar("Invalid Phone Number");
      return;
    }
    if (profileForm.name === "") {
      openSnackbar("Profile Name is required");
      return;
    }
    if (profileForm.email === "") {
      openSnackbar("Email is required");
      return;
    }

    if (profileForm.avatar === profileData?.profile?.ProfilePic) {
      await updateProfile({
        variables: {
          input: {
            name: profileForm.name,
            email: profileForm.email,
            phoneNo: profileForm.phoneNo,
          },
        },
      });
    } else {
      await updateProfile({
        variables: {
          input: {
            name: profileForm.name,
            email: profileForm.email,
            phoneNo: profileForm.phoneNo,
            avatar: profileForm.avatar,
          },
        },
      });
    }
  };

  if (getProfileLoading) {
    return <></>;
  }

  const handleProfileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || !value) {
      setProfileNameHelperText("Profile Name is required");
    } else {
      setProfileNameHelperText(null);
    }

    setProfileForm({ ...profileForm, name: value });
  };

  const fileChangedHandler = (event) => {
    const attachmentHelper = new AttachmentHelper(acceptFileType, 25);
    const file = event.target.files[0];
    if (!attachmentHelper.checkFile(file)) {
      openSnackbar(
        `Uploaded file must be an image and less than ${attachmentHelper.maxFileSize} MB.`
      );
      return;
    }
    setProfileForm({ ...profileForm, avatar: file });
    let reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  if (profileData) {
    return (
      <ColumnFlex fullWidth crossAxisAlignment="center" gap="40px">
        <GeneralHeader
          title="Edit Profile"
          backTo={OTHERS_PATH.PROFILE.replace(":accountCode", getPathCookie())}
          style={{ background: headerColor }}
        />
        <div style={{ width: "100%" }}>
          <CardNeu style={{ padding: "20px", background: cardColor }}>
            <ColumnFlex fullWidth crossAxisAlignment="center">
              <div className="avatarWithButtonContainer">
                <Avatar
                  src={imagePreview || profileForm.avatar}
                  sx={{ height: "120px", width: "120px" }}
                />
                <Mui_Button
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  className="button"
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                >
                  <UploadIcon fontSize="large" className="icon" />
                  <VisuallyHiddenInput
                    type="file"
                    accept={acceptString}
                    onChange={fileChangedHandler}
                  />
                </Mui_Button>
              </div>
              <ColumnFlex fullWidth gap="3px" className="m-b-10">
                <span className="fs-12 color-text-2">Profile Name *</span>
                <div className="text-field-font-style neu-text-field-style fullwidth">
                  <input
                    type="text"
                    value={profileForm.name}
                    onChange={handleProfileName}
                    className="fullwidth"
                    style={{ background: inputFieldColor }}
                  />
                </div>
                <span className="fs-12 color-red fw-400">
                  {profileNameHelperText}
                </span>
              </ColumnFlex>

              <ColumnFlex fullWidth gap="3px">
                <span className="fs-12 color-text-2">Email</span>
                <TextFieldNeu
                  value={profileForm.email}
                  disabled
                  label={profileForm.email}
                  className="text-field-font-style"
                />
              </ColumnFlex>
              <ColumnFlex fullWidth gap="3px">
                <span className="fs-12 color-text-2">Mobile No *</span>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={profileForm.phoneNo}
                  onChange={(value) => {
                    if (value === undefined) {
                      setPhoneNoHelperText("Mobile No. is required");
                    } else if (!isValidPhoneNumber(value)) {
                      setPhoneNoHelperText("Invalid Mobile No.");
                    } else {
                      setPhoneNoHelperText(null);
                    }

                    setProfileForm({ ...profileForm, phoneNo: value || "" });
                  }}
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry={(countryCode as Country) || "MY"}
                  className="text-field-font-style neu-text-field-style"

                  // style={{
                  //   width: "100%",
                  //   minWidth: "200px",
                  //   maxWidth: "300px",
                  // }}
                />
                <span className="fs-12 color-red fw-400">
                  {phoneNoHelperText}
                </span>
              </ColumnFlex>
            </ColumnFlex>
          </CardNeu>
        </div>
        <Button
          className="disable-full-uppercase font-family-strict color-text-light-blue fw-600-strict"
          onClick={handleSubmitEditProfile}
          style={{ minWidth: "200px" }}
          disabled={updateProfileLoading}
        >
          Save
        </Button>
      </ColumnFlex>
    );
  }
};

export default EditProfile;
