import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import CardNeu from "../../../../components/Card/Card";
import { useEffect, useState } from "react";
import { GENERAL_PATH } from "../../../../containers/modules/GeneralPages/GeneralRoutes";
import ContainerX from "../../../../components/general/ContainerX";
import {
  useGetTownshipDetailsLazyQuery,
} from "../../../../generated/graphql";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

const TownshipDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const saleId = searchParams.get("saleId");
  const [details, setDetails] = useState<any>(null);
  const projectId = searchParams.get("projectId");
  const isIntegrate = searchParams.get("isIntegrate");
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();
  const [
    getTownshipDetails,
    { data: getTownshipDetailsData, loading: getTownshipDetailsLoading },
  ] = useGetTownshipDetailsLazyQuery({
      onCompleted: (data) => {
        if(data?.getTownshipDetails)
          setDetails(data.getTownshipDetails);
      },
    });

  useEffect(() => {
    if (
      !projectId ||
      !saleId ||
      isIntegrate === undefined ||
      isIntegrate === null ||
      isIntegrate === "" ||
      isIntegrate === "null" ||
      isIntegrate === "undefined"
    ) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
      return;
    }

    getTownshipDetails({
      variables: {
        projectId,
      },
    });
  }, []);

  return (
    <ColumnFlex fullWidth gap="24px">
      <GeneralHeader title="Township Details" style={{ background: headerColor}}/>

      <CardNeu style={{ background: cardColor}}>
        <ContainerX padding={{ all: "12px" }}>
          <ColumnFlex fullWidth gap="14px">
            <span className="fw-700 fs-12 color-text">Township : {details?.TownshipName || '-'}</span>
            <RowGrid>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Person-in-Charge (PIC)
                  </span>
                  <span className="fw-600 fs-12 color-text">
                    {details?.PICName || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Email
                  </span>
                  <span className="fw-600 fs-12 color-text">
                    {details?.PICEmail || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">Contact Number</span>
              <span className="fw-600 fs-12 color-text">
                {details?.PICContactNo || '-'}
              </span>
            </ColumnFlex>
          </ColumnFlex>
        </ContainerX>
      </CardNeu>
    </ColumnFlex>
  );
};

export default TownshipDetails;
