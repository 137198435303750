import CardNeu from "../../../../components/Card/Card";
import ContainerX from "../../../../components/general/ContainerX";
import Slider from "react-slick";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { useState } from "react";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import ZoomedImageDialog from "../../../../components/Dialog/ZoomedImageDialog";

type ImageSwiperProps = {
  attachments: { resourceUrl: string }[];
};

const ImageSwiper = ({ attachments }) => {
  const [zoomedImageIndex, setZoomedImageIndex] = useState(-1); //-1 means no zoomed image
  const [mouseDownTime, setMouseDownTime] = useState(null);

  const handleMouseDown = () => {
    setMouseDownTime(new Date().getTime());
  };

  const handleMouseUp = (index) => {
    const mouseUpTime = new Date().getTime();
    const timeDifference = mouseUpTime - mouseDownTime;
    if (timeDifference < 90) {
      setZoomedImageIndex(index);
    }
    setMouseDownTime(null);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => (
      <RowFlex fullWidth mainAxisAlignment="center" crossAxisAlignment="center">
        <ul id="dots" className={`custom-dots-container `}>
          {dots}
        </ul>
      </RowFlex>
    ),
  };

  return (
    <ColumnFlex fullWidth overflow="hidden">
      <Slider {...settings}>
        {attachments.map((item, index) => (
          <ContainerX key={item.resourceUrl} className="inherit-border-radius">
            <img
              src={item?.resourceUrl}
              alt=""
              className="site-progress-img"
              // onClick={() => setZoomedImageIndex(index)}
              onMouseDown={handleMouseDown}
              onMouseUp={() => handleMouseUp(index)}
            />
            <ZoomedImageDialog
              visible={zoomedImageIndex === index}
              onClose={() => setZoomedImageIndex(-1)}
              src={item.resourceUrl}
            />
          </ContainerX>
        ))}
      </Slider>
      <ContainerX
        width={"100%"}
        height={"30px"}
        backgroundColor="#e5e8f7"
      ></ContainerX>
    </ColumnFlex>
  );
};

export default ImageSwiper;
