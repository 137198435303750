import React, { Suspense } from "react";
import { FC } from "react";
import { Navigate, Outlet, Route, useLocation } from "react-router";
import { getAccessToken } from "../helpers/AccessToken";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import {
  AUTH_PATH,
  Invalid_Auth_Path,
} from "../modules/Authentication/AuthRoutes";
import { useCheckAccountCodeValidQuery } from "../../generated/graphql";
import { DASHBOARDS_PATH } from "../../containers/modules/Dashboards/DashboardRoutes";
import { getPathCookie } from "../../containers/helpers/PathCookie";
import { GENERAL_PATH } from "../../containers/modules/GeneralPages/GeneralRoutes";

export const AuthGuard = () => {
  const { accountCode } = useParams();
  const AccessToken = getAccessToken();
  const location = useLocation();

  const { data: isValidAccountCodeResponse, error: isInvalidAccountCode } =
    useCheckAccountCodeValidQuery({
      variables: { accountCode: accountCode || "" },
    });

  if (isInvalidAccountCode && getAccessToken() && getPathCookie()) {
    // if user input invalid account code and already logged in, automatic fix the account code
    return (
      <Navigate
        to={DASHBOARDS_PATH.DASHBOARD.replace(":accountCode", getPathCookie())}
        replace
      />
    );
  }

  if (isInvalidAccountCode && !getAccessToken()) {
    // if user input invalid account code and not logged in, redirect to login page with the invalid account code
    return (
      <Navigate
        to={AUTH_PATH.LOGIN.replace(":accountCode", accountCode)}
        replace
      />
    );
  }

  if (
    AccessToken &&
    !isInvalidAccountCode &&
    location.pathname === `/${accountCode}`
  ) {
    return (
      <Navigate
        to={DASHBOARDS_PATH.DASHBOARD.replace(
          ":accountCode",
          accountCode || ""
        )}
      />
    );
  } else if (AccessToken && !isInvalidAccountCode) {
    return (
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    );
  } else {
    return accountCode ? (
      <Navigate
        to={AUTH_PATH.LOGIN.replace(":accountCode", accountCode)}
        replace
      />
    ) : (
      <Navigate to={GENERAL_PATH.NOT_FOUND_404} />
    );
  }
};
