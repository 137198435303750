import { Button, IconButton } from "ui-neumorphism";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { useNavigate } from "react-router";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useSearchParams } from "react-router-dom";
import CardNeu from "../../../../components/Card/Card";
import { useEffect } from "react";
import { GENERAL_PATH } from "../../../../containers/modules/GeneralPages/GeneralRoutes";
import ContainerX from "../../../../components/general/ContainerX";
import {
  useGetSaleAttachmentLazyQuery,
  useGetSaleSigningInfoLazyQuery,
} from "../../../../generated/graphql";
import imageIcon from "../../../../assets/images/icon/image-icon.svg";
import TextX from "../../../../components/general/TextX";
import GoogleMapIcon from "../../../../assets/images/icon/google-map.png";
import PhoneIcon from "../../../../assets/images/icon/call.png";
import EmailIcon from "../../../../assets/images/icon/email.png";
import MapActionIcon from "../../../../assets/images/icon/map-action.png";
import CallActionIcon from "../../../../assets/images/icon/call-action.png";
import MailActionIcon from "../../../../assets/images/icon/mail-action.png";

import GeneralHeader from "../../../../components/Header/GeneralHeader";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

import { useContext } from "react";

const SigningInformation = () => {
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const saleId = searchParams.get("saleId");
  const projectId = searchParams.get("projectId");
  const isIntegrate = searchParams.get("isIntegrate");
  const [
    getSaleSigningInfo,
    { data: saleSigningInfoData, loading: saleSigningInfoLoading },
  ] = useGetSaleSigningInfoLazyQuery();
  useEffect(() => {
    if (
      !projectId ||
      !saleId ||
      isIntegrate === undefined ||
      isIntegrate === null ||
      isIntegrate === "" ||
      isIntegrate === "null" ||
      isIntegrate === "undefined"
    ) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getSaleSigningInfo({
        variables: {
          saleId: saleId,
          projectId: projectId,
          isIntegrate: isIntegrate === "true" ? true : false,
        },
      });
    }
  }, []);

  if (saleSigningInfoData?.getSaleSigningInfo) {
    return (
      <ColumnFlex fullWidth gap="34px">
        <GeneralHeader
          title="Signing Information"
          style={{ background: headerColor }}
        />

        {saleSigningInfoData?.getSaleSigningInfo?.DevSolicitor
          .DevLawyerName && (
          <CardNeu rounded style={{ background: cardColor }}>
            <ContainerX padding={{ all: "18px" }}>
              <ColumnFlex>
                <span className="fw-700 fs-16 color-text text-with-shadow">
                  {
                    saleSigningInfoData?.getSaleSigningInfo?.DevSolicitor
                      ?.DevLawyerName
                  }
                </span>
                <span className="fs-10 fw-500 color-text-2">
                  Developer Solicitor
                </span>
                <RowFlex crossAxisAlignment="center" gap="2px">
                  <img
                    src={GoogleMapIcon}
                    alt="googleMapIcon"
                    width="50px"
                    className="click-able"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          saleSigningInfoData.getSaleSigningInfo.DevSolicitor
                            ?.DevLawyerAddress
                        )}`
                      );
                    }}
                  />
                  <span className="fw-600 fs-12 color-text">
                    {saleSigningInfoData.getSaleSigningInfo.DevSolicitor
                      ?.DevLawyerAddress || "-"}
                  </span>
                </RowFlex>
                <RowFlex crossAxisAlignment="center" gap="2px">
                  <a
                    href={`tel:${saleSigningInfoData.getSaleSigningInfo.DevSolicitor?.DevLawyerPhone}`}
                  >
                    <img src={PhoneIcon} alt="phoneIcon" width="50px" />
                  </a>
                  <span className="fw-600 fs-12 color-text">
                    {saleSigningInfoData.getSaleSigningInfo.DevSolicitor
                      ?.DevLawyerPhone || "-"}
                  </span>{" "}
                </RowFlex>
                <RowFlex crossAxisAlignment="center" gap="2px">
                  <a
                    href={`mailto:${saleSigningInfoData.getSaleSigningInfo.DevSolicitor?.DevLawyerEmail}`}
                  >
                    <img src={EmailIcon} alt="emailIcon" width={"50px"} />
                  </a>
                  <span className="fw-600 fs-12 color-text">
                    {saleSigningInfoData.getSaleSigningInfo.DevSolicitor
                      ?.DevLawyerEmail || "-"}
                  </span>
                </RowFlex>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>
        )}

        {/* Buyer Section */}
        {saleSigningInfoData?.getSaleSigningInfo?.DevSolicitor
          .BuyerLawyerName && (
          <CardNeu rounded style={{ background: cardColor }}>
            <ContainerX padding={{ all: "18px" }}>
              <ColumnFlex>
                <span className="fw-700 fs-16 color-text text-with-shadow">
                  {
                    saleSigningInfoData?.getSaleSigningInfo?.DevSolicitor
                      ?.BuyerLawyerName
                  }
                </span>
                <span className="fs-10 fw-500 color-text-2">
                  Buyer Solicitor
                </span>
                <RowFlex crossAxisAlignment="center" gap="2px">
                  <img
                    src={GoogleMapIcon}
                    alt="googleMapIcon"
                    width="50px"
                    className="click-able"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          saleSigningInfoData.getSaleSigningInfo.DevSolicitor
                            ?.BuyerLawyerAddress
                        )}`
                      );
                    }}
                  />
                  <span className="fw-600 fs-12 color-text">
                    {saleSigningInfoData.getSaleSigningInfo.DevSolicitor
                      ?.BuyerLawyerAddress || "-"}
                  </span>
                </RowFlex>
                <RowFlex crossAxisAlignment="center" gap="2px">
                  <a
                    href={`tel:${saleSigningInfoData.getSaleSigningInfo.DevSolicitor?.BuyerLawyerPhone}`}
                  >
                    <img src={PhoneIcon} alt="phoneIcon" width="50px" />
                  </a>
                  <span className="fw-600 fs-12 color-text">
                    {saleSigningInfoData.getSaleSigningInfo.DevSolicitor
                      ?.BuyerLawyerPhone || "-"}
                  </span>{" "}
                </RowFlex>
                <RowFlex crossAxisAlignment="center" gap="2px">
                  <a
                    href={`mailto:${saleSigningInfoData.getSaleSigningInfo.DevSolicitor?.BuyerLawyerEmail}`}
                  >
                    <img src={EmailIcon} alt="emailIcon" width={"50px"} />
                  </a>
                  <span className="fw-600 fs-12 color-text">
                    {saleSigningInfoData.getSaleSigningInfo.DevSolicitor
                      ?.BuyerLawyerEmail || "-"}
                  </span>
                </RowFlex>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>
        )}

        {/* End Financier Solicitor Details */}
        {saleSigningInfoData?.getSaleSigningInfo?.FinancierDetails.length > 0 &&
          saleSigningInfoData?.getSaleSigningInfo?.FinancierDetails.map(
            (financier) => (
              <CardNeu rounded style={{ background: cardColor }}>
                <ContainerX padding={{ all: "18px" }}>
                  <ColumnFlex gap="20px">
                    <ColumnFlex>
                      <span className="fw-700 fs-16 color-text text-with-shadow">
                        {financier?.FinancierName}
                      </span>
                      <span className="fs-10 fw-500 color-text-2">
                        End Financier
                      </span>
                      <RowFlex crossAxisAlignment="center" gap="2px">
                        <img
                          src={GoogleMapIcon}
                          alt="googleMapIcon"
                          width="50px"
                          className="click-able"
                          onClick={() => {
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                financier.FinancierAddress
                              )}`
                            );
                          }}
                        />
                        <span className="fw-600 fs-12 color-text">
                          {financier.FinancierAddress || "-"}
                        </span>
                      </RowFlex>
                      <RowFlex crossAxisAlignment="center" gap="2px">
                        <a href={`tel:${financier.FinancierPhone}`}>
                          <img src={PhoneIcon} alt="phoneIcon" width="50px" />
                        </a>
                        <span className="fw-600 fs-12 color-text">
                          {financier.FinancierPhone || "-"}
                        </span>{" "}
                      </RowFlex>
                      <RowFlex crossAxisAlignment="center" gap="2px">
                        <a href={`mailto:${financier.FinancierEmail}`}>
                          <img src={EmailIcon} alt="emailIcon" width={"50px"} />
                        </a>
                        <span className="fw-600 fs-12 color-text">
                          {financier.FinancierEmail || "-"}
                        </span>
                      </RowFlex>
                    </ColumnFlex>

                    {/* Financier Solicitor Information */}
                    {(financier?.FinancierLawyerName ||
                      financier?.FinancierLawyerReferenceNo ||
                      financier?.FinancierLawyerAddress ||
                      financier?.FinancierLawyerPhone ||
                      financier?.FinancierLawyerEmail) && (
                      <ColumnFlex>
                        {financier?.FinancierLawyerName && (
                          <span className="fw-700 fs-16 color-text text-with-shadow">
                            {financier.FinancierEmail || "-"}
                          </span>
                        )}
                        {financier?.FinancierLawyerReferenceNo && (
                          <ColumnFlex gap="4px">
                            <span className="fs-10 fw-500 color-text-2">
                              Financier Solicitor
                            </span>
                            <span className="fw-600 fs-12 color-text">
                              {financier.FinancierLawyerReferenceNo || "-"}
                            </span>
                          </ColumnFlex>
                        )}

                        {financier?.FinancierLawyerAddress && (
                          <RowFlex crossAxisAlignment="center" gap="2px">
                            <img
                              src={MapActionIcon}
                              alt="googleMapIcon"
                              width="50px"
                              style={{ padding: 10 }}
                              className="click-able"
                              onClick={() => {
                                window.open(
                                  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                    financier.FinancierLawyerAddress
                                  )}`
                                );
                              }}
                            />
                            <span className="fw-600 fs-12 color-text">
                              {financier.FinancierLawyerAddress || "-"}
                            </span>
                          </RowFlex>
                        )}

                        {financier?.FinancierLawyerPhone && (
                          <RowFlex crossAxisAlignment="center" gap="2px">
                            <a href={`tel:${financier.FinancierLawyerPhone}`}>
                              <img
                                src={CallActionIcon}
                                alt="phoneIcon"
                                width="50px"
                                style={{ padding: 10 }}
                              />
                            </a>
                            <span className="fw-600 fs-12 color-text">
                              {financier.FinancierLawyerPhone || "-"}
                            </span>{" "}
                          </RowFlex>
                        )}

                        {financier?.FinancierLawyerEmail && (
                          <RowFlex crossAxisAlignment="center" gap="2px">
                            <a
                              href={`mailto:${financier.FinancierLawyerEmail}`}
                            >
                              <img
                                src={MailActionIcon}
                                alt="emailIcon"
                                width={50}
                                style={{ padding: 10 }}
                              />
                            </a>
                            <span className="fw-600 fs-12 color-text">
                              {financier.FinancierLawyerEmail || "-"}
                            </span>
                          </RowFlex>
                        )}
                      </ColumnFlex>
                    )}
                  </ColumnFlex>
                </ContainerX>
              </CardNeu>
            )
          )}
      </ColumnFlex>
    );
  }
};

export default SigningInformation;
