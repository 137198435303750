import { Pagination } from "@mui/material";

type CustomPaginationProps = {
  currentPage: number;
  totalPages: number;
  limit: number;
  setCurrentPage: (page: number) => void;
};

const CustomPagination: React.FC<CustomPaginationProps> = ({
  currentPage,
  totalPages,
  limit,
  setCurrentPage,
}) => {
  const count = Math.ceil(totalPages / limit);
  return (
    <Pagination
      count={count}
      page={currentPage}
      onChange={(e, value) => setCurrentPage(value)}
    />
  );
};

export default CustomPagination;
