import { RouteObject } from "react-router";
import PaymentConfirm from "./PaymentConfirm";
import SelectBank from "./SelectBank";
import PaymentResult from "./PaymentResult";

export const PAYMENT_PATH = {
  PAYMENT_CONFIRM_IPAY88: `/:accountCode/payment/ipay88`,
  PAYMENT_CONFIRM_REVENUE_MONSTER: `/:accountCode/payment/revenuemonster`,
  PAYMENT_SELECT_BANK: `/:accountCode/payment/select-bank`,
  PAYMENT_SELECT_EWALLET: `/:accountCode/payment/select-ewallet`,
  PAYMENT_RESULT: `/:accountCode/payment/payment-result`,
} as const;

export enum PaymentType {
  IPAY88 = "ipay88",
  REVENUE_MONSTER = "revenuemonster",
  EWALLET = "E-wallet",
  BANK = "Bank",
}

const PaymentRoutes: RouteObject[] = [
  {
    path: PAYMENT_PATH.PAYMENT_CONFIRM_IPAY88,
    element: <PaymentConfirm paymentType={PaymentType.IPAY88} />,
  },
  {
    path: PAYMENT_PATH.PAYMENT_CONFIRM_REVENUE_MONSTER,
    element: <PaymentConfirm paymentType={PaymentType.REVENUE_MONSTER} />,
  },
  {
    path: PAYMENT_PATH.PAYMENT_SELECT_BANK,
    element: <SelectBank paymentType={PaymentType.BANK} />,
  },
  {
    path: PAYMENT_PATH.PAYMENT_SELECT_EWALLET,
    element: <SelectBank paymentType={PaymentType.EWALLET} />,
  },
  {
    path: PAYMENT_PATH.PAYMENT_RESULT,
    element: <PaymentResult />,
  },
];

export default PaymentRoutes;
