import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import {
  ProjectDetailQuery,
  ProjectYoutubeLinkOutput,
  PublicProjectDetailQuery,
} from "../../../generated/graphql";
import { Positioned } from "../../../components/LayoutX/Stack";
import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
  PlayArrow,
  PlayArrowRounded,
} from "@mui/icons-material";
import Slider from "react-slick";
import { StackItem, StackX } from "../../../components/LayoutX/StackLayout";
import SimpleDialog from "../../../components/Dialog/SimpleDialog";
import { useEffect, useState } from "react";
import ZoomedImageDialog from "../../../components/Dialog/ZoomedImageDialog";
import { useSnackbar } from "react-simple-snackbar";
import {
  isDesktop_minWidth,
  useMediaQuery,
} from "../../../useHook/useMediaQuery";
import { cardChangeColor } from "../../context/CardContext";

type SalesGallerySwiperProps = {
  projectDetailsData?: ProjectDetailQuery;
  publicProjectDetailData?: PublicProjectDetailQuery;
};

enum VideoType {
  YOUTUBE = "Youtube",
  VIMEO = "Vimeo",
  GOOGLE_DRIVE = "GoogleDrive",
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={" swiper-custom-btn box-shadow-dark"}
      style={{ right: "6px" }}
      onClick={onClick}
    >
      <NavigateNextRounded fontSize="large" className="color-text" />
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={"swiper-custom-btn box-shadow-dark"}
      style={{ left: "6px" }}
      onClick={onClick}
    >
      <NavigateBeforeRounded fontSize="large" className="color-text" />
    </div>
  );
};

function combineArrays(
  arr1: ProjectYoutubeLinkOutput[],
  arr2: ProjectYoutubeLinkOutput[],
  arr3: ProjectYoutubeLinkOutput[]
): ProjectYoutubeLinkOutput[] {
  const combinedArray: ProjectYoutubeLinkOutput[] = [];

  if (arr1?.length > 0) {
    combinedArray.push(...arr1);
  }

  if (arr2?.length > 0) {
    combinedArray.push(...arr2);
  }

  if (arr3?.length > 0) {
    combinedArray.push(...arr3);
  }

  return combinedArray;
}

const ProjectSwiper: React.FC<SalesGallerySwiperProps> = ({
  projectDetailsData,
  publicProjectDetailData,
}) => {
  if (!projectDetailsData && !publicProjectDetailData) {
    return null;
  }

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const {
    embededYoutubeLink,
    galleryImage,
    virtualModel,
    embededVimeoLink,
    embededGoogleDriveLink,
  } = projectDetailsData?.getProjectById
    ? projectDetailsData?.getProjectById
    : publicProjectDetailData?.getPublicProjectById;
  const mediaLinkItem = combineArrays(
    embededGoogleDriveLink,
    embededVimeoLink,
    embededYoutubeLink
  );

  const [open3DTourDialog, setOpen3DTourDialog] = useState(false);
  const [mouseDownTime, setMouseDownTime] = useState(null);
  const [isZoomImage, setIsZoomImage] = useState(-1); // -1 means no image is zoomed
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { cardColor } = cardChangeColor();

  const totalSlides = mediaLinkItem?.length + galleryImage?.length;
  const handleClose3DTourDialog = () => {
    setOpen3DTourDialog(false);
  };

  const handleOpen3DTourDialog = () => {
    if (virtualModel.length === 0) {
      openSnackbar("There's no 3D tour available!");
      return;
    }

    setOpen3DTourDialog(true);
  };

  const handleMouseDown = () => {
    setMouseDownTime(new Date().getTime());
  };

  const handleZoomImage = (index) => {
    const mouseUpTime = new Date().getTime();
    const timeDifference = mouseUpTime - mouseDownTime;
    if (timeDifference < 90) {
      setIsZoomImage(index);
    }
    setMouseDownTime(null);
  };

  const handleJumpVideoResource = (videoObj) => {
    const mouseUpTime = new Date().getTime();
    const timeDifference = mouseUpTime - mouseDownTime;
    if (timeDifference < 110) {
      handleOpenVideoResource(videoObj);
    }
    setMouseDownTime(null);
  };

  const settings = {
    dots: totalSlides > 1,
    infinite: totalSlides > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: totalSlides > 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    afterChange: (current) => setCurrentSlideIndex(current),
    appendDots: (dots) => (
      <RowFlex fullWidth mainAxisAlignment="center" crossAxisAlignment="center">
        <ul
          id="dots"
          className={`custom-dots-container ${
            currentSlideIndex + 1 >= 10 ? "overflow-active" : ""
          }`}
        >
          {dots}
        </ul>
      </RowFlex>
    ),
  };

  const handleOpenVideoResource = (videoObj) => {
    if (videoObj.videoType === VideoType.YOUTUBE) {
      let splittedLink = videoObj.url.split("/");
      let youtubeId = splittedLink[splittedLink.length - 1];
      window.open(`https://www.youtube.com/watch?v=${youtubeId}`);
    } else if (videoObj.videoType === VideoType.VIMEO) {
      window.open(videoObj.url);
    } else if (videoObj.videoType === VideoType.GOOGLE_DRIVE) {
      let link = videoObj.url.replace("preview", "view");
      window.open(link);
    }
  };

  if (mediaLinkItem || galleryImage || virtualModel) {
    return (
      <ContainerX margin={{ only: { bottom: "20px" } }}>
        <StackX>
          <ContainerX width={"100%"}>
            <Slider {...settings}>
              {mediaLinkItem?.length > 0 &&
                mediaLinkItem?.map((item) => (
                  <ContainerX
                    key={item?.url}
                    decoration={{ borderRadius: "10px" }}
                    overflow="hidden"
                    height={"100%"}
                  >
                    <div
                      className="click-able"
                      onMouseDown={handleMouseDown}
                      onMouseUp={() => handleJumpVideoResource(item)}
                    >
                      <StackX height="210px">
                        <Positioned>
                          <ContainerX
                            key={item?.url}
                            className="swiper-slide gallery-sales-swiper"
                          >
                            <img
                              src={item?.thumbnail}
                              alt="galleryImage"
                              className="image"
                              height={"210px"}
                            />
                          </ContainerX>
                        </Positioned>
                        <Positioned>
                          <ContainerX
                            backgroundColor="rgba(0,0,0,.7803921568627451)"
                            height={"210px"}
                            alignment="center"
                          ></ContainerX>
                        </Positioned>
                        <Positioned>
                          <div className="play-arrow-btn">
                            <PlayArrowRounded
                              className="color-white"
                              fontSize="medium"
                            />
                          </div>
                        </Positioned>
                      </StackX>
                    </div>
                  </ContainerX>
                ))}
              {galleryImage?.length > 0 &&
                galleryImage?.map((item, index) => (
                  <>
                    <ContainerX
                      key={item?.url}
                      className="swiper-slide gallery-sales-swiper"
                      decoration={{ borderRadius: "10px" }}
                      overflow="hidden"
                      height={"210px"}
                    >
                      <img
                        src={item?.url}
                        alt="galleryImage"
                        className="image click-able"
                        onMouseDown={handleMouseDown}
                        onMouseUp={() => handleZoomImage(index)}
                      />
                    </ContainerX>
                  </>
                ))}
            </Slider>
          </ContainerX>

          {virtualModel?.length > 0 && (
            <StackItem top="6px" right="10px">
              <div onClick={handleOpen3DTourDialog} className="click-able">
                <ContainerX
                  decoration={{ borderRadius: "5px" }}
                  padding={{
                    symmetric: { vertical: "8px", horizontal: "12px" },
                  }}
                  className={"disable-full-uppercase vr-tour-btn"}
                  backgroundColor={cardColor}
                >
                  <RowFlex crossAxisAlignment="center" gap="2px">
                    <div className="vr-font">VR</div>
                    <span className="fs-14 fw-500 color-black">3D Tour</span>
                  </RowFlex>
                </ContainerX>
              </div>
            </StackItem>
          )}
        </StackX>
        <SimpleDialog
          visible={open3DTourDialog}
          onClose={handleClose3DTourDialog}
          background={cardColor}
        >
          <ColumnFlex gap="14px">
            <span className="fw-700 fs-16 color-text text-with-shadow">
              Select 3D Tour
            </span>
            {virtualModel?.map((item) => (
              <div
                key={item?.url}
                onClick={() => window.open(item?.url)}
                className="click-able"
              >
                <CardNeu style={{ background: cardColor }}>
                  <ContainerX
                    padding={{
                      all: "14px",
                    }}
                  >
                    <RowFlex
                      crossAxisAlignment="center"
                      mainAxisAlignment="space-between"
                    >
                      <ColumnFlex gap="2px">
                        <span className="fw-700 fs-12 color-text">
                          {item?.caption}
                        </span>
                        <span className="fw-500 fs-10 color-text-2">
                          {item?.url}
                        </span>
                      </ColumnFlex>
                      <NavigateNextRounded />
                    </RowFlex>
                  </ContainerX>
                </CardNeu>
              </div>
            ))}
          </ColumnFlex>
        </SimpleDialog>
        <ZoomedImageDialog
          visible={isZoomImage !== -1}
          onClose={() => setIsZoomImage(-1)}
          src={galleryImage[isZoomImage]?.url}
        />
      </ContainerX>
    );
  }
};

export default ProjectSwiper;
