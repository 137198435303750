import { RouteObject } from "react-router";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import NewPassword from "./NewPassword";

export const AUTH_PATH = {
  LOGIN: `/:accountCode/authentication/login`,
  REGISTER: `/:accountCode/authentication/register`,
  FORGOT_PASSWORD: `/:accountCode/authentication/forgot`,
  NEW_PASSWORD: `/:accountCode/authentication/new-pwd`,
} as const;

export const Invalid_Auth_Path = {
  LOGIN: AUTH_PATH.LOGIN.replace("/:accountCode", ""),
  REGISTER: AUTH_PATH.REGISTER.replace("/:accountCode", ""),
  FORGOT_PASSWORD: AUTH_PATH.FORGOT_PASSWORD.replace("/:accountCode", ""),
  NEW_PASSWORD: AUTH_PATH.NEW_PASSWORD.replace("/:accountCode", ""),
} as const;

const AuthRoutes: RouteObject[] = [
  {
    path: AUTH_PATH.LOGIN,
    element: <Login />,
  },
  {
    path: AUTH_PATH.REGISTER,
    element: <Register />,
  },
  {
    path: AUTH_PATH.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: AUTH_PATH.NEW_PASSWORD,
    element: <NewPassword />,
  },
  {
    path: Invalid_Auth_Path.LOGIN,
    element: <Login />,
  },
  {
    path: Invalid_Auth_Path.REGISTER,
    element: <Register />,
  },
  {
    path: Invalid_Auth_Path.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: Invalid_Auth_Path.NEW_PASSWORD,
    element: <NewPassword />,
  },
];

export default AuthRoutes;
