import CardNeu from "../Card/Card";
import { ColumnFlex } from "../LayoutX/Flex/ColumnFlex";
import { cardChangeColor } from "../../containers/context/CardContext";

type EmptyRecordProps = {
  hasSubLable?: boolean;
  label?: string;
  subLabel?: string;
  onClick?: () => void;
  onlyContent?: boolean;
};

const EmptyRecord: React.FC<EmptyRecordProps> = ({
  hasSubLable = false,
  label,
  subLabel,
  onClick,
  onlyContent = false,
}) => {
  const { cardColor } = cardChangeColor();

  if (onlyContent)
    return (
      <ColumnFlex
        fullWidth
        crossAxisAlignment="center"
        className={`p-20 ${onClick && "click-able"}`}
      >
        <span className="fs-12 fw-700 color-text">
          {label || "No Record Found"}
        </span>
        {hasSubLable && (
          <span className="fs-10 fw-500 color-text-2">
            {subLabel || "Add a new record now"}
          </span>
        )}
      </ColumnFlex>
    );

  return (
    <CardNeu rounded style={{ background: cardColor }}>
      <ColumnFlex
        fullWidth
        crossAxisAlignment="center"
        className={`p-20 ${onClick && "click-able"}`}
      >
        <span className="fs-12 fw-700 color-text">
          {label || "No Record Found"}
        </span>
        {hasSubLable && (
          <span className="fs-10 fw-500 color-text-2">
            {subLabel || "Add a new record now"}
          </span>
        )}
      </ColumnFlex>
    </CardNeu>
  );
};

export default EmptyRecord;
