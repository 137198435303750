import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCheckAccountCodeValidLazyQuery } from "../../generated/graphql";
// Define the context type
export type ProjectCardColor = {
  projectCardColor: string;
};

// Create the context
const ProjectCardContext = createContext<{
  projectCardColor: string;
  setColorState: React.Dispatch<React.SetStateAction<ProjectCardColor>>;
}>({
  projectCardColor: "e4ebf5",
  setColorState: () => {},
});

// useEffect to change color
export const projectCardChangeColor = () => {
  const [projectCardColor, setColorState] = useState("#e4ebf5");
  const { accountCode = "" } = useParams();
  const customizeColor: any = localStorage.getItem("projectListColorInLocal");

  // const [getAccountCode, { data: accountCodeData }] =
  //   useCheckAccountCodeValidLazyQuery({
  //     fetchPolicy: "no-cache",
  //   });

  useEffect(() => {
    // const sendRequest = async () => {
    //   const { data } = await getAccountCode({
    //     variables: {
    //       accountCode: accountCode || "",
    //     },
    //   });

    //   if (data?.checkAccountCodeValid?.subscriptionLogo) {
    //     setColorState("#fee1e1"); // Set the color
    //   }
    // };

    // sendRequest();
    if (customizeColor == null) {
      setColorState("#e4ebf5");
    } else {
      setColorState(customizeColor);
    }
  }, [accountCode]);

  return { projectCardColor, setColorState };
};

export default ProjectCardContext;
