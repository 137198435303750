import { Navigate, RouteObject, useParams } from "react-router";
import NotFound_404 from "./NotFound_404";

export const GENERAL_PATH = {
  NOT_FOUND_404: `/not-found-404`,
  NOT_FOUND_404_WITH_ACCOUNTCODE: `/:accountCode/not-found-404`,
  NAVIGATE_TO_NOT_FOUND_404: `/:accountCode/*`,
};

export const GeneralRoutes: RouteObject[] = [
  {
    path: GENERAL_PATH.NOT_FOUND_404,
    element: <NotFound_404 />,
  },
  {
    path: GENERAL_PATH.NOT_FOUND_404_WITH_ACCOUNTCODE,
    element: <NotFound_404 />,
  },
  {
    path: GENERAL_PATH.NAVIGATE_TO_NOT_FOUND_404,
    element: (
      <Navigate
        to={GENERAL_PATH.NOT_FOUND_404_WITH_ACCOUNTCODE.replace(
          ":accountCode",
          window.location.href.split("/")[3]
        )}
      />
    ),
  },
];
