import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { useEffect, useState } from "react";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ContainerX from "../../../../components/general/ContainerX";

const RefundPolicyConsent = ({
  setIsOpenRefundPolicyConsent,
  setProjectRequiredCheckbox,
  content,
}) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#e8ebf5",
          width: "100%",
          transform: "translateY(-20px)",
          height: "100vh",
          overflow: "auto",
          top: "0",
          left: "0",
          zIndex: 21,
        }}
      >
        <ContainerX className="MainContainer">
          <ColumnFlex fullWidth gap="40px">
            <RowFlex crossAxisAlignment="center" gap="20px" fullWidth>
              <Button onClick={() => setIsOpenRefundPolicyConsent(false)}>
                <CloseRoundedIcon className="color-text-light-blue" />
              </Button>
              <span className="fs-16 fw-700 text-with-shadow">
                PDPA Consent
              </span>
            </RowFlex>
            <ContainerX
              padding={{
                only: { left: "20px", right: "20px", bottom: "100px" },
              }}
            >
              <ColumnFlex gap="15px">
                <div
                  className="page-details-content"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </ColumnFlex>
            </ContainerX>
          </ColumnFlex>
        </ContainerX>
      </div>
      <div
        style={{
          width: "100%",
          position: "fixed",
          bottom: "0px",
          height: "80px",
          backgroundColor: "rgba(232, 235, 245, 0.8)",
          zIndex: 22,
        }}
      >
        <RowFlex crossAxisAlignment="center" className="fullheight">
          <Button
            rounded
            className={"disable-full-uppercase color-text"}
            style={{
              minWidth: "200px",
              maxWidth: "500px",
              width: "50%",
              margin: "0 auto",
            }}
            onClick={() => {
              setProjectRequiredCheckbox((prev) => ({
                ...prev,
                refundPolicy: true,
              }));
              setIsOpenRefundPolicyConsent(false);
            }}
          >
            Agree
          </Button>
        </RowFlex>
      </div>
    </>
  );
};

export default RefundPolicyConsent;
