import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { Fab, IconButton } from "ui-neumorphism";
import PropertyX_logo from "../../../assets/images/property-X.png";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import TextFieldNeu from "../../../components/TextField/TextFieldNeu";
import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { useSnackbar } from "react-simple-snackbar";
import {
  PassswordPolicyOuput,
  useCheckAccountCodeValidLazyQuery,
  useNewPasswordCheckMutation,
  useNewPasswordMutation,
  usePasswordPolicyLazyQuery,
} from "../../../generated/graphql";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import { passwordValidation } from "../../helpers/PasswordValidate";
import { setTempAccessToken } from "../../helpers/AccessToken";
import { AUTH_PATH, Invalid_Auth_Path } from "./AuthRoutes";
import { cardChangeColor } from "../../../containers/context/CardContext";

const NewPassword = () => {
  //reset password page require we access with a "p" param such as /reset-password?p={token}
  //this token is save in request header and send to server to verify the user, instead of using browser's cookie
  const [searchParams] = useSearchParams();
  const tempToken = searchParams.get("q");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { accountCode = "" } = useParams();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [showWarningContent, setShowWarningContent] = useState(false);
  useState(false);
  const [warningContent, setWarningContent] = useState("");
  const [passwordPolicy, setPasswordPolicy] = useState<PassswordPolicyOuput>();
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [userInfo, setUserInfo] = useState({} as any);
  const navigation = useNavigate();
  const { cardColor } = cardChangeColor();

  const [getPasswordPolicy, { data: passwordPolicyData }] =
    usePasswordPolicyLazyQuery();

  const [
    resetPassword,
    { data: resetPasswordData, error: resetPasswordError },
  ] = useNewPasswordMutation({
    variables: {
      newPassword: password,
      confirmPassword: confirmPassword,
    },
  });

  const [
    checkNewPassword,
    { data: checkNewPasswordData, error: checkNewPasswordError },
  ] = useNewPasswordCheckMutation({
    context: {
      header: {
        Authorization: `Bearer ${tempToken}`,
      },
    },
  });

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleShowConfirmPassword = () => {
    setIsShowConfirmPassword(!isShowConfirmPassword);
  };

  const [
    getAccountCode,
    {
      data: isValidAccountCodeResponse,
      loading: getAccCodeLoading,
      error: getAccCodeError,
    },
  ] = useCheckAccountCodeValidLazyQuery({});

  useEffect(() => {
    const sendRequest = async () => {
      await getAccountCode({
        variables: {
          accountCode: accountCode || "",
        },
      });
    };

    sendRequest();
  }, [accountCode]);

  useEffect(() => {
    if (resetPasswordData?.newPassword) {
      openSnackbar(
        userInfo?.tokenType
          ? "Password Set Successfully"
          : "Password Reset Successfully"
      );
      navigation(
        accountCode
          ? AUTH_PATH.LOGIN.replace(":accountCode", accountCode || "")
          : Invalid_Auth_Path.LOGIN,
        { replace: true }
      );
    }
  }, [resetPasswordData]);

  useEffect(() => {
    getPasswordPolicy().then(async (res) => {
      if (res.data?.passswordPolicy) {
        const passwordPolicy = res.data.passswordPolicy;
        setPasswordPolicy(passwordPolicy);
      }
    });

    if (tempToken) {
      setTempAccessToken(tempToken);

      checkNewPassword()
        .then(async (res) => {
          if (res.data?.newPasswordCheck) {
            setUserInfo(res.data.newPasswordCheck);
          }
        })
        .catch((err) => {
          openSnackbar(err.message);
        });
    }
  }, [tempToken]);

  const handlePasswordChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (!passwordPolicy) return;
    const result = passwordValidation(password, passwordPolicy);
    setShowWarningContent(!result.isValid);
    setWarningContent(result.message);

    if (result.isValid) {
      if (password === confirmPassword) {
        setIsPasswordMatch(true);
      } else {
        setIsPasswordMatch(false);
      }
    }
  }, [password, confirmPassword]);

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmitResetPassword = async () => {
    if (!accountCode) {
      openSnackbar("Invalid Account Code");
      return;
    }

    if (checkNewPasswordError || !tempToken) {
      openSnackbar("Invalid Token");
      return;
    }

    if (password && confirmPassword && isPasswordMatch && !showWarningContent) {
      await resetPassword();
    } else {
      openSnackbar("Invalid Request");
    }
  };

  return (
    <div className="Auth-Container">
      <ColumnFlex gap="30px" crossAxisAlignment="center" fullWidth>
        <div style={{ width: "100%" }}>
          <CardNeu elavation={2} style={{ background: cardColor }}>
            <ContainerX
              padding={{
                symmetric: { vertical: "20px", horizontal: "40px" },
              }}
              alignment="center"
            >
              <ColumnFlex crossAxisAlignment="center" gap="0px">
                <ColumnFlex crossAxisAlignment="center" gap="0px">
                  <img
                    src={
                      isValidAccountCodeResponse?.checkAccountCodeValid
                        ?.purchaserAppCss?.logoUrl || PropertyX_logo
                    }
                    className="company-logo"
                  />
                  <span
                    className="color-orange text-with-shadow fs-14"
                    style={{
                      width: "100%",
                      textAlign: "end",
                      paddingRight: "10%",
                    }}
                  >
                    Purchaser App
                  </span>
                </ColumnFlex>
                <span className="fs-20 fw-700 text-with-shadow color-text p-b-10">
                  {userInfo?.tokenType
                    ? userInfo?.tokenType === 2
                      ? "Set Password"
                      : "Reset Password"
                    : "Invalid Token"}
                </span>
                <RowFlex
                  gap="5px"
                  crossAxisAlignment="center"
                  className={`p-10 ${checkNewPasswordError ? "color-red" : ""}`}
                >
                  <EmailIcon fontSize="small" />
                  <span
                    className={`fs-12 fw-600 ${
                      checkNewPasswordError ? "color-red" : "color-text"
                    }`}
                  >
                    {userInfo?.userContact?.email || "Invalid user"}
                  </span>
                </RowFlex>
                <RowFlex
                  fullWidth
                  gap="5px"
                  height={`${showWarningContent ? "40px" : ""}`}
                >
                  <TextFieldNeu
                    placeholder="Password"
                    showWarning
                    type={`${isShowPassword ? "text" : "password"}`}
                    onInput={handlePasswordChange}
                    value={password}
                  />
                  {/* <IconButton style={{ transform: "translate(-105%,2px)" }}> */}
                  <IconButton rounded onClick={handleShowPassword}>
                    {isShowPassword ? (
                      <VisibilityOffIcon fontSize="small" />
                    ) : (
                      <VisibilityIcon fontSize="small" />
                    )}
                  </IconButton>
                </RowFlex>
                {showWarningContent && (
                  <span
                    style={{ width: "100%" }}
                    className="color-red fs-12 m-b-10"
                  >
                    {warningContent}
                  </span>
                )}

                <RowFlex
                  fullWidth
                  gap="5px"
                  height={`${!isPasswordMatch ? "40px" : ""}`}
                >
                  <TextFieldNeu
                    placeholder="Confirm Password"
                    showWarning
                    type={`${isShowConfirmPassword ? "text" : "password"}`}
                    onInput={handleConfirmPasswordChange}
                    value={confirmPassword}
                  />
                  {/* <IconButton style={{ transform: "translate(-105%,2px)" }}> */}
                  <IconButton rounded onClick={handleShowConfirmPassword}>
                    {isShowConfirmPassword ? (
                      <VisibilityOffIcon fontSize="small" />
                    ) : (
                      <VisibilityIcon fontSize="small" />
                    )}
                  </IconButton>
                </RowFlex>
                {!isPasswordMatch && (
                  <span
                    style={{ width: "100%" }}
                    className="color-red fs-12 m-b-10"
                  >
                    Password not match! Try again.
                  </span>
                )}
                <span className="fs-14 fw-300">
                Do you remember your password?{" "}
                <Link
                  to={
                    accountCode
                      ? AUTH_PATH.LOGIN.replace(
                          ":accountCode",
                          accountCode
                        )
                      : Invalid_Auth_Path.LOGIN
                  }
                  className="click-text-hover fs-14 color-text-2"
                >
                  Try logging now
                </Link>
              </span>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>
        </div>
        <Fab
          className={"fullwidth-with-child"}
          disabled={getAccCodeLoading}
          onClick={handleSubmitResetPassword}
          style={
            getAccCodeLoading
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <RowFlex crossAxisAlignment="center" gap="10px">
            {/* {getAccCodeLoading && <Spinner direction="horizontal" />} */}
            <span className="color-text-blue-gradient">Submit</span>
          </RowFlex>
        </Fab>
      </ColumnFlex>
    </div>
  );
};

export default NewPassword;
