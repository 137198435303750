import { CircularProgress } from "@mui/material";

const Spinner = () => {
  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
        zIndex: 77,
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
};

export default Spinner;
