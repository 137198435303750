import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { useEffect, useState } from "react";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { Button, IconButton } from "ui-neumorphism";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TextX from "../../../../components/general/TextX";
import CardNeu from "../../../../components/Card/Card";
import { useGetBrochureListForPublicLazyQuery } from "../../../../generated/graphql";
import ContainerX from "../../../../components/general/ContainerX";
import { DownloadRounded } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { useSnackbar } from "react-simple-snackbar";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import EmptyRecord from "../../../../components/general/EmptyRecord";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

import { useContext } from "react";
const Public_BrochureListing = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const navigate = useNavigate();
  const [getBrochureList, { data: brochureListData }] =
    useGetBrochureListForPublicLazyQuery();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  useEffect(() => {
    if (!projectId) {
      //   navigate(GENERAL_PATH.NOT_FOUND_404);
      return;
    } else {
      getBrochureList({
        variables: {
          projectId: projectId,
        },
      });
    }
  }, []);

  const handleDownloadBrochure = (brochure) => {
    if (brochure.attachment.resourceUrl && brochure.attachment.fileName) {
      saveAs(brochure.attachment.resourceUrl, brochure.attachment.fileName);
    } else {
      openSnackbar("Sales Document Not Found.");
    }
  };

  return (
    <ColumnFlex fullWidth gap="40px">
      <GeneralHeader
        title="Brochure List"
        style={{ background: headerColor }}
      />
      <ColumnFlex gap="30px">
        {brochureListData?.getBrochureListForPublic.map((brochure, index) => (
          <CardNeu style={{ background: cardColor }}>
            <ContainerX padding={{ all: "16px" }}>
              <RowFlex
                mainAxisAlignment="space-between"
                crossAxisAlignment="center"
              >
                <ColumnFlex gap="4px">
                  <span className="fw-700 fs-12 color-text">
                    {brochure.docName}
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    Upload Date: <TextX data={brochure.modTs} dataType="Date" />
                  </span>
                </ColumnFlex>
                <IconButton
                  rounded
                  onClick={() => handleDownloadBrochure(brochure)}
                >
                  <DownloadRounded />
                </IconButton>
              </RowFlex>
            </ContainerX>
          </CardNeu>
        ))}

        {brochureListData?.getBrochureListForPublic.length === 0 && (
          <EmptyRecord />
        )}
      </ColumnFlex>
    </ColumnFlex>
  );
};

export default Public_BrochureListing;
