import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import { useNavigate } from "react-router";
import { NavigateBeforeRounded } from "@mui/icons-material";
import CardNeu from "../../../components/Card/Card";
import ContainerX from "../../../components/general/ContainerX";
import { RowGrid } from "../../../components/LayoutX/Grid/RowGrid";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TextFieldNeu from "../../../components/TextField/TextFieldNeu";
import { NumericFormat } from "react-number-format"; // Import NumericFormat component
import { InputBase, TextField } from "@mui/material";
import NumberFormatTextField, {
  ValidationResult,
} from "../../../components/TextField/NumberFormat";
import LensIcon from "@mui/icons-material/Lens";
import TextX from "../../../components/general/TextX";
import { PieChart } from "@mui/x-charts/PieChart";
import { Wrap } from "../../../components/LayoutX/Wrap";
import {
  isDesktop_minWidth,
  useMediaQuery,
} from "../../../useHook/useMediaQuery";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import { abbreviateNumber } from "../../../containers/helpers/AbbreviateNumber";
import { headerChangeColor } from "../../../containers/context/Context";
import { cardChangeColor } from "../../../containers/context/CardContext";

import { useContext } from "react";
const Mortgage = () => {
  const isDesktop: boolean = useMediaQuery(isDesktop_minWidth);
  const [searchParams] = useSearchParams();
  const project_currency = searchParams.get("project_currency");
  // const project_name = searchParams.get("project_name");
  // const project_id = searchParams.get("project_id");
  const [priceError, setPriceError] = useState<string | undefined>();
  const [downPaymentError, setDownPaymentError] = useState<
    string | undefined
  >();
  const [downPercentError, setDownPercentError] = useState<
    string | undefined
  >();
  const [yearError, setYearError] = useState<string | undefined>();
  const [rateError, setRateError] = useState<string | undefined>();
  const [monthlyPay, setMonthlyPay] = useState<number>(0);
  const [principal, setPrincipal] = useState<number>(0);
  const [interest, setInterest] = useState<number>(0);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  const [formData, setFormData] = useState({
    price: 0.0,
    downPayment: 0.0,
    downPercent: 10.0,
    year: 35,
    rate: 3,
  });

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const { price, downPercent } = formData;
    const downPaymentValue = (price / 100) * downPercent;
    setFormData({
      ...formData,
      price: price,
      downPayment: downPaymentValue,
      downPercent: downPercent,
    });
  }, [formData.price]);

  useEffect(() => {
    const { price, downPayment } = formData;
    const downPaymentPercent = (downPayment / price) * 100;
    setFormData({
      ...formData,
      price: price,
      downPayment: downPayment,
      downPercent: downPaymentPercent,
    });
  }, [formData.downPayment]);

  useEffect(() => {
    const { price, downPercent } = formData;
    const downPaymentValue = (price / 100) * downPercent;
    setFormData({
      ...formData,
      price: price,
      downPayment: downPaymentValue,
      downPercent: downPercent,
    });
  }, [formData.downPercent]);

  useEffect(() => {
    if (
      priceError ||
      downPaymentError ||
      downPercentError ||
      yearError ||
      rateError
    ) {
      setMonthlyPay(0);
      setPrincipal(0);
      setInterest(0);

      return;
    }

    const { price, downPayment, year, rate } = formData;

    const principalValue = price - downPayment;
    const rateValue = formData.rate / 1200;
    const monthlyPayValue =
      (principalValue * rateValue) /
      (1 - Math.pow(1 / (1 + rateValue), formData.year * 12));
    const totalInterest =
      monthlyPayValue * (formData.year * 12) - principalValue;

    setMonthlyPay(monthlyPayValue);
    setPrincipal(principalValue);
    setInterest(totalInterest);
  }, [formData]);

  return (
    <ColumnFlex fullWidth gap="40px">
      <GeneralHeader title="Mortgage" style={{ background: headerColor }} />
      <CardNeu style={{ background: cardColor }}>
        <ContainerX
          padding={{ symmetric: { vertical: "18px", horizontal: "20px" } }}
        >
          <ColumnFlex fullWidth gap="20px" className="neu-text-field-style">
            <ColumnFlex fullWidth gap="6px">
              <span className="fw-400 fs-12 color-text-2">Property Price</span>
              <NumberFormatTextField
                name="price"
                value={formData.price}
                onChange={(value) => handleChange("price", value)}
                onValidation={(value) => setPriceError(value.errorMessage)}
                required
                moreThan={0}
                objectName="Property Price"
              />
              {priceError && (
                <span className="fw-400 fs-12 color-red">{priceError}</span>
              )}
            </ColumnFlex>
            <RowGrid
              // gridTemplateColumns={`1fr ${isDesktop ? "1fr" : ""}`}
              gridTemplateColumns="1fr 1fr"
              // gap={`${isDesktop ? "40px" : "20px"}`}
              gap="20px"
            >
              <ColumnFlex gap="6px" fullWidth>
                <span
                  className="fw-400 fs-12 color-text-2"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Down Payment
                </span>
                <NumberFormatTextField
                  style={{ width: "100%" }}
                  width={"50px"}
                  value={formData.downPayment}
                  objectName="Down Payment"
                  onChange={(value) => handleChange("downPayment", value)}
                  minValue={0}
                  onValidation={(value) =>
                    setDownPaymentError(value.errorMessage)
                  }
                  required
                  additionalValidate={(values) => {
                    const { floatValue } = values;
                    if (floatValue > formData.price) {
                      return {
                        valid: false,
                        errorMessage: `Down Payment must less than Property Price`,
                      };
                    }
                    return { valid: true, errorMessage: undefined };
                  }}
                />
                {downPaymentError && (
                  <span className="fw-400 fs-12 color-red">
                    {downPaymentError}
                  </span>
                )}
              </ColumnFlex>
              <ColumnFlex gap="6px">
                <span
                  className="fw-400 fs-12 color-text-2"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Percentage (%)
                </span>
                <NumberFormatTextField
                  value={formData.downPercent}
                  objectName="Percentage"
                  onChange={(value) => handleChange("downPercent", value)}
                  minValue={0}
                  maxValue={100}
                  onValidation={(err) => setDownPercentError(err.errorMessage)}
                  required
                  style={{ width: "100%" }}
                />
                {downPercentError && (
                  <span className="fw-400 fs-12 color-red">
                    {downPercentError}
                  </span>
                )}
              </ColumnFlex>
            </RowGrid>

            <ColumnFlex fullWidth gap="6px">
              <span className="fw-400 fs-12 color-text-2">
                Loan Tenure (Years)
              </span>
              <NumberFormatTextField
                value={formData.year}
                objectName="Loan Tenure (Years)"
                onChange={(value) => handleChange("year", value)}
                minValue={1}
                onValidation={(err) => setYearError(err.errorMessage)}
                required
                decimalScale={0}
              />
              {yearError && (
                <span className="fw-400 fs-12 color-red">{yearError}</span>
              )}
            </ColumnFlex>

            <ColumnFlex fullWidth gap="6px">
              <span className="fw-400 fs-12 color-text-2">
                Interest Rate (%)
              </span>
              <NumberFormatTextField
                value={formData.rate}
                objectName="Interest Rate"
                onChange={(value) => handleChange("rate", value)}
                moreThan={0}
                maxValue={100}
                onValidation={(err) => setRateError(err.errorMessage)}
                required
                decimalScale={2}
              />
              {rateError && (
                <span className="fw-400 fs-12 color-red">{rateError}</span>
              )}
            </ColumnFlex>
          </ColumnFlex>
        </ContainerX>
      </CardNeu>
      <CardNeu style={{ background: cardColor }}>
        <ContainerX
          padding={{ symmetric: { vertical: "18px", horizontal: "20px" } }}
        >
          <RowGrid
            gridTemplateColumns={`1fr ${isDesktop ? "1fr" : ""}`}
            // gridTemplateColumns="1fr 1fr"
            gap={`${isDesktop ? "0px" : "20px"}`}
          >
            <ColumnFlex
              gap="10px"
              className="fw-400 fs-16 color-text-1"
              // overflow="auto"
            >
              <span className="fs-12">Estimation</span>
              <ColumnFlex gap="20px">
                <span>
                  {project_currency}{" "}
                  {monthlyPay.toFixed(2).toString().length < 10 ? (
                    <TextX
                      data={Number(monthlyPay).toFixed(2)}
                      dataType="Number"
                      className="fw-700 fs-20 color-text"
                    />
                  ) : (
                    <span className="fw-700 fs-20 color-text">
                      {abbreviateNumber(Number(monthlyPay.toFixed(2)))}
                    </span>
                  )}
                  {"/month"}
                </span>
                <RowFlex gap="6px" crossAxisAlignment="center">
                  <LensIcon sx={{ width: "10px", color: "#1976d2" }} />
                  <span>
                    {project_currency}{" "}
                    {/* <TextX data={principal.toFixed(2)} dataType="Number" />{" "} */}
                    {principal.toFixed(2).toString().length < 10 ? (
                      <TextX
                        data={Number(principal).toFixed(2)}
                        dataType="Number"
                      />
                    ) : (
                      <span>
                        {abbreviateNumber(Number(principal.toFixed(2)))}
                      </span>
                    )}{" "}
                    Principal
                  </span>
                </RowFlex>
                <RowFlex gap="6px" crossAxisAlignment="center">
                  <LensIcon sx={{ width: "10px", color: "#26dad2" }} />
                  <span>
                    {project_currency}{" "}
                    {/* <TextX data={interest.toFixed(2)} dataType="Number" />{" "} */}
                    {interest.toFixed(2).toString().length < 10 ? (
                      <TextX
                        data={Number(interest).toFixed(2)}
                        dataType="Number"
                      />
                    ) : (
                      <span>
                        {abbreviateNumber(Number(interest.toFixed(2)))}
                      </span>
                    )}{" "}
                    Interest
                  </span>
                </RowFlex>
              </ColumnFlex>
            </ColumnFlex>

            <ColumnFlex gap="10px">
              <Wrap rowGap="20px" colGap="8px">
                <RowFlex gap="4px" crossAxisAlignment="center">
                  <div
                    style={{
                      backgroundColor: "#1976d2",
                      width: "30px",
                      height: "12px",
                    }}
                  ></div>
                  <span className="color-text-1 fw-500 fs-12">Principal</span>
                </RowFlex>
                <RowFlex gap="4px" crossAxisAlignment="center">
                  <div
                    style={{
                      backgroundColor: "#26dad2",
                      width: "30px",
                      height: "12px",
                    }}
                  ></div>
                  <span className="color-text-1 fw-500 fs-12">Interest</span>
                </RowFlex>
              </Wrap>
              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: principal,
                        // label: "Principal",
                        color: "#1976d2",
                      },
                      {
                        id: 1,
                        value: interest,
                        // label: "Interest",
                        color: "#26dad2",
                      },
                    ],
                  },
                ]}
                width={300}
                height={isDesktop ? 200 : 150}
              />
            </ColumnFlex>
          </RowGrid>
        </ContainerX>
      </CardNeu>
    </ColumnFlex>
  );
};

export default Mortgage;
