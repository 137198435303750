import { Button, IconButton } from "ui-neumorphism";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { useNavigate } from "react-router";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useSearchParams } from "react-router-dom";
import CardNeu from "../../../../components/Card/Card";
import { useEffect, useState } from "react";
import { GENERAL_PATH } from "../../../../containers/modules/GeneralPages/GeneralRoutes";
import ContainerX from "../../../../components/general/ContainerX";
import {
  useBookingFeeDetailLazyQuery,
  useGetELetterPdfLazyQuery,
  useGetMultiELetterBySaleIdLazyQuery,
  useGetPaymentQrCodeUrlLazyQuery,
} from "../../../../generated/graphql";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import { saveAs } from "file-saver";
import TextX from "../../../../components/general/TextX";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import QRCode from "react-qr-code";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

import { useContext } from "react";

const BookingFee = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const saleId = searchParams.get("saleId");
  const projectId = searchParams.get("projectId");
  const isIntegrate = searchParams.get("isIntegrate");
  const [qrCodeURL, setQrCodeURL] = useState<string>(null);
  const [
    getBookingFeeDetail,
    { data: bookingFeeDetailData, loading: feeDetailLoading },
  ] = useBookingFeeDetailLazyQuery({
    onCompleted: (data) => {
      if (
        data?.GetBookingDetail?.bookingFeeInfo?.paymentMethod ===
          "ONLINE_PAYMENT" &&
        data?.GetBookingDetail?.bookingFeeInfo?.status !== "SUCCESS"
      ) {
        getPaymentQRCodeURL({
          variables: {
            sale_id: saleId,
            project_id: projectId,
          },
        });
      }
    },
  });

  const [getMultiELetterBySaleId, { data: multiELetterData }] =
    useGetMultiELetterBySaleIdLazyQuery();
  const [getELetterPDF, { data: eLetterPDFData }] = useGetELetterPdfLazyQuery();
  const [getPaymentQRCodeURL] = useGetPaymentQrCodeUrlLazyQuery({
    onCompleted: (data) => {
      const url = data?.getPaymentQRCodeURL;
      setQrCodeURL(url);
    },
  });
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  useEffect(() => {
    if (
      !projectId ||
      !saleId ||
      isIntegrate === undefined ||
      isIntegrate === null ||
      isIntegrate === "" ||
      isIntegrate === "null" ||
      isIntegrate === "undefined"
    ) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
      return;
    }

    getBookingFeeDetail({
      variables: {
        saleId: saleId,
      },
    });

    getMultiELetterBySaleId({
      variables: {
        saleId: saleId,
      },
    });
  }, []);

  const downloadELetterPDF = (letterTemplateId: string) => {
    getELetterPDF({
      variables: {
        saleId: saleId,
        projectId: projectId,
        letterTemplateId: letterTemplateId,
      },
      onCompleted: (data) => {
        saveAs(data.getELetterPDF.content, data.getELetterPDF.fileName);
      },
    });
  };

  return (
    <ColumnFlex fullWidth gap="24px">
      <GeneralHeader title="Booking Fee" style={{ background: headerColor }} />

      <CardNeu style={{ background: cardColor }}>
        {/* <div
          className="fw-700 fs-12 p-10 p-l-12 inherit-border-radius color-text"
          style={{
            backgroundColor: "#fff8ec",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            width: "100%",
          }}
        >
          Booking Fee Details
        </div> */}
        <ContainerX padding={{ all: "12px" }}>
          <ColumnFlex fullWidth gap="14px">
            <span className="fw-700 fs-12 color-text">Booking Fee Details</span>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">
                Total Booking Fee
              </span>
              <TextX
                className="fw-600 fs-12 color-text"
                data={
                  bookingFeeDetailData?.GetBookingDetail.bookingFeeInfo.amount
                }
                dataType="Number"
              />
            </ColumnFlex>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">Payment To</span>
              <span className="fw-600 fs-12 color-text">
                {bookingFeeDetailData?.GetBookingDetail.projectCompany.name}
              </span>
            </ColumnFlex>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">
                Developer Address
              </span>
              <span className="fw-600 fs-12 color-text">
                {
                  bookingFeeDetailData?.GetBookingDetail.projectCompany.address
                    .address
                }
              </span>
            </ColumnFlex>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">
                Developer Contact
              </span>
              <a
                className="fw-800 fs-12 color-orange click-text"
                href={`tel:${bookingFeeDetailData?.GetBookingDetail.projectCompany.officeTel}`}
              >
                {
                  bookingFeeDetailData?.GetBookingDetail.projectCompany
                    .officeTel
                }
              </a>
            </ColumnFlex>
          </ColumnFlex>
        </ContainerX>
      </CardNeu>

      {bookingFeeDetailData?.GetBookingDetail?.bookingFeeInfo?.status ===
        "SUCCESS" && (
        <CardNeu style={{ background: cardColor }}>
          <ContainerX padding={{ all: "12px" }}>
            <ColumnFlex fullWidth gap="14px">
              <span className="fw-700 fs-12 color-text">Payment</span>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Payment Method
                </span>
                <span className="fw-600 fs-12 color-text">
                  {
                    bookingFeeDetailData?.GetBookingDetail.bookingFeeInfo
                      .paymentMethodDesc
                  }
                </span>
              </ColumnFlex>
              <RowGrid>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Payment Amount
                  </span>
                  <TextX
                    className="fw-600 fs-12 color-text"
                    data={
                      bookingFeeDetailData?.GetBookingDetail.bookingFeeInfo
                        .amount
                    }
                    dataType="Number"
                  />
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Reference No.
                  </span>
                  <span className="fw-600 fs-12 color-text">
                    {bookingFeeDetailData?.GetBookingDetail.bookingFeeInfo
                      .refNo || "-"}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Attachment</span>
                {bookingFeeDetailData?.GetBookingDetail.bookingFeeAttachments
                  .length === 0 &&
                  multiELetterData.getMultiELetterBySaleId.length === 0 &&
                  "-"}
                {bookingFeeDetailData?.GetBookingDetail.bookingFeeAttachments.map(
                  (item) => (
                    <span
                      key={item.fileName}
                      className="fw-600 fs-12 click-text color-orange"
                      onClick={() => saveAs(item.resourceUrl, item.fileName)}
                    >
                      {item.fileName}
                    </span>
                  )
                )}
                {multiELetterData?.getMultiELetterBySaleId.map((item) =>
                  item.letter.map((letter) => (
                    <span
                      key={letter.name}
                      className="fw-600 fs-12 click-text color-orange"
                      onClick={() =>
                        downloadELetterPDF(letter.letterTemplateId)
                      }
                    >
                      {letter.name}
                    </span>
                  ))
                )}
              </ColumnFlex>
            </ColumnFlex>
          </ContainerX>
        </CardNeu>
      )}

      {/* Online Payment */}
      {bookingFeeDetailData?.GetBookingDetail?.bookingFeeInfo?.paymentMethod ===
        "ONLINE_PAYMENT" &&
        bookingFeeDetailData?.GetBookingDetail?.bookingFeeInfo?.status !==
          "SUCCESS" && (
          <CardNeu style={{ background: cardColor }}>
            <ContainerX padding={{ all: "12px" }}>
              <ColumnFlex fullWidth gap="14px">
                <span className="fw-700 fs-12 color-text">Online Payment</span>
                <ColumnFlex crossAxisAlignment="center" gap="4px">
                  {qrCodeURL && <QRCode value={qrCodeURL} size={150} />}
                  <span className="fw-500 fs-10 color-text-2">
                    Scan the QR code above to make payment.
                  </span>
                </ColumnFlex>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>
        )}
    </ColumnFlex>
  );
};

export default BookingFee;
