// let accessToken = ''

export const ACCESS_TOKEN = {
  ACCESS: "JwtAccessToken",
  REFRESH: "JwtRefreshToken",
}


const getAll = () =>{
  let pairs = document.cookie.split(";").filter(x => x);
  let cookies = {};
  for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split("=");
      cookies[(pair[0] + '').trim()] = unescape(pair[1]);
  }
  return Object.keys(cookies).length == 0 ? null : cookies;
}

export const setAccessToken = (value: string, path: string = "/") => {
  document.cookie = `${ACCESS_TOKEN.ACCESS}=${value};path=${path}`;
}

export const getAccessToken = () => {  
  const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(ACCESS_TOKEN.ACCESS)) {
        const result = cookie.substring(`${ACCESS_TOKEN.ACCESS}=`.length, cookie.length);
        return result;
      }
    }

    return '';
}

export const deleteAccessToken = () => {
  let cookies = getAll();
  let filtered = Object.keys(cookies)
    .filter(key => key === ACCESS_TOKEN.ACCESS)
    .reduce((obj, key) => {
      obj[key] = cookies[key];
      return obj;
    }, {});
  Object.keys(filtered).forEach(key => {
    document.cookie = `${key}=;path=/;expires=${new Date(0).toUTCString()};`
  });
}

let tempAccessToken = '';

export const getTempAccessToken = () => {  
  const token = tempAccessToken;
  return token;
}

export const setTempAccessToken = (s: string) => {  
  tempAccessToken = s;
}