import { deleteAccessToken, getAccessToken, getTempAccessToken, setAccessToken } from './containers/helpers/AccessToken'
import {
    ApolloClient,
    InMemoryCache,
    ApolloLink,
    Observable,
    HttpLink,
    RequestHandler,
    Operation,
    NextLink,
} from '@apollo/client'
import  createUploadLink from '../node_modules/apollo-upload-client/createUploadLink.mjs';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';
import { getPathCookie} from './containers/helpers/PathCookie';
import { AUTH_PATH, Invalid_Auth_Path } from './containers/modules/Authentication/AuthRoutes';
import { GENERAL_PATH } from './containers/modules/GeneralPages/GeneralRoutes';
import moment from 'moment'
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { jwtDecode } from 'jwt-decode';
import { deleteSwiperSalesIdCookie, setSwiperSalesIdCookie } from './containers/helpers/salesIdCookie';
import { setSnackBar } from './containers/helpers/snackBarSubjectHelper';
import { setLoading } from './containers/helpers/loadingHelper';

let purchaserGraphQLNodeUrl = import.meta.env.VITE_PURCHASERX_GRAPHQL_HOST
export let GoogleAPIKey = import.meta.env.VITE_GoogleAPIKey
export let PaymentUrl = import.meta.env.VITE_PAYMENT_URL
export const propertyXRefreshUrl = import.meta.env.VITE_REFRESH_URL

const cache = new InMemoryCache({})
const uploadLink = createUploadLink({
  uri: purchaserGraphQLNodeUrl,
  credentials: 'include',
});

const authLink = setContext(async (request, previousContext) => {
  try {
    const originalToken = getAccessToken();
    
    if (originalToken) {
      return {
        headers: {
          authorization: `Bearer ${originalToken}`
        }
      };
    } else {
      const tempToken = getTempAccessToken();
      
      return {
        headers: {
          authorization: `Bearer ${tempToken}`
        }
      };
    }
  } catch (error) {
    console.error("Error occurred while setting authorization:", error);
    return {};
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      const errorMsg = message.toLowerCase()
      
    if(errorMsg === "Not Authenticated" || message === "Not Authenticated") {
      setSnackBar(message);
      const accountCode = getPathCookie();
      setAccessToken("");
      deleteAccessToken();
      setSwiperSalesIdCookie("");
      deleteSwiperSalesIdCookie();
      if(accountCode){
        window.location.href = `${AUTH_PATH.LOGIN.replace(":accountCode", accountCode)}`;
      } else {
        window.location.href = `${Invalid_Auth_Path.LOGIN}`;
      }
    } else {
      setSnackBar(message);
    }
  });
  if (networkError) {
    // window.location.replace(`${GENERAL_PATH.NOT_FOUND_404}`);
  };
});

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: async req => {
    if (req.operationName === 'login' || !req.operationName) return true

    const token = getAccessToken()
    if (!token) {
      return true
    }

    try {
      const decodedToken = jwtDecode(token)

      const isValid = moment(moment()).isBefore(decodedToken['exp'] * 1000)

      return isValid
    } catch (err) {
      return false
    }
  },
  fetchAccessToken: () => {
    return fetch(propertyXRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    })
  },
  handleFetch: (accessToken: string, operation) => {
    setAccessToken(accessToken)
  },
  handleError: err => {
    console.warn('Your refresh token is invalid. Try to relogin')
    console.error(err)
  },
});

const httpLink = new HttpLink({
  uri: purchaserGraphQLNodeUrl,
  credentials: 'include'
});

let apiNum: number = 0
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          setLoading(true)
          apiNum++
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${accessToken}`,
              },
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))
      
      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const responseData = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (apiNum > 0) {
      --apiNum
    }
    let resultResponse = JSON.parse(JSON.stringify(response))
    if (apiNum === 0) {
      setLoading(false)
    }
    return resultResponse
  })
})



const client = new ApolloClient({
    cache,
    link: ApolloLink.from([tokenRefreshLink,requestLink, responseData, errorLink, authLink, uploadLink, httpLink]),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
        fetchPolicy: "no-cache"
      },
      mutate: {
        errorPolicy: 'all',
        fetchPolicy: "no-cache"
      },
    },
});

export default client;