import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { useEffect, useRef, useState } from "react";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CardNeu from "../../../../components/Card/Card";
import ContainerX from "../../../../components/general/ContainerX";
import Expanded from "../../../../components/LayoutX/Expanded";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import {
  GetAvailableAndBookedUnitsQuery,
  GetPurchaserContactDetailsQuery,
  GetUnitDetailQuery,
  ProjectSpecOutput,
  PurchaserContactDetails,
  UnitEntity,
  UnitsAvailableAndBookedOutput,
  useAddBookingExpressMutation,
  useGetAvailableAndBookedUnitsLazyQuery,
  useGetBookingFormMasterDataLazyQuery,
  useGetPurchaserContactDetailsLazyQuery,
  useGetWaterMarkTextLazyQuery,
  useUnitDetailsLazyQuery,
} from "../../../../generated/graphql";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import TextX from "../../../../components/general/TextX";
import BumiIcon from "../../../../assets/images/icon/letter-b.svg";
import { Wrap } from "../../../../components/LayoutX/Wrap";
import {
  ArrowDropDown,
  ArrowRight,
  ArrowRightRounded,
  NavigateBeforeRounded,
  Publish,
  UploadFileRounded,
  UploadRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import NumberFormatTextField from "../../../../components/TextField/NumberFormat";
import {
  AttachmentHelper,
  acceptFileType,
  attachmentRefTypeList,
  compressImage,
  setWatermark,
  acceptFileTypeArr,
} from "../../../../containers/helpers/AttachmentHelper";
import { useSnackbar } from "react-simple-snackbar";
import { Checkbox, IconButton, styled } from "@mui/material";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import PDPAconsent from "./PDPAconsent";
import RefundPolicyConsent from "./RefundPolicyConsent";
import SignatureCanvas from "react-signature-canvas";
import EraserIcon from "../../../../assets/images/icon/eraser.svg";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import { StackX, StackItem } from "../../../../components/LayoutX/StackLayout";
import { setSeconds } from "date-fns";
import { headerChangeColor } from "../../../context/Context";
import { cardChangeColor } from "../../../context/CardContext";
import { buttonChangeColor } from "../../../context/ButttonContext";

import { useContext } from "react";
import { red } from "@mui/material/colors";

type BookingFormType = {
  unitNo?: string;
  Remarks?: string;
  BookingFee?: number;
  PaymentMethod?: string;
  PaymentRemarks?: string;
  PaymentRefNo?: string;
  UploadAttachment?: File;
  Signature?: string;
};

const maxFileSize = 25;

type Variables = {
  projectId: string;
  contactId?: string;
  isIntegrate?: boolean;
};

type PartialPurchaserInfo = Partial<PurchaserContactDetails> & {
  [key: string]: any;
};

const NewBooking = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const unitId = searchParams.get("unitId");
  const projectId = searchParams.get("projectId");
  const contactId = searchParams.get("contactId");
  const isIntegrate = searchParams.get("isIntegrate");
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { headerColor } = headerChangeColor();
  const { buttonColor } = buttonChangeColor();
  const { cardColor } = cardChangeColor();

  const [bookingForm, setBookingForm] = useState<BookingFormType>({
    unitNo: "",
    Remarks: "",
    BookingFee: 0,
    PaymentMethod: "",
    PaymentRemarks: "",
    PaymentRefNo: "",
    UploadAttachment: null,
    Signature: null,
  });
  const [projectRequirement, setProjectRequirement] =
    useState<ProjectSpecOutput>();
  const [projectRequiredCheckbox, setProjectRequiredCheckbox] = useState({
    pdpa: false,
    refundPolicy: false,
  });
  const [isOpenPDPAconsent, setIsOpenPDPAconsent] = useState(false);
  const [isOpenRefundPolicyConsent, setIsOpenRefundPolicyConsent] =
    useState(false);
  const [isOpenPaymentMethodSelection, setIsOpenPaymentMethodSelection] =
    useState(false);
  const [isShowRefNo, setIsShowRefNo] = useState(false);
  const [isShowUploadAtt, setIsShowUploadAtt] = useState(false);
  const signatureRef = useRef<SignatureCanvas>();
  const [isShowAvailableUnitList, setIsShowAvailableUnitList] = useState(false);

  const [purchaserInfo, setPurchaseInfo] =
    useState<GetPurchaserContactDetailsQuery["getPurchaserContactDetails"]>();
  const [targetUnit, setTargetUnit] =
    useState<Partial<UnitsAvailableAndBookedOutput>>();
  const [availableUnitList, setAvailableUnitList] =
    useState<Partial<UnitsAvailableAndBookedOutput>[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const availableUnitListContainer = useRef(null);
  const [isReachedBottomList, setIsReachedBottomList] = useState(false);
  const limit = 10;

  const [getAvailableAndBookedUnits, { data: availableAndBookedUnitsData }] =
    useGetAvailableAndBookedUnitsLazyQuery({
      fetchPolicy: "no-cache",
      // onCompleted: (data) => {
      //   if (data.getAvailableAndBookedUnits.length > 0) {
      //     setAvailableUnitList((prev) => [
      //       ...prev,
      //       ...data.getAvailableAndBookedUnits,
      //     ]);
      //   }
      // },
    });

  const [getBookingFormMasterData, { data: bookingFromMasterData }] =
    useGetBookingFormMasterDataLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.getBookingFormData) {
          setBookingForm({
            ...bookingForm,
            BookingFee:
              data.getBookingFormData.projectSpec?.minBookingfeeAmount || 0,
          });
          setProjectRequirement(data?.getBookingFormData?.projectSpec);
          setProjectRequiredCheckbox({
            pdpa: !data?.getBookingFormData?.projectSpec
              .bookingPDPAconsentRequired,
            refundPolicy:
              !data?.getBookingFormData?.projectSpec
                .bookingRefundPolicyconsentRequired,
          });
        }
      },
    });

  const [getWaterMarkText, { data: waterMarkTextData }] =
    useGetWaterMarkTextLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [getPurchaserContactDetails, { data: purchaserContactDetailsData }] =
    useGetPurchaserContactDetailsLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.getPurchaserContactDetails) {
          setPurchaseInfo(data.getPurchaserContactDetails);
        }
      },
    });

  const [addBookingExpress, { data: submitBookingData }] =
    useAddBookingExpressMutation({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.saveBookingExpress) {
          openSnackbar("Unit booked successfully.");
          if (data.saveBookingExpress.response) {
            window.location.replace(data.saveBookingExpress.response);
          } else {
            navigate(-1);
          }
        }
      },
    });

  useEffect(() => {
    if (isReachedBottomList) {
      setCurrentPage(currentPage + 1);
    }
  }, [isReachedBottomList]);

  const handleScrollAvailableUnitList = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      availableUnitListContainer.current;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setIsReachedBottomList(true);
    }
  };

  // refetch available unit list and append when user scroll to bottom of the available unit list
  useEffect(() => {
    if (currentPage <= 1) return;
    getAvailableAndBookedUnits({
      variables: {
        projectId: projectId,
        page: currentPage,
        limit: limit,
        search: bookingForm.unitNo,
        unitId: null,
      },
      onCompleted: (data) => {
        if (data.getAvailableAndBookedUnits.length > 0) {
          setAvailableUnitList((prev) => [
            ...prev,
            ...data.getAvailableAndBookedUnits,
          ]);

          setIsReachedBottomList(false);
        }
      },
    });
  }, [currentPage]);

  useEffect(() => {
    if (currentPage !== 1) return;
    timer = setTimeout(() => {
      getAvailableAndBookedUnits({
        variables: {
          projectId: projectId,
          page: currentPage,
          limit: limit,
          search: bookingForm.unitNo,
          unitId: null,
        },
        onCompleted: (data) => {
          if (data.getAvailableAndBookedUnits) {
            setAvailableUnitList(data.getAvailableAndBookedUnits || []);
          }
        },
      });
    }, 500);
  }, [bookingForm.unitNo, currentPage]);

  let timer: NodeJS.Timeout;
  const handleUnitNoChange = async (event) => {
    setBookingForm((prev) => {
      return {
        ...prev,
        unitNo: event.target.value,
      };
    });

    setIsReachedBottomList(false);
    setCurrentPage(1);
    clearTimeout(timer);
  };

  useEffect(() => {
    if (!unitId || !projectId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
      return;
    }

    getAvailableAndBookedUnits({
      variables: {
        projectId: projectId,
        page: currentPage,
        limit: limit,
        search: null,
        unitId: unitId,
      },
      onCompleted: (data) => {
        if (data.getAvailableAndBookedUnits.length > 0) {
          setTargetUnit(data.getAvailableAndBookedUnits[0]);
          setBookingForm({
            ...bookingForm,
            unitNo: data.getAvailableAndBookedUnits[0].unitNo,
          });
          setAvailableUnitList(data.getAvailableAndBookedUnits);
        }
      },
    });

    getWaterMarkText({
      variables: {
        projectId: projectId,
      },
    });

    getBookingFormMasterData({
      variables: {
        projectId: projectId,
      },
    });

    let variables: Variables = { projectId: projectId };
    if (contactId && isIntegrate) {
      variables = {
        projectId: projectId,
        contactId: contactId,
        isIntegrate: isIntegrate === "true" ? true : false,
      };
    }

    getPurchaserContactDetails({
      variables: variables,
    });
  }, []);

  const handleRefNumShow = () => {
    if (
      bookingForm.PaymentMethod == "ONLINE_TRANSFER" ||
      bookingForm.PaymentMethod == "CHEQUE" ||
      bookingForm.PaymentMethod == "CREDIT_CARD"
    ) {
      setIsShowRefNo(true);
      return true;
    }

    setIsShowRefNo(false);
    return false;
  };

  const handleUploadAttShow = () => {
    if (
      bookingForm?.PaymentMethod === "CASH" ||
      bookingForm?.PaymentMethod === "ONLINE_TRANSFER" ||
      bookingForm?.PaymentMethod === "CHEQUE" ||
      bookingForm?.PaymentMethod === "CREDIT_CARD"
    ) {
      setIsShowUploadAtt(true);
      return true;
    } else {
      setIsShowUploadAtt(false);
      return false;
    }
  };

  useEffect(() => {
    handleRefNumShow();
    handleUploadAttShow();
  }, [bookingForm.PaymentMethod]);

  const handleFileChange = async (event) => {
    let file: File = event.target.files[0];
    if (!file) return;

    const attachmentHelper = new AttachmentHelper(
      acceptFileTypeArr,
      maxFileSize
    );
    if (!attachmentHelper.checkFileType(file)) {
      openSnackbar("Upload attachment must be pictures or PDF.");
      return;
    } else if (!attachmentHelper.checkFileSize(file)) {
      openSnackbar(`Upload attachment must be less than ${maxFileSize}MB.`);
      return;
    }

    if (file.type !== acceptFileType.PDF) {
      file = await compressImage(file);
    }

    setBookingForm((prev) => ({
      ...prev,
      UploadAttachment: file,
    }));
  };

  const isValidBookingForm = (): boolean => {
    const minBookingFee =
      bookingFromMasterData?.getBookingFormData.projectSpec
        ?.minBookingfeeAmount || 0;

    if (!bookingForm.unitNo) {
      openSnackbar("Unit No. is required.");
      return false;
    } else if (
      !availableUnitList.find((unit) => unit?.unitNo === bookingForm.unitNo)
    ) {
      openSnackbar("Invalid Unit No.");
      return false;
    } else if (
      projectRequirement.bookingfeeAmountRequired &&
      projectRequirement.bookingFeeMandatory &&
      bookingForm.BookingFee < 0
    ) {
      openSnackbar("Booking Fee is required.");
      return false;
    } else if (!bookingForm.PaymentMethod) {
      openSnackbar("Payment Method is required.");
      return false;
    } else if (isShowRefNo && !bookingForm.PaymentRefNo) {
      openSnackbar("Payment Reference No. is required.");
      return false;
    } else if (isShowUploadAtt && !bookingForm.UploadAttachment) {
      openSnackbar("Upload Attachment is required.");
      return false;
    } else if (bookingForm.BookingFee < minBookingFee) {
      openSnackbar(`Minimum Payment Amount is ${minBookingFee}`);
      return false;
    } else if (
      !Object?.keys(
        bookingFromMasterData?.GetAllMasterInfo.PaymentMethods
      ).includes(bookingForm.PaymentMethod)
    ) {
      openSnackbar("Invalid Payment Method.");
      return false;
    } else if (
      projectRequirement?.bookingPDPAconsentRequired &&
      !projectRequiredCheckbox.pdpa
    ) {
      openSnackbar("Please read and agree the PDPA.");
      return false;
    } else if (
      projectRequirement?.bookingRefundPolicyconsentRequired &&
      !projectRequiredCheckbox.refundPolicy
    ) {
      openSnackbar("Please read and agree the Refund Policy.");
      return false;
    } else if (
      projectRequirement?.bookingSignRequired &&
      !bookingForm.Signature
    ) {
      openSnackbar("Signature is required.");
      return false;
    } else if (
      projectRequirement?.bookingSignRequired &&
      !bookingForm.Signature
    ) {
      openSnackbar("Signature is required.");
      return false;
    }

    return true;
  };

  const handleSubmitBooking = async () => {
    if (!isValidBookingForm()) return;
    addBookingExpress({
      variables: {
        input: {
          unit_id: unitId,
          sales_team_id: null,
          remarks: bookingForm.Remarks || null,
          is_bumi: targetUnit.isBumi,
          pay_method: bookingForm.PaymentMethod || null,
          pay_amount: bookingForm.BookingFee || null,
          pay_remark: bookingForm.PaymentRemarks || null,
          ref_num: bookingForm.PaymentRefNo || null,
          pdpa_signature: null,
          bookingFeeAttachment: bookingForm.UploadAttachment,
          buyer: {
            lead_id: null,
            // contact_id: contactId | null,
            nationality_id: purchaserInfo.Nationality.id,
            name: purchaserInfo.FullName,
            identity_no: purchaserInfo.IdentityNo,
            phone_no: purchaserInfo.PhoneNo,
            email: purchaserInfo.Email,
            is_company: false,
            company_name: null,
            company_reg_no: null,
            signature: bookingForm.Signature || null,
            nricBase64: null,
            isNric: true,
            is_military: false,
            military_ic: null,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (isOpenPDPAconsent || isOpenRefundPolicyConsent) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpenPDPAconsent, isOpenRefundPolicyConsent]);

  if (!purchaserInfo || !targetUnit || !bookingFromMasterData) return null;

  return (
    <>
      <ColumnFlex fullWidth gap="40px">
        <GeneralHeader
          title="New Booking"
          style={{ background: headerColor }}
        />
        <CardNeu style={{ background: cardColor }}>
          <ContainerX
            padding={{ symmetric: { vertical: "20px", horizontal: "16px" } }}
            className="color-text divider-bottom-border"
          >
            <ColumnFlex gap="20px">
              <ColumnFlex className="text-overflow">
                <span className="fs-10 fw-500 color-text-2">Name</span>
                <TextX
                  data={purchaserInfo?.FullName}
                  dataType="Text"
                  className="fs-12 fw-600"
                />
              </ColumnFlex>

              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex className="text-overflow">
                  <span className="fs-10 fw-500 color-text-2">Nationality</span>
                  <TextX
                    data={purchaserInfo?.Nationality?.name}
                    dataType="Text"
                    className="fs-12 fw-600"
                  />
                </ColumnFlex>
                <ColumnFlex className="text-overflow">
                  <span className="fs-10 fw-500 color-text-2">
                    NRIC No. / Passport No.
                  </span>
                  <TextX
                    data={purchaserInfo?.IdentityNo}
                    dataType="Text"
                    className="fs-12 fw-600"
                  />
                </ColumnFlex>
              </RowGrid>

              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex className="text-overflow">
                  <span className="fs-10 fw-500 color-text-2">Email</span>
                  <TextX
                    data={purchaserInfo?.Email}
                    dataType="Text"
                    className="fs-12 fw-600 text-overflow"
                  />
                </ColumnFlex>
                <ColumnFlex className="text-overflow">
                  <span className="fs-10 fw-500 color-text-2">Contact No.</span>
                  <TextX
                    data={purchaserInfo?.PhoneNo}
                    dataType="Text"
                    className="fs-12 fw-600"
                  />
                </ColumnFlex>
              </RowGrid>
            </ColumnFlex>
          </ContainerX>
          <ContainerX
            padding={{ all: "16px" }}
            className="color-text neu-text-field-style"
          >
            <ColumnFlex gap="20px">
              <ColumnFlex className="neu-text-field-style" gap="4px" fullWidth>
                <RowFlex gap="6px" crossAxisAlignment="center">
                  <span className="fs-12 fw-400 color-text-2">Unit No. *</span>
                  {targetUnit.isBumi && <img src={BumiIcon} alt="Bumi Icon" />}
                </RowFlex>
                <StackX width="100%">
                  <input
                    type="text"
                    value={bookingForm.unitNo}
                    onChange={handleUnitNoChange}
                    className="fullwidth"
                    onFocus={() => setIsShowAvailableUnitList(true)}
                  />
                  {isShowAvailableUnitList && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        transform: "translateY(10px)",
                        width: "100%",
                      }}
                    >
                      <CardNeu elavation={1} style={{ background: cardColor }}>
                        <div
                          ref={availableUnitListContainer}
                          style={{
                            width: "100%",
                            maxHeight: "250px",
                            overflow: "auto",
                          }}
                          onScroll={handleScrollAvailableUnitList}
                        >
                          <ColumnFlex height="100%">
                            {availableUnitList?.map((unit) => (
                              <Button
                                text
                                key={unit?.unitNo}
                                className={
                                  "divider-bottom-border clear-neu-button-style fullwidth-with-child disable-full-uppercase"
                                }
                                style={{ minHeight: "40px" }}
                                onClick={() => {
                                  setBookingForm((prev) => {
                                    return {
                                      ...prev,
                                      unitNo: unit?.unitNo,
                                    };
                                  });
                                  setTargetUnit(unit);
                                  setIsShowAvailableUnitList(false);
                                }}
                              >
                                <RowFlex
                                  mainAxisAlignment="space-between"
                                  crossAxisAlignment="center"
                                  fullWidth
                                >
                                  <RowFlex
                                    crossAxisAlignment="center"
                                    gap="4px"
                                  >
                                    <span style={{ width: "fit-content" }}>
                                      {unit?.unitNo}
                                    </span>
                                    {unit?.unitStatus === "AVAILABLE" &&
                                      unit?.isBumi && (
                                        <img
                                          src={BumiIcon}
                                          alt="Bumi Icon"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                  </RowFlex>
                                  <RowFlex
                                    crossAxisAlignment="center"
                                    gap="4px"
                                  >
                                    <span style={{ width: "fit-content" }}>
                                      {unit?.unitStatus === "AVAILABLE" &&
                                        "Available"}
                                      {unit?.unitStatus === "BOOK" && "Booked"}
                                      {unit?.unitStatus !== "BOOK" &&
                                        unit?.unitStatus !== "AVAILABLE" &&
                                        unit?.unitStatus}
                                    </span>
                                    {unit?.unitStatus === "BOOK" &&
                                      unit?.isBumi && (
                                        <img
                                          src={BumiIcon}
                                          alt="Bumi Icon"
                                          style={{ width: "10px" }}
                                        />
                                      )}
                                  </RowFlex>
                                </RowFlex>
                              </Button>
                            ))}
                            {!availableUnitList ||
                              (availableUnitList.length === 0 && (
                                <span
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    padding: "10px 0",
                                  }}
                                  className="fw-500 fs-12"
                                >
                                  No unit found
                                </span>
                              ))}
                          </ColumnFlex>
                        </div>
                      </CardNeu>
                    </div>
                  )}
                </StackX>
                {!availableUnitList.find(
                  (unit) => unit?.unitNo === bookingForm.unitNo
                ) && (
                  <span className="fs-10 fs-400 color-red">
                    Invalid Unit No.
                  </span>
                )}
              </ColumnFlex>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex gap="4px">
                  <span className="fs-10 fw-400 color-text-2">List Price</span>
                  <TextX
                    data={targetUnit?.price}
                    dataType="Number"
                    className="fs-12 fw-600"
                  />
                </ColumnFlex>
                <ColumnFlex gap="4px">
                  <span className="fs-10 fw-400 color-text-2">
                    Selling Price
                  </span>
                  <TextX
                    data={targetUnit?.price}
                    dataType="Number"
                    alt="-"
                    className="fs-12 fw-600"
                  />
                </ColumnFlex>
              </RowGrid>

              <ColumnFlex className="neu-text-field-style" gap="4px">
                <span className="fs-10 fw-400 color-text-2">Remarks</span>
                <input
                  type="text"
                  value={bookingForm.Remarks}
                  onChange={(event) =>
                    setBookingForm((prev) => {
                      return {
                        ...prev,
                        Remarks: event.target.value,
                      };
                    })
                  }
                />
              </ColumnFlex>

              <ColumnFlex gap="4px">
                <span className="fs-10 fw-400 color-text-2">Booking Fee</span>
                <NumberFormatTextField
                  value={bookingForm.BookingFee}
                  onChange={(value) =>
                    setBookingForm((prev) => {
                      return {
                        ...prev,
                        BookingFee: value,
                      };
                    })
                  }
                />
              </ColumnFlex>

              <ColumnFlex className="neu-text-field-style" gap="10px">
                <span className="fs-10 fw-400 color-text-2">
                  Payment Method
                </span>
                <ContainerX>
                  <Button
                    rounded
                    className={
                      "fullwidth-with-child clear-neu-button-style disable-full-uppercase"
                    }
                    onClick={() => setIsOpenPaymentMethodSelection(true)}
                  >
                    <RowFlex
                      className="color-text"
                      mainAxisAlignment="space-between"
                      crossAxisAlignment="center"
                      fullWidth
                    >
                      <span
                        style={{ width: "fit-content", paddingLeft: "6px" }}
                      >
                        {bookingFromMasterData.GetAllMasterInfo.PaymentMethods[
                          bookingForm.PaymentMethod
                        ] || "Select"}
                      </span>
                      {isOpenPaymentMethodSelection ? (
                        <ArrowDropDown />
                      ) : (
                        <ArrowRight />
                      )}
                    </RowFlex>
                  </Button>
                  {isOpenPaymentMethodSelection && (
                    <CardNeu
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        transform: "translateY(10px)",
                        minWidth: "100px",
                        background: cardColor,
                      }}
                    >
                      <ContainerX>
                        <ColumnFlex fullWidth>
                          {Object.keys(
                            bookingFromMasterData.GetAllMasterInfo
                              .PaymentMethods
                          ).map((objKey) => (
                            <Button
                              key={objKey}
                              className={
                                "color-text fullwidth-with-child clear-neu-button-style disable-full-uppercase"
                              }
                              style={{ padding: "20px 16px" }}
                              text
                              onClick={() => {
                                setBookingForm({
                                  ...bookingForm,
                                  PaymentMethod: objKey,
                                });
                                setIsOpenPaymentMethodSelection(false);
                              }}
                            >
                              {
                                bookingFromMasterData.GetAllMasterInfo
                                  .PaymentMethods[objKey]
                              }
                            </Button>
                          ))}
                        </ColumnFlex>
                      </ContainerX>
                    </CardNeu>
                  )}
                </ContainerX>
              </ColumnFlex>

              <ColumnFlex className="neu-text-field-style" gap="4px">
                <span className="fs-10 fw-400 color-text-2">
                  Payment Remark
                </span>
                <input
                  type="text"
                  value={bookingForm.PaymentRemarks}
                  onChange={(event) =>
                    setBookingForm((prev) => {
                      return {
                        ...prev,
                        PaymentRemarks: event.target.value,
                      };
                    })
                  }
                />
              </ColumnFlex>

              {isShowRefNo && (
                <ColumnFlex className="neu-text-field-style" gap="4px">
                  <span className="fs-10 fw-400 color-text-2">
                    Payment Reference No.
                  </span>
                  <input
                    type="text"
                    value={bookingForm.PaymentRefNo}
                    onChange={(event) =>
                      setBookingForm((prev) => {
                        return {
                          ...prev,
                          PaymentRefNo: event.target.value,
                        };
                      })
                    }
                  />
                </ColumnFlex>
              )}

              {isShowUploadAtt && (
                <ColumnFlex className="neu-text-field-style" gap="10px">
                  <span className="fs-10 fw-400 color-text-2">
                    Upload Attachment
                  </span>
                  <Button
                    rounded
                    className={
                      "color-text fullwidth-with-child clear-neu-button-style disable-full-uppercase"
                    }
                    onClick={() => {
                      document.getElementById("upload-attachment").click();
                    }}
                  >
                    <RowFlex
                      mainAxisAlignment="space-between"
                      fullWidth
                      crossAxisAlignment="center"
                    >
                      <span
                        style={{ paddingLeft: "6px", textAlign: "left" }}
                        className="text-overflow"
                      >
                        {bookingForm.UploadAttachment?.name || "Upload"}
                      </span>
                      <Publish />
                    </RowFlex>
                    <input
                      type="file"
                      id="upload-attachment"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Button>
                </ColumnFlex>
              )}
            </ColumnFlex>
          </ContainerX>
        </CardNeu>

        {(projectRequirement?.bookingPDPAconsentRequired ||
          projectRequirement?.bookingRefundPolicyconsentRequired) && (
          <CardNeu
            rounded
            padding={{ symmetric: { horizontal: "10px", vertical: "2px" } }}
            style={{ background: cardColor }}
          >
            <RowFlex crossAxisAlignment="flex-start">
              <Checkbox
                size="small"
                checked={
                  projectRequiredCheckbox.pdpa &&
                  projectRequiredCheckbox.refundPolicy
                }
                onClick={() => {
                  if (
                    !projectRequiredCheckbox.pdpa ||
                    !projectRequiredCheckbox.refundPolicy
                  ) {
                    openSnackbar(
                      "Please read and agree with the PDPA and Refund Policy to continue this booking."
                    );
                    return;
                  }
                  setProjectRequiredCheckbox({
                    pdpa: !projectRequirement?.bookingPDPAconsentRequired,
                    refundPolicy:
                      !projectRequirement?.bookingRefundPolicyconsentRequired,
                  });
                }}
              />
              <ContainerX
                padding={{
                  symmetric: { vertical: "10px", horizontal: "0px" },
                }}
              >
                <p className="fs-14 color-text-1">
                  <span>Read and agree with the </span>
                  {projectRequirement?.bookingPDPAconsentRequired && (
                    <span
                      className="fw-800 color-text click-text"
                      onClick={() => {
                        closeSnackbar();
                        setIsOpenPDPAconsent(true);
                      }}
                    >
                      PDPA
                    </span>
                  )}
                  {projectRequirement?.bookingPDPAconsentRequired &&
                    projectRequirement?.bookingRefundPolicyconsentRequired && (
                      <span> and </span>
                    )}
                  {projectRequirement?.bookingRefundPolicyconsentRequired && (
                    <span
                      className="fw-800 color-text click-text"
                      onClick={() => {
                        closeSnackbar();
                        setIsOpenRefundPolicyConsent(true);
                      }}
                    >
                      Refund Policy
                    </span>
                  )}
                  {/* TODO: company name */}
                  <span>
                    {" "}
                    {false ? "of <company name>" : ""} to continue this booking.
                  </span>
                </p>
              </ContainerX>
            </RowFlex>
          </CardNeu>
        )}

        {projectRequirement?.bookingSignRequired && (
          <CardNeu style={{ background: cardColor }}>
            <ContainerX padding={{ all: "16px" }}>
              <ColumnFlex crossAxisAlignment="center" gap="6px">
                <RowFlex
                  mainAxisAlignment="space-between"
                  crossAxisAlignment="center"
                  fullWidth
                >
                  <span className="color-text-2 fs-10 fw-600">
                    Please sign within the box.
                  </span>
                  <IconButton
                    sx={{ margin: "3px", backgroundColor: "#2f5cff" }}
                    size="small"
                    onClick={() => {
                      signatureRef.current.clear();
                      setBookingForm((prev) => {
                        return {
                          ...prev,
                          Signature: null,
                        };
                      });
                    }}
                  >
                    <img src={EraserIcon} alt="Clear" />
                  </IconButton>
                </RowFlex>
                <div
                  style={{
                    borderBottom: "2px dashed black",
                  }}
                >
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                      width: 500,
                      minWidth: 250,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    backgroundColor="white"
                    onEnd={() => {
                      if (signatureRef.current.isEmpty()) return;
                      setBookingForm((prev) => {
                        return {
                          ...prev,
                          Signature: signatureRef.current.toDataURL(),
                        };
                      });
                    }}
                  />
                </div>
                <span className="fs-12 fw-500">Purchaser Signature</span>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>
        )}

        <Button
          rounded
          className={"disable-full-uppercase color-text"}
          style={{
            minWidth: "200px",
            width: "50%",
            margin: "0 auto",
          }}
          onClick={handleSubmitBooking}
        >
          Save
        </Button>
      </ColumnFlex>
      {isOpenPaymentMethodSelection && (
        <div
          onClick={() => setIsOpenPaymentMethodSelection(false)}
          style={{
            position: "fixed",
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
            top: "0px",
            left: "0px",
            zIndex: 1,
          }}
        ></div>
      )}

      {isShowAvailableUnitList && (
        <div
          onClick={() => setIsShowAvailableUnitList(false)}
          style={{
            position: "fixed",
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
            top: "0px",
            left: "0px",
            zIndex: 1,
          }}
        ></div>
      )}

      {isOpenPDPAconsent && (
        <PDPAconsent
          setIsOpenPDPAconsent={setIsOpenPDPAconsent}
          setProjectRequiredCheckbox={setProjectRequiredCheckbox}
        />
      )}

      {isOpenRefundPolicyConsent && (
        <RefundPolicyConsent
          setIsOpenRefundPolicyConsent={setIsOpenRefundPolicyConsent}
          setProjectRequiredCheckbox={setProjectRequiredCheckbox}
          content={projectRequirement?.statementRefundPolicy}
        />
      )}
    </>
  );
};

export default NewBooking;
