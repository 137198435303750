import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { Button, Fab, IconButton } from "ui-neumorphism";
import PropertyX_logo from "../../../assets/images/property-X.png";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TextFieldNeu from "../../../components/TextField/TextFieldNeu";
import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { useSnackbar } from "react-simple-snackbar";
import {
  PassswordPolicyOuput,
  useChangePasswordMutation,
  useCheckAccountCodeValidLazyQuery,
  useNewPasswordCheckMutation,
  useNewPasswordMutation,
  usePasswordPolicyLazyQuery,
  usePasswordPolicyQuery,
} from "../../../generated/graphql";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import { passwordValidation } from "../../helpers/PasswordValidate";
import { setTempAccessToken } from "../../helpers/AccessToken";
import { DASHBOARDS_PATH } from "../Dashboards/DashboardRoutes";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import { headerChangeColor } from "../../../containers/context/Context";
import { cardChangeColor } from "../../../containers/context/CardContext";
import { textChangeColor } from "../../../containers/context/ContainerXContext";
import { inputFieldChangeColor } from "../../../containers/context/InputFieldContext";

import { useContext } from "react";
const ResetPassword = () => {
  //reset password page require we access with a "p" param such as /reset-password?p={token}
  //this token is save in request header and send to server to verify the user, instead of using browser's cookie
  const [searchParams] = useSearchParams();
  const navigation = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const accountCode = getPathCookie();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [showWarningContent, setShowWarningContent] = useState(false);
  useState(false);
  const [warningContent, setWarningContent] = useState("");
  const [passwordPolicy, setPasswordPolicy] = useState<PassswordPolicyOuput>();
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordHelperText, setCurrentPasswordHelperText] =
    useState(null);
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
    useState(null);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();
  const { textColor } = textChangeColor();
  const { inputFieldColor } = inputFieldChangeColor();

  const { data: passwordPolicyData } = usePasswordPolicyQuery({
    onCompleted: (data) => {
      if (data.passswordPolicy) {
        setPasswordPolicy(data.passswordPolicy);
      }
    },
  });

  const [
    resetPassword,
    { data: resetPasswordData, error: resetPasswordError },
  ] = useChangePasswordMutation({
    onCompleted: (data) => {
      if (data.changePassword) {
        openSnackbar("Password Reset Successfully");
        navigation(
          DASHBOARDS_PATH.DASHBOARD.replace(":accountCode", accountCode)
        );
      }
    },
  });

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleShowNewPassword = () => {
    setIsShowNewPassword(!isShowNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setIsShowConfirmPassword(!isShowConfirmPassword);
  };

  const handleCurrentPasswordChange = (event) => {
    if (event.target.value === "")
      setCurrentPasswordHelperText("Current Password is required");
    else setCurrentPasswordHelperText(null);

    setCurrentPassword(event.target.value);
  };

  const handlePasswordChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (!passwordPolicy) return;
    const result = passwordValidation(password, passwordPolicy);
    setShowWarningContent(!result.isValid);
    setWarningContent(result.message);

    if (result.isValid) {
      if (password === confirmPassword) {
        setIsPasswordMatch(true);
      } else {
        setIsPasswordMatch(false);
      }
    }
  }, [password, confirmPassword]);

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === "")
      setConfirmPasswordHelperText("Confirm Password is required");
    else setConfirmPasswordHelperText(null);

    setConfirmPassword(e.target.value);
  };

  const handleSubmitResetPassword = async () => {
    if (password && confirmPassword && isPasswordMatch && !showWarningContent) {
      resetPassword({
        variables: {
          input: {
            oldPassword: currentPassword,
            newPassword: password,
            confirmPassword: confirmPassword,
          },
        },
      });
    }
  };

  return (
    <ColumnFlex gap="30px" crossAxisAlignment="center" fullWidth>
      <GeneralHeader
        title="Reset Password"
        style={{ background: headerColor }}
      />
      <CardNeu width="100%" style={{ background: cardColor }}>
        <ContainerX
          padding={{
            only: { top: "30px", bottom: "20px", left: "25px", right: "20px" },
          }}
          alignment="center"
        >
          <ColumnFlex
            crossAxisAlignment="center"
            gap="10px"
            className="neu-text-field-style"
          >
            <ColumnFlex fullWidth gap="4px">
              <span
                className="fs-12 fw-500 color-text-2"
                style={{ color: textColor }}
              >
                Current Password *
              </span>
              <RowFlex fullWidth gap="5px">
                <input
                  type={`${isShowPassword ? "text" : "password"}`}
                  onChange={handleCurrentPasswordChange}
                  value={currentPassword}
                  className="fullwidth"
                  style={{ background: inputFieldColor }}
                />
                {/* <IconButton style={{ transform: "translate(-105%,2px)" }}> */}
                <IconButton rounded onClick={handleShowPassword}>
                  {isShowPassword ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </RowFlex>
              {currentPassword === "" && (
                <span className="fs-12 color-red fw-400">
                  {currentPasswordHelperText}
                </span>
              )}
            </ColumnFlex>

            <ColumnFlex fullWidth gap="4px">
              <span
                className="fs-12 fw-500 color-text-2"
                style={{ color: textColor }}
              >
                New Password *
              </span>
              <RowFlex fullWidth gap="5px">
                <input
                  type={`${isShowNewPassword ? "text" : "password"}`}
                  onChange={handlePasswordChange}
                  value={password}
                  className="fullwidth"
                  style={{ background: inputFieldColor }}
                />
                <IconButton rounded onClick={handleShowNewPassword}>
                  {isShowNewPassword ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </RowFlex>
              {showWarningContent && (
                <span style={{ width: "100%" }} className="color-red fs-12">
                  {warningContent}
                </span>
              )}
            </ColumnFlex>

            <ColumnFlex fullWidth gap="4px">
              <span
                className="fs-12 fw-500 color-text-2"
                style={{ color: textColor }}
              >
                Confirm New Password *
              </span>
              <RowFlex fullWidth gap="5px">
                <input
                  type={`${isShowConfirmPassword ? "text" : "password"}`}
                  onChange={handleConfirmPasswordChange}
                  value={confirmPassword}
                  className="fullwidth"
                  style={{ background: inputFieldColor }}
                />
                {/* <IconButton style={{ transform: "translate(-105%,2px)" }}> */}
                <IconButton rounded onClick={handleShowConfirmPassword}>
                  {isShowConfirmPassword ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </RowFlex>
              {(confirmPasswordHelperText || !isPasswordMatch) && (
                <span className="fs-12 color-red fw-400">
                  {confirmPasswordHelperText ||
                    "Confirm password does not match."}
                </span>
              )}
            </ColumnFlex>
          </ColumnFlex>
        </ContainerX>
      </CardNeu>
      <Button
        className={"disable-full-uppercase"}
        rounded
        disabled={
          !(
            password &&
            confirmPassword &&
            isPasswordMatch &&
            !showWarningContent
          )
        }
        onClick={handleSubmitResetPassword}
        style={{ width: "50%", minWidth: "200px", height: "45px" }}
      >
        <RowFlex crossAxisAlignment="center" gap="10px">
          <span className="color-text-blue-gradient ">Submit</span>
        </RowFlex>
      </Button>
    </ColumnFlex>
  );
};

export default ResetPassword;
