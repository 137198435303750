import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { Button, IconButton } from "ui-neumorphism";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CardNeu from "../../../../components/Card/Card";
import { useEffect, useState } from "react";
import ContainerX from "../../../../components/general/ContainerX";
import { useLoanListingBySalesLazyQuery } from "../../../../generated/graphql";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import { EmailRounded } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

import { useContext } from "react";

const docType = {
  bookingFrom: "BOOKING_FORM",
  customerIC: [
    "BOOKING_BUYER_NRIC_ATTACHMENT",
    "BOOKING_JOINT_BUYER_NRIC_ATTACHMENT",
    "BOOKING_BUYER_PASSPORT_ATTACHMENT",
  ],
  customerCreditReport: "BOOKING_CCR_ATTACHMENT",
  customerPayslips: "LOAN_PAY_SLIP",
  customerEPFEABank: "LOAN_INCOME_STATEMENT",
  otherDocuments: "LOAN_OTHER_ATTACHMENT",
};

const LoanDetails = () => {
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  const [searchParams] = useSearchParams();
  const saleId = searchParams.get("saleId");
  const projectId = searchParams.get("projectId");
  const financierIdId = searchParams.get("financierIdId");
  const navigate = useNavigate();
  const [getLoanListingBySales, { data: loanListingBySalesData }] =
    useLoanListingBySalesLazyQuery();

  useEffect(() => {
    if (!projectId || !saleId || !financierIdId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getLoanListingBySales({
        variables: {
          projectId: projectId,
          saleId: saleId,
          financierId: financierIdId,
        },
      });
    }
  }, []);

  const calculateDocProgress = (saleLoanDocArray) => {
    const documentChecklist = {
      bookingFrom: false,
      customerIC: false,
      customerCreditReport: false,
      customerPayslips: false,
      customerEPFEABank: false,
      otherDocuments: false,
    };

    saleLoanDocArray.forEach((obj) => {
      switch (obj.refType) {
        case "BOOKING_FORM":
          documentChecklist.bookingFrom = true;
          break;
        case "BOOKING_BUYER_NRIC_ATTACHMENT":
        case "BOOKING_JOINT_BUYER_NRIC_ATTACHMENT":
        case "BOOKING_BUYER_PASSPORT_ATTACHMENT":
          documentChecklist.customerIC = true;
          break;
        case "BOOKING_CCR_ATTACHMENT":
          documentChecklist.customerCreditReport = true;
          break;
        case "LOAN_PAY_SLIP":
          documentChecklist.customerPayslips = true;
          break;
        case "LOAN_INCOME_STATEMENT":
          documentChecklist.customerEPFEABank = true;
          break;
        case "LOAN_OTHER_ATTACHMENT":
          documentChecklist.otherDocuments = true;
          break;
        default:
          break;
      }
    });

    let selectedDocumentCount = 0;
    Object.values(documentChecklist).forEach((x) => {
      if (x) selectedDocumentCount++;
    });

    return selectedDocumentCount;
  };

  const includeSpecificType = (saleLoanDocArray, type: string | string[]) => {
    return saleLoanDocArray.some((obj) => {
      if (Array.isArray(type)) {
        return type.includes(obj.refType);
      } else {
        return obj.refType === type;
      }
    });
  };

  if (loanListingBySalesData?.loanListingBySales) {
    return (
      <ColumnFlex fullWidth gap="24px">
        <GeneralHeader
          title="Loan Details"
          style={{ background: headerColor }}
        />

        {loanListingBySalesData?.loanListingBySales?.map((loan, index) => (
          <CardNeu key={loan?.loanId} style={{ background: cardColor }}>
            <ContainerX padding={{ all: "10px" }}>
              <ColumnFlex gap="6px">
                <span className="color-text fs-12 fw-700">
                  End Financier Contact:{" "}
                  <span className="color-text-light-blue">
                    {loan?.financierName}
                  </span>
                </span>
                <RowGrid gridTemplateColumns="1fr 1fr">
                  <RowFlex gap="4px" crossAxisAlignment="center">
                    <SmartphoneIcon sx={{ width: "12px" }} />
                    <a
                      className="click-text color-orange fw-800 fs-10"
                      href={`tel:${loan?.financierContact?.phoneNo}`}
                    >
                      {loan?.financierContact?.phoneNo}
                    </a>
                    {!loan?.financierContact?.phoneNo && (
                      <span className="fw-800 fs-10">-</span>
                    )}
                  </RowFlex>
                  <RowFlex crossAxisAlignment="center" gap="4px">
                    <EmailRounded sx={{ width: "12px" }} />
                    <a
                      className="click-text color-orange fw-800 fs-10"
                      href={`mail:${loan?.financierContact?.email}`}
                    >
                      {loan?.financierContact?.email}
                    </a>
                    {!loan?.financierContact?.email && (
                      <span className="fw-800 fs-10">-</span>
                    )}
                  </RowFlex>
                </RowGrid>
                <RowFlex className="color-text fs-12 fw-700">
                  <span>{"Documents ("}</span>
                  <span className="color-text-light-blue">
                    {calculateDocProgress(loan?.saleLoanDoc)}
                  </span>
                  <span>{"/6)"}</span>
                </RowFlex>
                <ColumnFlex className="fs-10 fw-500" gap="4px">
                  <RowFlex gap="5px">
                    <DoneIcon
                      fontSize="inherit"
                      className={`${
                        includeSpecificType(
                          loan?.saleLoanDoc,
                          docType.bookingFrom
                        )
                          ? "color-text-light-blue"
                          : "color-text"
                      }`}
                    />
                    <span>Booking Information</span>
                  </RowFlex>

                  <RowFlex gap="5px">
                    <DoneIcon
                      fontSize="inherit"
                      className={`${
                        includeSpecificType(
                          loan?.saleLoanDoc,
                          docType.customerIC
                        )
                          ? "color-text-light-blue"
                          : "color-text"
                      }`}
                    />
                    <span>Customer IC (Main & Joint Buyer)</span>
                  </RowFlex>

                  <RowFlex gap="5px">
                    <DoneIcon
                      fontSize="inherit"
                      className={`${
                        includeSpecificType(
                          loan?.saleLoanDoc,
                          docType.customerCreditReport
                        )
                          ? "color-text-light-blue"
                          : "color-text"
                      }`}
                    />
                    <span>Customer Credit Report (ie. CCRIS, CTOS)</span>
                  </RowFlex>

                  <RowFlex gap="5px">
                    <DoneIcon
                      fontSize="inherit"
                      className={`${
                        includeSpecificType(
                          loan?.saleLoanDoc,
                          docType.customerPayslips
                        )
                          ? "color-text-light-blue"
                          : "color-text"
                      }`}
                    />
                    <span>Payslip (3 Months)</span>
                  </RowFlex>

                  <RowFlex gap="5px">
                    <DoneIcon
                      fontSize="inherit"
                      className={`${
                        includeSpecificType(
                          loan?.saleLoanDoc,
                          docType.customerEPFEABank
                        )
                          ? "color-text-light-blue"
                          : "color-text"
                      }`}
                    />
                    <span>EPF / EA / Bank Statement (3 Months)</span>
                  </RowFlex>

                  <RowFlex gap="5px">
                    <DoneIcon
                      fontSize="inherit"
                      className={`${
                        includeSpecificType(
                          loan?.saleLoanDoc,
                          docType.otherDocuments
                        )
                          ? "color-text-light-blue"
                          : "color-text"
                      }`}
                    />
                    <span>Others</span>
                  </RowFlex>
                </ColumnFlex>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>
        ))}
      </ColumnFlex>
    );
  }
};

export default LoanDetails;
