import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import {
  Ipay88PaymentOutput,
  useGetIpay88PaymentDataLazyQuery,
  useGetIpay88PaymentMethodEnumLazyQuery,
  useUpdateStatusToInProgressMutation,
} from "../../../generated/graphql";
import {
  KeyboardArrowUpRounded,
  NavigateNextRounded,
} from "@mui/icons-material";
import { Button } from "ui-neumorphism";
import { PaymentType } from "./PaymentRoutes";
import MaybankIcon from "../../../assets/images/maybank.png";
import CIMBIcon from "../../../assets/images/cimb.png";
import PublicBankIcon from "../../../assets/images/publicbank.png";
import RHBBankIcon from "../../../assets/images/rhbbank.png";
import HongLeongBankIcon from "../../../assets/images/hongleong.png";
import { Checkbox, Radio, RadioGroup } from "@mui/material";
import { StackX } from "../../../components/LayoutX/StackLayout";
import "./Payment.scss";
import { iPay88PaymentService } from "./PaymentService";
import { headerChangeColor } from "../../../containers/context/Context";
import { useContext } from "react";

const CommonBank = {
  MAYBANK: "6",
  CIMB: "20",
  PUBLIC_BANK: "31",
  RHB_BANK: "14",
  HONG_LEONG_BANK: "15",
};

const blockWithBankPayment = [
  "2",
  "210",
  "243",
  "6",
  "20",
  "31",
  "14",
  "15",
  "22",
];

const EWallet_Bank = ["210", "243", "22"];

const SelectBank = ({ paymentType }) => {
  const [openSnackbar] = useSnackbar();
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get("paymentId");
  const projectId = searchParams.get("projectId");

  const [selectedBank, setSelectedBank] = useState(null);
  const [bankSelectionBar, setBankSelectionBar] = useState<{
    key: string;
    value: string;
  } | null>(null);
  const [isOpenBankSelection, setIsOpenBankSelection] = useState(false);
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState<Ipay88PaymentOutput>(null);

  const [getIpay88PaymentMethodEnum, { data: iPay88PaymentMethodEnum }] =
    useGetIpay88PaymentMethodEnumLazyQuery();
  const [getIpay88PaymentData] = useGetIpay88PaymentDataLazyQuery({
    onCompleted: (data) => {
      setPaymentDetail(data?.getIpay88PaymentData);
    },
  });
  const [ipay88PaymentDataQuery] = useGetIpay88PaymentDataLazyQuery();
  const { headerColor } = headerChangeColor();

  const [updateStatusToInProgress] = useUpdateStatusToInProgressMutation({
    onCompleted: (data) => {
      if (data.updateStatusToInProgress) {
        ipay88PaymentDataQuery({
          variables: { paymentId, projectId },
          onCompleted: (result) => {
            const ipay88ModelData = result.getIpay88PaymentData;
            iPay88PaymentService(ipay88ModelData, paymentId);
          },
        });
      }
    },
  });

  useEffect(() => {
    if ((!paymentId || !projectId) && paymentType === PaymentType.BANK) {
      openSnackbar("Invalid Params");
      return;
    }

    if (paymentType === PaymentType.EWALLET && !projectId) {
      openSnackbar("Invalid Params");
      return;
    }

    getIpay88PaymentMethodEnum();

    if (paymentType === PaymentType.BANK) {
      getIpay88PaymentData({
        variables: {
          paymentId: paymentId,
          projectId: projectId,
        },
      });
    }
  }, []);

  const onBankSelect = (value, type) => {
    if (type === "checkbox") {
      setSelectedBank(value);
      //reset
      setBankSelectionBar(null);
    }

    if (type === "selectionBar") {
      setBankSelectionBar(value);
      setSelectedBank(null);
      setIsOpenBankSelection(false);
    }
  };

  const handlePayNow = () => {
    if (!selectedBank && !bankSelectionBar) {
      openSnackbar(`Selection of ${paymentType} is required`);
      return;
    }

    if (!isAcceptTerms) {
      openSnackbar("Agreement of terms and coditions required");
      return;
    }

    updateStatusToInProgress({
      variables: {
        paymentId: paymentId,
      },
    });
  };

  if (!iPay88PaymentMethodEnum) return null;

  return (
    <>
      <ColumnFlex gap="30px" fullWidth>
        <GeneralHeader
          title="Online Payment"
          style={{ background: headerColor }}
        />

        <CardNeu>
          <ColumnFlex gap="0px">
            <ContainerX
              padding={{ symmetric: { horizontal: "12px", vertical: "8px" } }}
              className="divider-bottom-border"
            >
              <span className="color-text fw-700 fs-12 ">Select Bank</span>
            </ContainerX>

            {paymentType === PaymentType.BANK && (
              <RadioGroup value={selectedBank}>
                <ColumnFlex>
                  {/* Maybank */}
                  <ContainerX
                    padding={{
                      symmetric: { horizontal: "16px", vertical: "14px" },
                    }}
                    className="divider-bottom-border"
                  >
                    <RowFlex
                      mainAxisAlignment="space-between"
                      crossAxisAlignment="center"
                    >
                      <RowFlex gap="12px" crossAxisAlignment="center">
                        <img
                          src={MaybankIcon}
                          alt="Maybank"
                          width={55}
                          height={40}
                        />
                        <span className="color-text fs-12 fw-700">Maybank</span>
                      </RowFlex>
                      <Radio
                        value={CommonBank.MAYBANK}
                        onChange={() =>
                          onBankSelect(CommonBank.MAYBANK, "checkbox")
                        }
                      />
                    </RowFlex>
                  </ContainerX>

                  {/* CIMB */}
                  <ContainerX
                    padding={{
                      symmetric: { horizontal: "16px", vertical: "14px" },
                    }}
                    className="divider-bottom-border"
                  >
                    <RowFlex
                      mainAxisAlignment="space-between"
                      crossAxisAlignment="center"
                    >
                      <RowFlex gap="12px" crossAxisAlignment="center">
                        <img src={CIMBIcon} alt="CIMB" width={55} height={40} />
                        <span className="color-text fs-12 fw-700">CIMB</span>
                      </RowFlex>
                      <Radio
                        value={CommonBank.CIMB}
                        onChange={() =>
                          onBankSelect(CommonBank.CIMB, "checkbox")
                        }
                      />
                    </RowFlex>
                  </ContainerX>

                  {/* Public Bank */}
                  <ContainerX
                    padding={{
                      symmetric: { horizontal: "16px", vertical: "14px" },
                    }}
                    className="divider-bottom-border"
                  >
                    <RowFlex
                      mainAxisAlignment="space-between"
                      crossAxisAlignment="center"
                    >
                      <RowFlex gap="12px" crossAxisAlignment="center">
                        <img
                          src={PublicBankIcon}
                          alt="Public Bank"
                          width={55}
                          height={40}
                        />
                        <span className="color-text fs-12 fw-700">
                          Public Bank
                        </span>
                      </RowFlex>
                      <Radio
                        value={CommonBank.PUBLIC_BANK}
                        onChange={() =>
                          onBankSelect(CommonBank.PUBLIC_BANK, "checkbox")
                        }
                      />
                    </RowFlex>
                  </ContainerX>

                  {/* RHB Bank */}
                  <ContainerX
                    padding={{
                      symmetric: { horizontal: "16px", vertical: "14px" },
                    }}
                    className="divider-bottom-border"
                  >
                    <RowFlex
                      mainAxisAlignment="space-between"
                      crossAxisAlignment="center"
                    >
                      <RowFlex gap="12px" crossAxisAlignment="center">
                        <img
                          src={RHBBankIcon}
                          alt="RHB Bank"
                          width={55}
                          height={40}
                        />
                        <span className="color-text fs-12 fw-700">
                          RHB Bank
                        </span>
                      </RowFlex>
                      <Radio
                        value={CommonBank.RHB_BANK}
                        onChange={() =>
                          onBankSelect(CommonBank.RHB_BANK, "checkbox")
                        }
                      />
                    </RowFlex>
                  </ContainerX>

                  {/* Hong Leong Bank */}
                  <ContainerX
                    padding={{
                      symmetric: { horizontal: "16px", vertical: "14px" },
                    }}
                    className="divider-bottom-border"
                  >
                    <RowFlex
                      mainAxisAlignment="space-between"
                      crossAxisAlignment="center"
                    >
                      <RowFlex gap="12px" crossAxisAlignment="center">
                        <img
                          src={HongLeongBankIcon}
                          alt="Hong Leong Bank"
                          width={55}
                          height={40}
                        />
                        <span className="color-text fs-12 fw-700">
                          Hong Leong Bank
                        </span>
                      </RowFlex>
                      <Radio
                        value={CommonBank.HONG_LEONG_BANK}
                        onChange={() =>
                          onBankSelect(CommonBank.HONG_LEONG_BANK, "checkbox")
                        }
                      />
                    </RowFlex>
                  </ContainerX>
                </ColumnFlex>
              </RadioGroup>
            )}
            <ContainerX
              padding={{
                symmetric: { horizontal: "16px", vertical: "14px" },
              }}
            >
              <StackX>
                <ColumnFlex gap="8px">
                  <span className="color-text-2 fs-12 fw-500">
                    Select{" "}
                    {paymentType === PaymentType.BANK
                      ? "Other Bank"
                      : "E-wallet"}
                  </span>
                  <Button
                    rounded
                    className={
                      "fullwidth-with-child clear-neu-button-style disable-full-uppercase"
                    }
                    onClick={() => setIsOpenBankSelection(!isOpenBankSelection)}
                  >
                    <RowFlex
                      mainAxisAlignment="space-between"
                      crossAxisAlignment="center"
                      fullWidth
                    >
                      <span
                        style={{ width: "fit-content", paddingLeft: "10px" }}
                      >
                        {bankSelectionBar?.value || ""}
                      </span>
                      {isOpenBankSelection ? (
                        <KeyboardArrowUpRounded />
                      ) : (
                        <NavigateNextRounded />
                      )}
                    </RowFlex>
                  </Button>
                </ColumnFlex>
                {isOpenBankSelection && (
                  <div
                    onClick={() => setIsOpenBankSelection(false)}
                    style={{
                      position: "fixed",
                      backgroundColor: "transparent",
                      width: "100%",
                      height: "100%",
                      top: "0px",
                      left: "0px",
                    }}
                  ></div>
                )}
                {isOpenBankSelection && (
                  <div
                    style={{
                      position: "absolute",
                      top: paymentType === PaymentType.BANK ? "0" : null,
                      bottom: paymentType !== PaymentType.BANK ? "0" : null,
                      left: "0",
                      transform: `translateY(${
                        paymentType === PaymentType.BANK ? "-100%" : "100%"
                      }) translateY(${
                        paymentType === PaymentType.BANK ? "-6px" : "6px"
                      })`,
                      width: "100%",
                      maxHeight: "250px",
                      overflowY: "auto",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      zIndex: 2,
                    }}
                    className="box-shadow"
                  >
                    <ColumnFlex overflow="auto">
                      {Object.entries(
                        iPay88PaymentMethodEnum?.getIpay88PaymentMethodEnum
                      ).map(
                        ([key, value]) =>
                          (paymentType === PaymentType.BANK
                            ? !blockWithBankPayment.includes(key)
                            : EWallet_Bank.includes(key)) && (
                            <div
                              key={key}
                              className="click-able selectionbar-item "
                              onClick={() =>
                                onBankSelect(
                                  {
                                    key: key as string,
                                    value: value as string,
                                  },
                                  "selectionBar"
                                )
                              }
                            >
                              <span>{value as string}</span>
                            </div>
                          )
                      )}
                    </ColumnFlex>
                  </div>
                )}
              </StackX>
            </ContainerX>
          </ColumnFlex>
        </CardNeu>

        <CardNeu
          padding={{ symmetric: { horizontal: "14px", vertical: "12px" } }}
        >
          <RowFlex crossAxisAlignment="flex-start" gap="10px">
            <Checkbox
              checked={isAcceptTerms}
              color="primary"
              onChange={() => {
                setIsAcceptTerms(!isAcceptTerms);
              }}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 20 },
                width: 20,
                height: 20,
              }}
            />
            <span className="fs-14 fw-500">
              I agree to Ipay88's Terms & Condition and Privacy Notice, and
              hereby authorize {paymentDetail?.name} to debit net charges from
              my
            </span>
          </RowFlex>
        </CardNeu>

        <Button
          style={{ width: "40%", minWidth: "150px" }}
          className={
            "margin-horizontal-center disable-full-uppercase color-text-light-blue"
          }
          rounded
          onClick={handlePayNow}
        >
          Pay Now
        </Button>
      </ColumnFlex>
    </>
  );
};

export default SelectBank;
