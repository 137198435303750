import React from "react";
import ReactDOM from "react-dom/client";
import App from "./containers/app/App";
import "ui-neumorphism/dist/index.css";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/styles/website/root.scss";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
