import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import { ApolloProvider } from "@apollo/client";
import client from "../../PropertyClient";
import { RootRoutes } from "../../containers/routes/RootRoutes";
import { RootInitialState, RootReducer } from "./context/Reducer";
import AppContext from "./context/Context";
import { Button, IconButton, Snackbar } from "@mui/material";
import { snackBarObservable } from "../../containers/helpers/snackBarSubjectHelper";
import { CloseRounded } from "@mui/icons-material";
import Spinner from "../../components/Spinner/FullSpinner";
import { loadingObservable } from "../../containers/helpers/loadingHelper";
import useSocket from "../../useHook/useSocket";
import { getAccessToken } from "../../containers/helpers/AccessToken";

function App() {
  const [rootState, rootDispatch] = React.useReducer(
    RootReducer,
    RootInitialState
  );

  const { socketDisconnection, connectStatus } = useSocket();

  useEffect(() => {
    if (!getAccessToken()) {
      socketDisconnection();
    }
  }, [getAccessToken()]);

  const handleClose = (
    event?: Event | React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    rootDispatch({
      type: "snackBar",
      payload: {
        open: false,
        message: "",
      },
    });
  };

  useEffect(() => {
    loadingObservable().subscribe((x) => {
      rootDispatch({
        type: "loading",
        payload: x,
      });
    });

    snackBarObservable().subscribe((x) => {
      if (x) {
        rootDispatch({
          type: "snackBar",
          payload: {
            open: true,
            message: x,
          },
        });
      }
    });
  }, []);

  return (
    <>
      <AppContext.Provider value={{ rootState, rootDispatch }}>
        <ApolloProvider client={client}>
          <SnackbarProvider>
            <RouterProvider router={RootRoutes} />
            <Snackbar
              open={rootState.snackBar?.open}
              autoHideDuration={2000}
              onClose={handleClose}
              message={rootState.snackBar?.message}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              action={
                <IconButton size="small" color="inherit" onClick={handleClose}>
                  <CloseRounded fontSize="small" />
                </IconButton>
              }
            />
            {rootState.loading && <Spinner />}
          </SnackbarProvider>
        </ApolloProvider>
      </AppContext.Provider>
    </>
  );
}

export default App;
