import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import {
  GetPaymentDataByIdQuery,
  useGetIpay88PaymentDataLazyQuery,
  useGetPaymentDataByIdLazyQuery,
  useGetRevenueMonsterPaymentUrlLazyQuery,
  useUpdateStatusToInProgressMutation,
} from "../../../generated/graphql";
import { NavigateNextRounded } from "@mui/icons-material";
import TextX from "../../../components/general/TextX";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import CreditCardIcon from "../../../assets/images/icon/cd-db-card.png";
import OnlineBankingIcon from "../../../assets/images/icon/online-banking.png";
import eWalletIcon from "../../../assets/images/icon/e-wallet.png";
import { PAYMENT_PATH, PaymentType } from "./PaymentRoutes";
import { iPay88PaymentService } from "./PaymentService";
import { Button } from "ui-neumorphism";
import { headerChangeColor } from "../../../containers/context/Context";
import { useContext } from "react";

const PaymentConfirm = ({ paymentType }) => {
  const navigate = useNavigate();
  const [openSnackbar] = useSnackbar();
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get("paymentId");
  const projectId = searchParams.get("projectId");
  const [paymentDetails, setPaymentDetails] =
    useState<GetPaymentDataByIdQuery["getPaymentDataById"]>();
  const [getPaymentDataById, { data: paymentData }] =
    useGetPaymentDataByIdLazyQuery({
      onCompleted: (data) => {
        if (data.getPaymentDataById) {
          setPaymentDetails(data.getPaymentDataById);
        }
      },
    });
  const [updateStatusToInProgress] = useUpdateStatusToInProgressMutation();
  const [ipay88PaymentDataQuery] = useGetIpay88PaymentDataLazyQuery();
  const [getRevenueMonsterPaymentUrl] =
    useGetRevenueMonsterPaymentUrlLazyQuery();
  const { headerColor } = headerChangeColor();

  useEffect(() => {
    if (!paymentId || !projectId) {
      openSnackbar("Invalid Params");
      return;
    }

    getPaymentDataById({
      variables: {
        paymentId: paymentId,
      },
    });
  }, []);

  const handleCreditOrDebitCard = async () => {
    updateStatusToInProgress({
      variables: {
        paymentId: paymentId,
      },
      onCompleted: (data) => {
        if (data.updateStatusToInProgress) {
          if (paymentType === PaymentType.IPAY88) {
            ipay88PaymentDataQuery({
              variables: { paymentId, projectId },
              onCompleted: (result) => {
                const ipay88ModelData = result.getIpay88PaymentData;
                iPay88PaymentService(ipay88ModelData, paymentId);
              },
            });
          }

          if (paymentType === PaymentType.REVENUE_MONSTER) {
            getRevenueMonsterPaymentUrl({
              variables: {
                paymentId: paymentId,
                projectId: projectId,
                refNo: paymentDetails?.refNo,
              },
              onCompleted: (result) => {
                window.location.replace(result?.revenueMonsterPaymentUrl);
              },
            });
          }
        }
      },
    });
  };

  const handleOnlineBanking = () => {
    navigate(
      `${PAYMENT_PATH.PAYMENT_SELECT_BANK.replace(
        ":accountCode",
        getPathCookie()
      )}?paymentId=${paymentId}&projectId=${projectId}&unitId=${paymentDetails?.unitId}`
    );
  };

  const handleEWallet = () => {
    navigate(
      `${PAYMENT_PATH.PAYMENT_SELECT_EWALLET.replace(
        ":accountCode",
        getPathCookie()
      )}?projectId=${projectId}`
    );
  };

  return (
    <ColumnFlex gap="30px" fullWidth overflow="visible">
      <GeneralHeader
        title="Online Payment"
        style={{ background: headerColor }}
      />
      <CardNeu>
        <ColumnFlex gap="12px">
          <ContainerX
            padding={{ symmetric: { horizontal: "12px", vertical: "8px" } }}
            className="divider-bottom-border"
          >
            <span className="color-text fw-700 fs-12 ">PDPA Consent</span>
          </ContainerX>
          <ContainerX
            padding={{ only: { left: "12px", right: "12px", bottom: "16px" } }}
          >
            <span className="fs-12 fw-500">
              I acknowledge that {paymentDetails?.devName} may collect, obtain,
              store and process your personal data that you provide in this form
              for the purpose of receiving updates, promotional and marketing
              mails or materials from {paymentDetails?.devName}.
            </span>
          </ContainerX>
        </ColumnFlex>
      </CardNeu>

      <CardNeu>
        <ColumnFlex gap="12px">
          <ContainerX
            padding={{ symmetric: { horizontal: "12px", vertical: "8px" } }}
            className="divider-bottom-border"
          >
            <span className="color-text fw-700 fs-12">
              Payment Confirmation
            </span>
          </ContainerX>
          <ContainerX
            padding={{ only: { left: "12px", right: "12px", bottom: "16px" } }}
          >
            <ColumnFlex gap="12px">
              <span className="color-text fw-600 fs-12">
                {paymentDetails?.devName}
              </span>
              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">Unit No.</span>
                <span className="color-text fw-600 fs-12">
                  {paymentDetails?.unitNo}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">Reference No.</span>
                <span className="color-text fw-600 fs-12">
                  {paymentDetails?.refNo}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">Payment To</span>
                <span className="color-text fw-600 fs-12">
                  {paymentDetails?.companyName}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">
                  Developer Address
                </span>
                <span className="color-text fw-600 fs-12">
                  {paymentDetails?.address?.address}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">
                  Developer Contact
                </span>
                <span className="color-text fw-600 fs-12">
                  {paymentDetails?.officeTel || "-"}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">
                  Payment Amount
                </span>
                <RowFlex
                  className="color-text-light-blue fw-600 fs-12"
                  gap="2px"
                >
                  <span>RM</span>
                  <TextX data={paymentDetails?.amount} dataType="Number" />
                </RowFlex>
              </ColumnFlex>
            </ColumnFlex>
          </ContainerX>
        </ColumnFlex>
      </CardNeu>

      {paymentType === PaymentType.IPAY88 && (
        <CardNeu>
          <ContainerX
            padding={{ symmetric: { horizontal: "16px", vertical: "8px" } }}
            className="divider-bottom-border"
          >
            <span className="color-text fw-700 fs-12">
              Select Payment Method
            </span>
          </ContainerX>
          <ContainerX
            padding={{ symmetric: { horizontal: "16px", vertical: "14px" } }}
            className="divider-bottom-border"
          >
            <div className="click-able" onClick={handleCreditOrDebitCard}>
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                className="color-text"
              >
                <RowFlex gap="6px" crossAxisAlignment="center">
                  <img src={CreditCardIcon} alt="Credit Card" width={24} />
                  <span className="color-text fw-600 fs-12">
                    Credit/Debit Card
                  </span>
                </RowFlex>
                <NavigateNextRounded />
              </RowFlex>
            </div>
          </ContainerX>

          <ContainerX
            padding={{ symmetric: { horizontal: "16px", vertical: "14px" } }}
            className="divider-bottom-border"
          >
            <div className="click-able" onClick={handleOnlineBanking}>
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                className="color-text"
              >
                <RowFlex gap="6px" crossAxisAlignment="center">
                  <img
                    src={OnlineBankingIcon}
                    alt="Online Banking"
                    width={24}
                  />
                  <span className="color-text fw-600 fs-12">
                    Online Banking
                  </span>
                </RowFlex>
                <NavigateNextRounded />
              </RowFlex>
            </div>
          </ContainerX>

          <ContainerX
            padding={{ symmetric: { horizontal: "16px", vertical: "14px" } }}
            className="divider-bottom-border"
          >
            <div className="click-able" onClick={handleEWallet}>
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                className="color-text"
              >
                <RowFlex gap="6px" crossAxisAlignment="center">
                  <img src={eWalletIcon} alt="E-Wallet" width={24} />
                  <span className="color-text fw-600 fs-12">e-Wallet</span>
                </RowFlex>
                <NavigateNextRounded />
              </RowFlex>
            </div>
          </ContainerX>
        </CardNeu>
      )}

      {paymentType === PaymentType.REVENUE_MONSTER && (
        <Button
          rounded
          className={"disable-full-uppercase color-text-light-blue"}
          onClick={handleCreditOrDebitCard}
        >
          Make Payment
        </Button>
      )}
    </ColumnFlex>
  );
};

export default PaymentConfirm;
