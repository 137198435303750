import { Button } from "ui-neumorphism";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import React from "react";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import { useNavigate, useParams } from "react-router";
import { DASHBOARDS_PATH } from "../Dashboards/DashboardRoutes";
import { getAccessToken } from "../../../containers/helpers/AccessToken";
import { AUTH_PATH, Invalid_Auth_Path } from "../Authentication/AuthRoutes";
import { useSearchParams } from "react-router-dom";
import {
  isDesktop_minWidth,
  isMobileWidth,
  isSmallMobileWidth,
  useMediaQuery,
} from "../../../useHook/useMediaQuery";
import ContainerX from "../../../components/general/ContainerX";

const NotFound_404 = () => {
  const navigate = useNavigate();
  const { accountCode } = useParams();
  const isMobile = useMediaQuery(isMobileWidth);

  const handleBackToHome = () => {
    if (getPathCookie() && getAccessToken()) {
      navigate(
        DASHBOARDS_PATH.DASHBOARD.replace(":accountCode", getPathCookie())
      );
    } else if (getPathCookie() && !getAccessToken()) {
      navigate(AUTH_PATH.LOGIN.replace(":accountCode", getPathCookie()));
    } else if (!getPathCookie() && accountCode) {
      // For guess user access public sales gallery but redirect to 404 page.
      navigate(AUTH_PATH.LOGIN.replace(":accountCode", accountCode));
    } else {
      navigate(Invalid_Auth_Path.LOGIN);
    }
  };

  return (
    <ContainerX padding={{ symmetric: { horizontal: "16px" } }}>
      <ColumnFlex
        className="font-family-strict"
        fullWidth
        crossAxisAlignment="center"
        mainAxisAlignment="center"
        height="100vh"
        gap="30px"
      >
        <p
          style={{
            fontSize: `${isMobile ? "100px" : "210px"}`,
            fontWeight: "900",
            lineHeight: `${isMobile ? "100px" : "210px"}`,
            margin: "0",
            textShadow: "4px 5px 1px #b3bbbf",
            color: "rgba(0,0,0,.87)",
          }}
        >
          404
        </p>
        <ColumnFlex gap="4px" crossAxisAlignment="center">
          <p
            style={{
              lineHeight: "30px",
              fontSize: "21px",
              fontWeight: "400",
              color: "rgba(0,0,0,.87)",
            }}
          >
            Page Not Found !
          </p>
          <p className="color-text-2">
            You seem to be trying to find this way to home
          </p>
        </ColumnFlex>
        <Button
          className={"disable-full-uppercase"}
          rounded
          style={{ padding: "20px 30px" }}
          onClick={handleBackToHome}
        >
          Back to home
        </Button>
      </ColumnFlex>
    </ContainerX>
  );
};

export default NotFound_404;
