import React from "react";
import { GridX } from "../GridX";
import {
  AlignItemsEnum,
  JustifyContentEnum,
  OverflowEnum,
  verticalAlignEnum,
} from "../LayoutXenum";

type RowGridProps = {
  children?: React.ReactNode;
  className?: string;
  crossAxisAlignment?: AlignItemsEnum;
  fullWidth?: boolean;
  gap?: string;
  gridTemplateColumns?: string;
  height?: string;
  mainAxisAlignment?: JustifyContentEnum;
  overflow?: OverflowEnum;
  verticalAlign?: verticalAlignEnum;
};

export const RowGrid = ({
  children,
  className,
  crossAxisAlignment,
  fullWidth,
  gap,
  gridTemplateColumns,
  height,
  mainAxisAlignment,
  overflow,
  verticalAlign,
}: RowGridProps) => {
  return (
    <GridX
      children={children}
      className={className}
      crossAxisAlignment={crossAxisAlignment}
      direction="row"
      fullWidth={fullWidth}
      gap={gap}
      gridTemplate={gridTemplateColumns || "repeat(auto-fit, minmax(0, 1fr)"}
      height={height}
      mainAxisAlignment={mainAxisAlignment}
      overflow={overflow}
      verticalAlign={verticalAlign}
    />
  );
};
