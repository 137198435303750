import MainLayout from "../../containers/app/MainLayout";
import {
  Navigate,
  RouteObject,
  createBrowserRouter,
  useParams,
  useRoutes,
} from "react-router-dom";
import NotFound_404 from "../modules/GeneralPages/NotFound_404";
import Dashboard from "../modules/Dashboards/Dashboard";
import { AuthGuard } from "./AuthGuard";
import { Blank } from "./Blank";
import AuthRoutes, {
  AUTH_PATH,
  Invalid_Auth_Path,
} from "../../containers/modules/Authentication/AuthRoutes";
import DashboardRoutes, {
  DASHBOARDS_PATH,
} from "../modules/Dashboards/DashboardRoutes";
import {
  GENERAL_PATH,
  GeneralRoutes,
} from "../../containers/modules/GeneralPages/GeneralRoutes";
import OthersRoutes from "../../containers/modules/Others/OthersRoutes";
import BookingRoutes from "../../containers/modules/Booking/BookingRoutes";
import publicProjectRoutes from "../../containers/modules/Project/PublicPage/PublicProjectRoutes";
import PaymentRoutes from "../../containers/modules/Payments/PaymentRoutes";
import { getPathCookie } from "../../containers/helpers/PathCookie";

const RoutesObj: RouteObject[] = [
  {
    path: "/",
    element: (
      <Navigate
        to={
          getPathCookie()
            ? DASHBOARDS_PATH.DASHBOARD.replace(":accountCode", getPathCookie())
            : Invalid_Auth_Path.LOGIN
        }
      />
    ),
  },
  {
    path: ":accountCode",
    element: <AuthGuard />,
    children: [
      {
        path: "",
        element: <MainLayout />,
        children: [
          // {
          //   path: `:accountCode/`,
          //   element: <Navigate to={DASHBOARDS_PATH.DASHBOARD} />,
          // },
          // {
          //   path: "*",
          //   element: <Navigate to="/not-found-404" />,
          // },
          {
            path: "",
            children: [...DashboardRoutes],
          },
          {
            path: "",
            children: [...BookingRoutes],
          },
          {
            path: "",
            children: [...OthersRoutes],
          },
          {
            path: "",
            children: [...PaymentRoutes],
          },
          // {
          //   path: "*",
          //   element: <Navigate to={AUTH_PATH.LOGIN} />,
          // },
        ],
      },
    ],
  },
  {
    path: "",
    element: <Blank />,
    children: [...AuthRoutes],
  },
  {
    path: "",
    children: [...GeneralRoutes],
  },
  {
    path: "",
    children: [...publicProjectRoutes],
  },
];

export const RootRoutes = createBrowserRouter(RoutesObj, {
  // basename: ":accountCode",
});
