import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { useEffect, useState } from "react";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CardNeu from "../../../../components/Card/Card";
import ContainerX from "../../../../components/general/ContainerX";
import Expanded from "../../../../components/LayoutX/Expanded";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { useUnitDetailsLazyQuery } from "../../../../generated/graphql";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import TextX from "../../../../components/general/TextX";
import { Icon } from "@mui/material";
import JointBuyerIcon from "../../../../assets/images/icon/joint-buyer.svg";
import PurchaserDocumentIcon from "../../../../assets/images/icon/purchaser-document.svg";
import SalesGalleryIcon from "../../../../assets/images/icon/sales-gallery.svg";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import BookingFeeIcon from "../../../../assets/images/icon/booking-fee.svg";
import SnPSigningInfoIcon from "../../../../assets/images/icon/snp-signing.svg";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import { DASHBOARDS_PATH } from "../DashboardRoutes";
import { getPathCookie } from "../../../../containers/helpers/PathCookie";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import BumiIcon from "../../../../assets/images/icon/letter-b.svg";
import { headerChangeColor } from "../../../context/Context";
import { cardChangeColor } from "../../../context/CardContext";
import { textChangeColor } from "../../../context/ContainerXContext";

import { useContext } from "react";
const BookingDetails = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const unitId = searchParams.get("unitId");
  const saleId = searchParams.get("saleId");
  const isIntegrate = searchParams.get("isIntegrate");
  const [openPurchaserInfoDialog, setOpenPurchaserInfoDialog] = useState(false);
  const [openUnitInfoDialog, setOpenUnitInfoDialog] = useState(false);
  const navigate = useNavigate();
  const [
    getUnitDetais,
    { data: unitDetailsData, loading: unitDetailsLoading },
  ] = useUnitDetailsLazyQuery();
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();
  const { textColor } = textChangeColor();

  useEffect(() => {
    if (!projectId || !unitId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getUnitDetais({
        variables: {
          projectId: projectId,
          saleId: saleId,
          isIntegrate: isIntegrate === "true",
        },
      });
    }
  }, []);

  const handleJumpToJointBuyerListing = () => {
    navigate(
      `${DASHBOARDS_PATH.JOINT_BUYER_LISTING.replace(
        ":accountCode",
        getPathCookie()
      )}?projectId=${projectId}&saleId=${saleId}&isIntegrate=${isIntegrate}`
    );
  };

  const handleJumpToPurchaserDocument = () => {
    navigate(
      `${DASHBOARDS_PATH.PURCHASER_DOCUMENT.replace(
        ":accountCode",
        getPathCookie()
      )}?projectId=${projectId}&saleId=${saleId}&isIntegrate=${isIntegrate}`
    );
  };

  const handleJumpToBookingFee = () => {
    navigate(
      `${DASHBOARDS_PATH.BOOKING_FEE.replace(
        ":accountCode",
        getPathCookie()
      )}?projectId=${projectId}&saleId=${saleId}&isIntegrate=${isIntegrate}`
    );
  };

  const handleJumpToTownshipDetails = () => {
    navigate(
      `${DASHBOARDS_PATH.TOWNSHIP_DETAILS.replace(
        ":accountCode",
        getPathCookie()
      )}?projectId=${projectId}&saleId=${saleId}&isIntegrate=${isIntegrate}`
    );
  };

  const handleJumpToSigningInfo = () => {
    navigate(
      `${DASHBOARDS_PATH.SIGN_INFO.replace(
        ":accountCode",
        getPathCookie()
      )}?projectId=${projectId}&saleId=${saleId}&isIntegrate=${isIntegrate}`
    );
  };

  const cancelJump = (e, value) => {
    //if empty, it will not jump
    if (!value) {
      e.preventDefault();
    }
  };

  if (unitDetailsLoading) return null;

  if (unitDetailsData) {
    return (
      <>
        <ColumnFlex fullWidth gap="24px">
          <GeneralHeader
            title="Booking Details"
            style={{ background: headerColor }}
          />
          <CardNeu rounded style={{ background: cardColor }}>
            <ContainerX padding={{ all: "20px" }}>
              <ColumnFlex gap="14px">
                <ColumnFlex>
                  <span
                    className="fw-700 fs-16 color-text click-text"
                    onClick={() => setOpenPurchaserInfoDialog(true)}
                    style={{ color: textColor }}
                  >
                    {unitDetailsData.getSalesUnitDetails.PurchaserName || "-"}
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {unitDetailsData.getSalesUnitDetails.PurchaserIdentityNo ||
                      "-"}
                  </span>
                </ColumnFlex>
                <RowGrid gridTemplateColumns="1fr 1fr">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Contact No.
                    </span>
                    <RowFlex
                      className="fs-12"
                      gap="5px"
                      crossAxisAlignment="center"
                    >
                      <LocalPhoneIcon fontSize="inherit" color="primary" />
                      <a
                        href={`tel:${unitDetailsData.getSalesUnitDetails.PurchaserMobileNo}`}
                        className="fw-800 fs-10 color-orange click-text"
                      >
                        {unitDetailsData.getSalesUnitDetails
                          .PurchaserMobileNo || "-"}
                      </a>
                    </RowFlex>
                  </ColumnFlex>
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">Email</span>
                    <RowFlex
                      className="fs-12"
                      gap="5px"
                      crossAxisAlignment="center"
                    >
                      <EmailIcon fontSize="inherit" color="primary" />
                      <a
                        href={`mailto:${unitDetailsData.getSalesUnitDetails.PurchaserEmail}`}
                        className="fw-800 fs-10 color-orange click-text"
                      >
                        {unitDetailsData.getSalesUnitDetails.PurchaserEmail ||
                          "-"}
                      </a>
                    </RowFlex>
                  </ColumnFlex>
                </RowGrid>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>
          <CardNeu rounded style={{ background: cardColor }}>
            <ContainerX padding={{ all: "18px" }}>
              <ColumnFlex gap="12px">
                <RowFlex className="fs-12 fw-700" gap="8px">
                  <span className="color-text">Booking No:</span>
                  <span className="color-text-light-blue">
                    {unitDetailsData.getSalesUnitDetails.SalesNo || "-"}
                  </span>
                </RowFlex>
                <RowGrid gap="10px">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">Unit No.</span>
                    <div
                      onClick={() => setOpenUnitInfoDialog(true)}
                      className="click-able"
                    >
                      <RowFlex gap="4px">
                        <span className="fw-600 fs-12 color-orange click-text">
                          {unitDetailsData.getSalesUnitDetails.UnitNo || "-"}
                        </span>
                        <img src={BumiIcon} />
                      </RowFlex>
                    </div>
                  </ColumnFlex>
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Layout Type
                    </span>
                    <span className="fw-600 fs-12 color-text">
                      {unitDetailsData.getSalesUnitDetails.UnitLayoutType ||
                        "-"}
                    </span>
                  </ColumnFlex>
                </RowGrid>

                <div
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
                ></div>

                <RowGrid gap="10px">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Sales Date
                    </span>
                    <TextX
                      className="fw-600 fs-12 color-text"
                      data={unitDetailsData.getSalesUnitDetails.SaleDate}
                      dataType="Date"
                    />
                  </ColumnFlex>
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">Sign Date</span>
                    <TextX
                      className="fw-600 fs-12 color-text"
                      data={unitDetailsData.getSalesUnitDetails.SaleSignDate}
                      dataType="Date"
                    />
                  </ColumnFlex>
                </RowGrid>
                <RowGrid gap="10px">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Sales Person / Agent
                    </span>
                    <span className="fw-600 fs-12 color-text">
                      {unitDetailsData.getSalesUnitDetails.SalesPerson || "-"}
                    </span>
                  </ColumnFlex>

                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Contract No.
                    </span>
                    <span className="fw-600 fs-12 color-text">
                      {unitDetailsData.getSalesUnitDetails.ContractNo || "-"}
                    </span>
                  </ColumnFlex>
                </RowGrid>
                <RowGrid gap="10px">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">Package</span>
                    <span className="fw-600 fs-12 color-text text-overflow">
                      {unitDetailsData.getSalesUnitDetails.PackageName || "-"}
                    </span>
                  </ColumnFlex>

                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Package Price
                    </span>
                    <TextX
                      className="fw-600 fs-12 color-text"
                      data={unitDetailsData.getSalesUnitDetails.PackagePrice}
                      dataType="Number"
                    />
                  </ColumnFlex>
                </RowGrid>
                <RowGrid gap="10px">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">Discount</span>
                    <span className="fw-600 fs-12 color-text">
                      {unitDetailsData.getSalesUnitDetails.DiscountName || "-"}
                    </span>
                  </ColumnFlex>

                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Discount Price
                    </span>
                    <TextX
                      className="fw-600 fs-12 color-text"
                      data={unitDetailsData.getSalesUnitDetails.DiscountPrice}
                      dataType="Number"
                    />
                  </ColumnFlex>
                </RowGrid>
                <RowGrid gap="10px">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      List Price
                    </span>
                    <TextX
                      className="fw-600 fs-12 color-text"
                      data={unitDetailsData.getSalesUnitDetails.UnitListPrice}
                      dataType="Number"
                    />
                  </ColumnFlex>

                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Selling Price
                    </span>
                    <TextX
                      className="fw-600 fs-12 color-text"
                      data={
                        unitDetailsData.getSalesUnitDetails.SaleSellingPrice
                      }
                      dataType="Number"
                    />
                  </ColumnFlex>
                </RowGrid>
                <RowGrid gap="10px">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">Remarks</span>
                    <span className="fw-600 fs-12 color-text">
                      {unitDetailsData.getSalesUnitDetails.SaleRemark || "-"}
                    </span>
                  </ColumnFlex>

                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Sub-Product Price
                    </span>
                    <TextX
                      data={unitDetailsData.getSalesUnitDetails.SubProductPrice}
                      dataType="Number"
                      className="fw-600 fs-12 color-text"
                    />
                  </ColumnFlex>
                </RowGrid>
                <RowGrid gap="10px">
                  <ColumnFlex>
                    <span className="fw-500 fs-10 color-text-2">
                      Progress %
                    </span>
                    <TextX
                      data={
                        unitDetailsData.getSalesUnitDetails.SaleBillProgress
                      }
                      dataType="Percentage"
                      className="fw-600 fs-12 color-text"
                    />
                  </ColumnFlex>

                  <ColumnFlex></ColumnFlex>
                </RowGrid>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>

          {/* Booking Fee Item */}
          {unitDetailsData.getSalesUnitDetails.BookingFeeAttached && (
            <CardNeu style={{ background: cardColor }}>
              <div onClick={handleJumpToBookingFee} className="click-able">
                <ContainerX
                  padding={{
                    symmetric: { horizontal: "16px", vertical: "14px" },
                  }}
                >
                  <RowFlex
                    mainAxisAlignment="space-between"
                    crossAxisAlignment="center"
                    className="fw-700 fs-12 color-text"
                  >
                    <RowFlex crossAxisAlignment="center" gap="16px">
                      <Icon>
                        <img src={BookingFeeIcon} alt="purchaserDocumentIcon" />
                      </Icon>
                      <ColumnFlex gap="1px">
                        <span>Booking Fee</span>
                        {unitDetailsData?.getSalesUnitDetails
                          ?.BookingFeePaymentType !== "-" && (
                          <RowFlex className="fw-500 fs-10 color-text-2">
                            <span>
                              {
                                unitDetailsData?.getSalesUnitDetails
                                  ?.BookingFeePaymentType
                              }
                              :{" "}
                            </span>
                            <TextX
                              data={
                                unitDetailsData?.getSalesUnitDetails
                                  ?.BookingFeeAmt
                              }
                              dataType="Number"
                            />
                          </RowFlex>
                        )}
                      </ColumnFlex>
                    </RowFlex>
                    <ArrowForwardIosRoundedIcon sx={{ width: "16px" }} />
                  </RowFlex>
                </ContainerX>
              </div>
            </CardNeu>
          )}

          {/* Joint Buyer Item */}
          <CardNeu style={{ background: cardColor }}>
            <div onClick={handleJumpToJointBuyerListing} className="click-able">
              <ContainerX
                padding={{
                  symmetric: { horizontal: "16px", vertical: "14px" },
                }}
              >
                <RowFlex
                  mainAxisAlignment="space-between"
                  crossAxisAlignment="center"
                  className="fw-700 fs-12 color-text"
                >
                  <RowFlex crossAxisAlignment="center" gap="16px">
                    <Icon>
                      <img src={JointBuyerIcon} alt="jointBuyerIcon" />
                    </Icon>
                    <span>
                      Joint Buyer (
                      {unitDetailsData.getSalesUnitDetails.JointBuyerCount || 0}
                      )
                    </span>
                  </RowFlex>
                  <ArrowForwardIosRoundedIcon sx={{ width: "16px" }} />
                </RowFlex>
              </ContainerX>
            </div>
          </CardNeu>

          {/* Purchaser Document Item */}
          <CardNeu style={{ background: cardColor }}>
            <div onClick={handleJumpToPurchaserDocument} className="click-able">
              <ContainerX
                padding={{
                  symmetric: { horizontal: "16px", vertical: "14px" },
                }}
              >
                <RowFlex
                  mainAxisAlignment="space-between"
                  crossAxisAlignment="center"
                  className="fw-700 fs-12 color-text"
                >
                  <RowFlex crossAxisAlignment="center" gap="16px">
                    <Icon>
                      <img
                        src={PurchaserDocumentIcon}
                        alt="purchaserDocumentIcon"
                      />
                    </Icon>
                    <ColumnFlex gap="1px">
                      <span>
                        Purchaser Document (
                        {unitDetailsData.getSalesUnitDetails
                          .SaleAttachmentCount || 0}
                        )
                      </span>
                      <span className="fw-500 fs-10 color-text-2">
                        NRIC, Credit Report, PDPA Consent, Booking Form
                      </span>
                    </ColumnFlex>
                  </RowFlex>
                  <ArrowForwardIosRoundedIcon sx={{ width: "16px" }} />
                </RowFlex>
              </ContainerX>
            </div>
          </CardNeu>

          {/* Signing Information */}
          {unitDetailsData.getSalesUnitDetails.SaleStatus === "SIGNED" && (
            <CardNeu style={{ background: cardColor }}>
              <div onClick={handleJumpToSigningInfo} className="click-able">
                <ContainerX
                  padding={{
                    symmetric: { horizontal: "16px", vertical: "14px" },
                  }}
                >
                  <RowFlex
                    mainAxisAlignment="space-between"
                    crossAxisAlignment="center"
                    className="fw-700 fs-12 color-text"
                  >
                    <RowFlex crossAxisAlignment="center" gap="16px">
                      <Icon>
                        <img
                          src={SnPSigningInfoIcon}
                          alt="purchaserDocumentIcon"
                        />
                      </Icon>
                      <span>Signing Information</span>
                    </RowFlex>
                    <ArrowForwardIosRoundedIcon sx={{ width: "16px" }} />
                  </RowFlex>
                </ContainerX>
              </div>
            </CardNeu>
          )}
          {/* Township Details */}
          {unitDetailsData.getSalesUnitDetails.IntegrationType === "DOTNET" && (
          <CardNeu style={{ background: cardColor }}>
            <div onClick={handleJumpToTownshipDetails} className="click-able">
              <ContainerX
                padding={{
                  symmetric: { horizontal: "16px", vertical: "14px" },
                }}
              >
                <RowFlex
                  mainAxisAlignment="space-between"
                  crossAxisAlignment="center"
                  className="fw-700 fs-12 color-text"
                >
                  <RowFlex crossAxisAlignment="center" gap="16px">
                    <Icon>
                      <img
                        src={SalesGalleryIcon}
                        alt="SalesGalleryIcon"
                      />
                    </Icon>
                    <ColumnFlex gap="1px">
                      <span>
                        Township Information
                      </span>
                    </ColumnFlex>
                  </RowFlex>
                  <ArrowForwardIosRoundedIcon sx={{ width: "16px" }} />
                </RowFlex>
              </ContainerX>
            </div>
          </CardNeu>
          )}
        </ColumnFlex>
        <SimpleDialog
          visible={openPurchaserInfoDialog}
          onClose={() => setOpenPurchaserInfoDialog(false)}
          background={cardColor}
        >
          <ColumnFlex gap="12px">
            <span className="fw-700 fs-16 color-text text-with-shadow">
              Purchaser Info
            </span>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">
                Full Name (as per NRIC)
              </span>
              <span className="color-text fw-600 fs-12">
                {unitDetailsData.getSalesUnitDetails.PurchaserName || "-"}
              </span>
            </ColumnFlex>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">
                NRIC No. / Passport No. / ROC No.
              </span>
              <span className="color-text fw-600 fs-12">
                {unitDetailsData.getSalesUnitDetails.PurchaserIdentityNo || "-"}
              </span>
            </ColumnFlex>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">
                Correspondence Address
              </span>
              <span className="color-text fw-800 fs-12">
                {unitDetailsData.getSalesUnitDetails.PurchaserAddress || "-"}
              </span>
            </ColumnFlex>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">Mobile No.</span>
              <a
                href={`tel:${unitDetailsData.getSalesUnitDetails.PurchaserMobileNo}`}
                className="color-text click-text fw-800 fs-12"
              >
                {unitDetailsData.getSalesUnitDetails.PurchaserMobileNo || "-"}
              </a>
            </ColumnFlex>
            <ColumnFlex>
              <span className="fw-500 fs-10 color-text-2">Email</span>
              <a
                href={`mailto:${unitDetailsData.getSalesUnitDetails.PurchaserEmail}`}
                className="color-text click-text fw-800 fs-12"
              >
                {unitDetailsData.getSalesUnitDetails.PurchaserEmail || "-"}
              </a>
            </ColumnFlex>
          </ColumnFlex>
        </SimpleDialog>

        <SimpleDialog
          visible={openUnitInfoDialog}
          onClose={() => setOpenUnitInfoDialog(false)}
          background={cardColor}
        >
          <ColumnFlex gap="12px">
            <span className="fw-700 fs-16 color-text text-with-shadow">
              Unit Info
            </span>
            <RowGrid gap="5px" gridTemplateColumns="1fr 1fr">
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Unit No.</span>
                <span className="fw-600 fs-12 color-text">
                  {unitDetailsData.getSalesUnitDetails.UnitNo || "-"}
                </span>
              </ColumnFlex>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Block</span>
                <span className="fw-600 fs-12 color-text">
                  {unitDetailsData.getSalesUnitDetails.UnitBlock || "-"}
                </span>
              </ColumnFlex>
            </RowGrid>
            <RowGrid gap="5px" gridTemplateColumns="1fr 1fr">
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Layout Type</span>
                <span className="fw-600 fs-12 color-text">
                  {unitDetailsData.getSalesUnitDetails.UnitLayoutType || "-"}
                </span>
              </ColumnFlex>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Direction</span>
                <span className="fw-600 fs-12 color-text">
                  {unitDetailsData.getSalesUnitDetails.UnitDirection || "-"}
                </span>
              </ColumnFlex>
            </RowGrid>
            <RowGrid gap="5px" gridTemplateColumns="1fr 1fr">
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Build Up Area</span>
                <span className="fw-600 fs-12 color-text">
                  {unitDetailsData.getSalesUnitDetails.UnitBuildUp || "-"}
                </span>
              </ColumnFlex>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Rate (sqft/sqm)
                </span>
                <TextX
                  data={unitDetailsData.getSalesUnitDetails.UnitLandRate}
                  dataType="Percentage"
                  className="fw-600 fs-12 color-text"
                />
              </ColumnFlex>
            </RowGrid>
            <RowGrid gap="5px" gridTemplateColumns="1fr 1fr">
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Layout Type</span>
                <span className="fw-600 fs-12 color-text">
                  {unitDetailsData.getSalesUnitDetails.UnitLayoutType || "-"}
                </span>
              </ColumnFlex>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Direction</span>
                <span className="fw-600 fs-12 color-text">
                  {unitDetailsData.getSalesUnitDetails.UnitDirection || "-"}
                </span>
              </ColumnFlex>
            </RowGrid>
            <RowGrid gap="5px" gridTemplateColumns="1fr 1fr">
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Extra Land Price
                </span>
                <span className="fw-600 fs-12 color-text">
                  {unitDetailsData.getSalesUnitDetails.UnitExtraLandPrice ||
                    "-"}
                </span>
              </ColumnFlex>
              <ColumnFlex></ColumnFlex>
            </RowGrid>
          </ColumnFlex>
        </SimpleDialog>
      </>
    );
  }
};

export default BookingDetails;
