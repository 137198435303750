import React, { useState, useEffect } from "react";
import LoadingIcon from "../../assets/images/icon/loading.png";
import "./Spinner.css";
import { ColumnFlex } from "../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../components/LayoutX/Flex/RowFlex";
import { ProgressCircular } from "ui-neumorphism";

type PixelValue = `${number}px`;

type SpinnerProps = {
  message?: string;
  direction?: "horizontal" | "vertical";
  style?: React.CSSProperties;
  // size?: "small" | "medium" | "large" | PixelValue;
  size?: number;
};

const Spinner: React.FC<SpinnerProps> = ({
  direction = "vertical",
  message,
  // size = "medium",
  size = 24,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(true);
  // const sizeValue = {
  //   small: "20px",
  //   medium: "40px",
  //   large: "60px",
  // };

  if (isVisible) {
    return direction === "vertical" ? (
      <ColumnFlex
        gap="10px"
        fullWidth
        crossAxisAlignment="center"
        mainAxisAlignment="center"
        {...props}
      >
        <ProgressCircular indeterminate size={size} color={"#2f5cff"} />
        {message && (
          <span className="fw-500 fs-12 color-text-2 disable-full-uppercase">
            {message}
          </span>
        )}
      </ColumnFlex>
    ) : (
      <RowFlex
        gap="10px"
        fullWidth
        mainAxisAlignment="center"
        crossAxisAlignment="center"
      >
        <ProgressCircular indeterminate size={size} color={"#2f5cff"} />
        {message && (
          <span className="fw-500 fs-12 color-text-2 disable-full-uppercase">
            {message}
          </span>
        )}
      </RowFlex>
    );
  }

  return null;
};

export default Spinner;
