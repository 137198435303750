import { Button, IconButton } from "ui-neumorphism";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { useNavigate } from "react-router";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useSearchParams } from "react-router-dom";
import CardNeu from "../../../../components/Card/Card";
import { useEffect } from "react";
import { GENERAL_PATH } from "../../../../containers/modules/GeneralPages/GeneralRoutes";
import ContainerX from "../../../../components/general/ContainerX";
import { useGetSaleAttachmentLazyQuery } from "../../../../generated/graphql";
import pdfIcon from "../../../../assets/images/icon/pdf.png";
import imageIcon from "../../../../assets/images/icon/image-icon.svg";
import TextX from "../../../../components/general/TextX";
import { Avatar, Icon } from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import { saveAs } from "file-saver";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import EmptyRecord from "../../../../components/general/EmptyRecord";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

import { useContext } from "react";

const PurchaserDocumentListing = () => {
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const saleId = searchParams.get("saleId");
  const projectId = searchParams.get("projectId");
  const isIntegrate = searchParams.get("isIntegrate");
  const [
    getSaleAttachment,
    { data: saleAttachmentData, loading: saleAttachmentLoading },
  ] = useGetSaleAttachmentLazyQuery();
  useEffect(() => {
    if (
      !projectId ||
      !saleId ||
      isIntegrate === undefined ||
      isIntegrate === null ||
      isIntegrate === "" ||
      isIntegrate === "null" ||
      isIntegrate === "undefined"
    ) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getSaleAttachment({
        variables: {
          saleId: saleId,
          projectId: projectId,
          isIntegrate: isIntegrate === "true" ? true : false,
        },
      });
    }
  }, []);

  const downloadAttachment = async (
    fileURL: string,
    fileName: string,
    fileType: string
  ) => {
    saveAs(fileURL, fileName);
  };

  if (saleAttachmentData) {
    return (
      <ColumnFlex fullWidth gap="24px">
        <GeneralHeader
          title="Purchaser Document"
          style={{ background: headerColor }}
        />
        <CardNeu rounded style={{ background: cardColor }}>
          <ColumnFlex>
            {saleAttachmentData?.getSaleAttachment?.length === 0 && (
              <EmptyRecord hasSubLable onClick={() => {}} />
            )}
            {saleAttachmentData?.getSaleAttachment.length > 0 &&
              saleAttachmentData?.getSaleAttachment?.map((item, index) => (
                <ContainerX
                  key={`${item?.FileName}-${index}`}
                  padding={{
                    only: {
                      top: "14px",
                      bottom: "14px",
                      left: "16px",
                      right: "16px",
                    },
                  }}
                  className={`${
                    index !== saleAttachmentData?.getSaleAttachment.length - 1
                      ? "divider-bottom-border"
                      : ""
                  }`}
                >
                  <RowFlex
                    mainAxisAlignment="space-between"
                    crossAxisAlignment="center"
                  >
                    <RowFlex gap="15px" crossAxisAlignment="center">
                      <Avatar
                        src={
                          item?.FileType === "application/pdf"
                            ? pdfIcon
                            : imageIcon
                        }
                      />
                      <ColumnFlex>
                        <span className="fs-12 fw-600 color-text">
                          {item?.FileName}
                        </span>
                        <TextX
                          data={item?.DocumentDate}
                          dataType="Date"
                          className="fs-12 fw-500 color-text-2"
                        />
                      </ColumnFlex>
                    </RowFlex>
                    <IconButton
                      rounded
                      onClick={() =>
                        downloadAttachment(
                          item?.FileContent,
                          item?.FileName,
                          item?.FileType
                        )
                      }
                    >
                      <FileDownloadRoundedIcon />
                    </IconButton>
                  </RowFlex>
                </ContainerX>
              ))}
          </ColumnFlex>
        </CardNeu>
      </ColumnFlex>
    );
  }
};

export default PurchaserDocumentListing;
