import { Button } from "ui-neumorphism";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { useNavigate } from "react-router";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useSearchParams } from "react-router-dom";
import CardNeu from "../../../../components/Card/Card";
import { useEffect, useRef, useState } from "react";
import { GENERAL_PATH } from "../../../../containers/modules/GeneralPages/GeneralRoutes";
import ContainerX from "../../../../components/general/ContainerX";
import { useGetListSiteProgressLazyQuery } from "../../../../generated/graphql";
import TextX from "../../../../components/general/TextX";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  isDesktop_minWidth,
  useMediaQuery,
} from "../../../../useHook/useMediaQuery";
import Expanded from "../../../../components/LayoutX/Expanded";
import ZoomedImageDialog from "../../../../components/Dialog/ZoomedImageDialog";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import CustomPagination from "../../../../components/Pagination/CustomPagination";
import EmptyRecord from "../../../../components/general/EmptyRecord";
import ImageSwiper from "./SiteProgressImageSwiper";
import "./SiteProgress.scss";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

import { useContext } from "react";

const SiteProgress = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const isIntegrate = searchParams.get("isIntegrate");
  const unitId = searchParams.get("unitId");
  const isDesktop: boolean = useMediaQuery(isDesktop_minWidth);
  const [ZoomedImageIndex, setZoomedImageIndex] = useState(-1); //-1 means no zoomed image
  const parentRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(300);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  //pagination
  const limit = 30;
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [
    getListSiteProgress,
    { data: siteProgressData, loading: siteProgressLoading },
  ] = useGetListSiteProgressLazyQuery({
    onCompleted: (data) => {
      setTotalPages(data?.getListSiteProgress?.total);
    },
  });

  useEffect(() => {
    if (
      !projectId ||
      !unitId ||
      isIntegrate === undefined ||
      isIntegrate === null ||
      isIntegrate === "" ||
      isIntegrate === "null" ||
      isIntegrate === "undefined"
    ) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getListSiteProgress({
        variables: {
          projectId: projectId,
          unitId: unitId,
          isIntegrate: isIntegrate === "true",
          limit: limit,
          page: currentPage,
          search: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    setImageWidth(parentRef.current?.clientWidth || 300);
  }, [parentRef.current?.clientWidth]);

  useEffect(() => {
    getListSiteProgress({
      variables: {
        projectId: projectId,
        unitId: unitId,
        isIntegrate: isIntegrate === "true",
        limit: limit,
        page: currentPage,
        search: null,
      },
    });
  }, [currentPage]);

  if (siteProgressData?.getListSiteProgress) {
    return (
      <>
        <ColumnFlex fullWidth gap="24px">
          <GeneralHeader
            title="Site Progress"
            style={{ background: headerColor }}
          />
          <CardNeu style={{ background: cardColor }}>
            <ContainerX
              padding={{ symmetric: { horizontal: "16px", vertical: "14px" } }}
            >
              <div>
                <RowFlex mainAxisAlignment="space-between">
                  <span className="fw-700 fs-16 color-text">
                    {siteProgressData.getListSiteProgress.unitNo}
                  </span>
                  <RowFlex
                    className="color-text-light-blue fs-12"
                    crossAxisAlignment="center"
                    gap="2px"
                  >
                    <MonetizationOnIcon fontSize="inherit" />
                    <TextX
                      dataType="Number"
                      data={siteProgressData.getListSiteProgress.price}
                      className="fw-800"
                    />
                  </RowFlex>
                </RowFlex>
              </div>
            </ContainerX>
          </CardNeu>

          {(!siteProgressData?.getListSiteProgress?.siteData ||
            siteProgressData.getListSiteProgress.siteData.length === 0) && (
            <EmptyRecord />
          )}

          <ContainerX>
            {siteProgressData?.getListSiteProgress?.siteData?.map(
              (item, index) => (
                <RowFlex key={`${item.title}_${index}`}>
                  {isDesktop && index % 2 === 0 ? (
                    <Expanded>
                      <ContainerX padding={{ symmetric: { vertical: "25px" } }}>
                        <ColumnFlex gap="8px" fullWidth>
                          <ColumnFlex>
                            <TextX
                              dataType="Date"
                              data={item.publishDate}
                              className="color-text fw-700 fs-12"
                            />
                            <span className="fw-500 fs-10 color-text-2">
                              {item.title}
                            </span>
                          </ColumnFlex>
                          {item?.attachment?.length === 1 && (
                            <CardNeu>
                              <RowFlex
                                key={item?.attachment[0].resourceUrl}
                                className="inherit-border-radius"
                              >
                                <img
                                  src={item?.attachment[0]?.resourceUrl}
                                  alt=""
                                  className="site-progress-img"
                                  onClick={() => setZoomedImageIndex(index)}
                                />
                              </RowFlex>
                            </CardNeu>
                          )}
                          {item?.attachment?.length > 1 && (
                            <CardNeu
                              // width={imageWidth.toString() + "px"}
                              style={{
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                              className="site-progress-swiper-item"
                            >
                              <ImageSwiper attachments={item.attachment} />
                            </CardNeu>
                          )}
                        </ColumnFlex>
                      </ContainerX>
                    </Expanded>
                  ) : isDesktop ? (
                    <Expanded></Expanded>
                  ) : (
                    <div></div>
                  )}
                  <RowFlex>
                    <div className="timeline">
                      <div className="timeline-dot"></div>
                    </div>
                  </RowFlex>
                  {!isDesktop || index % 2 !== 0 ? (
                    <Expanded>
                      <ContainerX padding={{ symmetric: { vertical: "25px" } }}>
                        <ColumnFlex gap="8px">
                          <ColumnFlex>
                            <TextX
                              dataType="Date"
                              data={item.publishDate}
                              className="color-text fw-700 fs-12"
                            />
                            <span
                              className="fw-500 fs-10 color-text-2"
                              ref={parentRef}
                            >
                              {item.title}
                            </span>
                          </ColumnFlex>
                          {item?.attachment?.length === 1 && (
                            <CardNeu>
                              <RowFlex
                                key={item?.attachment[0].resourceUrl}
                                className="inherit-border-radius"
                              >
                                <img
                                  src={item?.attachment[0]?.resourceUrl}
                                  alt=""
                                  className="site-progress-img"
                                  onClick={() => setZoomedImageIndex(index)}
                                />
                              </RowFlex>
                            </CardNeu>
                          )}
                          {item?.attachment?.length > 1 && (
                            <CardNeu
                              // width={imageWidth.toString() + "px"}
                              style={{
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                              className="site-progress-swiper-item"
                            >
                              <ImageSwiper attachments={item.attachment} />
                            </CardNeu>
                          )}
                        </ColumnFlex>
                      </ContainerX>
                    </Expanded>
                  ) : isDesktop ? (
                    <Expanded></Expanded>
                  ) : (
                    <div></div>
                  )}
                  <ZoomedImageDialog
                    visible={ZoomedImageIndex !== -1}
                    onClose={() => setZoomedImageIndex(-1)}
                    src={
                      siteProgressData.getListSiteProgress.siteData[
                        ZoomedImageIndex
                      ]?.attachment[0].resourceUrl
                    }
                  />
                </RowFlex>
              )
            )}
          </ContainerX>

          {totalPages > limit && (
            <ContainerX className="margin-horizontal-center">
              <CustomPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                totalPages={totalPages}
              />
            </ContainerX>
          )}
        </ColumnFlex>
      </>
    );
  }
};

export default SiteProgress;
