import { RouteObject } from "react-router";
import Profile from "../Others/Profile";
import EditProfile from "../Others/EditProfile";
import ResetPassword from "./ResetPassword";

export const OTHERS_PATH = {
  PROFILE: `/:accountCode/others/my-profile`,
  EDIT_PROFILE: `/:accountCode/others/edit-profile`,
  RESET_PASSWORD: `/:accountCode/others/reset-password`,
} as const;

const OthersRoutes: RouteObject[] = [
  {
    path: OTHERS_PATH.PROFILE,
    element: <Profile />,
  },
  {
    path: OTHERS_PATH.EDIT_PROFILE,
    element: <EditProfile />,
  },
  {
    path: OTHERS_PATH.RESET_PASSWORD,
    element: <ResetPassword />,
  },
];

export default OthersRoutes;
