import { NavigateBeforeRounded } from "@mui/icons-material";
import { RowFlex } from "../LayoutX/Flex/RowFlex";
import { useNavigate } from "react-router";
import { Button } from "ui-neumorphism";
import { buttonChangeColor } from "../../containers/context/ButttonContext";

type GeneralHeaderProps = {
  title: string;
  backTo?: string;
  style?: React.CSSProperties;
};

const GeneralHeader: React.FC<GeneralHeaderProps> = ({
  title,
  backTo,
  style,
}) => {
  const navigate = useNavigate();
  const { buttonColor } = buttonChangeColor();

  const handleBackPrev = () => {
    if (backTo === undefined || !backTo || backTo === "") return navigate(-1);
    else navigate(backTo);
  };

  return (
    <div className="general-header" style={style}>
      <RowFlex crossAxisAlignment="center" gap="20px" fullWidth>
        <Button onClick={handleBackPrev} style={{ background: buttonColor }}>
          <NavigateBeforeRounded className="color-text-light-blue" />
        </Button>
        <span className="fs-16 fw-700 text-with-shadow">{title}</span>
      </RowFlex>
    </div>
  );
};

export default GeneralHeader;
