import React, { CSSProperties } from 'react'

type Props = {
  flex?: CSSProperties['flex']
  children?: React.ReactNode
}

const Expanded = ({ flex = 1, children }: Props) => {
  return <div style={{ flex: flex }}>{children}</div>
}

export default Expanded
