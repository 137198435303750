import React from 'react'
import { FlexX } from '../FlexX'
import {
  AlignItemsEnum,
  JustifyContentEnum,
  OverflowEnum,
  verticalAlignEnum,
} from '../LayoutXenum'

type ColumnFlexProps = {
  children?: React.ReactNode
  className?: string
  crossAxisAlignment?: AlignItemsEnum
  fullWidth?: boolean
  gap?: string
  height?: string
  mainAxisAlignment?: JustifyContentEnum
  overflow?: OverflowEnum
  verticalAlign?: verticalAlignEnum
}

export const ColumnFlex = ({
  children,
  className,
  crossAxisAlignment,
  fullWidth,
  gap,
  height,
  mainAxisAlignment,
  overflow,
  verticalAlign,
}: ColumnFlexProps) => {
  return (
    <FlexX
      children={children}
      className={className}
      crossAxisAlignment={crossAxisAlignment}
      flexDirection="column"
      fullWidth={fullWidth}
      gap={gap}
      height={height}
      mainAxisAlignment={mainAxisAlignment}
      overflow={overflow}
      verticalAlign={verticalAlign}
    />
  )
}
