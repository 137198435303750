import {
  isEmpty,
  isValidEmail,
  validationResponse,
} from "../../containers/helpers/InputValidation";
import { ColumnFlex } from "../../components/LayoutX/Flex/ColumnFlex";
import React, { useEffect, useState } from "react";
import { TextField } from "ui-neumorphism";

type InputType = "text" | "email" | "password";

type TextFieldProps = {
  placeholder?: string;
  showWarning?: boolean;
  warningContent?: string;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: InputType;
  className?: string;
  value?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  name?: string;
  id?: string;
  style?: React.CSSProperties;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  validTest?: boolean;
  handleIsValid?: (isValid: boolean) => void;
  label?: string;
};

const TextFieldNeu: React.FC<TextFieldProps> = ({
  showWarning = false,
  warningContent = "Please input warning message...",
  className = "",
  type = "text",
  onInput,
  validTest = false,
  label,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showWarningContent, setShowWarningContent] = useState(false);
  const [warningContentState, setWarningContentState] =
    useState(warningContent);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.trim() === "") {
      setShowWarningContent(true);
      setWarningContentState("Empty Content");
    } else {
      setShowWarningContent(false);
    }

    if (!isEmpty(value).result) {
      const isValidContent = isValidFormat();
      if (!isValidContent) {
        setShowWarningContent(true);
      } else {
        setShowWarningContent(false);
      }
    }

    if (onInput) {
      onInput(event);
    }
  };

  const isValidFormat = (): boolean => {
    if (!validTest) return true;

    let validationResp: validationResponse = {
      result: false,
      message: "Error!",
    };

    if (type === "email") {
      validationResp = isValidEmail(inputValue);
    } else if (type === "password") {
      validationResp = isEmpty(inputValue);
      validationResp.result = !validationResp.result; //reverse the result
    } else if (type === "text") {
      validationResp = isEmpty(inputValue);
    }

    setShowWarningContent(!validationResp.result);
    setWarningContentState(validationResp.message);

    return validationResp.result;
  };

  useEffect(() => {
    if (validTest && props.handleIsValid && handleInputChange) {
      props.handleIsValid(!showWarningContent);
    }
  }, [showWarningContent]);

  return (
    <ColumnFlex fullWidth>
      <TextField
        className={`clear-style text-field ${className} ${
          props.disabled ? "disable-hover-style" : ""
        }`}
        onInput={handleInputChange}
        value={inputValue}
        type={type}
        placeholder={props.placeholder}
        label={label}
        disabled={props.disabled}
      />
      {showWarning && validTest && showWarningContent && (
        <span className="color-red fs-14">{warningContentState}</span>
      )}
    </ColumnFlex>
  );
};

export default TextFieldNeu;
