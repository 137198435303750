import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../GeneralPages/GeneralRoutes";
import React, { useEffect, useState } from "react";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Button, IconButton } from "ui-neumorphism";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  useGetNewsListingLazyQuery,
  useGetProjectDetailsForPublicLazyQuery,
  useProjectDetailLazyQuery,
  ProjectDetailQuery,
  useAddLeadMutation,
} from "../../../generated/graphql";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Positioned, Stack } from "../../../components/LayoutX/Stack";
import ContainerX from "../../../components/general/ContainerX";
import newsImage01 from "../../../assets/images/background/weatherbg.jpg";
import TextX from "../../../components/general/TextX";
import CardNeu from "../../../components/Card/Card";
import { DASHBOARDS_PATH } from "../Dashboards/DashboardRoutes";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import {
  ContentCopyRounded,
  EmailRounded,
  GridOnRounded,
  LocationOnRounded,
  NavigateBeforeRounded,
  NavigateNextRounded,
  PlayArrow,
  ShareRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import projectIcon from "../../../assets/images/icon/type-icon.png";
import sizeIcon from "../../../assets/images/icon/sqft-icon.png";
import { Avatar, Icon, Button as Mui_Button } from "@mui/material";
import { RowGrid } from "../../../components/LayoutX/Grid/RowGrid";
import { format } from "date-fns";
import ProjectSwiper from "./ProjectSwiper";
import FloorPlanSwiper from "./FloorPlanSwiper";
import UnitLayoutSwiper from "./UnitLayoutSwiper";
import Slider from "react-slick";
import GoogleMapLogo from "../../../assets/images/icon/google_map.svg";
import WazeLogo from "../../../assets/images/icon/waze.svg";
import { useSnackbar } from "react-simple-snackbar";
import { BOOKING_PATH } from "../Booking/BookingRoutes";
import SimpleDialog from "../../../components/Dialog/SimpleDialog";
import WhatsappIcon from "../../../assets/images/whatsapp.png";
import QRCode from "react-qr-code";
import CustomizeHeader from "../../../components/Header/CustomizeHeader";
import EmailEditor from "./EmailEditor";
import parse from "html-react-parser";
import Expanded from "../../../components/LayoutX/Expanded";
import {
  isDesktop_minWidth,
  useMediaQuery,
} from "../../../useHook/useMediaQuery";
import ZoomedImageDialog from "../../../components/Dialog/ZoomedImageDialog";
import DefaultCompanyImage from "../../../assets/images/background/weatherbg.jpg";
import "./PageDetails.scss";
import { headerChangeColor } from "../../context/Context";
import { cardChangeColor } from "../../context/CardContext";
import { buttonChangeColor } from "../../context/ButttonContext";

import { useContext } from "react";

export type EmailFormType = {
  toEmails: string[];
  subject: string;
  message: string;
};

const PageDetail = () => {
  const baseUrl = window.location.href.substring(
    0,
    window.location.href.search(window.location.pathname)
  );
  const isDesktop = useMediaQuery(isDesktop_minWidth);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const contactId = searchParams.get("contactId");
  const isIntegrate = searchParams.get("isIntegrate");
  const navigate = useNavigate();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [isZoomImage, setIsZoomImage] = useState(false);
  const [getProjectDetails, { data: projectDetailsData }] =
    useProjectDetailLazyQuery({
      fetchPolicy: "no-cache",
    });
  const [addLead] = useAddLeadMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      openSnackbar("Created lead succesfully");
      getProjectDetails({
        variables: { projectId: projectId },
      });
    },
  });

  const [shareTo, setShareTo] = useState({
    link: "",
    whatsappContent: "",
    qrCodeUrl: "",
  });
  const [isOpenEmailEditor, setIsOpenEmailEditor] = useState(false);
  const [emailForm, setEmailForm] = useState<EmailFormType>({
    toEmails: [],
    subject: "",
    message: "",
  });
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();
  const { buttonColor } = buttonChangeColor();

  useEffect(() => {
    if (projectDetailsData) {
      const link = `${baseUrl}/${getPathCookie()}/p?id=${projectDetailsData
        ?.getProjectById?.id}`;
      const whatsappContent = `Please find the link below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. ${link}`;
      setShareTo({
        link: link,
        whatsappContent: whatsappContent,
        qrCodeUrl: link,
      });
      setEmailForm({
        toEmails: [],
        subject: `${projectDetailsData?.getProjectById?.name}`,
        message: `Greetings,<br /><br />Please find the links below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. <br /> <a href="${link}">${link}</a><br><br><br>Thank you.`,
      });
    }
  }, [
    projectDetailsData?.getProjectById?.id,
    baseUrl,
    projectDetailsData?.getProjectById?.name,
  ]);

  useEffect(() => {
    //reset email form when open/close email editor
    setEmailForm({
      toEmails: [],
      subject: `${projectDetailsData?.getProjectById?.name}`,
      message: `Greetings,<br /><br />Please find the links below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. <br /> <a href="${shareTo.link}">${shareTo.link}</a><br><br><br>Thank you.`,
    });
  }, [isOpenEmailEditor]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareTo.link);
    openSnackbar("Copied to clipboard");
  };

  useEffect(() => {
    if (!projectId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getProjectDetails({
        variables: { projectId: projectId },
      });
    }
  }, []);

  const handleRegisterProject = () => {
    let params = "";
    if (contactId) {
      params = `?projectId=${projectId}&contactId=${contactId}&isIntegrate=${isIntegrate}`;
    } else {
      params = `?projectId=${projectId}`;
    }

    if (
      projectDetailsData?.getProjectById?.projectSpec[0].purchaserAppSetting ===
      "BOOKING"
    ) {
      navigate(
        `${BOOKING_PATH.BOOKING_UNIT.replace(
          ":accountCode",
          getPathCookie()
        )}${params}`
      );
      return;
    }

    if (
      projectDetailsData?.getProjectById?.projectSpec[0].purchaserAppSetting ===
      "LEAD"
    ) {
      if (
        projectDetailsData?.getProjectById?.projectSpec[0]
          .purchaserRegisteredInterest
      ) {
        openSnackbar("Already register as a lead");
        return;
      }

      addLead({
        variables: {
          contactId: contactId,
          projectId: projectId,
          isIntegrate: isIntegrate === "true",
        },
      });
    }
  };

  const handleJumpToBrochure = () => {
    navigate(
      `${DASHBOARDS_PATH.BROCHURE.replace(
        ":accountCode",
        getPathCookie()
      )}?projectId=${projectId}`
    );
  };

  const handleJumpToMortgage = () => {
    navigate(
      `${DASHBOARDS_PATH.MORTGAGE.replace(
        ":accountCode",
        getPathCookie()
      )}?project_id=${projectId}&project_name=${projectDetailsData
        ?.getProjectById?.name}&project_currency=${projectDetailsData
        ?.getProjectById?.currency}`
    );
  };

  const handleBackPrev = () => {
    navigate(-1);
  };

  if (isOpenEmailEditor) {
    return (
      <EmailEditor
        emailForm={emailForm}
        setEmailForm={setEmailForm}
        setIsOpenEmailEditor={setIsOpenEmailEditor}
      />
    );
  }

  if (projectDetailsData) {
    return (
      <>
        <ColumnFlex fullWidth gap="24px">
          <CustomizeHeader style={{ background: headerColor }}>
            <RowFlex
              crossAxisAlignment="center"
              mainAxisAlignment="space-between"
            >
              <RowFlex crossAxisAlignment="center" gap="20px">
                <Button onClick={handleBackPrev}>
                  <NavigateBeforeIcon className="color-text-light-blue" />
                </Button>
                <span className="fs-16 fw-700 text-with-shadow">
                  Sales Gallery
                </span>
              </RowFlex>
              <Button
                onClick={() => setOpenShareDialog(true)}
                bgColor={buttonColor}
              >
                <ShareRounded className="color-text-light-blue" />
              </Button>
            </RowFlex>
          </CustomizeHeader>

          {(projectDetailsData?.getProjectById?.galleryImage?.length > 0 ||
            projectDetailsData?.getProjectById?.embededYoutubeLink?.length >
              0 ||
            projectDetailsData?.getProjectById?.virtualModel?.length > 0) && (
            <ProjectSwiper projectDetailsData={projectDetailsData} />
          )}

          <CardNeu style={{ background: cardColor }}>
            <ContainerX padding={{ all: "12px" }}>
              <ColumnFlex gap="12px">
                <span className="fs-16 fw-700 color-text text-with-shadow">
                  {projectDetailsData?.getProjectById?.name}
                </span>
                <RowFlex
                  className="fw-600 fs-12 color-text-2"
                  crossAxisAlignment="center"
                  gap="4px"
                >
                  <LocationOnRounded fontSize="inherit" />
                  <span className="fs-10">
                    {projectDetailsData?.getProjectById?.saleGalleryAddress ||
                      "-"}
                  </span>
                </RowFlex>
                <RowFlex
                  className="fw-600 fs-14 color-text-2"
                  crossAxisAlignment="center"
                  gap="4px"
                >
                  <img src={sizeIcon} alt="projectIcon" />

                  {projectDetailsData?.getProjectById?.unitMinBuiltUp && (
                    <span className="fs-10">
                      fr.
                      {Number.isInteger(
                        parseFloat(
                          projectDetailsData?.getProjectById?.unitMinBuiltUp
                        )
                      )
                        ? Math.round(
                            parseFloat(
                              projectDetailsData?.getProjectById?.unitMinBuiltUp
                            )
                          ).toLocaleString("en-US")
                        : parseFloat(
                            projectDetailsData?.getProjectById?.unitMinBuiltUp
                          ).toLocaleString("en-US")}{" "}
                      {projectDetailsData?.getProjectById?.uom === "SQ_METER" &&
                        "sq.m."}
                      {projectDetailsData?.getProjectById?.uom === "SQ_FEET" &&
                        "sq.ft."}
                      {projectDetailsData?.getProjectById?.uom !== "SQ_METER" &&
                        projectDetailsData?.getProjectById?.uom !== "SQ_FEET" &&
                        projectDetailsData?.getProjectById?.uom}
                    </span>
                  )}
                  {!projectDetailsData?.getProjectById?.unitMinBuiltUp && (
                    <span className="fs-10">-</span>
                  )}
                </RowFlex>
                <RowGrid gridTemplateColumns="1fr 1fr">
                  <ColumnFlex gap="2px">
                    <span className="fw-500 fs-10 color-text-2">Township</span>
                    <span className="fs-12 fw-600 color-text">
                      {projectDetailsData?.getProjectById?.townshipName || "-"}
                    </span>
                  </ColumnFlex>
                  <ColumnFlex gap="2px">
                    <span className="fw-500 fs-10 color-text-2">Land Type</span>
                    <span className="fs-12 fw-600 color-text">
                      {projectDetailsData?.getProjectById?.landTypeDesc || "-"}
                    </span>
                  </ColumnFlex>
                </RowGrid>

                <RowGrid gridTemplateColumns="1fr 1fr">
                  <ColumnFlex gap="2px">
                    <span className="fw-500 fs-10 color-text-2">Tensure</span>
                    <span className="fs-12 fw-600 color-text">
                      {projectDetailsData?.getProjectById?.tenureDesc || "-"}
                    </span>
                  </ColumnFlex>
                  <ColumnFlex gap="2px">
                    <span className="fw-500 fs-10 color-text-2">Built-up</span>
                    {projectDetailsData?.getProjectById?.unitMinBuiltUp &&
                      projectDetailsData?.getProjectById?.unitMaxBuiltUp && (
                        <span className="fs-12 fw-600 color-text">
                          {"From "}
                          <span>
                            fr.
                            {Number.isInteger(
                              parseFloat(
                                projectDetailsData?.getProjectById
                                  ?.unitMinBuiltUp
                              )
                            )
                              ? Math.round(
                                  parseFloat(
                                    projectDetailsData?.getProjectById
                                      .unitMinBuiltUp
                                  )
                                ).toLocaleString("en-US")
                              : parseFloat(
                                  projectDetailsData?.getProjectById
                                    ?.unitMinBuiltUp
                                ).toLocaleString("en-US")}{" "}
                            {projectDetailsData?.getProjectById?.uom ===
                              "SQ_METER" && "sq.m."}
                            {projectDetailsData?.getProjectById?.uom ===
                              "SQ_FEET" && "sq.ft."}
                            {projectDetailsData?.getProjectById?.uom !==
                              "SQ_METER" &&
                              projectDetailsData?.getProjectById?.uom !==
                                "SQ_FEET" &&
                              projectDetailsData?.getProjectById?.uom}
                          </span>
                          {" - "}
                          <span>
                            fr.
                            {Number.isInteger(
                              parseFloat(
                                projectDetailsData?.getProjectById
                                  ?.unitMaxBuiltUp
                              )
                            )
                              ? Math.round(
                                  parseFloat(
                                    projectDetailsData?.getProjectById
                                      .unitMaxBuiltUp
                                  )
                                ).toLocaleString("en-US")
                              : parseFloat(
                                  projectDetailsData?.getProjectById
                                    ?.unitMaxBuiltUp
                                ).toLocaleString("en-US")}{" "}
                            {projectDetailsData?.getProjectById?.uom ===
                              "SQ_METER" && "sq.m"}
                            {projectDetailsData?.getProjectById?.uom ===
                              "SQ_FEET" && "sq.ft"}
                            {projectDetailsData?.getProjectById?.uom !==
                              "SQ_METER" &&
                              projectDetailsData?.getProjectById?.uom !==
                                "SQ_FEET" &&
                              projectDetailsData?.getProjectById?.uom}
                          </span>
                        </span>
                      )}
                    {!projectDetailsData?.getProjectById?.unitMinBuiltUp &&
                      !projectDetailsData?.getProjectById?.unitMaxBuiltUp && (
                        <span className="fs-12 fw-600 color-text">-</span>
                      )}
                  </ColumnFlex>
                </RowGrid>

                <RowGrid gridTemplateColumns="1fr 1fr">
                  <ColumnFlex gap="2px">
                    <span className="fw-500 fs-10 color-text-2">
                      Completion Year
                    </span>
                    <span className="fs-12 fw-600 color-text">
                      {projectDetailsData?.getProjectById?.completionDate
                        ? format(
                            new Date(
                              projectDetailsData?.getProjectById?.completionDate
                            ),
                            "yyyy"
                          )
                        : "-"}
                    </span>
                  </ColumnFlex>
                  <ColumnFlex gap="2px">
                    <span className="fw-500 fs-10 color-text-2">
                      Launch Date
                    </span>
                    <TextX
                      dataType="Date"
                      data={projectDetailsData?.getProjectById?.launchDate}
                      className="fs-12 fw-600 color-text"
                    />
                  </ColumnFlex>
                </RowGrid>
                <RowGrid
                  gridTemplateColumns="1fr 1fr"
                  gap="20px"
                  className="p-t-10"
                >
                  <Button
                    rounded
                    className={"custom-neu-button-style color-text"}
                    onClick={handleJumpToBrochure}
                    bgColor={buttonColor}
                  >
                    <VisibilityRounded fontSize="inherit" />
                    <span className="p-h-10">Brochure</span>
                  </Button>
                  <Button
                    rounded
                    className={"custom-neu-button-style color-text"}
                    onClick={handleJumpToMortgage}
                    bgColor={buttonColor}
                  >
                    <GridOnRounded fontSize="inherit" />
                    <span className="p-h-10">Mortgage</span>
                  </Button>
                </RowGrid>
              </ColumnFlex>
            </ContainerX>
          </CardNeu>

          {projectDetailsData?.getProjectById?.desc && (
            <CardNeu style={{ background: cardColor }}>
              <ContainerX padding={{ all: "12px" }}>
                <ColumnFlex>
                  <span className="fs-10 fw-500 color-text-2">Description</span>
                  <ColumnFlex gap="8px" className="page-details-content">
                    {projectDetailsData?.getProjectById?.desc &&
                      parse(projectDetailsData?.getProjectById?.desc)}
                  </ColumnFlex>
                </ColumnFlex>
              </ContainerX>
            </CardNeu>
          )}

          {projectDetailsData?.getProjectById?.floorGalleryImage?.length >
            0 && <FloorPlanSwiper projectDetailsData={projectDetailsData} />}

          {projectDetailsData?.getProjectById?.unitLayoutGalleryImage?.length >
            0 && <UnitLayoutSwiper projectDetailsData={projectDetailsData} />}

          <ColumnFlex gap="20px">
            {(projectDetailsData?.getProjectById?.projectAddress ||
              projectDetailsData?.getProjectById?.projectGps) && (
              <CardNeu
                rounded
                style={{ overflow: "hidden", background: cardColor }}
                margin={{ only: { top: "20px" } }}
              >
                <iframe
                  width={"100%"}
                  height="300px"
                  src={`https://maps.google.com/?q=${projectDetailsData?.getProjectById?.projectGps}&output=embed`}
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                ></iframe>
                <ContainerX
                  backgroundColor="rgba(0,0,0,.7803921568627451)"
                  width={"100%"}
                  className="color-white fw-700 fs-12"
                  padding={{
                    symmetric: { horizontal: "16px", vertical: "12px" },
                  }}
                >
                  <ColumnFlex gap="2px">
                    <span className="color-white fw-700 fs-12">
                      Project Location
                    </span>
                    <span className="color-text-2 fw-500 fs-10">
                      {projectDetailsData?.getProjectById?.projectAddress}
                    </span>

                    <RowFlex gap="10px">
                      <img
                        src={GoogleMapLogo}
                        width={"25px"}
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              projectDetailsData?.getProjectById?.projectGps
                            )}`
                          )
                        }
                      />
                      <img
                        src={WazeLogo}
                        width={"25px"}
                        onClick={() =>
                          window.open(
                            `https://www.waze.com/ul?ll=${encodeURIComponent(
                              projectDetailsData?.getProjectById?.projectGps
                            )}`
                          )
                        }
                      />
                    </RowFlex>
                  </ColumnFlex>
                </ContainerX>
              </CardNeu>
            )}

            {(projectDetailsData?.getProjectById?.saleGalleryAddress ||
              projectDetailsData?.getProjectById?.saleGalleryGps) && (
              <CardNeu
                rounded
                style={{ overflow: "hidden", background: cardColor }}
              >
                <iframe
                  width={"100%"}
                  height="300px"
                  src={`https://maps.google.com/?q=${projectDetailsData?.getProjectById?.saleGalleryGps}&output=embed`}
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                ></iframe>
                <ContainerX
                  backgroundColor="rgba(0,0,0,.7803921568627451)"
                  width={"100%"}
                  className="color-white fw-700 fs-12"
                  padding={{
                    symmetric: { horizontal: "16px", vertical: "12px" },
                  }}
                >
                  <ColumnFlex gap="2px">
                    <span className="color-white fw-700 fs-12">
                      Sales Gallery Location
                    </span>
                    <span className="color-text-2 fw-500 fs-10">
                      {projectDetailsData?.getProjectById?.saleGalleryAddress}
                    </span>

                    <RowFlex gap="10px">
                      <img
                        src={GoogleMapLogo}
                        width={"25px"}
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              projectDetailsData?.getProjectById?.saleGalleryGps
                            )}`
                          )
                        }
                      />
                      <img
                        src={WazeLogo}
                        width={"25px"}
                        onClick={() =>
                          window.open(
                            `https://www.waze.com/ul?ll=${encodeURIComponent(
                              projectDetailsData?.getProjectById?.saleGalleryGps
                            )}`
                          )
                        }
                      />
                    </RowFlex>
                  </ColumnFlex>
                </ContainerX>
              </CardNeu>
            )}
          </ColumnFlex>

          <CardNeu
            rounded
            margin={{ only: { top: "20px" } }}
            style={{ background: cardColor }}
          >
            <ContainerX
              className={"divider-bottom-border"}
              padding={{ symmetric: { vertical: "14px", horizontal: "16px" } }}
            >
              <RowFlex gap="16px" crossAxisAlignment="center">
                <div
                  className="click-able"
                  onClick={() => setIsZoomImage(true)}
                >
                  <Avatar
                    src={
                      projectDetailsData?.getProjectById?.company?.imageUrl ||
                      DefaultCompanyImage
                    }
                    sx={{ bgcolor: "transparent" }}
                  />
                </div>
                <ColumnFlex>
                  <span className="fw-700 fs-16 color-text text-with-shadow">
                    {projectDetailsData?.getProjectById?.company?.name}
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {projectDetailsData?.getProjectById?.company?.companyRegNo}
                  </span>
                </ColumnFlex>
              </RowFlex>
            </ContainerX>
            <ContainerX
              padding={{
                only: {
                  top: "14px",
                  left: "16px",
                  right: "16px",
                  bottom: "16px",
                },
              }}
              className="fw-500 fs-10 color-text-2"
            >
              {projectDetailsData?.getProjectById?.company.description}
            </ContainerX>
          </CardNeu>
        </ColumnFlex>
        <div
          className="sales-gallery-footer"
          style={{ width: !isDesktop && "100%", background: cardColor }}
        >
          <RowFlex
            mainAxisAlignment="space-between"
            crossAxisAlignment="center"
            className={`fw-700 fs-14`}
          >
            <span className="color-text-1">
              {projectDetailsData?.getProjectById?.priceRange}
            </span>
            <Mui_Button
              className="disable-full-uppercase"
              onClick={handleRegisterProject}
              // style={{ whiteSpace: "pre-wrap" }}
              sx={{ lineHeight: "1", padding: "12px 20px" }}
              variant="contained"
            >
              <span>
                {projectDetailsData?.getProjectById?.projectSpec[0]
                  .purchaserRegisteredInterest
                  ? "Registered Interest"
                  : "I'm Interested"}
              </span>
            </Mui_Button>
          </RowFlex>
        </div>
        {openShareDialog && (
          <SimpleDialog
            visible={openShareDialog}
            onClose={() => setOpenShareDialog(false)}
            background={cardColor}
          >
            <ColumnFlex gap="10px">
              <span className="color-text text-with-shadow fs-16 fw-700">
                Share To
              </span>
              <RowFlex gap="10px">
                {/* <Avatar
                  style={{ backgroundColor: "transparent" }}
                  src={
                    projectDetailsData?.getProjectById?.firstGalleryImage.url ||
                    DefaultCompanyImage
                  }
                  square
                  size={"large"}
                /> */}
                <Avatar
                  src={
                    projectDetailsData?.getProjectById?.firstGalleryImage.url ||
                    DefaultCompanyImage
                  }
                  variant="rounded"
                  sx={{ bgcolor: "transparent", width: "60px", height: "60px" }}
                />
                <ColumnFlex gap="2px">
                  <span className="fw-600 fs-12 color-text">
                    {projectDetailsData?.getProjectById?.name}
                  </span>
                  <RowFlex gap="6px" crossAxisAlignment="flex-start">
                    <LocationOnRounded
                      sx={{ width: "12px", height: "12px" }}
                      color="primary"
                      className="page-details-dialog-company-logo"
                    />
                    <span className="fw-600 fs-10 color-text-2">
                      {projectDetailsData?.getProjectById?.saleGalleryAddress ||
                        "-"}
                    </span>
                  </RowFlex>
                  <RowFlex gap="0px" crossAxisAlignment="center">
                    <IconButton
                      rounded
                      onClick={() =>
                        window.open(
                          `https://wa.me/?text=${encodeURIComponent(
                            shareTo.whatsappContent
                          )}`,
                          "_blank"
                        )
                      }
                    >
                      <img src={WhatsappIcon} alt="whatsapp" width={21} />
                    </IconButton>
                    <IconButton
                      rounded
                      onClick={() => {
                        setIsOpenEmailEditor(true);
                        setOpenShareDialog(false);
                      }}
                    >
                      <EmailRounded color="primary" />
                    </IconButton>
                  </RowFlex>
                </ColumnFlex>
              </RowFlex>
              <ColumnFlex gap="10px" fullWidth crossAxisAlignment="center">
                <ColumnFlex gap="0px" fullWidth mainAxisAlignment="flex-start">
                  <span className="fw-600 fs-10 color-text-2">
                    Click to copy link
                  </span>
                  <RowFlex
                    crossAxisAlignment="center"
                    className="click-able"
                    fullWidth
                  >
                    <span
                      className="fs-12 color-text fw-700 click-text"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      onClick={copyToClipboard}
                    >
                      {shareTo.link}
                    </span>
                    <div onClick={copyToClipboard}>
                      <ContentCopyRounded fontSize="small" />
                    </div>
                  </RowFlex>
                </ColumnFlex>
                <QRCode value={shareTo.qrCodeUrl} size={150} />
                <span className="color-text-1 fs-12 fw-500">
                  Scan the QR code to view more detail.
                </span>
              </ColumnFlex>
            </ColumnFlex>
          </SimpleDialog>
        )}
        <ZoomedImageDialog
          visible={isZoomImage}
          onClose={() => setIsZoomImage(false)}
          src={projectDetailsData?.getProjectById?.company?.imageUrl}
        />
      </>
    );
  }
};

export default PageDetail;
