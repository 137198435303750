import { Button } from "ui-neumorphism";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { useNavigate } from "react-router";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useSearchParams } from "react-router-dom";
import CardNeu from "../../../../components/Card/Card";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { useEffect } from "react";
import { GENERAL_PATH } from "../../../../containers/modules/GeneralPages/GeneralRoutes";
import ContainerX from "../../../../components/general/ContainerX";
import { useGetSaleJointBuyerLazyQuery } from "../../../../generated/graphql";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import EmptyRecord from "../../../../components/general/EmptyRecord";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

import { useContext } from "react";

const JointBuyerListing = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const saleId = searchParams.get("saleId");
  const projectId = searchParams.get("projectId");
  const isIntegrate = searchParams.get("isIntegrate");
  const [
    getSaleJointBuyer,
    { data: saleJointBuyerData, loading: saleJointBuyerLoading },
  ] = useGetSaleJointBuyerLazyQuery();

  useEffect(() => {
    if (!projectId || !saleId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getSaleJointBuyer({
        variables: {
          saleId: saleId,
          projectId: projectId,
          isIntegrate: isIntegrate === "true",
        },
      });
    }
  }, []);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  if (saleJointBuyerData) {
    return (
      <ColumnFlex fullWidth gap="24px">
        <GeneralHeader
          title="Joint Buyer"
          style={{ background: headerColor }}
        />
        <CardNeu rounded style={{ background: cardColor }}>
          <ColumnFlex>
            {saleJointBuyerData?.getSaleJointBuyer?.length === 0 && (
              <EmptyRecord hasSubLable onClick={() => {}} />
            )}
            {saleJointBuyerData?.getSaleJointBuyer?.length > 0 &&
              saleJointBuyerData?.getSaleJointBuyer?.map((item, index) => (
                <ContainerX
                  key={index}
                  padding={{
                    only: {
                      top: "8px",
                      bottom: "14px",
                      left: "16px",
                      right: "16px",
                    },
                  }}
                  className={`${
                    index !== saleJointBuyerData?.getSaleJointBuyer.length - 1
                      ? "divider-bottom-border"
                      : ""
                  }`}
                >
                  <span className="fw-700 fs-12 color-text">{item?.Name}</span>
                  <RowFlex gap="8px" className="fw-800 fs-12">
                    <RowFlex gap="4px" crossAxisAlignment="center">
                      <SmartphoneRoundedIcon fontSize="inherit" />
                      <span className="click-text color-orange fs-10">
                        {item?.MobilePhone}
                      </span>
                    </RowFlex>
                    <span className="fw-400">|</span>
                    <RowFlex crossAxisAlignment="center" gap="4px">
                      <EmailRoundedIcon fontSize="inherit" />
                      <span className="click-text color-orange fs-10">
                        {item?.Email}
                      </span>
                    </RowFlex>
                  </RowFlex>
                </ContainerX>
              ))}
          </ColumnFlex>
        </CardNeu>
      </ColumnFlex>
    );
  }
};

export default JointBuyerListing;
