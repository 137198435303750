import React, { useEffect, useState } from "react";
import { Button, IconButton } from "ui-neumorphism";
import SimpleDialog from "../../../components/Dialog/SimpleDialog";
import LocationCallIcon from "../../../assets/images/icon/location-call.png";
import { RowGrid } from "../../../components/LayoutX/Grid/RowGrid";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { useNavigate, useParams } from "react-router";
import { AUTH_PATH, Invalid_Auth_Path } from "../Authentication/AuthRoutes";
import { Avatar, Menu, Skeleton, colors } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CardNeu from "../../../components/Card/Card";
import ProfileIcon from "../../../assets/images/icon/profile.png";
import ChangePasswordIcon from "../../../assets/images/icon/change-password.png";
import LogoutIcon from "../../../assets/images/icon/logout.png";
import {
  SalesAccountQuery,
  useGetAccountXDetailsLazyQuery,
  useGetCompanyLazyQuery,
  useGetCompanyQuery,
} from "../../../generated/graphql";
import { useSearchParams } from "react-router-dom";
import ContainerX from "../../../components/general/ContainerX";
import { OTHERS_PATH } from "../Others/OthersRoutes";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import GoogleMapIcon from "../../../assets/images/icon/google-map.png";
import PhoneIcon from "../../../assets/images/icon/call.png";
import EmailIcon from "../../../assets/images/icon/email.png";
import DefaultCompanyLogo from "../../../assets/images/background/weatherbg.jpg";
import { headerChangeColor } from "../../context/Context";
import { buttonChangeColor } from "../../context/ButttonContext";
import { cardChangeColor } from "../../context/CardContext";

import { useContext } from "react";

import {
  deleteSwiperSalesIdCookie,
  setSwiperSalesIdCookie,
} from "../../../containers/helpers/salesIdCookie";
import TextX from "../../../components/general/TextX";
import ZoomedImageDialog from "../../../components/Dialog/ZoomedImageDialog";
import { LogoutProcess } from "../../../containers/helpers/clearBrowserData";
import Expanded from "../../../components/LayoutX/Expanded";
import { StackItem, StackX } from "../../../components/LayoutX/StackLayout";
import CustomizeHeader from "../../../components/Header/CustomizeHeader";
import {
  isDesktop_minWidth,
  minWidth_1024,
  useMediaQuery,
} from "../../../useHook/useMediaQuery";
import useSocket from "../../../useHook/useSocket";

type UnitDetailsHeaderProps = {
  salesAccount: SalesAccountQuery;
};

const UnitDetailsHeader: React.FC<UnitDetailsHeaderProps> = ({
  salesAccount,
}) => {
  const [isOpenCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { accountCode } = useParams();
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [getCompany, { data: companyData, loading: getCompanyLoading }] =
    useGetCompanyLazyQuery();
  const [getAccountXDetails, { data: accountXData }] =
    useGetAccountXDetailsLazyQuery();
  const [isZoomImage, setIsZoomImage] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const isDesktop = useMediaQuery(minWidth_1024);
  const isMobile = useMediaQuery(isDesktop_minWidth);
  const { socketDisconnection } = useSocket();
  const { headerColor } = headerChangeColor();
  const { buttonColor } = buttonChangeColor();
  const { cardColor } = cardChangeColor();

  useEffect(() => {
    if (salesAccount?.getSalesAccount) {
      if (salesAccount?.getSalesAccount.length > 0) {
        getCompany({
          fetchPolicy: "no-cache",
          variables: {
            projectId: projectId,
          },
        });
      } else {
        getAccountXDetails({
          fetchPolicy: "no-cache",
        });
      }
    }
  }, [projectId]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsOpenMenu(false);
  };

  const handleOpenCompanyDialog = async () => {
    setOpenCompanyDialog(true);
  };

  const handleCloseCompanyDialog = () => {
    setOpenCompanyDialog(false);
  };

  const handleViewProfile = () => {
    handleCloseMenu();
    navigation(OTHERS_PATH.PROFILE.replace(":accountCode", accountCode || ""));
  };

  const handleChangePassword = () => {
    handleCloseMenu();
    navigation(
      OTHERS_PATH.RESET_PASSWORD.replace(":accountCode", accountCode || "")
    );
  };

  const handleLogout = () => {
    LogoutProcess();
    socketDisconnection();
    navigation(
      accountCode
        ? AUTH_PATH.LOGIN.replace(":accountCode", accountCode || "")
        : Invalid_Auth_Path.LOGIN
    );
  };

  const handleJumpToGoogleMap = () => {
    if (companyData?.getCompany || accountXData?.getAccountXDetails) {
      const company =
        companyData?.getCompany || accountXData?.getAccountXDetails;
      let address = companyData
        ? Object.values(companyData?.getCompany.address).join("")
        : accountXData?.getAccountXDetails?.address.address;
      return window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          address
        )}`
      );
    }
  };

  const handleJumpToCall = () => {
    const company = companyData?.getCompany || accountXData?.getAccountXDetails;
    if (company) {
      window.location.href = `tel:${
        companyData?.getCompany?.officeTel ||
        accountXData?.getAccountXDetails?.contactNo
      }`;
    }
  };

  const handleJumpToEmail = () => {
    const company = companyData?.getCompany || accountXData?.getAccountXDetails;
    if (company) {
      window.location.href = `mailto:${company.email}`;
    }
  };

  // Function to format address from AddressJson object
  const formatAddress = (addressJson) => {
    if (!addressJson) return "";
    const { address, postCode, city, state, country } = addressJson;
    return [address, postCode, city, state, country].filter(Boolean).join(", ");
  };

  const formattedAddress = companyData
    ? formatAddress(companyData.getCompany.address)
    : formatAddress(accountXData?.getAccountXDetails?.address);

  return (
    <>
      <CustomizeHeader style={{ background: headerColor }}>
        <RowFlex
          gap="20px"
          crossAxisAlignment="center"
          mainAxisAlignment="space-between"
        >
          <Button
            onClick={handleOpenCompanyDialog}
            disabled={
              !companyData?.getCompany && !accountXData?.getAccountXDetails
            }
            bgColor={buttonColor}
          >
            <img src={LocationCallIcon} className="icon-normal" />
          </Button>
          <div style={{ flex: "1" }} className="text-overflow">
            {getCompanyLoading ? (
              <Skeleton variant="text" />
            ) : (
              <span className={"fs-16 fw-800 neu-text-shadow text-overflow"}>
                {companyData?.getCompany?.name ||
                  accountXData?.getAccountXDetails?.name ||
                  ""}
              </span>
            )}
          </div>
          <StackX>
            <Button onClick={handleOpenMenu} bgColor={buttonColor}>
              <MoreHorizIcon />
            </Button>
            {isOpenMenu && (
              <div
                onClick={() => setIsOpenMenu(false)}
                style={{
                  position: "fixed",
                  backgroundColor: "transparent",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  // zIndex: 1,
                }}
              ></div>
            )}
            {isOpenMenu && (
              <StackItem top="45px" right={!isDesktop && "0px"}>
                <CardNeu width="175px" style={{ background: cardColor }}>
                  <ColumnFlex
                    fullWidth
                    className="p-t-10 p-b-10 disable-full-uppercase"
                    gap="0px"
                  >
                    <Button
                      className="clear-neu-button-style p-v-22"
                      text
                      onClick={handleViewProfile}
                    >
                      <img src={ProfileIcon} />
                      <span className="p-l-6 fs-12 fw-700 color-text">
                        My Profile
                      </span>
                    </Button>
                    <Button
                      className="clear-neu-button-style p-v-22"
                      text
                      onClick={handleChangePassword}
                    >
                      <img src={ChangePasswordIcon} />
                      <span className="p-l-6 fs-12 fw-700 color-text">
                        Change Password
                      </span>
                    </Button>
                    <Button
                      className="clear-neu-button-style p-v-22"
                      text
                      onClick={handleLogout}
                    >
                      <img src={LogoutIcon} />
                      <span className="p-l-6 fs-12 fw-700 color-text">
                        Logout
                      </span>
                    </Button>
                  </ColumnFlex>
                </CardNeu>
              </StackItem>
            )}
          </StackX>
        </RowFlex>
      </CustomizeHeader>
      <SimpleDialog
        visible={isOpenCompanyDialog}
        onClose={handleCloseCompanyDialog}
        background={cardColor}
      >
        {(companyData?.getCompany || accountXData?.getAccountXDetails) && (
          <ColumnFlex gap="20px" crossAxisAlignment="center">
            <span className="fw-700 fs-16 color-text text-with-shadow">
              {companyData?.getCompany.companyRegNo ||
                accountXData.getAccountXDetails.regNo}
            </span>
            <ColumnFlex crossAxisAlignment="center" gap="8px">
              {/* <Avatar
                src={companyData?.getCompany.imageUrl || DefaultCompanyLogo}
                alt="companyLogo"
                sx={{ width: 130, height: 130 }}
                className="box-shadow-3"
                onClick={() => setIsZoomImage(true)}
              /> */}
              <div
                style={{
                  borderRadius: "50%",
                  width: "130px",
                  height: "130px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
                className="box-shadow-3"
                onClick={() => setIsZoomImage(true)}
              >
                <img
                  src={companyData?.getCompany.imageUrl || DefaultCompanyLogo}
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <span
                className="fw-700 fs-14 color-text text-with-shadow"
                style={{ width: "100%", textAlign: "center" }}
              >
                {companyData?.getCompany?.name ||
                  accountXData?.getAccountXDetails?.name}
              </span>
            </ColumnFlex>
            <ColumnFlex fullWidth gap="12px">
              <RowFlex gap="7px">
                <LocationOnIcon fontSize="inherit" />
                <span className="fw-600 fs-12 color-text">
                  {companyData
                    ? formattedAddress
                    : accountXData?.getAccountXDetails?.address.address}
                </span>
              </RowFlex>
              <RowFlex gap="7px">
                <LocalPhoneIcon fontSize="inherit" />
                <span className="fw-600 fs-12 color-text">
                  {companyData?.getCompany?.officeTel ||
                    accountXData?.getAccountXDetails?.contactNo}
                </span>
              </RowFlex>
              <RowFlex mainAxisAlignment="space-evenly">
                <IconButton
                  className={"fullwidth"}
                  onClick={handleJumpToGoogleMap}
                >
                  <img src={GoogleMapIcon} alt="googleMapIcon" />
                </IconButton>
                <IconButton className={"fullwidth"} onClick={handleJumpToCall}>
                  <img src={PhoneIcon} alt="phoneIcon" />
                </IconButton>
                <IconButton className={"fullwidth"} onClick={handleJumpToEmail}>
                  <img src={EmailIcon} alt="emailIcon" />
                </IconButton>
              </RowFlex>
            </ColumnFlex>
          </ColumnFlex>
        )}
      </SimpleDialog>
      <ZoomedImageDialog
        visible={isZoomImage}
        onClose={() => setIsZoomImage(false)}
        src={companyData?.getCompany?.imageUrl || DefaultCompanyLogo}
      />
    </>
  );
};

export default UnitDetailsHeader;
