import {
  Ipay88PaymentOutput,
} from "../../../generated/graphql";

export const iPay88PaymentService = async (
  ipay88ModelData: Ipay88PaymentOutput,
  paymentId: string
) => {
  const iPay88Model = new FormData();
  iPay88Model.append("MerchantCode", ipay88ModelData.merchantCode);
  iPay88Model.append("PaymentId", paymentId);
  iPay88Model.append("RefNo", ipay88ModelData.refNo);
  iPay88Model.append("Amount", ipay88ModelData.amount);
  iPay88Model.append("Currency", ipay88ModelData.currency);
  iPay88Model.append("ProdDesc", ipay88ModelData.prodDesc);
  iPay88Model.append("UserName", ipay88ModelData.name);
  iPay88Model.append("UserEmail", ipay88ModelData.email);
  iPay88Model.append("UserContact", ipay88ModelData.contactNo);
  iPay88Model.append("Remark", ipay88ModelData.remark);
  iPay88Model.append("Lang", ipay88ModelData.lang);
  iPay88Model.append("SignatureType", ipay88ModelData.signatureType);
  iPay88Model.append("Signature", ipay88ModelData.signature);
  iPay88Model.append("ResponseURL", ipay88ModelData.redirectUrl);
  iPay88Model.append("BackendURL", ipay88ModelData.notifyUrl);

  const form = document.createElement("form");
  form.setAttribute("method", ipay88ModelData.method);
  form.setAttribute("action", ipay88ModelData.paymentUrl);

  iPay88Model.forEach(function (val, key: any) {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", key);
    hiddenField.setAttribute("value", val.toString());

    form.appendChild(hiddenField);
  });
  document.body.appendChild(form);
  form.submit();
};
