import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../GeneralPages/GeneralRoutes";
import React, { useEffect, useState } from "react";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  AttachmentEntity,
  useGetNewsDetailsLazyQuery,
} from "../../../generated/graphql";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { StackX, StackItem } from "../../../components/LayoutX/StackLayout";
import ContainerX from "../../../components/general/ContainerX";
import newsImage01 from "../../../assets/images/background/weatherbg.jpg";
import TextX from "../../../components/general/TextX";
import CardNeu from "../../../components/Card/Card";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import ZoomedImageDialog from "../../../components/Dialog/ZoomedImageDialog";
import Slider from "react-slick";
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
} from "@mui/icons-material";
import { headerChangeColor } from "../../context/Context";
import { cardChangeColor } from "../../context/CardContext";

import { useContext } from "react";

const NewsDetails = () => {
  const [searchParams] = useSearchParams();
  const newsId = searchParams.get("newsId");
  const navigate = useNavigate();
  const [getNewsDetails, { data: newsDetailsData }] =
    useGetNewsDetailsLazyQuery({
      onCompleted: (data) => {
        setHtmlString(data?.getNewsDetails?.description);
      },
    });
  const [htmlString, setHtmlString] = useState("");
  const [isZoomedImage, setIsZoomedImage] = useState(false);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  useEffect(() => {
    if (!newsId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getNewsDetails({
        variables: { newsId: newsId },
      });
    }
  }, []);

  if (newsDetailsData) {
    return (
      <>
        <ColumnFlex fullWidth gap="24px">
          <GeneralHeader
            title="News Details"
            style={{ background: headerColor }}
          />
          {newsDetailsData.getNewsDetails?.attachment?.length > 0 && (
            <ContainerX
              className="box-shadow-3"
              decoration={{ borderRadius: "14px" }}
              overflow="hidden"
            >
              <NewsImageSwiper
                newsList={newsDetailsData?.getNewsDetails?.attachment}
              />
            </ContainerX>
          )}

          <ColumnFlex>
            <ColumnFlex gap="4px">
              <span className="color-text text-with-shadow fw-700 fs-16">
                {newsDetailsData.getNewsDetails.title}
              </span>
              <TextX
                data={newsDetailsData.getNewsDetails.startDate}
                dataType="Date"
                className="fw-500 fs-10 color-text-2"
              />
            </ColumnFlex>

            <div className="fw-400 fs-14 color-text-2 news-content">
              {parseHTMLString(htmlString)}
            </div>
          </ColumnFlex>
        </ColumnFlex>
        <ZoomedImageDialog
          visible={isZoomedImage}
          onClose={() => setIsZoomedImage(false)}
          src={newsDetailsData?.getNewsDetails.attachment[0].resourceUrl}
        />
      </>
    );
  }
};

function parseHTMLString(htmlString) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlString, "text/html");

  const bodyContent = parsedHtml.body;

  const reactComponents = Array.from(bodyContent.childNodes).map(
    (node, index) => {
      switch (node.nodeType) {
        case Node.ELEMENT_NODE:
          if (node instanceof Element) {
            return React.createElement(
              node.tagName.toLowerCase(),
              { key: index },
              parseHTMLString(node.innerHTML)
            );
          }
          break;
        case Node.TEXT_NODE:
          return node.textContent;
        default:
          return null;
      }
    }
  );

  return reactComponents;
}

const NewsImageSwiper = ({ newsList }: { newsList: any[] }) => {
  const [isZoomImage, setIsZoomImage] = useState(-1); // -1 means no image is zoomed
  const [mouseDownTime, setMouseDownTime] = useState(null);
  const { cardColor } = cardChangeColor();

  const handleMouseDown = () => {
    setMouseDownTime(new Date().getTime());
  };

  const handleMouseUp = (index) => {
    const mouseUpTime = new Date().getTime();
    const timeDifference = mouseUpTime - mouseDownTime;
    if (timeDifference < 90) {
      setIsZoomImage(index);
    }
    setMouseDownTime(null);
  };

  const settings = {
    dots: false,
    infinite: newsList?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: newsList?.length > 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  if (newsList?.length < 1) {
    return null;
  }

  return (
    <Slider {...settings}>
      {newsList?.map((news, index) => (
        <ContainerX
          key={news.resourceUrl}
          className="swiper-slide gallery-sales-swiper"
          decoration={{ borderRadius: "10px" }}
          overflow="hidden"
          height={"210px"}
          // backgroundColor={cardColor}
        >
          <img
            src={news.resourceUrl}
            alt="galleryImage"
            className="image click-able"
            onMouseDown={handleMouseDown}
            onMouseUp={() => handleMouseUp(index)}
          />
          <ZoomedImageDialog
            visible={isZoomImage === index}
            onClose={() => setIsZoomImage(-1)}
            src={news.resourceUrl}
          />
        </ContainerX>
      ))}
    </Slider>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={" swiper-custom-btn box-shadow-dark"}
      style={{ right: "6px" }}
      onClick={onClick}
    >
      <NavigateNextRounded fontSize="large" className="color-text" />
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={"swiper-custom-btn box-shadow-dark"}
      style={{ left: "6px" }}
      onClick={onClick}
    >
      <NavigateBeforeRounded fontSize="large" className="color-text" />
    </div>
  );
};

export default NewsDetails;
