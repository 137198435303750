import { getAccessToken } from "../containers/helpers/AccessToken";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import io from "socket.io-client";

const useSocket = () => {
  const [socket, setSocket] = useState(null);
  const [connectStatus, setConnectStatus] = useState(false); // Add connectStatus state
  const environment = import.meta.env;
  const token = getAccessToken();

  useEffect(() => {
    const newSocket = io(environment.VITE_SOCKET_HOST, {
      path: environment.VITE_ENV_TYPE !== "Local" ? "/ws" : "",
      // path: '',
      auth: {
        token,
      },
    });
    setSocket(newSocket);
    newSocket.on("connect", () => {
      setConnectStatus(newSocket.connected);
      logUserOnlineStatus(newSocket);
    });

    newSocket.on("reconnect", () => {
      logUserOnlineStatus(newSocket);
    });
  }, []);

  const logUserOnlineStatus = (socket) => {
    if (!socket) return;
    socket.emit("login", token);
  };

  const socketDisconnection = () => {
    setConnectStatus(false);
    socket?.disconnect();
  };

  return { logUserOnlineStatus, socketDisconnection, connectStatus };
};

export default useSocket;
