import { ValueSetter } from "date-fns/parse/_lib/Setter";
import { ChangeEvent, useState } from "react";
import {
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
} from "react-number-format";

export type ValidationResult = {
  valid: boolean;
  errorMessage?: string;
};

type NumberFormatTextFieldProps = NumericFormatProps & {
  emptyValue?: string;
  objectName?: string;
  minValue?: number | undefined;
  maxValue?: number | undefined;
  lessThan?: number | undefined;
  moreThan?: number | undefined;
  autoCorrectValue?: boolean;
  onChange?: (value: any) => void;
  onValidation?: (error: ValidationResult) => void;
  required?: boolean;
  additionalValidate?: (values: NumberFormatValues) => ValidationResult;
};

const NumberFormatTextField: React.FC<NumberFormatTextFieldProps> = ({
  minValue = undefined,
  maxValue = undefined,
  objectName = "value",
  lessThan,
  moreThan,
  emptyValue = minValue || 0.0,
  name = undefined,
  onChange,
  onValidation,
  additionalValidate,
  required,
  ...props
}) => {
  const { value, ...restProps } = props;

  const setError = (validationResult: ValidationResult) => {
    if (!onValidation) return;
    onValidation(validationResult);
  };

  function formatNumberString(input: string): string {
    const parts = input.split(".");
    let integerPart = parts[0].replace(/^0+/, ""); // 去除整数部分前面的0，除了"0."
    let decimalPart = parts[1] || "";

    if (decimalPart.length > 2) {
      integerPart = parts[0] + decimalPart.slice(0, decimalPart.length - 2);

      decimalPart = decimalPart.slice(
        decimalPart.length - 2,
        decimalPart.length
      );
    }

    if (integerPart[0] === ".") {
      integerPart = "0" + integerPart;
    } else if (integerPart[0] === "0" && integerPart[1] !== ".") {
      integerPart = integerPart.slice(1, integerPart.length);
    }

    decimalPart = decimalPart.padEnd(2, "0");

    return integerPart + "." + decimalPart;
  }

  const handleValueChange = (values: NumberFormatValues) => {
    const { floatValue, value } = values;

    // let resultValue = formatNumberString(value);
    let resultValue = value;

    if (minValue !== undefined && floatValue < minValue) {
      setError({
        valid: false,
        errorMessage: `${objectName} cannot less than ${minValue}`,
      });
    } else if (maxValue !== undefined && floatValue > maxValue) {
      setError({
        valid: false,
        errorMessage: `${objectName} cannot greater than ${maxValue}`,
      });
    } else if (lessThan !== undefined && floatValue >= lessThan) {
      setError({
        valid: false,
        errorMessage: `${objectName} must less than ${lessThan}`,
      });
    } else if (moreThan !== undefined && floatValue <= moreThan) {
      setError({
        valid: false,
        errorMessage: `${objectName} must more than ${moreThan}`,
      });
    } else if (required && value === "") {
      setError({
        valid: false,
        errorMessage: `${objectName} is required`,
      });
    } else if (additionalValidate) {
      const result = additionalValidate(values);
      if (!result.valid) {
        setError(result);
        return;
      } else {
        setError({ valid: true, errorMessage: undefined });
      }
    } else {
      setError({ valid: true, errorMessage: undefined });
    }

    //formating
    if (resultValue[0] === "0" && resultValue[1] !== ".") {
      resultValue = resultValue.slice(1, resultValue.length);
    }

    if (onChange) {
      onChange(Number(value) || emptyValue);
    }
  };

  const validation = (values: NumberFormatValues) => {
    const { value, floatValue } = values;

    if (value.charAt(0) === ".") return false;

    if (value.charAt(0) === "0" && value.charAt(1) !== "." && value.length > 2)
      return false;

    if (value.includes(" ")) return false;

    // setError({ valid: true });
    return true;
  };

  const mergedProps: NumericFormatProps = {
    value: value,
    thousandSeparator: true,
    allowNegative: false,
    decimalScale: 2,
    // fixedDecimalScale: true,
    decimalSeparator: ".",
    allowLeadingZeros: false,
    onValueChange: handleValueChange,
    isAllowed: validation,
    ...restProps,
  };

  return <NumericFormat {...mergedProps} />;
};

export default NumberFormatTextField;
