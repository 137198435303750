import { RouteObject } from "react-router-dom";
import Public_PageDetail from "./Public_PageDetail";
import MainLayout from "../../../../containers/app/MainLayout";
import Mortgage from "../Mortgage";
import Public_BrochureListing from "./Public_BrochureListing";

export const PUBLIC_PROJECT_PATH = {
  P_PAGE_DETAIL: "/:accountCode/p",
  P_MORTGAGE: "/:accountCode/p/mortgage",
  P_BROCHURE: "/:accountCode/p/brochure",
};

const publicProjectRoutes: RouteObject[] = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: PUBLIC_PROJECT_PATH.P_PAGE_DETAIL,
        element: <Public_PageDetail />,
      },
      {
        path: PUBLIC_PROJECT_PATH.P_MORTGAGE,
        element: <Mortgage />,
      },
      {
        path: PUBLIC_PROJECT_PATH.P_BROCHURE,
        element: <Public_BrochureListing />,
      },
    ],
  },
];

export default publicProjectRoutes;
