import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { useEffect, useState } from "react";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Avatar, Button, IconButton } from "ui-neumorphism";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  useGetNewsListingLazyQuery,
  useGetProjectDetailsForPublicLazyQuery,
  useProjectDetailLazyQuery,
  ProjectDetailQuery,
  usePublicProjectDetailLazyQuery,
  useAddLeadFromPublicMutation,
} from "../../../../generated/graphql";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Positioned, Stack } from "../../../../components/LayoutX/Stack";
import ContainerX from "../../../../components/general/ContainerX";
import newsImage01 from "../../../../assets/images/background/weatherbg.jpg";
import TextX from "../../../../components/general/TextX";
import CardNeu from "../../../../components/Card/Card";
import { DASHBOARDS_PATH } from "../../Dashboards/DashboardRoutes";
import { getPathCookie } from "../../../../containers/helpers/PathCookie";
import {
  ContentCopyRounded,
  EmailRounded,
  GridOnRounded,
  LocationOnRounded,
  NavigateBeforeRounded,
  NavigateNextRounded,
  PlayArrow,
  ShareRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import projectIcon from "../../../../assets/images/icon/type-icon.png";
import sizeIcon from "../../../../assets/images/icon/sqft-icon.png";
import { Button as Mui_Button } from "@mui/material";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import { format } from "date-fns";
import ProjectSwiper from "../ProjectSwiper";
import FloorPlanSwiper from "../FloorPlanSwiper";
import UnitLayoutSwiper from "../UnitLayoutSwiper";
import Slider from "react-slick";
import GoogleMapLogo from "../../../../assets/images/icon/google_map.svg";
import WazeLogo from "../../../../assets/images/icon/waze.svg";
import { useSnackbar } from "react-simple-snackbar";
import { BOOKING_PATH } from "../../Booking/BookingRoutes";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import WhatsappIcon from "../../../../assets/images/whatsapp.png";
import QRCode from "react-qr-code";
import CustomizeHeader from "../../../../components/Header/CustomizeHeader";
import { PUBLIC_PROJECT_PATH } from "./PublicProjectRoutes";
import PhoneInput, {
  Country,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { isValidEmail } from "../../../../containers/helpers/InputValidation";
import { fetchCountryCode } from "../../../../containers/helpers/GetGeoIp";
import { register } from "module";
import parse from "html-react-parser";
import "../PageDetails.scss";
import { cardChangeColor } from "../../../../containers/context/CardContext";
import { buttonChangeColor } from "../../../../containers/context/ButttonContext";

type registerFormType = {
  name: string;
  email: string;
  phoneNo: string;
};

const Public_PageDetail = () => {
  // const baseUrl = window.location.href.substring(
  //   0,
  //   window.location.href.search(window.location.pathname)
  // );
  const [searchParams] = useSearchParams();
  //   const projectId = searchParams.get("projectId");
  //   const contactId = searchParams.get("contactId");
  const { accountCode } = useParams();
  const projectId = searchParams.get("id");
  const navigate = useNavigate();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [countryCode, setCountryCode] = useState("");
  const { cardColor } = cardChangeColor();
  const { buttonColor } = buttonChangeColor();

  const [registerForm, setProfileForm] = useState<registerFormType>({
    name: "",
    email: "",
    phoneNo: "",
  });

  useEffect(() => {
    fetchCountryCode().then((code) => {
      setCountryCode(code);
    });
  }, []);

  const [registerFormError, setProfileFormError] = useState({
    name: null,
    email: null,
    phoneNumber: null,
  });

  const [isOpenRegisterDialog, setIsOpenRegisterDialog] = useState(false);
  const [getPublicProjectDetails, { data: projectDetailsData }] =
    usePublicProjectDetailLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [addLeadFromPublic] = useAddLeadFromPublicMutation({
    onCompleted: (data) => {
      if (data.addLeadFromPublic) {
        openSnackbar("Registered Successfully");
        setIsOpenRegisterDialog(false);
      } else {
        openSnackbar("Failed to register");
      }
    },
  });

  useEffect(() => {
    if (!projectId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getPublicProjectDetails({
        variables: { projectId: projectId },
      });
    }
  }, []);

  const isValidRegisterForm = () => {
    return (
      !registerFormError.email &&
      !registerFormError.name &&
      registerForm.phoneNo &&
      isValidPhoneNumber(registerForm.phoneNo) &&
      isValidEmail(registerForm.email).result &&
      registerForm.name.length > 0
    );
  };

  const handleRegisterProject = () => {
    if (!isValidRegisterForm()) return;
    addLeadFromPublic({
      variables: {
        input: {
          email: registerForm.email,
          name: registerForm.name,
          phoneNo: registerForm.phoneNo,
          projectId: projectId,
        },
      },
    });
  };

  const handleJumpToBrochure = () => {
    navigate(
      `${PUBLIC_PROJECT_PATH.P_BROCHURE.replace(
        ":accountCode",
        accountCode
      )}?projectId=${projectId}`
    );
  };

  const handleJumpToMortgage = () => {
    navigate(
      `${PUBLIC_PROJECT_PATH.P_MORTGAGE.replace(
        ":accountCode",
        accountCode
      )}?project_id=${projectId}&project_name=${projectDetailsData
        ?.getPublicProjectById?.name}`
    );
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    const emailValidation = isValidEmail(value);

    if (!emailValidation.result) {
      setProfileFormError({
        ...registerFormError,
        email: emailValidation.message,
      });
    } else {
      setProfileFormError({ ...registerFormError, email: null });
    }

    setProfileForm({ ...registerForm, email: value });
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    if (value.length < 1) {
      setProfileFormError({
        ...registerFormError,
        name: "Name is required",
      });
    } else {
      setProfileFormError({ ...registerFormError, name: null });
    }
    setProfileForm({ ...registerForm, name: value });
  };

  if (projectDetailsData) {
    return (
      <>
        <ContainerX margin={{ only: { top: "-50px" } }} width={"100%"}>
          <ColumnFlex fullWidth gap="24px">
            {(projectDetailsData?.getPublicProjectById?.galleryImage?.length >
              0 ||
              projectDetailsData?.getPublicProjectById?.embededYoutubeLink
                ?.length > 0 ||
              projectDetailsData?.getPublicProjectById?.virtualModel?.length >
                0) && (
              <ProjectSwiper publicProjectDetailData={projectDetailsData} />
            )}

            <CardNeu style={{ background: cardColor }}>
              <ContainerX padding={{ all: "12px" }}>
                <ColumnFlex gap="12px">
                  <span className="fs-16 fw-700 color-text text-with-shadow">
                    {projectDetailsData?.getPublicProjectById?.name}
                  </span>
                  <RowFlex
                    className="fw-600 fs-12 color-text-2"
                    crossAxisAlignment="center"
                    gap="4px"
                  >
                    <LocationOnRounded fontSize="inherit" />
                    <span className="fs-10">
                      {projectDetailsData?.getPublicProjectById
                        .saleGalleryAddress || "-"}
                    </span>
                  </RowFlex>
                  <RowFlex
                    className="fw-600 fs-14 color-text-2"
                    crossAxisAlignment="center"
                    gap="4px"
                  >
                    <img src={sizeIcon} alt="projectIcon" />

                    {projectDetailsData?.getPublicProjectById
                      ?.unitMinBuiltUp && (
                      <span className="fs-10">
                        fr.
                        {Number.isInteger(
                          parseFloat(
                            projectDetailsData?.getPublicProjectById
                              .unitMinBuiltUp
                          )
                        )
                          ? Math.round(
                              parseFloat(
                                projectDetailsData?.getPublicProjectById
                                  .unitMinBuiltUp
                              )
                            ).toLocaleString("en-US")
                          : parseFloat(
                              projectDetailsData?.getPublicProjectById
                                .unitMinBuiltUp
                            ).toLocaleString("en-US")}{" "}
                        {projectDetailsData?.get?.uom === "SQ_METER" && "sq.m."}
                        {projectDetailsData?.getPublicProjectById.uom ===
                          "SQ_FEET" && "sq.ft."}
                        {projectDetailsData?.getPublicProjectById.uom !==
                          "SQ_METER" &&
                          projectDetailsData?.getPublicProjectById.uom !==
                            "SQ_FEET" &&
                          projectDetailsData?.getPublicProjectById.uom}
                      </span>
                    )}
                    {!projectDetailsData?.getPublicProjectById
                      ?.unitMinBuiltUp && <span className="fs-10">-</span>}
                  </RowFlex>
                  <RowGrid gridTemplateColumns="1fr 1fr">
                    <ColumnFlex gap="2px">
                      <span className="fw-500 fs-10 color-text-2">
                        Township
                      </span>
                      <span className="fs-12 fw-600 color-text">
                        {projectDetailsData?.getPublicProjectById
                          ?.townshipName || "-"}
                      </span>
                    </ColumnFlex>
                    <ColumnFlex gap="2px">
                      <span className="fw-500 fs-10 color-text-2">
                        Land Type
                      </span>
                      <span className="fs-12 fw-600 color-text">
                        {projectDetailsData?.getPublicProjectById
                          ?.landTypeDesc || "-"}
                      </span>
                    </ColumnFlex>
                  </RowGrid>

                  <RowGrid gridTemplateColumns="1fr 1fr">
                    <ColumnFlex gap="2px">
                      <span className="fw-500 fs-10 color-text-2">Tensure</span>
                      <span className="fs-12 fw-600 color-text">
                        {projectDetailsData?.getPublicProjectById?.tenureDesc ||
                          "-"}
                      </span>
                    </ColumnFlex>
                    <ColumnFlex gap="2px">
                      <span className="fw-500 fs-10 color-text-2">
                        Built-up
                      </span>
                      <span className="fs-12 fw-600 color-text">
                        {"From "}
                        <span>
                          fr.
                          {Number.isInteger(
                            parseFloat(
                              projectDetailsData?.getPublicProjectById
                                .unitMinBuiltUp
                            )
                          )
                            ? Math.round(
                                parseFloat(
                                  projectDetailsData?.getPublicProjectById
                                    .unitMinBuiltUp
                                )
                              ).toLocaleString("en-US")
                            : parseFloat(
                                projectDetailsData?.getPublicProjectById
                                  .unitMinBuiltUp
                              ).toLocaleString("en-US")}{" "}
                          {projectDetailsData?.get?.uom === "SQ_METER" &&
                            "sq.m."}
                          {projectDetailsData?.getPublicProjectById.uom ===
                            "SQ_FEET" && "sq.ft."}
                          {projectDetailsData?.getPublicProjectById.uom !==
                            "SQ_METER" &&
                            projectDetailsData?.getPublicProjectById.uom !==
                              "SQ_FEET" &&
                            projectDetailsData?.getPublicProjectById.uom}
                        </span>
                        {" - "}
                        <span>
                          fr.
                          {Number.isInteger(
                            parseFloat(
                              projectDetailsData?.getPublicProjectById
                                .unitMaxBuiltUp
                            )
                          )
                            ? Math.round(
                                parseFloat(
                                  projectDetailsData?.getPublicProjectById
                                    .unitMaxBuiltUp
                                )
                              ).toLocaleString("en-US")
                            : parseFloat(
                                projectDetailsData?.getPublicProjectById
                                  .unitMaxBuiltUp
                              ).toLocaleString("en-US")}{" "}
                          {projectDetailsData?.getPublicProjectById?.uom ===
                            "SQ_METER" && "sq.m."}
                          {projectDetailsData?.getPublicProjectById.uom ===
                            "SQ_FEET" && "sq.ft."}
                          {projectDetailsData?.getPublicProjectById.uom !==
                            "SQ_METER" &&
                            projectDetailsData?.getPublicProjectById.uom !==
                              "SQ_FEET" &&
                            projectDetailsData?.getPublicProjectById.uom}
                        </span>
                      </span>
                    </ColumnFlex>
                  </RowGrid>

                  <RowGrid gridTemplateColumns="1fr 1fr">
                    <ColumnFlex gap="2px">
                      <span className="fw-500 fs-10 color-text-2">
                        Completion Year
                      </span>
                      <span className="fs-12 fw-600 color-text">
                        {projectDetailsData?.getPublicProjectById
                          ?.completionDate
                          ? format(
                              new Date(
                                projectDetailsData?.getPublicProjectById
                                  ?.completionDate
                              ),
                              "yyyy"
                            )
                          : "-"}
                      </span>
                    </ColumnFlex>
                    <ColumnFlex gap="2px">
                      <span className="fw-500 fs-10 color-text-2">
                        Launch Date
                      </span>
                      <TextX
                        dataType="Date"
                        data={
                          projectDetailsData?.getPublicProjectById?.launchDate
                        }
                        className="fs-12 fw-600 color-text"
                      />
                    </ColumnFlex>
                  </RowGrid>
                  <RowGrid
                    gridTemplateColumns="1fr 1fr"
                    gap="20px"
                    className="p-t-10"
                  >
                    <Button
                      rounded
                      className={"custom-neu-button-style color-text"}
                      onClick={handleJumpToBrochure}
                      bgColor={buttonColor}
                    >
                      <VisibilityRounded fontSize="inherit" />
                      <span className="p-h-10">Brochure</span>
                    </Button>
                    <Button
                      rounded
                      className={"custom-neu-button-style color-text"}
                      onClick={handleJumpToMortgage}
                      bgColor={buttonColor}
                    >
                      <GridOnRounded fontSize="inherit" />
                      <span className="p-h-10">Mortgage</span>
                    </Button>
                  </RowGrid>
                </ColumnFlex>
              </ContainerX>
            </CardNeu>
            {projectDetailsData?.getPublicProjectById?.desc && (
              <CardNeu style={{ background: cardColor }}>
                <ContainerX padding={{ all: "12px" }}>
                  <ColumnFlex>
                    <span className="fs-10 fw-500 color-text-2">
                      Description
                    </span>
                    <ColumnFlex gap="8px" className="page-details-content">
                      {projectDetailsData?.getPublicProjectById?.desc &&
                        parse(projectDetailsData?.getPublicProjectById?.desc)}
                    </ColumnFlex>
                  </ColumnFlex>
                </ContainerX>
              </CardNeu>
            )}

            {projectDetailsData?.getPublicProjectById?.floorGalleryImage
              ?.length > 0 && (
              <FloorPlanSwiper publicProjectDetailsData={projectDetailsData} />
            )}

            {projectDetailsData?.getPublicProjectById?.unitLayoutGalleryImage
              ?.length > 0 && (
              <UnitLayoutSwiper publicProjectDetailsData={projectDetailsData} />
            )}

            {(projectDetailsData?.getPublicProjectById?.projectAddress ||
              projectDetailsData?.getPublicProjectById?.projectGps) && (
              <CardNeu
                rounded
                style={{ overflow: "hidden", background: cardColor }}
                margin={{ symmetric: { vertical: "20px" } }}
              >
                <iframe
                  width={"100%"}
                  height="300px"
                  src={`https://maps.google.com/?q=${projectDetailsData?.getPublicProjectById?.projectGps}&output=embed`}
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                ></iframe>
                <ContainerX
                  backgroundColor="rgba(0,0,0,.7803921568627451)"
                  width={"100%"}
                  className="color-white fw-700 fs-12"
                  padding={{
                    symmetric: { horizontal: "16px", vertical: "12px" },
                  }}
                >
                  <ColumnFlex gap="2px">
                    <span className="color-white fw-700 fs-12">
                      Project Location
                    </span>
                    <span className="color-text-2 fw-500 fs-10">
                      {projectDetailsData?.getPublicProjectById?.projectAddress}
                    </span>

                    <RowFlex gap="10px">
                      <img
                        src={GoogleMapLogo}
                        width={"25px"}
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              projectDetailsData?.getPublicProjectById
                                ?.projectGps
                            )}`
                          )
                        }
                      />
                      <img
                        src={WazeLogo}
                        width={"25px"}
                        onClick={() =>
                          window.open(
                            `https://www.waze.com/ul?ll=${encodeURIComponent(
                              projectDetailsData?.getPublicProjectById
                                ?.projectGps
                            )}`
                          )
                        }
                      />
                    </RowFlex>
                  </ColumnFlex>
                </ContainerX>
              </CardNeu>
            )}

            {(projectDetailsData?.getPublicProjectById?.saleGalleryAddress ||
              projectDetailsData?.getPublicProjectById?.saleGalleryGps) && (
              <CardNeu
                rounded
                style={{ overflow: "hidden", background: cardColor }}
                margin={{ symmetric: { vertical: "20px" } }}
              >
                <iframe
                  width={"100%"}
                  height="300px"
                  src={`https://maps.google.com/?q=${projectDetailsData?.getPublicProjectById?.saleGalleryGps}&output=embed`}
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                ></iframe>
                <ContainerX
                  backgroundColor="rgba(0,0,0,.7803921568627451)"
                  width={"100%"}
                  className="color-white fw-700 fs-12"
                  padding={{
                    symmetric: { horizontal: "16px", vertical: "12px" },
                  }}
                >
                  <ColumnFlex gap="2px">
                    <span className="color-white fw-700 fs-12">
                      Sales Gallery Location
                    </span>
                    <span className="color-text-2 fw-500 fs-10">
                      {
                        projectDetailsData?.getPublicProjectById
                          .saleGalleryAddress
                      }
                    </span>

                    <RowFlex gap="10px">
                      <img
                        src={GoogleMapLogo}
                        width={"25px"}
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              projectDetailsData?.getPublicProjectById
                                ?.saleGalleryGps
                            )}`
                          )
                        }
                      />
                      <img
                        src={WazeLogo}
                        width={"25px"}
                        onClick={() =>
                          window.open(
                            `https://www.waze.com/ul?ll=${encodeURIComponent(
                              projectDetailsData?.getPublicProjectById
                                ?.saleGalleryGps
                            )}`
                          )
                        }
                      />
                    </RowFlex>
                  </ColumnFlex>
                </ContainerX>
              </CardNeu>
            )}

            <CardNeu rounded style={{ background: cardColor }}>
              <ContainerX
                className={"divider-bottom-border"}
                padding={{
                  symmetric: { vertical: "14px", horizontal: "16px" },
                }}
              >
                <RowFlex gap="16px" crossAxisAlignment="center">
                  <Avatar
                    style={{ backgroundColor: "transparent" }}
                    src={
                      projectDetailsData?.getPublicProjectById?.company.imageUrl
                    }
                  />
                  <ColumnFlex>
                    <span className="fw-700 fs-16 color-text text-with-shadow">
                      {projectDetailsData?.getPublicProjectById?.company.name}
                    </span>
                    <span className="fw-500 fs-10 color-text-2">
                      {
                        projectDetailsData?.getPublicProjectById?.company
                          .companyRegNo
                      }
                    </span>
                  </ColumnFlex>
                </RowFlex>
              </ContainerX>
              <ContainerX
                padding={{
                  only: {
                    top: "14px",
                    left: "16px",
                    right: "16px",
                    bottom: "16px",
                  },
                }}
                className="fw-500 fs-10 color-text-2"
              >
                {projectDetailsData?.getPublicProjectById?.company.description}
              </ContainerX>
            </CardNeu>
          </ColumnFlex>
        </ContainerX>
        <div className="sales-gallery-footer" style={{ background: cardColor }}>
          <RowFlex
            mainAxisAlignment="space-between"
            crossAxisAlignment="center"
            className="fw-700 fs-14 margin-horizontal-center"
          >
            <span className="color-text-1">
              {projectDetailsData?.getPublicProjectById?.priceRange}
            </span>
            <Mui_Button
              className="disable-full-uppercase"
              onClick={() => setIsOpenRegisterDialog(true)}
              sx={{ lineHeight: "1", padding: "12px 20px" }}
              variant="contained"
            >
              I'm Interested
            </Mui_Button>
          </RowFlex>
        </div>

        <SimpleDialog
          visible={isOpenRegisterDialog}
          onClose={() => {
            setIsOpenRegisterDialog(false);
          }}
        >
          <ColumnFlex gap="20px" fullWidth className="neu-text-field-style">
            <span className="fw-700 fs-16 color-text text-with-shadow">
              Register Interest
            </span>
            <ColumnFlex fullWidth gap="3px">
              <span className="fs-12 color-text-2">Name *</span>
              <div className="fullwidth-with-child">
                <input
                  type="text"
                  value={registerForm.name}
                  onChange={handleNameChange}
                />
              </div>
              <span className="fs-12 color-red fw-400">
                {registerFormError.name ? registerFormError.name : null}
              </span>
            </ColumnFlex>

            {/* email */}
            <ColumnFlex fullWidth gap="3px">
              <span className="fs-12 color-text-2">Email *</span>
              <input
                type="text"
                value={registerForm.email}
                onChange={handleEmailChange}
              />
              <span className="fs-12 color-red fw-400">
                {registerFormError.email ? registerFormError.email : null}
              </span>
            </ColumnFlex>

            <ColumnFlex fullWidth gap="3px">
              <span className="fs-12 color-text-2">Mobile No *</span>
              <PhoneInput
                placeholder="Enter phone number"
                value={registerForm.phoneNo}
                onChange={(value) => {
                  if (!value || !isValidPhoneNumber(value)) {
                    setProfileFormError({
                      ...registerFormError,
                      phoneNumber: "Invalid Phone Number",
                    });
                  } else {
                    setProfileFormError({
                      ...registerFormError,
                      phoneNumber: null,
                    });
                  }
                  setProfileForm({ ...registerForm, phoneNo: value || "" });
                }}
                international
                countryCallingCodeEditable={false}
                defaultCountry={(countryCode as Country) || "MY"}
                className="text-field-font-style"
              />
              <span className="fs-12 color-red fw-400">
                {registerFormError.phoneNumber
                  ? registerFormError.phoneNumber
                  : null}
              </span>
            </ColumnFlex>
            <Button
              rounded
              style={{ width: "70%", minWidth: "140px" }}
              className="margin-horizontal-center disable-full-uppercase color-text"
              onClick={handleRegisterProject}
              disabled={!isValidRegisterForm()}
            >
              Submit
            </Button>
          </ColumnFlex>
        </SimpleDialog>
      </>
    );
  }
};

export default Public_PageDetail;
