import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCheckAccountCodeValidLazyQuery } from "../../generated/graphql";
// Define the context type
export type headerColor = {
  headerColor: string;
};

// Create the context
const HeaderContext = createContext<{
  headerColor: string;
  setColorState: React.Dispatch<React.SetStateAction<headerColor>>;
}>({
  headerColor: "e4ebf5",
  setColorState: () => {},
});

// useEffect to change color
export const headerChangeColor = () => {
  const [headerColor, setColorState] = useState("#e5e8f7");
  const { accountCode = "" } = useParams();
  const customizeColor: any = localStorage.getItem("headerColorInLocal");

  // const [getAccountCode, { data: accountCodeData }] =
  //   useCheckAccountCodeValidLazyQuery({
  //     fetchPolicy: "no-cache",
  //   });

  useEffect(() => {
    // const sendRequest = async () => {
    //   const { data } = await getAccountCode({
    //     variables: {
    //       accountCode: accountCode || "",
    //     },
    //   });

    //   if (data?.checkAccountCodeValid?.subscriptionLogo) {
    //     setColorState("#fee1e1"); // Set the color
    //     document.body.style.backgroundImage =
    //       "url('https://property.pkns.gov.my/cashiering_train/static/media/auth-background.7da6a08e.svg')";
    //     document.body.style.backgroundSize = "cover"; // Ensure the image covers the entire background
    //     document.body.style.backgroundPosition = "center";
    //     document.body.style.backgroundRepeat = "no-repeat"; // Prevent the image from repeating
    //     // document.body.style.backgroundColor = "#FFFFFF";
    //   }
    // };

    // sendRequest();

    if (customizeColor == null) {
      setColorState("#e4ebf5");
    } else {
      setColorState(customizeColor);
    }
  }, [accountCode]);

  return { headerColor, setColorState };
};

export default HeaderContext;
