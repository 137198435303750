import { useSearchParams } from "react-router-dom";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Button, H1, Tab, Tabs } from "ui-neumorphism";
import { useNavigate } from "react-router";
import { NavigateBeforeRounded, VisibilityRounded } from "@mui/icons-material";
import CardNeu from "../../../../components/Card/Card";
import ContainerX from "../../../../components/general/ContainerX";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import { useEffect, useState } from "react";
import { Slider, Checkbox, CircularProgress } from "@mui/material";
import { useMediaQuery } from "../../../../useHook/useMediaQuery";
import {
  CommonStatus,
  SalesChart3DUnitOutput,
  SalesChartUnitOutput,
  useGet3dStatusLazyQuery,
  useGetBlockUnitsLazyQuery,
  useGetSalesChartByBlockLazyQuery,
  useGetSalesChartDataLazyQuery,
  useGetUnitDetailLazyQuery,
} from "../../../../generated/graphql";
import { GENERAL_PATH } from "../../../../containers/modules/GeneralPages/GeneralRoutes";
import { StackItem, StackX } from "../../../../components/LayoutX/StackLayout";
import BumiIcon from "../../../../assets/images/icon/letter-b.svg";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TextX from "../../../../components/general/TextX";
import { SalesChart3D } from "./SalesChart3D";
import BottomSheet from "./BottomSheet";
import { useSnackbar } from "react-simple-snackbar";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import { abbreviateNumber } from "../../../../containers/helpers/AbbreviateNumber";
import "./BookingUnit.scss";
import EmptyRecord from "../../../../components/general/EmptyRecord";
import { headerChangeColor } from "../../../context/Context";
import { cardChangeColor } from "../../../context/CardContext";
import { buttonChangeColor } from "../../../context/ButttonContext";

import { useContext } from "react";
enum UnitStatus {
  Available = "AVAILABLE",
  Reserved_Sold = "BOOK",
  SPA_Signed = "SIGN",
  Available_Bumi = "AVAILABLE BUMI",
  Reserved_Sold_Bumi = "BOOK BUMI",
  SPA_Signed_Bumi = "SIGN BUMI",

  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

type UnitFilterType = {
  available: CommonStatus.Active | null;
  unavailable: CommonStatus.Inactive | null;
};

const SalesChart = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const contactId = searchParams.get("contactId");
  const isIntegrate = searchParams.get("isIntegrate");
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const [getSalesChartData, { data: salesChartData }] =
    useGetSalesChartDataLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setMinPrice(Number(data?.GetSalesChartData.unitMinPrice));
        setMaxPrice(Number(data?.GetSalesChartData.unitMaxPrice));
        setActiveBlock(0);

        if (data?.GetSalesChartData.distinctBlock.length < 1) {
          // openSnackbar("Empty Block");
          return;
        } else {
          get3dStatus({
            fetchPolicy: "no-cache",
            variables: {
              projectId: projectId,
            },
          });
        }
      },
    });

  const [getSalesChartByBlock, { data: salesChartByBlockData }] =
    useGetSalesChartByBlockLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const allTypeArray: string[] =
          data?.GetSalesChartByBlock?.unitTypesByBlock.map((unit) => unit.id);
        setUnitTypeFilter(allTypeArray);

        if (
          data.GetSalesChartByBlock.units.length === 0 ||
          !data.GetSalesChartByBlock.units
        ) {
          openSnackbar("Unit List is empty!");
        }
      },
    });

  const [getBlockUnits, { data: blockUnitsData }] = useGetBlockUnitsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const allTypeArray: string[] = data?.GetBlockUnits.unitTypesByBlock.map(
        (unit) => unit.id
      );
      setUnitTypeFilter(allTypeArray);

      if (
        data?.GetBlockUnits.salesChartUnitDisplay?.length === 0 ||
        !data?.GetBlockUnits.salesChartUnitDisplay
      ) {
        openSnackbar("Unit List is empty!");
      }
    },
  });

  const [get3dStatus, { data: threeDStatus }] = useGet3dStatusLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.getProjectChartMapped === true) {
        getBlockUnits({
          variables: {
            projectId: projectId,
            keySearch: null,
            block: salesChartData?.GetSalesChartData.saleschartBlock[0] || "",
          },
        });
      } else {
        getSalesChartByBlock({
          fetchPolicy: "no-cache",
          variables: {
            projectId: projectId,
            block: salesChartData?.GetSalesChartData.distinctBlock[0] || "",
            keySearch: null,
          },
        });
      }
    },
  });

  const [getUnitDetail, { data: unitDetailData = null }] =
    useGetUnitDetailLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.GetUnitDetail) {
          setOpenBottomSheet(true);
        }
      },
    });

  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [openPriceRange, setOpenPriceRange] = useState(false);
  const [openUnitType, setOpenUnitType] = useState(false);

  const [unitTypeFilter, setUnitTypeFilter] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeBlock, setActiveBlock] = useState<number>();
  const [unitFilter, setUnitFilter] = useState<UnitFilterType>({
    available: CommonStatus.Active,
    unavailable: CommonStatus.Inactive,
  });
  const AVAIL_COLOR = "#21c986";
  const UNAVAIL_COLOR = "#c5c5c5";
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();
  const { buttonColor } = buttonChangeColor();

  useEffect(() => {
    if (!projectId) {
      navigate(`${GENERAL_PATH.NOT_FOUND_404}`);
    } else {
      getSalesChartData({
        variables: {
          projectId: projectId,
          phase: "",
        },
      });
    }
  }, [projectId, contactId, isIntegrate]);

  const handleMinPrice = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setMinPrice(newValue);

      if (newValue > maxPrice) {
        setMaxPrice(newValue);
      }
    }
  };

  const handleMaxPrice = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setMaxPrice(newValue);

      if (newValue < minPrice) {
        setMinPrice(newValue);
      }
    }
  };

  const handleUnitTypeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, checked } = event.target;

    if (id === "allType" && checked) {
      if (blockUnitsData?.GetBlockUnits) {
        const allTypeArray: string[] =
          blockUnitsData?.GetBlockUnits.unitTypesByBlock.map(
            (unitType) => unitType.id
          ) || [];
        setUnitTypeFilter([...allTypeArray]);
      } else {
        const allTypeArray: string[] =
          salesChartByBlockData?.GetSalesChartByBlock.unitTypesByBlock.map(
            (unitType) => unitType.id
          ) || [];
        setUnitTypeFilter([...allTypeArray]);
      }
      return;
    }

    if (id === "allType" && !checked) {
      setUnitTypeFilter([]);
      return;
    }

    if (checked) {
      setUnitTypeFilter((prev) => {
        if (prev.includes(id)) return prev;
        return [...prev, id];
      });
    } else {
      setUnitTypeFilter((prev) => {
        return prev.filter((item) => item !== id);
      });
    }
  };

  let timer: NodeJS.Timeout;
  const handleSearchValue = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    //if user keep typing, clear the timer for prevent send search request to backend
    clearTimeout(timer);
  };

  useEffect(() => {
    if (
      !threeDStatus?.getProjectChartMapped &&
      !salesChartByBlockData?.GetSalesChartByBlock
    )
      return;

    if (threeDStatus?.getProjectChartMapped && !blockUnitsData?.GetBlockUnits)
      return;
    //if user stop typing for 800ms, send search request to backend
    timer = setTimeout(() => {
      if (threeDStatus?.getProjectChartMapped === true) {
        getBlockUnits({
          variables: {
            projectId: projectId,
            keySearch: searchValue,
            block:
              salesChartData?.GetSalesChartData.saleschartBlock[activeBlock],
          },
        });
      } else {
        getSalesChartByBlock({
          variables: {
            projectId: projectId,
            block: salesChartData?.GetSalesChartData.distinctBlock[activeBlock],
            keySearch: searchValue,
          },
        });
      }
    }, 500);
  }, [searchValue]);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;

    if (id === "available") {
      setUnitFilter((prev) => {
        return {
          ...prev,
          available: checked ? CommonStatus.Active : null,
        };
      });
    }

    if (id === "unavailable") {
      setUnitFilter((prev) => {
        return {
          ...prev,
          unavailable: checked ? CommonStatus.Inactive : null,
        };
      });
    }
  };

  const handleChangeBlockTab = (index: number) => {
    setActiveBlock(index);

    if (threeDStatus?.getProjectChartMapped === true) {
      getBlockUnits({
        variables: {
          projectId: projectId,
          keySearch: searchValue,
          block: salesChartData?.GetSalesChartData.saleschartBlock[index],
        },
      });
    } else {
      getSalesChartByBlock({
        variables: {
          projectId: projectId,
          block: salesChartData?.GetSalesChartData.distinctBlock[index],
          keySearch: searchValue,
        },
      });
    }
  };

  const isAvailableUnit = (unit: SalesChartUnitOutput): boolean => {
    return (
      unit.unit_status === UnitStatus.Available &&
      unit.common_status === CommonStatus.Active &&
      isValidPriceRange(unit) &&
      unitTypeFilter.includes(unit.unit_type)
    );
  };

  const isUnavailableUnit = (unit: SalesChartUnitOutput): boolean => {
    return (
      !isAvailableUnit(unit) &&
      isValidPriceRange(unit) &&
      unitTypeFilter.includes(unit.unit_type)
    );
  };

  const isValidPriceRange = (unit: SalesChartUnitOutput): boolean => {
    return unit.price >= minPrice && unit.price <= maxPrice;
  };

  const handleUnitFilter = (unit: SalesChartUnitOutput) => {
    //available
    if (unitFilter.available && isAvailableUnit(unit)) return true;

    //unavailable
    if (unitFilter.unavailable && isUnavailableUnit(unit)) return true;

    return false;
  };

  const handleOpenBottomSheet_for2DChart = (unit: SalesChartUnitOutput) => {
    if (threeDStatus?.getProjectChartMapped === true) return;

    if (
      unit.unit_status === UnitStatus.SPA_Signed ||
      unit.unit_status === UnitStatus.Inactive ||
      unit.common_status === CommonStatus.Inactive
    ) {
      return;
    }

    getUnitDetail({
      variables: {
        unitId: unit.unit_id,
      },
    });
  };

  useEffect(() => {
    const element = document.getElementsByTagName("body")[0];
    if (openBottomSheet && unitDetailData) {
      element.classList.add("disable-overflow");
    } else {
      element.classList.remove("disable-overflow");
    }
  }, [openBottomSheet, unitDetailData]);

  return (
    <>
      <ColumnFlex fullWidth gap="40px">
        <GeneralHeader
          title="Sales Chart"
          style={{ background: headerColor }}
        />
        <RowGrid gap="30px" gridTemplateColumns="1fr 1fr">
          <ContainerX>
            <Button
              rounded
              className={"fullwidth font-family-strict disable-full-uppercase"}
              onClick={() => setOpenPriceRange(true)}
              bgColor={buttonColor}
            >
              Price Range
            </Button>
            {openPriceRange && (
              <CardNeu
                style={{
                  position: "absolute",
                  zIndex: 3,
                  transform: "translateY(10px)",
                  background: cardColor,
                }}
                width="150px"
              >
                <ContainerX padding={{ all: "14px" }}>
                  <ColumnFlex gap="14px">
                    <ColumnFlex>
                      <span className="fw-800 fs-16 color-text">
                        Min{" "}
                        <span className="fw-400 fs-16 color-text-2">
                          {abbreviateNumber(minPrice)}
                        </span>
                      </span>
                      <Slider
                        value={minPrice}
                        size="small"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        min={Number(
                          salesChartData?.GetSalesChartData.unitMinPrice
                        )}
                        max={maxPrice}
                        onChange={handleMinPrice}
                      />
                    </ColumnFlex>
                    <div className="divider-bottom-border"></div>
                    <ColumnFlex className="divider-border-bottom">
                      <span className="fw-800 fs-16 color-text">
                        Max{" "}
                        <span className="fw-400 fs-16 color-text-2">
                          {abbreviateNumber(maxPrice)}
                        </span>
                      </span>
                      <Slider
                        value={maxPrice}
                        size="small"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        min={Number(
                          salesChartData?.GetSalesChartData.unitMinPrice
                        )}
                        max={Number(
                          salesChartData?.GetSalesChartData.unitMaxPrice
                        )}
                        onChange={handleMaxPrice}
                      />
                    </ColumnFlex>
                  </ColumnFlex>
                </ContainerX>
              </CardNeu>
            )}
          </ContainerX>
          <ContainerX>
            <Button
              rounded
              className={"fullwidth font-family-strict disable-full-uppercase"}
              onClick={() => setOpenUnitType(true)}
              bgColor={buttonColor}
            >
              Unit Type
            </Button>
            {openUnitType && (
              <CardNeu
                style={{
                  position: "absolute",
                  zIndex: 3,
                  background: cardColor,
                  transform: isMobile
                    ? "translate(-10px, 10px)"
                    : "translateY(10px)",
                }}
              >
                <ContainerX padding={{ all: "14px" }}>
                  <ColumnFlex gap="14px" crossAxisAlignment="flex-start">
                    <RowFlex crossAxisAlignment="center">
                      <Checkbox
                        id={"allType"}
                        checked={
                          threeDStatus?.getProjectChartMapped
                            ? blockUnitsData?.GetBlockUnits?.unitTypesByBlock.every(
                                (unitType) =>
                                  unitTypeFilter.includes(unitType.id)
                              )
                            : salesChartByBlockData?.GetSalesChartByBlock?.unitTypesByBlock.every(
                                (unitType) =>
                                  unitTypeFilter.includes(unitType.id)
                              )
                        }
                        onChange={handleUnitTypeCheckbox}
                      />
                      <span className="color-text">All Type</span>
                    </RowFlex>
                    {blockUnitsData?.GetBlockUnits?.unitTypesByBlock &&
                      blockUnitsData?.GetBlockUnits.unitTypesByBlock.map(
                        (unitType) => (
                          <RowFlex
                            crossAxisAlignment="center"
                            key={unitType.id}
                          >
                            <Checkbox
                              id={unitType.id}
                              checked={unitTypeFilter.includes(unitType.id)}
                              onChange={handleUnitTypeCheckbox}
                            />
                            <span>{`${unitType.name} (${unitType.builtUp} sq.ft)`}</span>
                          </RowFlex>
                        )
                      )}
                    {salesChartByBlockData &&
                      salesChartByBlockData?.GetSalesChartByBlock.unitTypesByBlock.map(
                        (unitType) => (
                          <RowFlex
                            crossAxisAlignment="center"
                            key={`${unitType.id}`}
                          >
                            <Checkbox
                              id={unitType.id}
                              checked={unitTypeFilter.includes(unitType.id)}
                              onChange={handleUnitTypeCheckbox}
                            />
                            <span>{`${unitType.name} (${unitType.builtUp} sq.ft)`}</span>
                          </RowFlex>
                        )
                      )}
                  </ColumnFlex>
                </ContainerX>
              </CardNeu>
            )}
          </ContainerX>
        </RowGrid>

        <ContainerX className="neu-text-field-style">
          <input
            type="text"
            placeholder="Search here..."
            value={searchValue}
            onChange={handleSearchValue}
            className="fullwidth"
          />
        </ContainerX>

        {!(threeDStatus?.getProjectChartMapped === true
          ? salesChartData?.GetSalesChartData?.saleschartBlock.length > 0
          : salesChartData?.GetSalesChartData?.distinctBlock?.length > 0) &&
          !blockUnitsData?.GetBlockUnits?.salesChartUnitDisplay?.length &&
          !salesChartByBlockData?.GetSalesChartByBlock?.floors?.length && (
            <EmptyRecord />
          )}

        <CardNeu elavation={1} style={{ background: cardColor }}>
          <ColumnFlex>
            {(threeDStatus?.getProjectChartMapped === true
              ? salesChartData?.GetSalesChartData?.saleschartBlock.length > 0
              : salesChartData?.GetSalesChartData?.distinctBlock?.length >
                0) && (
              <Tabs
                value={activeBlock}
                style={{ display: "flex", overflowX: "auto" }}
                className={"disable-full-uppercase"}
              >
                {threeDStatus?.getProjectChartMapped === true
                  ? salesChartData?.GetSalesChartData?.saleschartBlock.map(
                      (block, index) => (
                        <Tab
                          style={{ flex: 1 }}
                          key={block}
                          onClick={() => {
                            handleChangeBlockTab(index);
                          }}
                        >
                          {block}
                        </Tab>
                      )
                    )
                  : salesChartData?.GetSalesChartData?.distinctBlock.map(
                      (block, index) => (
                        <Tab
                          style={{ flex: 1 }}
                          key={block}
                          onClick={() => {
                            handleChangeBlockTab(index);
                          }}
                        >
                          {block}
                        </Tab>
                      )
                    )}
              </Tabs>
            )}

            {threeDStatus?.getProjectChartMapped === true &&
              !blockUnitsData?.GetBlockUnits.salesChartUnitDisplay?.length && (
                <EmptyRecord onlyContent />
              )}

            {threeDStatus?.getProjectChartMapped === false &&
              !salesChartByBlockData?.GetSalesChartByBlock?.floors?.length && (
                <EmptyRecord onlyContent />
              )}

            {/* 3D booking unit */}
            {blockUnitsData?.GetBlockUnits.salesChartUnitDisplay?.length > 0 &&
              threeDStatus?.getProjectChartMapped === true &&
              blockUnitsData?.GetBlockUnits.salesChartUnitDisplay && (
                <ContainerX
                  height={"400px"}
                  width={"100%"}
                  className="cont-chart"
                >
                  <SalesChart3D
                    // filterWatch={filterWatch}
                    // checked={checked}
                    // available={availableFilter}
                    imgUrl={blockUnitsData?.GetBlockUnits?.pictureBlockUrl}
                    blockData={blockUnitsData?.GetBlockUnits.salesChartUnitDisplay.filter(
                      (block) => {
                        return (
                          unitTypeFilter.includes(block.unit_type_id) &&
                          block.price >= minPrice &&
                          block.price <= maxPrice
                        );
                      }
                    )}
                    // blockData={GetBlockUnits?.salesChartUnitDisplay}
                    getUnitDetail={getUnitDetail}
                    // setSelected={setSelected}
                    AVAIL_COLOR={AVAIL_COLOR}
                    UNAVAIL_COLOR={UNAVAIL_COLOR}
                    // setOpenBottomSheet={setOpenBottomSheet}
                    contW={
                      document.getElementsByClassName("cont-chart")[0]
                        ?.clientWidth
                    }
                  />
                </ContainerX>
              )}

            {/* 2D booking unit */}
            {salesChartByBlockData?.GetSalesChartByBlock?.floors?.length > 0 &&
              salesChartByBlockData?.GetSalesChartByBlock?.floors && (
                <ContainerX className="block-container" overflow="auto">
                  <ColumnFlex className="overflow-width">
                    {salesChartByBlockData?.GetSalesChartByBlock.floors.map(
                      (floor) => (
                        <RowFlex
                          crossAxisAlignment="center"
                          className="overflow-width"
                          key={floor.floor}
                        >
                          <div
                            className="unit-selection-header"
                            style={{ background: cardColor }}
                          >
                            <ColumnFlex
                              fullWidth
                              mainAxisAlignment="center"
                              crossAxisAlignment="center"
                              className={`fullheight fs-12 fw-500 ${
                                salesChartByBlockData.GetSalesChartByBlock.units.filter(
                                  (unit) =>
                                    unit.floor === floor.floor &&
                                    handleUnitFilter(unit)
                                ).length <= 0
                                  ? "bg-greyblue-2"
                                  : "bg-white"
                              }`}
                            >
                              {floor.floor}
                            </ColumnFlex>
                          </div>
                          <RowFlex className="unit-selection-container">
                            {salesChartByBlockData?.GetSalesChartByBlock.units.map(
                              (unit) =>
                                unit.floor === floor.floor &&
                                handleUnitFilter(unit) && (
                                  <ContainerX
                                    width={"150px"}
                                    height={"80px"}
                                    padding={{ all: "12px" }}
                                    key={unit.unit_id}
                                  >
                                    <div
                                      className={`unit-selection box-shadow-dark-2 unit-selection-${
                                        isAvailableUnit(unit)
                                          ? "available"
                                          : "unavailable"
                                      }`}
                                      onClick={() =>
                                        handleOpenBottomSheet_for2DChart(unit)
                                      }
                                    >
                                      <StackX width="100%" height="100%">
                                        <StackItem
                                          horizontalCenter
                                          verticalCenter
                                        >
                                          <ColumnFlex
                                            crossAxisAlignment="center"
                                            mainAxisAlignment="center"
                                            className="fs-12 fw-500"
                                          >
                                            <span>{unit.unit_no}</span>
                                            {unit.unit_status ===
                                              UnitStatus.Available &&
                                            unit.common_status ===
                                              CommonStatus.Active ? (
                                              <RowFlex
                                                crossAxisAlignment="center"
                                                gap="4px"
                                                className="fs-10 fw-500"
                                              >
                                                <MonetizationOnIcon fontSize="inherit" />
                                                <TextX
                                                  data={unit.price}
                                                  dataType="Number"
                                                />
                                              </RowFlex>
                                            ) : null}
                                          </ColumnFlex>
                                        </StackItem>
                                        {unit.isBumi && (
                                          <StackItem right="6px" top="0px">
                                            <img
                                              src={BumiIcon}
                                              alt=""
                                              style={{
                                                width: "14px",
                                                height: "14px",
                                              }}
                                            />
                                          </StackItem>
                                        )}
                                        {!unit.isBumi &&
                                          unit.unitViewCount > 0 && (
                                            <StackItem right="6px" top="4px">
                                              <RowFlex
                                                gap="4px"
                                                className="color-white fs-12 fw-400"
                                                crossAxisAlignment="center"
                                              >
                                                <VisibilityRounded fontSize="inherit" />
                                                <span>
                                                  {unit.unitViewCount}
                                                </span>
                                              </RowFlex>
                                            </StackItem>
                                          )}
                                      </StackX>
                                    </div>
                                  </ContainerX>
                                )
                            )}
                            {salesChartByBlockData.GetSalesChartByBlock.units.filter(
                              (unit) =>
                                unit.floor === floor.floor &&
                                handleUnitFilter(unit)
                            ).length <= 0 && (
                              <ContainerX
                                width={"150px"}
                                height={"80px"}
                                padding={{ all: "12px" }}
                              ></ContainerX>
                            )}
                          </RowFlex>
                        </RowFlex>
                      )
                    )}
                  </ColumnFlex>
                </ContainerX>
              )}
          </ColumnFlex>
        </CardNeu>
      </ColumnFlex>
      <CardNeu
        style={{ position: "fixed", bottom: "0px", zIndex: 3 }}
        width="100%"
      >
        <ContainerX padding={{ all: "6px" }} width={"100%"}>
          <RowFlex
            mainAxisAlignment="space-evenly"
            className="fs-12 color-text-1 fw-500"
          >
            <RowFlex crossAxisAlignment="center">
              <Checkbox
                id="available"
                onChange={handleFilter}
                checked={unitFilter.available === CommonStatus.Active}
                style={{ color: "#21c986" }}
              />
              <span className="">
                Available (
                {salesChartByBlockData?.GetSalesChartByBlock.units.filter(
                  (unit) => isAvailableUnit(unit)
                ).length || 0}
                )
              </span>
            </RowFlex>
            <RowFlex crossAxisAlignment="center">
              <Checkbox
                id="unavailable"
                onChange={handleFilter}
                checked={unitFilter.unavailable === CommonStatus.Inactive}
                style={{ color: "#c5c5c5" }}
              />
              <span>
                Unavailable (
                {salesChartByBlockData?.GetSalesChartByBlock.units.filter(
                  (unit) => isUnavailableUnit(unit)
                ).length || 0}
                )
              </span>
            </RowFlex>
            {threeDStatus?.getProjectChartMapped === false && (
              <RowFlex crossAxisAlignment="center" gap="6px">
                <img src={BumiIcon} alt="" width={18} />
                <span>Bumi Unit</span>
              </RowFlex>
            )}
            {threeDStatus?.getProjectChartMapped === false && (
              <RowFlex crossAxisAlignment="center" gap="6px">
                <VisibilityRounded />
                <span>In-View</span>
              </RowFlex>
            )}
          </RowFlex>
        </ContainerX>
      </CardNeu>
      {openPriceRange && (
        <div
          onClick={() => setOpenPriceRange(false)}
          style={{
            position: "fixed",
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
            top: "0px",
            left: "0px",
            zIndex: 2,
          }}
        ></div>
      )}
      {openUnitType && (
        <div
          onClick={() => setOpenUnitType(false)}
          style={{
            position: "fixed",
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
            top: "0px",
            left: "0px",
            zIndex: 2,
          }}
        ></div>
      )}

      {unitDetailData && openBottomSheet && (
        <BottomSheet
          unitData={unitDetailData}
          setOpenBottomSheet={setOpenBottomSheet}
          projectId={projectId}
          contactId={contactId}
          isIntegrate={isIntegrate}
        />
      )}
    </>
  );
};

export default SalesChart;
