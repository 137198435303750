import { getAccessToken } from "./AccessToken";

const host = import.meta.env.VITE_PROPERTY365_CRM_API_HOST;
const url = `${host}/api/tools/geoip`;
const InterceptorSkipHeader = "X-Skip-Interceptor";

export const fetchCountryCode = async () => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch GeoIP data");
      }
      const location = await response.json();
      return location.IsoCode.toUpperCase();
    } catch (error) {
      console.error("Error fetching GeoIP data:", error);
    }
};
