import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Card } from "ui-neumorphism";
import ContainerX from "../../../components/general/ContainerX";
import newsImage01 from "../../../assets/images/background/weatherbg.jpg";
import newsImage02 from "../../../assets/images/background/profile-bg.jpg";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetDashboardNewsLazyQuery } from "../../../generated/graphql";
import TextX from "../../../components/general/TextX";
import { DASHBOARDS_PATH } from "../Dashboards/DashboardRoutes";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import ZoomedImageDialog from "../../../components/Dialog/ZoomedImageDialog";
import CardNeu from "../../../components/Card/Card";
import { textChangeColor } from "../../../containers/context/ContainerXContext";
import { useContext } from "react";

const NewsSwiper = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [isZoomImage, setIsZoomImage] = useState(-1); // -1 means no image is zoomed
  const [mouseDownTime, setMouseDownTime] = useState(null);

  const handleMouseDown = () => {
    setMouseDownTime(new Date().getTime());
  };

  const handleMouseUp = (index) => {
    const mouseUpTime = new Date().getTime();
    const timeDifference = mouseUpTime - mouseDownTime;
    if (timeDifference < 90) {
      setIsZoomImage(index);
    }
    setMouseDownTime(null);
  };

  const [
    getDashboardNews,
    { data: newsData, loading: getNewsLoading, error: getNewsError },
  ] = useGetDashboardNewsLazyQuery({});

  useEffect(() => {
    if (projectId) {
      getDashboardNews({ variables: { projectId: projectId } });
    }
  }, [projectId]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  if (newsData?.getDashboardNews?.length < 1) {
    return null;
  }

  return (
    <ColumnFlex fullWidth>
      <NewsHeader />
      <Slider {...settings}>
        {newsData?.getDashboardNews.map((news, index) => (
          <ContainerX key={news.id} overflow="visible">
            <ContainerX
              height={"150px"}
              decoration={{ borderRadius: "10px" }}
              overflow="hidden"
              margin={{ symmetric: { horizontal: "10px", vertical: "8px" } }}
              className="box-shadow-3"
            >
              <img
                src={news.attachment[0].resourceUrl || newsImage01}
                alt="newsImage"
                className="swiper-img"
                onMouseDown={handleMouseDown}
                onMouseUp={() => handleMouseUp(index)}
              />
              <div style={{ transform: "translateY(-100%)" }}>
                <ContainerX
                  backgroundColor="rgba(0,0,0,.7803921568627451)"
                  color="#fff"
                  alignment="center"
                  padding={{ all: "10px" }}
                >
                  <ColumnFlex crossAxisAlignment="center" gap="2px">
                    <span className="fs-12 fw-600">{news.title}</span>
                    <TextX
                      className="fw-500 fs-10 color-text-2"
                      dataType="Date"
                      data={news.startDate}
                    />
                  </ColumnFlex>
                </ContainerX>
              </div>
            </ContainerX>
          </ContainerX>
        ))}
      </Slider>
      <ZoomedImageDialog
        visible={isZoomImage !== -1}
        onClose={() => setIsZoomImage(-1)}
        src={
          newsData?.getDashboardNews?.[isZoomImage]?.attachment[0]
            .resourceUrl || newsImage01
        }
      />
    </ColumnFlex>
  );
};

const NewsHeader = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const navigation = useNavigate();
  const handleJumpToNewsListing = () => {
    navigation(
      `${DASHBOARDS_PATH.NEWS.replace(
        ":accountCode",
        getPathCookie()
      )}?projectId=${projectId}`
    );
  };
  const { textColor } = textChangeColor();

  return (
    <RowFlex
      className="p-l-10 p-b-5"
      mainAxisAlignment="space-between"
      crossAxisAlignment="center"
    >
      <span className="fw-700 fs-12" style={{ color: textColor }}>
        News & Announcement
      </span>
      <div style={{ cursor: "pointer" }} onClick={handleJumpToNewsListing}>
        <RowFlex crossAxisAlignment="center" className="color-text-light-blue">
          <span
            className="fs-12 fw-600 color-text-light-blue"
            style={{ color: textColor }}
          >
            See All
          </span>
          <KeyboardArrowRightOutlinedIcon />
        </RowFlex>
      </div>
    </RowFlex>
  );
};

export default NewsSwiper;
