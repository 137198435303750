import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { Fab } from "ui-neumorphism";
import PropertyX_logo from "../../../assets/images/property-X.png";
import Login_icon from "../../../assets/images/icon/login-img.png";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useEffect, useState } from "react";
import {
  useCheckAccountCodeValidLazyQuery,
  useLoginLazyQuery,
} from "../../../generated/graphql";
import Spinner from "../../../components/Spinner/Spinner";
import {
  deleteAccessToken,
  getAccessToken,
  setAccessToken,
} from "../../../containers/helpers/AccessToken";
import { DASHBOARDS_PATH } from "../Dashboards/DashboardRoutes";
import TextFieldNeu from "../../../components/TextField/TextFieldNeu";
import {
  deletePathCookie,
  getPathCookie,
  setPathCookie,
} from "../../../containers/helpers/PathCookie";
import { AUTH_PATH, Invalid_Auth_Path } from "./AuthRoutes";
import ServiceWorkerWrapper from "../../../components/ServiceWorker/ServiceWorker";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { StackItem, StackX } from "../../../components/LayoutX/StackLayout";
import { cardChangeColor } from "../../../containers/context/CardContext";

type userLoginForm = {
  LoginId: string;
  Password: string;
  accountCode: string;
};

const Login = () => {
  const { accountCode = "" } = useParams();
  const navigation = useNavigate();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [userForm, setUserForm] = useState<userLoginForm>({
    LoginId: "",
    Password: "",
    accountCode: accountCode || "",
  });
  const [emailWarning, setEmailWarning] = useState<string | null>(null);
  const [passwordWarning, setPasswordWarning] = useState<string | null>(null);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const { cardColor } = cardChangeColor();

  useEffect(() => {
    if (getAccessToken() && getPathCookie()) {
      // openSnackbar("Already Logged In");
      navigation(
        DASHBOARDS_PATH.DASHBOARD.replace(":accountCode", accountCode)
      );
    } else if (!accountCode && getPathCookie()) {
      // openSnackbar("Automatic Redirecting to correct account code");
      navigation(AUTH_PATH.LOGIN.replace(":accountCode", getPathCookie()));
    }
  }, []);

  const [getAccountCode, { data: accountCodeData }] =
    useCheckAccountCodeValidLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [
    loginRequest,
    { data: loginResponse, loading: loginLoading, error: loginError },
  ] = useLoginLazyQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const sendRequest = async () => {
      await getAccountCode({
        variables: {
          accountCode: accountCode || "",
        },
      });
    };

    sendRequest();
  }, [accountCode]);

  const handleLoginSubmit = async () => {
    await loginRequest({
      variables: userForm,
    });
  };

  useEffect(() => {
    if (loginResponse) {
      setAccessToken(loginResponse.login.accessToken);
      setPathCookie(accountCode);

      const path = DASHBOARDS_PATH.DASHBOARD.replace(
        ":accountCode",
        accountCode
      );

      // return <Navigate to={path} replace />;
      navigation(path);
    }
  }, [loginResponse, loginError]);

  const handleEmailChange = (e: any) => {
    if (e.target.value.length <= 0) {
      setEmailWarning("Email is required");
    } else {
      setEmailWarning(null);
    }

    setUserForm({ ...userForm, LoginId: e.target.value });
  };

  const handlePasswordChange = (e: any) => {
    if (e.target.value.length <= 0) {
      setPasswordWarning("Password is required");
    } else {
      setPasswordWarning(null);
    }
    setUserForm({ ...userForm, Password: e.target.value });
  };

  return (
    <>
      <ServiceWorkerWrapper />
      <div className="Auth-Container">
        <ColumnFlex gap="20px" crossAxisAlignment="center" fullWidth>
          {accountCodeData?.checkAccountCodeValid?.purchaserAppCss
            ?.showLoginIcon ? (
            <img src={Login_icon} className="login-logo" alt="Login Icon" />
          ) : null}
          <div style={{ width: "100%" }}>
            <CardNeu elavation={2} style={{ background: cardColor }}>
              <ContainerX
                padding={{
                  symmetric: { vertical: "20px", horizontal: "40px" },
                }}
                alignment="center"
              >
                <ColumnFlex crossAxisAlignment="center">
                  <ColumnFlex
                    crossAxisAlignment="center"
                    gap="2px"
                    className="p-b-20"
                    fullWidth
                  >
                    <img
                      src={
                        accountCodeData?.checkAccountCodeValid?.purchaserAppCss
                          ?.logoUrl || PropertyX_logo
                      }
                      className="company-logo"
                    />
                    <span
                      className="color-orange text-with-shadow fs-14"
                      style={{
                        width: "100%",
                        textAlign: "end",
                        paddingRight: "10%",
                      }}
                    >
                      {accountCodeData?.checkAccountCodeValid?.purchaserAppCss
                        ?.showPurchaserAppWord
                        ? "Purchaser App"
                        : null}
                    </span>
                  </ColumnFlex>
                  <ColumnFlex gap="30px" fullWidth>
                    <ColumnFlex
                      className="neu-text-field-style"
                      fullWidth
                      gap="14px"
                    >
                      <ColumnFlex gap="4px">
                        <input
                          type="text"
                          placeholder="Email"
                          onChange={handleEmailChange}
                          value={userForm.LoginId}
                          className="fullwidth"
                        />
                        {emailWarning && (
                          <span className="color-red fs-10 fw-400">
                            {emailWarning}
                          </span>
                        )}
                      </ColumnFlex>
                      <ColumnFlex gap="4px">
                        <StackX>
                          <input
                            type={isShowPassword ? "text" : "password"}
                            placeholder="Password"
                            onChange={handlePasswordChange}
                            value={userForm.Password}
                            className="fullwidth"
                            style={{ paddingRight: "40px" }}
                          />
                          <StackItem top="0" right="0">
                            <IconButton
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            >
                              {isShowPassword ? (
                                <VisibilityRounded />
                              ) : (
                                <VisibilityOffRounded />
                              )}
                            </IconButton>
                          </StackItem>
                        </StackX>
                        {passwordWarning && (
                          <span className="color-red fs-10 fw-400">
                            {passwordWarning}
                          </span>
                        )}
                      </ColumnFlex>
                    </ColumnFlex>
                    <Fab
                      className={"fullwidth-with-child"}
                      // style={{ marginTop: "30px" }}
                      onClick={handleLoginSubmit}
                      disabled={
                        loginLoading ||
                        userForm.LoginId?.length <= 0 ||
                        userForm.Password?.length <= 0
                      }
                    >
                      <RowFlex crossAxisAlignment="center" gap="10px">
                        {/* {loginLoading && <Spinner />} */}
                        <span className="color-text-blue-gradient">Login</span>
                      </RowFlex>
                    </Fab>
                    <div style={{ width: "100%" }}>
                      <RowFlex mainAxisAlignment="space-between" fullWidth>
                        <Link
                          to={
                            accountCode
                              ? AUTH_PATH.REGISTER.replace(
                                  ":accountCode",
                                  accountCode
                                )
                              : Invalid_Auth_Path.REGISTER
                          }
                          className="click-text-hover fs-14 color-text-2"
                        >
                          Sign Up
                        </Link>
                        <Link
                          to={
                            accountCode
                              ? AUTH_PATH.FORGOT_PASSWORD.replace(
                                  ":accountCode",
                                  accountCode
                                )
                              : Invalid_Auth_Path.FORGOT_PASSWORD
                          }
                          className="click-text-hover fs-14 color-text-2"
                        >
                          Forgot Password
                        </Link>
                      </RowFlex>
                    </div>
                  </ColumnFlex>
                </ColumnFlex>
              </ContainerX>
            </CardNeu>
          </div>
        </ColumnFlex>
      </div>
    </>
  );
};

export default Login;
