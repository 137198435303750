import ContainerX from "../../../components/general/ContainerX";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import UnitDetailsHeader from "./UnitDetailsHeader";
import UnitDetailsSwiper from "./UnitDetailsSwiper";
import { Skeleton } from "@mui/material";

const UnitDetails = ({ salesAccData }) => {
  if (salesAccData) {
    return (
      <ColumnFlex fullWidth>
        <UnitDetailsHeader salesAccount={salesAccData} />
        {salesAccData?.getSalesAccount.length > 0 && (
          <UnitDetailsSwiper salesAccount={salesAccData} />
        )}
      </ColumnFlex>
    );
  } else {
    return <Skeleton variant="text" sx={{ fontSize: "3rem" }} />;
  }
};

export default UnitDetails;
