import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../GeneralPages/GeneralRoutes";
import { useEffect, useRef, useState } from "react";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { Button } from "ui-neumorphism";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TextX from "../../../components/general/TextX";
import CardNeu from "../../../components/Card/Card";
import {
  useGetNotificationsLazyQuery,
  useMarkAllReadLazyQuery,
  useMarkAsReadMutation,
} from "../../../generated/graphql";
import LensIcon from "@mui/icons-material/Lens";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import ContainerX from "../../../components/general/ContainerX";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import { RowGrid } from "../../../components/LayoutX/Grid/RowGrid";
import { set } from "date-fns";
import EmptyRecord from "../../../components/general/EmptyRecord";
import { headerChangeColor } from "../../../containers/context/Context";
import { cardChangeColor } from "../../../containers/context/CardContext";

import { useContext } from "react";

const Notification = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState([] as any[]);
  const [getNotification, { data: notificationData }] =
    useGetNotificationsLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setNotificationList(data.getNotifications.data);

        if (data.getNotifications.data.length > 0) {
          setTotalPages(data.getNotifications.total);
        }
      },
    });

  const [markAsReadRequest, { data: markAsReadData }] = useMarkAsReadMutation();
  const [markAllRead] = useMarkAllReadLazyQuery();

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPages, setTotalPages] = useState(0);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  const handleMarkAsReadNotification = (id: string, isRead: boolean) => {
    if (isRead) return;
    markAsReadRequest({
      variables: {
        notificationIds: id,
      },
      onCompleted: () => {
        window.location.reload();
      },
    });
  };

  useEffect(() => {
    if (!projectId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getNotification({
        variables: {
          projectId: projectId,
          page: currentPage,
          limit: limit,
          search: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!projectId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getNotification({
        variables: {
          projectId: projectId,
          page: currentPage,
          limit: limit,
          search: null,
        },
      });
    }
  }, [currentPage]);

  const handleMarkAlllAsRead = () => {
    markAllRead({
      onCompleted: () => {
        window.location.reload();
      },
    });
  };

  return (
    <ColumnFlex fullWidth gap="40px">
      <GeneralHeader title="Notification" style={{ background: headerColor }} />
      <ColumnFlex fullWidth gap="20px">
        <RowFlex mainAxisAlignment="space-between" crossAxisAlignment="center">
          <span className="fs-12 fw-700">
            Notification Listing (
            {notificationData?.getNotifications?.total || 0})
          </span>
          {notificationData?.getNotifications?.total > 0 && (
            <Button
              className="custom-neu-button-style bg-blue color-white"
              onClick={handleMarkAlllAsRead}
              rounded
            >
              Mark All as Read
            </Button>
          )}
        </RowFlex>
        {notificationList &&
          notificationList?.map((item) => (
            <NotificationItem
              item={item}
              handleMarkAsReadNotification={handleMarkAsReadNotification}
            />
          ))}

        {(!notificationData || notificationList.length === 0) && (
          <EmptyRecord />
        )}
      </ColumnFlex>

      {totalPages > limit && (
        <ContainerX className="margin-horizontal-center">
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
          />
        </ContainerX>
      )}
    </ColumnFlex>
  );
};

const NotificationItem = ({ item, handleMarkAsReadNotification }) => {
  const descRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { cardColor } = cardChangeColor();

  useEffect(() => {
    if (descRef.current) {
      setIsOverflow(descRef.current.scrollWidth > descRef.current.offsetWidth);
    }
  }, [descRef]);

  return (
    <CardNeu
      className="p-v-18 p-h-12 color-text"
      key={item.id}
      rounded
      style={{ background: cardColor }}
    >
      <div
        onClick={() => handleMarkAsReadNotification(item.id, item.isRead)}
        style={{ cursor: "pointer" }}
      >
        <RowGrid fullWidth gap="5px" gridTemplateColumns="auto 1fr auto">
          <LensIcon
            sx={{
              width: "12px",
              height: "12px",
              padding: "1px",
              boxSizing: "content-box",
            }}
            className={`color-blue-1 ${item.isRead ? "color-transparent" : ""}`}
          />
          <ColumnFlex fullWidth gap="5px" overflow="hidden">
            <span className="fs-12 fw-600">{item.subject}</span>
            <span
              ref={descRef}
              className="fs-10 fw-500 color-text-2"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: `${expanded ? "" : "nowrap"}`,
              }}
            >
              {item.body}
            </span>
          </ColumnFlex>
          <ColumnFlex crossAxisAlignment="center" gap="5px">
            <TextX
              data={"10 Feb 2022"}
              className="fw-500 fs-10"
              textStyle={{ whiteSpace: "nowrap" }}
            />
            {isOverflow && !expanded && (
              <span
                className="fs-10 fw-600 click-text"
                onClick={() => setExpanded(true)}
              >
                Read more
              </span>
            )}
          </ColumnFlex>
        </RowGrid>
      </div>
    </CardNeu>
  );
};

export default Notification;
