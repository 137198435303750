import Spinner from "../../components/Spinner/Spinner";
import { Suspense } from "react";
import { Outlet } from "react-router";

export const Blank = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Outlet />
    </Suspense>
  );
};
