import { PassswordPolicyOuput } from "generated/graphql";

type PassswordValidationResult = {
    isValid: boolean;
    message: string;
}

export const passwordValidation = (password: string, passwordPolicy: PassswordPolicyOuput) => {    
    
    const result: PassswordValidationResult = {
        isValid: false,
        message: "error",
    };

    if (password.length < passwordPolicy.RequiredLength) {
        result.message = passwordPolicy.ErrorMsg.RequiredLength;
    } 
    
    else if (passwordPolicy.RequireDigit && !/\d/.test(password)) {
        result.message = passwordPolicy.ErrorMsg.RequireDigit;
    } 

    else if (passwordPolicy.RequireLowercase && !/[a-z]/.test(password)) {
        result.message = passwordPolicy.ErrorMsg.RequireLowercase;
    } 
    
    else if (passwordPolicy.RequireNonAlphanumeric && !containsSymbol(password)) {
        result.message = passwordPolicy.ErrorMsg.RequireNonAlphanumeric;
    } 

    else if (passwordPolicy.RequireUppercase && !/[A-Z]/.test(password)) {
        result.message = passwordPolicy.ErrorMsg.RequireUppercase;
    }
    
    
    else if (checkDuplicateChars(password, passwordPolicy.RequiredUniqueChars)) {
        result.message = passwordPolicy.ErrorMsg.RequiredUniqueChars;
    }
    else {
        result.isValid = true;
        result.message = "";
    }    

    return result;
}

//check the character count of the password greater than the required length
// return true if the password length is greather than the required length
function checkDuplicateChars(str: string, x: number): boolean {
    const charCount: Record<string, number> = {};

    for (const char of str) {
        charCount[char] = (charCount[char] || 0) + 1;
    }

    for (const char in charCount) {
        if (charCount[char] > x) {
            return true;
        }
    }

    return false;
}

//return true if contains a symbol
function containsSymbol(password: string): boolean {
    const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    return symbolRegex.test(password);
}