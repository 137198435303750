// import { Dialog } from "components/Dialogs/Dialog";
import { ColumnFlex } from "../LayoutX/Flex/ColumnFlex";
import SimpleDialog from "../Dialog/SimpleDialog";
import { useState } from "react";
import { Button } from "ui-neumorphism";
import { registerSW } from "virtual:pwa-register";
import ContainerX from "../general/ContainerX";

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState<boolean>(false);

  registerSW({
    onNeedRefresh() {
      setShowReload(true);
    },
  });

  const updateSW = registerSW({
    onRegistered(r) {
      r
        ? setInterval(() => {
            r.update();
          }, 60 * 60 * 1000)
        : setShowReload(false);
    },
  });

  return (
    <SimpleDialog visible={showReload} onClose={() => {}} width={400}>
      <ColumnFlex gap="30px" crossAxisAlignment="center" fullWidth>
        <span className="fw-700 fs-16 color-text text-with-shadow">
          A new version is available!
        </span>
        <Button
          className={"disable-full-uppercase color-text"}
          onClick={() => {
            updateSW();
            setShowReload(false);
          }}
          rounded
          style={{ width: "200px" }}
        >
          Reload
        </Button>
      </ColumnFlex>
    </SimpleDialog>
  );
};

export default ServiceWorkerWrapper;
