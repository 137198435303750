import React from "react";
import { Dialog } from "ui-neumorphism";
import "../../assets/styles/website/MainLayout.scss";

const ZoomedImageDialog = ({ visible, onClose, src }) => {
  return (
    <Dialog visible={visible && src} onClose={onClose}>
      <img src={src} alt="zoomImage" className="box-shadow-4 img-dialog" />
      {/* <ColumnFlex className="img-dialog-container"></ColumnFlex> */}
    </Dialog>
  );
};

export default ZoomedImageDialog;
