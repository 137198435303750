import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../../GeneralPages/GeneralRoutes";
import { Button, IconButton } from "ui-neumorphism";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CardNeu from "../../../../components/Card/Card";
import { useEffect, useState } from "react";
import ContainerX from "../../../../components/general/ContainerX";
import {
  useDownloadLoanAttachmentLazyQuery,
  useLoanDocumentsLazyQuery,
  useLoanListingBySalesLazyQuery,
  useUploadLoanAttachmentMutation,
  SalesUploadLoanDocInput,
  useDeleteLoanAttachmentLazyQuery,
  SalesAccountDocument,
} from "../../../../generated/graphql";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import { Cancel } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import Expanded from "../../../../components/LayoutX/Expanded";
import pdfIcon from "../../../../assets/images/property/pdf-icon.png";
import { ColumnGrid } from "../../../../components/LayoutX/Grid/ColumnGrid";
import PublishRoundedIcon from "@mui/icons-material/PublishRounded";
import { saveAs } from "file-saver";
import { ButtonBase, Button as Mui_Button, styled } from "@mui/material";
import { useSnackbar } from "react-simple-snackbar";
import {
  AttachmentHelper,
  acceptFileType,
  attachmentRefTypeList,
  compressImage,
  setWatermark,
} from "../../../../containers/helpers/AttachmentHelper";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import { StackItem, StackX } from "../../../../components/LayoutX/StackLayout";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import { set } from "date-fns";
import { headerChangeColor } from "../../../../containers/context/Context";
import { cardChangeColor } from "../../../../containers/context/CardContext";

import { useContext } from "react";

const docType = {
  bookingFrom: "BOOKING_FORM",
  customerIC: [
    "BOOKING_BUYER_NRIC_ATTACHMENT",
    "BOOKING_JOINT_BUYER_NRIC_ATTACHMENT",
    "BOOKING_BUYER_PASSPORT_ATTACHMENT",
  ],
  customerCreditReport: "BOOKING_CCR_ATTACHMENT",
  customerPayslips: "LOAN_PAY_SLIP",
  customerEPFEABank: "LOAN_INCOME_STATEMENT",
  otherDocuments: "LOAN_OTHER_ATTACHMENT",
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const PurchaserDocuments = () => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const saleId = searchParams.get("saleId");
  const projectId = searchParams.get("projectId");
  const loanId = searchParams.get("loanId");
  const [getLoanDocuments, { data: loanDocumentsData }] =
    useLoanDocumentsLazyQuery();
  const [downloadLoanAttachment] = useDownloadLoanAttachmentLazyQuery();
  const [uploadLoanAttachment] = useUploadLoanAttachmentMutation();
  const [deleteLoanAttachment] = useDeleteLoanAttachmentLazyQuery();
  const [isOpenDeleteDocumentDialog, setIsOpenDeleteDocumentDialog] = useState<
    string | null
  >(null);
  type attachmentUploadType = {
    refId: string;
    refType: (typeof attachmentRefTypeList)[keyof typeof attachmentRefTypeList];
    file: File;
  };
  const [attachmentData, setAttachmentData] =
    useState<attachmentUploadType>(null);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();

  useEffect(() => {
    if (!projectId || !saleId || !loanId) {
      navigation(-1);
    } else {
      getLoanDocuments({
        variables: {
          loanId: loanId,
        },
      });
    }
  }, []);

  const downloadAttachment = (attachmentId: string) => {
    downloadLoanAttachment({
      variables: {
        attachmentId: attachmentId,
      },
      onCompleted: (data) => {
        saveAs(
          data.downloadLoanAttachment?.base64,
          data.downloadLoanAttachment.fileName
        );
      },
    });
  };

  const handleFileChange = async (event, refId, refType) => {
    let file = event.target.files[0];
    if (!file) return;
    const attachmentHelper = new AttachmentHelper([""], 25);
    if (!attachmentHelper.checkFileSize(file)) {
      openSnackbar(
        `Uploaded file must be an image and less than ${attachmentHelper.maxFileSize} MB.`
      );
      return;
    }

    if (file.type !== acceptFileType.PDF) {
      file = await compressImage(file);
    }

    setAttachmentData({
      refId: refId,
      refType: refType,
      file: file,
    });
  };

  useEffect(() => {
    if (
      attachmentData &&
      attachmentData.refId &&
      attachmentData.refType &&
      attachmentData.file
    ) {
      uploadAttachment();
    }
  }, [attachmentData]);

  const uploadAttachment = () => {
    if (
      !attachmentData ||
      !attachmentData.file ||
      !attachmentData.refId ||
      !attachmentData.refType
    )
      return;
    uploadLoanAttachment({
      variables: {
        input: {
          refId: attachmentData.refId,
          refType: attachmentData.refType,
        },
        file: attachmentData.file,
      },
      onCompleted: (data) => {
        if (data.uploadLoanAttachment) {
          window.location.reload();
        }
      },
    });
  };

  const countSpecificDocType = (
    saleLoanDocArray: any[],
    type: string | string[]
  ): number | null => {
    const result = saleLoanDocArray?.filter((doc) => {
      if (Array.isArray(type)) {
        return type.includes(doc.refType);
      } else {
        return doc.refType === type;
      }
    }).length;
    return result === 0 ? null : result;
  };

  const handleDeleteDocument = (fileId: string) => {
    const target = loanDocumentsData?.getLoanDetails.documentData?.find(
      (item) => item.id === fileId
    );
    if (
      !target ||
      !target.allowDelete ||
      target.refType !== docType.customerCreditReport
    )
      return;

    deleteLoanAttachment({
      variables: {
        attachmentId: fileId,
      },
      onCompleted: () => {
        openSnackbar("File deleted successfully.");
        setIsOpenDeleteDocumentDialog(null);
        window.location.reload();
      },
    });
  };

  if (loanDocumentsData) {
    return (
      <>
        <ColumnFlex fullWidth gap="24px">
          <GeneralHeader
            title="Purchaser Documents"
            style={{ background: headerColor }}
          />

          <CardNeu style={{ background: cardColor }}>
            <ContainerX
              padding={{ all: "16px" }}
              className="divider-bottom-border"
            >
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">Booking Form</span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      loanDocumentsData?.getLoanDetails.documentData,
                      docType.bookingFrom
                    ) || "No"}{" "}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {loanDocumentsData?.getLoanDetails.documentData?.map(
                      (item) =>
                        item.refType === docType.bookingFrom && (
                          <ColumnFlex key={item.id}>
                            <div
                              className="purchaser-doc-img-container click-able"
                              onClick={() => downloadAttachment(item.id)}
                            >
                              <img
                                src={
                                  item.mediaType === "application/pdf"
                                    ? pdfIcon
                                    : item.resourceUrl
                                }
                                className="purchaser-doc-img"
                                alt={"documentImg"}
                              />
                            </div>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
              </RowFlex>
            </ContainerX>

            <ContainerX
              padding={{ all: "16px" }}
              className="divider-bottom-border"
            >
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">
                    NRIC Copy/Passport Copy (Main & Joint Buyer)
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      loanDocumentsData?.getLoanDetails.documentData,
                      docType.customerIC
                    ) || "No"}{" "}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {loanDocumentsData?.getLoanDetails.documentData?.map(
                      (item) =>
                        docType.customerIC.includes(item.refType) && (
                          <ColumnFlex key={item.id}>
                            <div
                              className="purchaser-doc-img-container click-able"
                              onClick={() => downloadAttachment(item.id)}
                            >
                              <img
                                src={
                                  item.mediaType === "application/pdf"
                                    ? pdfIcon
                                    : item.resourceUrl
                                }
                                className="purchaser-doc-img"
                              />
                            </div>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
                <Mui_Button
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  className="button"
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                >
                  <PublishRoundedIcon />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => {
                      handleFileChange(
                        event,
                        loanDocumentsData.getLoanDetails.id,
                        attachmentRefTypeList.customerIC_BuyerIC
                      );
                    }}
                  />
                </Mui_Button>
              </RowFlex>
            </ContainerX>

            <ContainerX
              padding={{ all: "16px" }}
              className="divider-bottom-border"
            >
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">
                    Customer Credit Report (ie. CCRIS, CTOS)
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      loanDocumentsData?.getLoanDetails.documentData,
                      docType.customerCreditReport
                    ) || "No"}{" "}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {loanDocumentsData?.getLoanDetails.documentData?.map(
                      (item) =>
                        item.refType === docType.customerCreditReport && (
                          <ColumnFlex key={item.id}>
                            <StackX>
                              <div
                                className="purchaser-doc-img-container click-able"
                                onClick={() => downloadAttachment(item.id)}
                              >
                                <img
                                  src={
                                    item.mediaType === "application/pdf"
                                      ? pdfIcon
                                      : item.resourceUrl
                                  }
                                  className="purchaser-doc-img"
                                />
                              </div>
                              {item.allowDelete && (
                                <StackItem top="-8px" right="-8px">
                                  <ButtonBase
                                    disableRipple
                                    disableTouchRipple
                                    onClick={() =>
                                      setIsOpenDeleteDocumentDialog(item.id)
                                    }
                                  >
                                    <Cancel color="error" />
                                  </ButtonBase>
                                </StackItem>
                              )}
                            </StackX>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                            <SimpleDialog
                              visible={isOpenDeleteDocumentDialog === item.id}
                              onClose={() =>
                                setIsOpenDeleteDocumentDialog(null)
                              }
                            >
                              <ColumnFlex gap="10px">
                                <span className="fw-700 fs-16 color-text text-with-shadow">
                                  Delete Confirmation
                                </span>
                                <span className="fw-400 fs-12">
                                  Are you sure want to delete this file?
                                </span>
                                <RowFlex
                                  fullWidth
                                  mainAxisAlignment="flex-end"
                                  gap="20px"
                                  className="disable-full-uppercase"
                                >
                                  <Button
                                    onClick={() =>
                                      setIsOpenDeleteDocumentDialog(null)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleDeleteDocument(item.id)
                                    }
                                  >
                                    Confirm
                                  </Button>
                                </RowFlex>
                              </ColumnFlex>
                            </SimpleDialog>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
              </RowFlex>
            </ContainerX>

            <ContainerX
              padding={{ all: "16px" }}
              className="divider-bottom-border"
            >
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">
                    Payslip (3 Months)
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      loanDocumentsData?.getLoanDetails.documentData,
                      docType.customerPayslips
                    ) || "No"}{" "}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {loanDocumentsData?.getLoanDetails.documentData?.map(
                      (item) =>
                        item.refType === docType.customerPayslips && (
                          <ColumnFlex key={item.id}>
                            <div
                              className="purchaser-doc-img-container click-able"
                              onClick={() => downloadAttachment(item.id)}
                            >
                              <img
                                src={
                                  item.mediaType === "application/pdf"
                                    ? pdfIcon
                                    : item.resourceUrl
                                }
                                className="purchaser-doc-img"
                              />
                            </div>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
              </RowFlex>
            </ContainerX>

            <ContainerX
              padding={{ all: "16px" }}
              className="divider-bottom-border"
            >
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">
                    EPF / EA / Bank Statement (3 Months)
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      loanDocumentsData?.getLoanDetails.documentData,
                      docType.customerEPFEABank
                    ) || "No"}{" "}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {loanDocumentsData?.getLoanDetails.documentData?.map(
                      (item) =>
                        item.refType === docType.customerEPFEABank && (
                          <ColumnFlex key={item.id}>
                            <div
                              className="purchaser-doc-img-container click-able"
                              onClick={() => downloadAttachment(item.id)}
                            >
                              <img
                                src={
                                  item.mediaType === "application/pdf"
                                    ? pdfIcon
                                    : item.resourceUrl
                                }
                                className="purchaser-doc-img"
                              />
                            </div>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
                <Mui_Button
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  className="button"
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                >
                  <PublishRoundedIcon />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => {
                      handleFileChange(
                        event,
                        loanDocumentsData.getLoanDetails.id,
                        attachmentRefTypeList.customerEPFEABank
                      );
                    }}
                  />
                </Mui_Button>
              </RowFlex>
            </ContainerX>

            <ContainerX padding={{ all: "16px" }}>
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">Others</span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      loanDocumentsData?.getLoanDetails.documentData,
                      docType.otherDocuments
                    ) || "No"}{" "}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {loanDocumentsData?.getLoanDetails.documentData?.map(
                      (item) =>
                        item.refType === docType.otherDocuments && (
                          <ColumnFlex key={item.id}>
                            <div
                              className="purchaser-doc-img-container click-able"
                              onClick={() => downloadAttachment(item.id)}
                            >
                              <img
                                src={
                                  item.mediaType === "application/pdf"
                                    ? pdfIcon
                                    : item.resourceUrl
                                }
                                className="purchaser-doc-img"
                              />
                            </div>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
              </RowFlex>
            </ContainerX>
          </CardNeu>
        </ColumnFlex>
      </>
    );
  }
};

export default PurchaserDocuments;
