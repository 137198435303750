import { CSSProperties, ReactNode } from "react";

export const Stack = ({ children }: { children: ReactNode }) => {
  return (
    <div style={{ position: "relative", width: "100%", height: "210px" }}>
      {children}
    </div>
  );
};

export const Positioned = ({
  top,
  left,
  right,
  bottom,
  children,
}: {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  children: ReactNode;
}) => {
  const positionStyle: CSSProperties = {
    position: "absolute",
    top: top || "0",
    left: left || "0",
    right: right || "0",
    bottom: bottom || "0",
  };

  return <div style={positionStyle}>{children}</div>;
};
