import { RouteObject } from "react-router-dom";
import Dashboard from "./Dashboard";
import BookingDetails from "./BookingInfo/BookingDetails";
import SiteProgress from "./SiteProgress/SiteProgress";
import LoanListing from "./Loan/LoanListing";
import Notification from "./Notification";
import NewsListing from "../News/NewsListing";
import SalesGallery from "../Project/PageDetail";
import JointBuyerListing from "./BookingInfo/JointBuyerListing";
import PurchaserDocumentListing from "./BookingInfo/PurchaserDocumentListing";
import BookingFee from "./BookingInfo/BookingFee";
import SigningInformation from "./BookingInfo/SignInfo";
import TownshipDetails from "./BookingInfo/TownshipDetails";
import LoanDetails from "./Loan/LoanDetails";
import PurchaserDocuments from "./Loan/PurchaserDocuments";
import NewsDetails from "../News/NewsDetails";
import SalesChart from "../Booking/Unit/SalesChart";
import BrochureListing from "../Project/BrochureListing";
import Mortgage from "../Project/Mortgage";
import AccountState from "./AccountState/AccountState";

export const DASHBOARDS_PATH = {
  DASHBOARD: `/:accountCode/dashboards/dashboard`,
  BOOKING_DETAILS: `/:accountCode/dashboards/spa-info`,
  SITE_PROGRESS: `/:accountCode/dashboards/site-progress`,
  LOAN_LISTING: `/:accountCode/dashboards/loan-listing`,
  NOTIFICATION: `/:accountCode/dashboards/notification`,
  NEWS: `/:accountCode/dashboards/news`,
  PAGE_DETAIL: `/:accountCode/dashboards/page-detail`,
  JOINT_BUYER_LISTING: `/:accountCode/dashboards/joint-buyer-listing`,
  PURCHASER_DOCUMENT: `/:accountCode/dashboards/purchaser-document`,
  BOOKING_FEE: `/:accountCode/dashboards/booking-fee`,
  SIGN_INFO: `/:accountCode/dashboards/sign-info`,
  TOWNSHIP_DETAILS: `/:accountCode/dashboards/township-details`,
  LOAN_DETAILS: `/:accountCode/dashboards/loan-details`,
  PURCHASER_DOCUMENTS: `/:accountCode/dashboards/purchaser-doc`,
  NEWS_DETAILS: `/:accountCode/dashboards/news-details`,
  BOOKING_UNIT: `/:accountCode/booking/unit`,
  BROCHURE: `/:accountCode/dashboards/brochure`,
  MORTGAGE: `/:accountCode/dashboards/mortgage`,
  ACCOUNT_STATE: `/:accountCode/dashboards/acc-state`,
} as const;

// export const InvalidDashboardPath = {
//   DASHBOARD: `/dashboard`,
// };

const DashboardRoutes: RouteObject[] = [
  {
    path: DASHBOARDS_PATH.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: DASHBOARDS_PATH.BOOKING_DETAILS,
    element: <BookingDetails />,
  },
  {
    path: DASHBOARDS_PATH.SITE_PROGRESS,
    element: <SiteProgress />,
  },
  {
    path: DASHBOARDS_PATH.LOAN_LISTING,
    element: <LoanListing />,
  },
  {
    path: DASHBOARDS_PATH.NOTIFICATION,
    element: <Notification />,
  },
  {
    path: DASHBOARDS_PATH.NEWS,
    element: <NewsListing />,
  },
  {
    path: DASHBOARDS_PATH.PAGE_DETAIL,
    element: <SalesGallery />,
  },
  {
    path: DASHBOARDS_PATH.JOINT_BUYER_LISTING,
    element: <JointBuyerListing />,
  },
  {
    path: DASHBOARDS_PATH.PURCHASER_DOCUMENT,
    element: <PurchaserDocumentListing />,
  },
  {
    path: DASHBOARDS_PATH.BOOKING_FEE,
    element: <BookingFee />,
  },
  {
    path: DASHBOARDS_PATH.SIGN_INFO,
    element: <SigningInformation />,
  },
  {
    path: DASHBOARDS_PATH.TOWNSHIP_DETAILS,
    element: <TownshipDetails />,
  },
  {
    path: DASHBOARDS_PATH.LOAN_DETAILS,
    element: <LoanDetails />,
  },
  {
    path: DASHBOARDS_PATH.PURCHASER_DOCUMENTS,
    element: <PurchaserDocuments />,
  },
  {
    path: DASHBOARDS_PATH.NEWS_DETAILS,
    element: <NewsDetails />,
  },
  {
    path: DASHBOARDS_PATH.BOOKING_UNIT,
    element: <SalesChart />,
  },
  {
    path: DASHBOARDS_PATH.BROCHURE,
    element: <BrochureListing />,
  },
  {
    path: DASHBOARDS_PATH.MORTGAGE,
    element: <Mortgage />,
  },
  {
    path: DASHBOARDS_PATH.ACCOUNT_STATE,
    element: <AccountState />,
  },
];

export default DashboardRoutes;
