import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCheckAccountCodeValidLazyQuery } from "../../generated/graphql";
// Define the context type
export type cardColor = {
  cardColor: string;
};

// Create the context
const CardContext = createContext<{
  cardColor: string;
  setColorState: React.Dispatch<React.SetStateAction<cardColor>>;
  shadow: string;
}>({
  cardColor: "e4ebf5",
  setColorState: () => {},
  shadow:
    "6px 6px 12px var(--light-bg-dark-shadow), -6px -6px 12px var(--light-bg-light-shadow)",
});

// useEffect to change color
export const cardChangeColor = () => {
  const [cardColor, setColorState] = useState("#e4ebf5");
  const [shadow, setShadow] = useState(
    "6px 6px 12px var(--light-bg-dark-shadow), -6px -6px 12px var(--light-bg-light-shadow)"
  );
  const { accountCode = "" } = useParams();
  const customizeColor: any = localStorage.getItem("cardColorInLocal");
  const boxShadow: any = localStorage.getItem("boxShadow");
  const [purchaserApp, setPurchaserApp] = useState(null);
  const [getAccountCode, { data: accountCodeData }] =
    useCheckAccountCodeValidLazyQuery({
      fetchPolicy: "no-cache",
    });

  //  this useEffect for 1st time to get all the customize info like background , other component color
  useEffect(() => {
    const sendRequest = async () => {
      const { data } = await getAccountCode({
        variables: {
          accountCode: accountCode || "",
        },
      });
      setPurchaserApp(data);
      //  this if else is to assume there have the customize color
      if (
        data?.checkAccountCodeValid?.purchaserAppCss
        // data?.checkAccountCodeValid?.cardColor
        // data?.checkAccountCodeValid?.backgroundImage
      ) {
        localStorage.setItem(
          "backgroundImage",
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundImage
        );
        localStorage.setItem(
          "backgroundSize",
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundSize
        );
        localStorage.setItem(
          "backgroundPosition",
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundPosition
        );
        localStorage.setItem(
          "backgroundAttachment",
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundAttachment
        );
        localStorage.setItem(
          "backgroundRepeat",
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundRepeat
        );
        localStorage.setItem(
          "cardColorInLocal",
          data?.checkAccountCodeValid?.purchaserAppCss?.cardColor
        );
        localStorage.setItem(
          "projectListColorInLocal",
          data?.checkAccountCodeValid?.purchaserAppCss?.cardColor
        );
        localStorage.setItem(
          "headerColorInLocal",
          data?.checkAccountCodeValid?.purchaserAppCss?.headerColor
        );
        localStorage.setItem(
          "boxShadow",
          data?.checkAccountCodeValid?.purchaserAppCss?.boxShadow
        );
        setColorState(data?.checkAccountCodeValid?.purchaserAppCss?.cardColor);
        setShadow(data?.checkAccountCodeValid?.purchaserAppCss?.boxShadow);
        document.body.style.backgroundImage = `url('${data?.checkAccountCodeValid?.purchaserAppCss?.backgroundImage}')`;
        document.body.style.backgroundSize =
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundSize; // Ensure the image covers the entire background
        document.body.style.backgroundPosition =
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundPosition;
        document.body.style.backgroundAttachment =
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundAttachment;
        document.body.style.backgroundRepeat =
          data?.checkAccountCodeValid?.purchaserAppCss?.backgroundRepeat; // Prevent the image from repeating
      } else {
        localStorage.clear();

        setColorState("#e4ebf5"); // original color
        setShadow(
          "6px 6px 12px var(--light-bg-dark-shadow), -6px -6px 12px var(--light-bg-light-shadow)"
        );
      }
    };

    if (purchaserApp == null) {
      sendRequest();
      localStorage.clear();
      setColorState("#e4ebf5");
      setShadow(
        "6px 6px 12px var(--light-bg-dark-shadow), -6px -6px 12px var(--light-bg-light-shadow)"
      );
    } else {
      setColorState(customizeColor);
      setShadow(boxShadow);
      const backgroundImageURL = localStorage.getItem("backgroundImage");
      document.body.style.backgroundImage = `url('${backgroundImageURL}')`;
      localStorage.getItem("backgroundImage");
      document.body.style.backgroundSize =
        localStorage.getItem("backgroundSize");
      document.body.style.backgroundPosition =
        localStorage.getItem("backgroundPosition");
      document.body.style.backgroundAttachment = localStorage.getItem(
        "backgroundAttachment"
      );
      document.body.style.backgroundRepeat =
        localStorage.getItem("backgroundRepeat");
    }
  }, [accountCode]);

  return { cardColor, setColorState, shadow };
};

export default CardContext;
