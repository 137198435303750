import { deleteAccessToken, getAccessToken, setAccessToken } from "./AccessToken";
import { setPathCookie } from "./PathCookie"
import { deleteSwiperSalesIdCookie, setSwiperSalesIdCookie } from "./salesIdCookie"


export const LogoutProcess = () => {
    setSwiperSalesIdCookie("");
    setAccessToken("");
    deleteAccessToken();
    deleteSwiperSalesIdCookie();
}

export const clearBrowserData = () => {
    LogoutProcess();
    setPathCookie("");
    deleteAccessToken();
    //clear local storage
    localStorage.clear();
    //clear session storage
    sessionStorage.clear();
    //clear cookies
    document.cookie.split(";").forEach((c) => {
        document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
}
