import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCheckAccountCodeValidLazyQuery } from "../../generated/graphql";
// Define the context type
export type inputFieldColor = {
  inputFieldColor: string;
};

// Create the context
const InputFieldContext = createContext<{
  inputFieldColor: string;
  setColorState: React.Dispatch<React.SetStateAction<inputFieldColor>>;
}>({
  inputFieldColor: "e4ebf5",
  setColorState: () => {},
});

// useEffect to change color
export const inputFieldChangeColor = () => {
  const [inputFieldColor, setColorState] = useState("#e4ebf5");
  const { accountCode = "" } = useParams();
  const customizeColor: any = localStorage.getItem("textColorInLocal");

  // const [getAccountCode, { data: accountCodeData }] =
  //   useCheckAccountCodeValidLazyQuery({
  //     fetchPolicy: "no-cache",
  //   });

  useEffect(() => {
    // const sendRequest = async () => {
    //   const { data } = await getAccountCode({
    //     variables: {
    //       accountCode: accountCode || "",
    //     },
    //   });

    //   if (data?.checkAccountCodeValid?.subscriptionLogo) {
    //     setColorState("#e5e8f7"); // Set the color
    //   }
    // };

    // sendRequest();
    if (customizeColor == null) {
      setColorState("#e5e8f7");
    }
  }, [accountCode]);

  return { inputFieldColor, setColorState };
};

export default InputFieldContext;
