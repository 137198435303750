import { createContext, Dispatch } from "react";
import { RootInitialState, RootStateProps } from "./Reducer";

export type AppContextProps = {
  rootState: RootStateProps;
  rootDispatch: Dispatch<any>;
};
const AppContext = createContext<AppContextProps>({
  rootState: RootInitialState,
  rootDispatch: () => null,
});
export default AppContext;
