import React from "react";
import { Dialog, Button, Card } from "ui-neumorphism";
import { ColumnFlex } from "../../components/LayoutX/Flex/ColumnFlex";
import "../../assets/styles/website/MainLayout.scss";
import { headerChangeColor } from "../../containers/context/Context";
import { cardChangeColor } from "../../containers/context/CardContext";

type SimpleDialogProps = {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: number;
  background?: string;
};

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  visible,
  onClose,
  children,
  width,
  background,
}) => {
  const element = document.getElementsByTagName("body")[0];
  if (visible) {
    element.classList.add("disable-overflow");
  } else {
    element.classList.remove("disable-overflow");
  }

  return (
    <Dialog visible={visible} onClose={onClose}>
      <Card
        className={"DialogContainer"}
        style={{ width: width, background: background }}
      >
        <ColumnFlex>{children}</ColumnFlex>
      </Card>
    </Dialog>
  );
};

export default SimpleDialog;
