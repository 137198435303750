import NewsSwiper from "../News/NewsSwiper";
import ProjectListing from "../Project/ProjectListing";
import UnitDetails from "./UnitDetails";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import {
  useSalesAccountLazyQuery,
  useSalesAccountQuery,
} from "../../../generated/graphql";
import { useEffect, useState } from "react";

const Dashboard = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [salesAccData, setSalesAccData] = useState(null);

  const {
    data: salesAccDataQuery,
    loading: getSalesAccLoading,
    error: getSalesAccError,
  } = useSalesAccountQuery({
    fetchPolicy: "no-cache",
    // Only use for testing
    // onError: (error) => {
    //   if (
    //     error.message ===
    //     "Cannot read properties of undefined (reading 'IsIntegrate')"
    //   ) {
    //     //request again
    //     handleReloadPage();
    //   }
    // },
    onCompleted: (data) => {
      setSalesAccData(data);
    },
  });

  // const [getSalesAccount] = useSalesAccountLazyQuery({
  //   onCompleted: (data) => {
  //     setSalesAccData(data);
  //   },
  // });

  // const handleReloadPage = () => {
  //   const timer = setTimeout(() => {
  //     getSalesAccount();
  //   }, 300);

  //   return () => clearTimeout(timer);
  // };

  if (
    salesAccData?.getSalesAccount.length > 0 &&
    (!projectId || projectId === "undefined" || projectId === undefined)
  ) {
    return (
      <Navigate
        to={`?projectId=${salesAccData?.getSalesAccount[0]?.ProjectID}`}
      />
    );
  }

  return (
    <ColumnFlex fullWidth gap="40px">
      <UnitDetails salesAccData={salesAccData} />
      {salesAccData?.getSalesAccount.length > 0 && <NewsSwiper />}
      <ProjectListing salesAccData={salesAccData} />
    </ColumnFlex>
  );
};

export default Dashboard;
