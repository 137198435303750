import { useNavigate, useSearchParams } from "react-router-dom";
import { GENERAL_PATH } from "../GeneralPages/GeneralRoutes";
import { useEffect, useState } from "react";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useGetNewsListingLazyQuery } from "../../../generated/graphql";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { StackX, StackItem } from "../../../components/LayoutX/StackLayout";
import ContainerX from "../../../components/general/ContainerX";
import newsImage01 from "../../../assets/images/background/weatherbg.jpg";
import TextX from "../../../components/general/TextX";
import CardNeu from "../../../components/Card/Card";
import { DASHBOARDS_PATH } from "../Dashboards/DashboardRoutes";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import { headerChangeColor } from "../../context/Context";
import { inputFieldChangeColor } from "../../context/InputFieldContext";

import { useContext } from "react";
const NewsListing = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [getNewsList, { data: newsListData }] = useGetNewsListingLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setTotalPages(data.getNewsAnnouncement?.total);
    },
  });

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 30;
  const [totalPages, setTotalPages] = useState(0);
  const { headerColor } = headerChangeColor();
  const { inputFieldColor } = inputFieldChangeColor();

  useEffect(() => {
    if (!projectId) {
      navigate(GENERAL_PATH.NOT_FOUND_404);
    } else {
      getNewsList({
        variables: { projectId: projectId, page: 1, limit: 30, search: null },
      });
    }
  }, []);

  let timer: NodeJS.Timeout;
  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearchValue(e.target.value);
    clearTimeout(timer);
  };

  useEffect(() => {
    timer = setTimeout(() => {
      getNewsList({
        variables: {
          projectId: projectId,
          page: 1,
          limit: limit,
          search: searchValue,
        },
      });
      setCurrentPage(1);
    }, 500);
  }, [searchValue]);

  useEffect(() => {
    getNewsList({
      variables: {
        projectId: projectId,
        page: currentPage,
        limit: limit,
        search: searchValue,
      },
    });
  }, [currentPage]);

  const handleJumpToNewsDetails = (newsId) => {
    navigate(
      `${DASHBOARDS_PATH.NEWS_DETAILS.replace(
        ":accountCode",
        getPathCookie()
      )}?newsId=${newsId}`
    );
  };

  return (
    <ColumnFlex fullWidth gap="24px">
      <GeneralHeader title="News Listing" style={{ background: headerColor }} />

      <StackX>
        <StackItem top="12px" left="12px">
          <SearchRoundedIcon className="color-text-2" />
        </StackItem>
        <div className="neu-text-field-style">
          <input
            placeholder="Search here"
            type="text"
            onChange={handleSearch}
            className="fs-14 fw-500 color-text"
            style={{
              width: "100%",
              height: "45px",
              paddingLeft: "40px",
            }}
          />
        </div>
      </StackX>

      <ColumnFlex gap="20px">
        {newsListData?.getNewsAnnouncement.newsData.map((news, index) => (
          <div
            key={news.id}
            className="click-able"
            onClick={() => handleJumpToNewsDetails(news.id)}
          >
            <CardNeu rounded height="150px" style={{ overflow: "hidden" }}>
              <img
                src={news.attachment[0].resourceUrl || newsImage01}
                alt="newsImage"
                className="swiper-img"
              />
              <div style={{ transform: "translateY(-100%)" }}>
                <ContainerX
                  backgroundColor="rgba(0,0,0,.7803921568627451)"
                  color="#fff"
                  padding={{
                    only: {
                      left: "20px",
                      bottom: "15px",
                      top: "10px",
                      right: "20px",
                    },
                  }}
                >
                  <ColumnFlex
                    crossAxisAlignment="flex-start"
                    mainAxisAlignment="center"
                    gap="2px"
                  >
                    <span className="fs-12 fw-600">{news.title}</span>
                    <TextX
                      className="fw-500 fs-10 color-text-2"
                      dataType="Date"
                      data={news.startDate}
                    />
                  </ColumnFlex>
                </ContainerX>
              </div>
            </CardNeu>
          </div>
        ))}
      </ColumnFlex>

      {totalPages > limit && (
        <ContainerX className="margin-horizontal-center">
          <CustomPagination
            totalPages={totalPages}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </ContainerX>
      )}
    </ColumnFlex>
  );
};

export default NewsListing;
