import { CSSProperties } from "react";

type ContainerXProps = {
  className?: string;
  alignment?:
    | "bottomCenter"
    | "bottomLeft"
    | "bottomRight"
    | "center"
    | "leftCenter"
    | "rightCenter"
    | "topCenter"
    | "topLeft"
    | "topRight";
  children?: React.ReactNode;
  color?: CSSProperties["color"];
  margin?: {
    all?: string;
    symmetric?: {
      vertical?: string;
      horizontal?: string;
    };
    only?: {
      top?: string;
      right?: string;
      bottom?: string;
      left?: string;
    };
  };
  padding?: {
    all?: string;
    symmetric?: {
      vertical?: string;
      horizontal?: string;
    };
    only?: {
      top?: string;
      right?: string;
      bottom?: string;
      left?: string;
    };
  };
  backgroundColor?: CSSProperties["backgroundColor"];
  height?: CSSProperties["height"];
  overflow?: CSSProperties["overflow"];
  width?: CSSProperties["width"];
  decoration?: {
    border?: CSSProperties["border"];
    borderColor?: CSSProperties["borderColor"];
    borderRadius?: CSSProperties["borderRadius"];
    boxShadow?: CSSProperties["boxShadow"];
  };
};

const ContainerX = ({
  className,
  alignment = "topLeft",
  backgroundColor,
  children,
  color,
  decoration,
  margin,
  overflow,
  padding,
  width,
  height,
}: ContainerXProps) => {
  const layout = {
    bottomCenter: { justifyContent: "center", alignItems: "flex-end" },
    bottomLeft: { justifyContent: "flex-start", alignItems: "flex-end" },
    bottomRight: { justifyContent: "flex-end", alignItems: "flex-end" },
    center: { justifyContent: "center", alignItems: "center" },
    leftCenter: { justifyContent: "flex-start", alignItems: "center" },
    rightCenter: { justifyContent: "flex-end", alignItems: "center" },
    topCenter: { justifyContent: "center", alignItems: "flex-start" },
    topLeft: { justifyContent: "flex-start", alignItems: "flex-start" },
    topRight: { justifyContent: "flex-end", alignItems: "flex-start" },
  };

  let marginConfig;
  if (margin) {
    if (margin.all) {
      marginConfig = margin.all;
    }
    if (margin.symmetric) {
      marginConfig = `${margin.symmetric?.vertical ?? "0px"} ${
        margin.symmetric?.horizontal ?? "0px"
      }`;
    }
    if (margin?.only) {
      marginConfig = `${margin.only.top ?? "0px"} ${
        margin.only.right ?? "0px"
      } ${margin.only.bottom ?? "0px"} ${padding?.only.left ?? "0px"}`;
    }
  }

  let paddingConfig;
  if (padding) {
    if (padding.all) {
      paddingConfig = padding.all;
    }
    if (padding.symmetric) {
      paddingConfig = `${padding.symmetric?.vertical ?? "0px"} ${
        padding.symmetric?.horizontal ?? "0px"
      }`;
    }
    if (padding.only) {
      paddingConfig = `${padding.only.top ?? "0px"} ${
        padding.only.right ?? "0px"
      } ${padding.only.bottom ?? "0px"} ${padding.only.left ?? "0px"}`;
    }
  }

  return (
    <div
      className={className}
      style={{
        alignItems: layout[alignment].alignItems,
        backgroundColor: backgroundColor,
        border: decoration?.border,
        borderColor: decoration?.borderColor,
        borderRadius: decoration?.borderRadius,
        boxShadow: decoration?.boxShadow,
        color: color,
        height: height,
        justifyContent: layout[alignment].justifyContent,
        margin: marginConfig,
        overflow: overflow,
        padding: paddingConfig,
        width: width,
      }}
    >
      {children}
    </div>
  );
};
export default ContainerX;
