import React from "react";
import { ColumnFlex } from "../../components/LayoutX/Flex/ColumnFlex";
import { Outlet } from "react-router";
import "../../assets/styles/website/MainLayout.scss";
import ServiceWorkerWrapper from "../../components/ServiceWorker/ServiceWorker";

const MainLayout = () => {
  return (
    <>
      <div className="MainContainer">
        <ColumnFlex
          key={"app"}
          mainAxisAlignment="flex-start"
          crossAxisAlignment="center"
          fullWidth
        >
          <Outlet />
        </ColumnFlex>
      </div>
      <ServiceWorkerWrapper />
    </>
  );
};

export default MainLayout;
