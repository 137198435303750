import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCheckAccountCodeValidLazyQuery } from "../../generated/graphql";
// Define the context type
export type textColor = {
  textColor: string;
};

// Create the context
const TextContext = createContext<{
  textColor: string;
  setColorState: React.Dispatch<React.SetStateAction<textColor>>;
}>({
  textColor: "e4ebf5",
  setColorState: () => {},
});

// useEffect to change color
export const textChangeColor = () => {
  const [textColor, setColorState] = useState("#3d4c76");
  const { accountCode = "" } = useParams();
  const customizeColor: any = localStorage.getItem("buttonColorInLocal");

  // const [getAccountCode, { data: accountCodeData }] =
  //   useCheckAccountCodeValidLazyQuery({
  //     fetchPolicy: "no-cache",
  //   });

  useEffect(() => {
    // const sendRequest = async () => {
    //   const { data } = await getAccountCode({
    //     variables: {
    //       accountCode: accountCode || "",
    //     },
    //   });

    //   if (data?.checkAccountCodeValid?.subscriptionLogo) {
    //     setColorState("#3d4c76"); // Set the color
    //   }
    // };

    // sendRequest();
    if (customizeColor == null) {
      setColorState("#3d4c76");
    }
  }, [accountCode]);

  return { textColor, setColorState };
};

export default TextContext;
