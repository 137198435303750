export function abbreviateNumber(num: number): string {
    const SI_POSTFIXES: string[] = ["", "k", "M", "G", "T", "P", "E"];

    const tier: number = Math.floor(Math.log10(Math.abs(num)) / 3);

    if (tier === 0) return num.toString();

    const postfix: string = SI_POSTFIXES[tier];
    const scale: number = Math.pow(10, tier * 3);

    const scaled: number = num / scale;

    let formatted: string = scaled.toFixed(1);

    if (formatted.endsWith('.0'))
        formatted = formatted.substr(0, formatted.length - 2);

    if(postfix) {   
        return formatted + ' ' + postfix;
    } 
    else if (num < 1000) {
        // if the number is less than 1000, we don't need to abbreviate it
        return num.toString();
    } 
    else {
        // if the number is greater than "E" format, we need to abbreviate it to such as "e+22" format
        const formattedNumber: string = num.toExponential(2);
        return formattedNumber;
    }

    return num.toString(); 
}