import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import {
  ProjectDetailQuery,
  PublicProjectDetailQuery,
} from "../../../generated/graphql";
import { Positioned } from "../../../components/LayoutX/Stack";
import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
  PlayArrow,
} from "@mui/icons-material";
import Slider from "react-slick";
import { StackItem, StackX } from "../../../components/LayoutX/StackLayout";
import SimpleDialog from "../../../components/Dialog/SimpleDialog";
import { useEffect, useState } from "react";
import { set } from "date-fns";
import ZoomedImageDialog from "../../../components/Dialog/ZoomedImageDialog";

type FloorPlanSwiperProps = {
  projectDetailsData?: ProjectDetailQuery;
  publicProjectDetailsData?: PublicProjectDetailQuery;
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={" swiper-custom-btn box-shadow-dark"}
      style={{ right: "0px" }}
      onClick={onClick}
    >
      <NavigateNextRounded fontSize="large" className="color-text" />
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={"swiper-custom-btn box-shadow-dark"}
      style={{ left: "0px" }}
      onClick={onClick}
    >
      <NavigateBeforeRounded fontSize="large" className="color-text" />
    </div>
  );
};

const FloorPlanSwiper: React.FC<FloorPlanSwiperProps> = ({
  projectDetailsData,
  publicProjectDetailsData,
}) => {
  if (!projectDetailsData && !publicProjectDetailsData) return null;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const { projectDetailsUnit, floorGalleryImage } =
    projectDetailsData?.getProjectById ||
    publicProjectDetailsData?.getPublicProjectById;
  const totalSlides = floorGalleryImage?.length || 0;
  const [isZoomedImage, setIsZoomedImage] = useState(-1);
  const [mouseDownTime, setMouseDownTime] = useState(null);

  const handleMouseDown = () => {
    setMouseDownTime(new Date().getTime());
  };

  const handleZoomImage = (index) => {
    const mouseUpTime = new Date().getTime();
    const timeDifference = mouseUpTime - mouseDownTime;
    if (timeDifference < 90) {
      setIsZoomedImage(index);
    }
    setMouseDownTime(null);
  };

  const getTotalBuiltUp = (block) => {
    const totalBuiltUp = projectDetailsUnit.reduce(
      (accumulator, currentValue) => {
        if (currentValue.block === block) {
          return accumulator + currentValue.builtUp;
        }
        return accumulator;
      },
      0
    );

    return Number.isInteger(totalBuiltUp)
      ? totalBuiltUp
      : totalBuiltUp.toFixed(2);
  };

  const settings = {
    dots: false,
    infinite: totalSlides > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  if (!projectDetailsUnit || !floorGalleryImage || floorGalleryImage.length < 1)
    return null;

  return (
    <>
      <ContainerX>
        <span className="fw-700 fs-12 color-text">Floor Plan</span>
        <Slider {...settings}>
          {floorGalleryImage &&
            projectDetailsUnit &&
            floorGalleryImage.map((floorPlan, index) => (
              <ContainerX
                key={`${floorPlan.block}_${index}`}
                className="floor-plan swiper-slide"
                alignment="center"
              >
                <CardNeu
                  rounded
                  className="floor-plan-swiper"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src={floorPlan.url}
                    alt=""
                    className="image"
                    onMouseDown={handleMouseDown}
                    onMouseUp={() => handleZoomImage(index)}
                  />
                </CardNeu>
                <ColumnFlex
                  fullWidth
                  crossAxisAlignment="center"
                  className="p-v-15"
                  gap="2px"
                >
                  <RowFlex gap="5px" className="fw-600 fs-12 color-text">
                    <span>Block {floorPlan.block}</span>
                    <span>|</span>
                    <span>Floor {floorPlan.floor}</span>
                  </RowFlex>
                  <RowFlex className="fs-10 fw-500 color-text-2" gap="4px">
                    <span>
                      {
                        projectDetailsUnit.find(
                          (item) => item.block === floorPlan.block
                        )?.projectDetailsUnitLayout?.desc
                      }
                    </span>
                    <span>
                      {"x"}
                      {
                        projectDetailsUnit.filter(
                          (item) => item.block === floorPlan.block
                        )?.length
                      }
                      {" Units"}
                    </span>
                  </RowFlex>
                  <span className="fw-600 fs-12 color-text">
                    {"Total "}
                    {/* {projectDetailsUnit.reduce((accumulator, currentValue) => {
                      if (currentValue.block === floorPlan.block) {
                        return accumulator + currentValue.builtUp;
                      }
                      return accumulator;
                    }, 0)} */}
                    {getTotalBuiltUp(floorPlan.block)}
                    {" Sq.ft."}
                  </span>
                </ColumnFlex>
              </ContainerX>
            ))}
        </Slider>
      </ContainerX>
      <ZoomedImageDialog
        visible={isZoomedImage !== -1}
        onClose={() => setIsZoomedImage(-1)}
        src={floorGalleryImage[isZoomedImage]?.url}
      />
    </>
  );
};

export default FloorPlanSwiper;
