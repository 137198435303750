import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import {
  ProjectDetailQuery,
  PublicProjectDetailQuery,
} from "../../../generated/graphql";
import { Positioned, Stack } from "../../../components/LayoutX/Stack";
import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
  PlayArrow,
} from "@mui/icons-material";
import Slider from "react-slick";
import { StackItem, StackX } from "../../../components/LayoutX/StackLayout";
import SimpleDialog from "../../../components/Dialog/SimpleDialog";
import { useEffect, useState } from "react";
import { set } from "date-fns";
import ZoomedImageDialog from "../../../components/Dialog/ZoomedImageDialog";

type UnitLayoutSwiperProps = {
  projectDetailsData?: ProjectDetailQuery;
  publicProjectDetailsData?: PublicProjectDetailQuery;
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={" swiper-custom-btn box-shadow-dark"}
      style={{ right: "-18px" }}
      onClick={onClick}
    >
      <NavigateNextRounded fontSize="large" className="color-text" />
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={"swiper-custom-btn box-shadow-dark"}
      style={{ left: "-18px" }}
      onClick={onClick}
    >
      <NavigateBeforeRounded fontSize="large" className="color-text" />
    </div>
  );
};

const UnitLayoutSwiper: React.FC<UnitLayoutSwiperProps> = ({
  projectDetailsData,
  publicProjectDetailsData,
}) => {
  const { unitLayoutGalleryImage } =
    projectDetailsData?.getProjectById ||
    publicProjectDetailsData?.getPublicProjectById;
  const totalSlides = unitLayoutGalleryImage?.length || 0;
  const [isZoomedImage, setIsZoomedImage] = useState(-1);
  const [mouseDownTime, setMouseDownTime] = useState(null);

  const handleMouseDown = () => {
    setMouseDownTime(new Date().getTime());
  };

  const handleZoomImage = (index) => {
    const mouseUpTime = new Date().getTime();
    const timeDifference = mouseUpTime - mouseDownTime;
    if (timeDifference < 90) {
      setIsZoomedImage(index);
    }
    setMouseDownTime(null);
  };

  const settings = {
    dots: false,
    infinite: totalSlides > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: totalSlides > 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  if (!unitLayoutGalleryImage || unitLayoutGalleryImage.length < 1) return null;

  return (
    <>
      <ContainerX>
        <span className="fw-700 fs-12 color-text">Unit Layout</span>
        <Slider {...settings}>
          {unitLayoutGalleryImage.map((item, index) => (
            <ContainerX
              key={item.url}
              decoration={{ borderRadius: "10px" }}
              overflow="hidden"
              padding={{ symmetric: { horizontal: "20px", vertical: "20px" } }}
            >
              <CardNeu style={{ overflow: "hidden" }} rounded>
                <ContainerX className="swiper-slide gallery-sales-swiper">
                  <img
                    src={item.url}
                    alt="galleryImage"
                    className="image"
                    onMouseDown={handleMouseDown}
                    onMouseUp={() => handleZoomImage(index)}
                  />
                  {item.unitLayout && item.noOfBathroom && item.noOfBedroom && (
                    <ContainerX
                      backgroundColor="rgba(0,0,0,.7803921568627451)"
                      height={"40px"}
                      alignment="center"
                      width={"100%"}
                      className="color-white fw-700 fs-12"
                    >
                      <RowFlex
                        gap="4px"
                        mainAxisAlignment="center"
                        crossAxisAlignment="center"
                        className="fullheight-strict"
                      >
                        <span>{item.unitLayout} |</span>
                        <span>
                          {item.noOfBedroom} Bedroom
                          {item.noOfBedroom > 1 ? "s" : ""} |
                        </span>
                        <span>
                          {item.noOfBathroom} Bathroom
                          {item.noOfBathroom > 1 ? "s" : ""}
                        </span>
                      </RowFlex>
                    </ContainerX>
                  )}
                </ContainerX>
              </CardNeu>
            </ContainerX>
          ))}
        </Slider>
      </ContainerX>
      <ZoomedImageDialog
        visible={isZoomedImage !== -1}
        onClose={() => setIsZoomedImage(-1)}
        src={unitLayoutGalleryImage[isZoomedImage]?.url || ""}
      />
    </>
  );
};

export default UnitLayoutSwiper;
