import ContainerX from "../../../../components/general/ContainerX";
import CardNeu from "../../../../components/Card/Card";
import GeneralHeader from "../../../../components/Header/GeneralHeader";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import {
  useCreatePrePaymentRecordMutation,
  useGetSoaSummaryLazyQuery,
} from "../../../../generated/graphql";
import { EventBusyRounded } from "@mui/icons-material";
import TextX from "../../../../components/general/TextX";
import { Wrap } from "../../../../components/LayoutX/Wrap";
import { Button } from "ui-neumorphism";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import NumberFormatTextField from "../../../../components/TextField/NumberFormat";
import { getPathCookie } from "../../../../containers/helpers/PathCookie";
import { PAYMENT_PATH } from "../../../../containers/modules/Payments/PaymentRoutes";
import EmptyRecord from "../../../../components/general/EmptyRecord";
import {
  isSmallMobileWidth,
  useMediaQuery,
} from "../../../../useHook/useMediaQuery";
import { headerChangeColor } from "../../../context/Context";
import { useContext } from "react";

export enum PaymentGatewayType {
  IPAY88 = "IPAY88",
  REVENUE_MONSTER = "REVENUE_MONSTER",
}

export enum TrxType {
  BILL = "Bill",
  RECEIPT = "Receipt",
  DN = "DN",
  CN = "CN",
}

const AccountState = () => {
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(isSmallMobileWidth);
  const [searchParams] = useSearchParams();
  const unitId = searchParams.get("unitId");
  const saleId = searchParams.get("saleId");
  const projectId = searchParams.get("projectId");
  const isIntegrate = searchParams.get("isIntegrate");
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [TotalOutStanding, setTotalOutStanding] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [isOpenMakePaymentDialog, setIsOpenMakePaymentDialog] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentAmountHelperText, setPaymentAmountHelperText] = useState(null);
  const { headerColor } = headerChangeColor();

  const [
    getSOASummary,
    {
      data: soaSummaryData,
      loading: soaSummaryLoading,
      error: soaSummaryError,
    },
  ] = useGetSoaSummaryLazyQuery({
    onCompleted: (data) => {
      const total = data.getSOASummary?.ListOfTrx.reduce(
        (acc, curr) => acc + curr?.DocumentAmt,
        0
      );
      if (
        !data.getSOASummary?.TotalOutStanding &&
        data.getSOASummary?.ListOfTrx?.length > 0
      ) {
        setTotalOutStanding(total);
      }

      const dueTrxAmt = data.getSOASummary?.ListOfTrx?.filter(
        (x) =>
          x.TrxType !== TrxType.CN &&
          x.TrxType !== TrxType.RECEIPT &&
          x.DueDate !== null
      )?.map((x) => x.DocumentAmt);
      setDueAmount(dueTrxAmt?.reduce((acc, curr) => acc + curr, 0) || 0);
    },
  });

  const [createPrePaymentRecord] = useCreatePrePaymentRecordMutation({
    onCompleted: (data) => {
      let paymentGateWayId: string = "";
      let paymentGateWayURL: string = "";

      paymentGateWayId = data.createPurchaserPaymentRecord;
      if (
        soaSummaryData?.getSOASummary?.GateWayType ===
        PaymentGatewayType.REVENUE_MONSTER
      ) {
        paymentGateWayURL = PAYMENT_PATH.PAYMENT_CONFIRM_REVENUE_MONSTER;
      }

      if (
        soaSummaryData?.getSOASummary?.GateWayType === PaymentGatewayType.IPAY88
      ) {
        paymentGateWayURL = PAYMENT_PATH.PAYMENT_CONFIRM_IPAY88;
      }

      if (!paymentGateWayURL || paymentGateWayURL === "") {
        openSnackbar("Invalid Payment Gateway");
        return;
      }

      navigate(
        `${paymentGateWayURL.replace(
          ":accountCode",
          getPathCookie()
        )}?projectId=${projectId}&paymentId=${paymentGateWayId}`
      );
    },
  });

  useEffect(() => {
    if (!unitId || !saleId || !projectId) {
      openSnackbar("AccountId or SaleId is required");
    } else {
      getSOASummary({
        variables: {
          unitId: unitId,
          saleId: saleId,
          projectId: projectId,
          isIntegrate: isIntegrate === "true",
        },
      });
    }
  }, [unitId, saleId, projectId, isIntegrate]);

  const handleCloseMakePaymentDialog = () => {
    setIsOpenMakePaymentDialog(false);
    setPaymentAmount(0);
  };

  const handleOpenMakePaymentDialog = () => {
    if (TotalOutStanding <= 0 || !TotalOutStanding) {
      openSnackbar("No outstanding balance");
      return;
    }

    if (!soaSummaryData?.getSOASummary?.GateWayId) {
      openSnackbar(
        "Please refer to application developer to setup payment gateway to the project."
      );
      return;
    }

    setIsOpenMakePaymentDialog(true);
  };

  const handleMakePayment = () => {
    if (paymentAmount === null || paymentAmount < 1) {
      openSnackbar("Please fill up required field");
      return;
    }

    createPrePaymentRecord({
      variables: {
        amount: Number(paymentAmount),
        unitId: soaSummaryData?.getSOASummary?.UnitId,
        ifcaSaleId: Number(saleId),
        paymentGateWayId: soaSummaryData?.getSOASummary?.GateWayId,
      },
    });

    setIsOpenMakePaymentDialog(false);
  };

  return (
    <>
      <ColumnFlex gap="40px" fullWidth>
        <GeneralHeader title="Accounts" style={{ background: headerColor }} />

        <ContainerX width={"100%"}>
          <ColumnFlex gap="20px">
            {(!soaSummaryData?.getSOASummary?.ListOfTrx?.length ||
              soaSummaryData?.getSOASummary?.ListOfTrx?.length <= 0) && (
              <EmptyRecord />
            )}
            {soaSummaryData?.getSOASummary?.ListOfTrx?.length > 0 && (
              <CardNeu>
                <ContainerX
                  className="divider-bottom-border"
                  padding={{
                    symmetric: { vertical: "14px", horizontal: "16px" },
                  }}
                >
                  <ColumnFlex>
                    <RowFlex mainAxisAlignment="space-between">
                      <span className="fs-16 fw-700 text-with-shadow color-text">
                        {soaSummaryData?.getSOASummary?.UnitNo}
                      </span>

                      <RowFlex gap="6px">
                        <span className="fs-12 fw-700 text-with-shadow color-text">
                          Total Outstanding :
                        </span>
                        <span className="fs-12 fw-700 text-with-shadow color-text">
                          RM
                        </span>{" "}
                        {TotalOutStanding ? (
                          <span className="fs-12 fw-700 text-with-shadow color-text">
                            {TotalOutStanding >= 0 && (
                              <TextX
                                data={TotalOutStanding}
                                dataType="Number"
                              />
                            )}
                            {TotalOutStanding < 0 && (
                              <span className="fs-12 fw-700 text-with-shadow color-text">
                                (
                                <TextX
                                  data={TotalOutStanding}
                                  dataType="Number"
                                />
                                )
                              </span>
                            )}
                          </span>
                        ) : (
                          <span className="fs-12 fw-700 text-with-shadow color-text">
                            <TextX data={0} dataType="Number" />
                          </span>
                        )}
                      </RowFlex>
                    </RowFlex>
                  </ColumnFlex>
                </ContainerX>
                <div
                  style={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}
                >
                  <ContainerX
                    padding={{
                      symmetric: { vertical: "14px", horizontal: "16px" },
                    }}
                  >
                    <ColumnFlex gap="28px">
                      {soaSummaryData?.getSOASummary?.ListOfTrx?.map(
                        (trx, index) => (
                          <ColumnFlex
                            key={trx?.DocumentNo}
                            // className={`${
                            //   index !==
                            //     soaSummaryData?.getSOASummary?.ListOfTrx.length - 1 &&
                            //   "divider-bottom-border"
                            // }`}
                          >
                            <Wrap
                              className="fw-800 fs-10 color-text"
                              // mainAxisAlignment="space-between"
                              crossAxisAlignment="center"
                            >
                              {/* <RowFlex crossAxisAlignment="center" gap="40px"> */}
                              <ContainerX width={100}>
                                <TextX
                                  data={trx?.DocumentDate}
                                  dataType="Date"
                                />
                              </ContainerX>
                              <span
                                style={{
                                  // paddingRight: "10px",
                                  flex: 1,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {trx?.DocumentNo}
                              </span>
                              {/* </RowFlex> */}
                              <ContainerX padding={{ only: { right: "10px" } }}>
                                {/* <RowFlex crossAxisAlignment="center" gap="10px"> */}
                                {trx?.DueDate && (
                                  <RowFlex
                                    crossAxisAlignment="center"
                                    className="color-red"
                                    gap="2px"
                                  >
                                    <EventBusyRounded
                                      sx={{ fontSize: "12px" }}
                                    />
                                    <TextX
                                      data={trx?.DueDate}
                                      dataType="Date"
                                    />
                                  </RowFlex>
                                )}
                              </ContainerX>
                              {trx?.DocumentAmt > 0 && (
                                <TextX
                                  data={trx?.DocumentAmt}
                                  dataType="Number"
                                />
                              )}
                              {trx?.DocumentAmt < 0 && (
                                <span>
                                  (
                                  <TextX
                                    data={Math.abs(trx?.DocumentAmt)}
                                    dataType="Number"
                                  />
                                  )
                                </span>
                              )}
                              {/* </RowFlex> */}
                            </Wrap>

                            <TextX
                              data={trx?.DocumentDesc}
                              dataType="Text"
                              className="fs-10 fw-500 color-text-1"
                            />
                          </ColumnFlex>
                        )
                      )}
                    </ColumnFlex>
                  </ContainerX>
                </div>
              </CardNeu>
            )}
          </ColumnFlex>
        </ContainerX>

        <div
          style={{
            position: "absolute",
            left: "0",
            bottom: "14px",
            width: "100%",
          }}
        >
          <ContainerX
            margin={{ symmetric: { horizontal: "auto" } }}
            padding={{ symmetric: { horizontal: "16px" } }}
            className={`${!isSmallMobile ? "fullwidth-container" : ""}`}
          >
            <ColumnFlex gap="12px">
              {/* <RowFlex
                mainAxisAlignment="space-between"
                className="color-text-light-blue fw-800 fs-12"
                fullWidth
              >
                <span>Total Outstanding</span>
                {TotalOutStanding >= 0 && (
                  <TextX data={TotalOutStanding} dataType="Number" />
                )}

                {TotalOutStanding < 0 && (
                  <span>
                    (
                    <TextX data={TotalOutStanding} dataType="Number" />)
                  </span>
                )}
              </RowFlex> */}
              {soaSummaryData?.getSOASummary?.GateWayId !== "" && (
                <Button
                  rounded
                  className={"disable-full-uppercase color-text-light-blue"}
                  onClick={handleOpenMakePaymentDialog}
                >
                  Make Payment
                </Button>
              )}
            </ColumnFlex>
          </ContainerX>
        </div>
      </ColumnFlex>
      <SimpleDialog
        visible={isOpenMakePaymentDialog}
        onClose={handleCloseMakePaymentDialog}
      >
        <span className="fs-16 fw-700 color-text text-with-shadow p-b-5">
          Make Payment
        </span>
        <ColumnFlex gap="16px">
          <RowFlex
            className="fs-10 fw-500"
            crossAxisAlignment="center"
            gap="4px"
          >
            <RowFlex gap="2px" crossAxisAlignment="center">
              <span>O/S:</span>
              <TextX
                data={TotalOutStanding}
                dataType="Number"
                className="color-text-light-blue"
              />
            </RowFlex>
            <span>|</span>
            <RowFlex gap="2px" crossAxisAlignment="center">
              <span>Due:</span>
              <TextX data={dueAmount} dataType="Number" className="color-red" />
            </RowFlex>
          </RowFlex>
          <ColumnFlex className="neu-text-field-style" gap="2px">
            <span className="fs-12 fw-500">Amount (RM) *</span>
            <NumberFormatTextField
              value={paymentAmount}
              onChange={(value) => {
                if (value === "") {
                  setPaymentAmountHelperText("Amount is required");
                } else if (Number(value) < 1) {
                  setPaymentAmountHelperText("Amount must be greater than 1");
                } else {
                  setPaymentAmountHelperText(null);
                }
                setPaymentAmount(Number(value));
              }}
            />
            {paymentAmountHelperText && (
              <span className="color-red fs-10 fw-400">
                {paymentAmountHelperText}
              </span>
            )}
          </ColumnFlex>
          <RowFlex
            mainAxisAlignment="flex-end"
            gap="12px"
            className="disable-full-uppercase"
          >
            <Button onClick={handleCloseMakePaymentDialog}>Cancel</Button>
            <Button
              className={"color-text-light-blue"}
              onClick={handleMakePayment}
            >
              Confirm
            </Button>
          </RowFlex>
        </ColumnFlex>
      </SimpleDialog>
    </>
  );
};

export default AccountState;
