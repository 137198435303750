import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useGetPaymentDataByIdLazyQuery } from "../../../generated/graphql";
import { CancelRounded, CheckCircleRounded } from "@mui/icons-material";
import { headerChangeColor } from "../../../containers/context/Context";
import { useContext } from "react";

const PaymentResult = () => {
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get("paymentId");
  const [openSnackbar] = useSnackbar();
  const [getPurchaserPaymentReult, { data: paymentResult }] =
    useGetPaymentDataByIdLazyQuery();
  const { headerColor } = headerChangeColor();

  useEffect(() => {
    if (!paymentId) {
      openSnackbar("Invalid Params");
      return;
    }

    getPurchaserPaymentReult({ variables: { paymentId } });
  }, []);

  return (
    <ColumnFlex fullWidth>
      <GeneralHeader
        title="Online Payment"
        style={{ background: headerColor }}
      />
      <CardNeu>
        <ColumnFlex>
          <ContainerX
            padding={{ symmetric: { vertical: "10px", horizontal: "12px" } }}
            className="divider-bottom-border"
          >
            <RowFlex crossAxisAlignment="center" gap="4px" className="fs-14">
              {paymentResult?.getPaymentDataById?.status === "SUCCESS" ? (
                <CheckCircleRounded fontSize="inherit" color="success" />
              ) : (
                <CancelRounded color="error" fontSize="inherit" />
              )}
              <span className="fw-700 fs-12 color-text">
                {paymentResult?.getPaymentDataById?.status === "SUCCESS"
                  ? "Payment Successful"
                  : "Payment Failed"}
              </span>
            </RowFlex>
          </ContainerX>
          <ContainerX
            padding={{ symmetric: { vertical: "12px", horizontal: "12px" } }}
          >
            <ColumnFlex gap="16px">
              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">
                  Developer Name
                </span>
                <span className="color-text fw-600 fs-12">
                  {paymentResult?.getPaymentDataById?.devName}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">Unit No</span>
                <span className="color-text fw-600 fs-12">
                  {paymentResult?.getPaymentDataById?.unitNo}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">Reference No.</span>
                <span className="color-text fw-600 fs-12">
                  {paymentResult?.getPaymentDataById?.refNo}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">Payment To</span>
                <span className="color-text fw-600 fs-12">
                  {paymentResult?.getPaymentDataById?.companyName}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">
                  Developer Address
                </span>
                <span className="color-text fw-600 fs-12">
                  {paymentResult?.getPaymentDataById?.address?.address}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">
                  Developer Contact
                </span>
                <span className="color-text fw-600 fs-12">
                  {paymentResult?.getPaymentDataById?.officeTel || "-"}
                </span>
              </ColumnFlex>

              <ColumnFlex gap="2px">
                <span className="fs-10 fw-500 color-text-2">
                  Payment Amount
                </span>
                <span className="color-text-light-blue fw-800 fs-12">
                  RM {paymentResult?.getPaymentDataById?.amount}
                </span>
              </ColumnFlex>
            </ColumnFlex>
          </ContainerX>
        </ColumnFlex>
      </CardNeu>
    </ColumnFlex>
  );
};

export default PaymentResult;
