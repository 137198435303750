import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import CardNeu from "../../../../components/Card/Card";
import ContainerX from "../../../../components/general/ContainerX";
import {
  GetUnitDetailQuery,
  useProjectCurrencyQuery,
} from "../../../../generated/graphql";
import { RowGrid } from "../../../../components/LayoutX/Grid/RowGrid";
import TextX from "../../../../components/general/TextX";
import BumiIcon from "../../../../assets/images/icon/letter-b.svg";
import "./BookingUnit.scss";
import { Wrap } from "../../../../components/LayoutX/Wrap";
import { NavigateNextRounded, VisibilityRounded } from "@mui/icons-material";
import { BOOKING_PATH } from "../BookingRoutes";
import { getPathCookie } from "../../../../containers/helpers/PathCookie";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import { cardChangeColor } from "../../../context/CardContext";
import { buttonChangeColor } from "../../../context/ButttonContext";
type BottomSheetProps = {
  unitData: GetUnitDetailQuery;
  setOpenBottomSheet: (val: boolean) => void;
  projectId: string;
  contactId: string;
  isIntegrate: string;
};

const BottomSheet: React.FC<BottomSheetProps> = ({
  unitData,
  setOpenBottomSheet,
  projectId,
  contactId,
  isIntegrate,
}) => {
  const [unit, setUnit] = useState(unitData.GetUnitDetail);
  const isAvailable = unit?.unitStatus === "AVAILABLE";
  const navigate = useNavigate();
  const [open3DTourDialog, setOpen3DTourDialog] = useState(false);
  const { cardColor } = cardChangeColor();
  const { buttonColor } = buttonChangeColor();
  const { data: currencyData } = useProjectCurrencyQuery({
    fetchPolicy: "no-cache",
    variables: {
      projectId: projectId,
    },
  });

  const handleJumpToNewBooking = () => {
    if (!projectId || !unit) {
      return;
    }

    let params = "";
    if (contactId) {
      params = `?unitId=${unit?.id}&projectId=${projectId}&contactId=${contactId}&isIntegrate=${isIntegrate}`;
    } else {
      params = `?unitId=${unit?.id}&projectId=${projectId}`;
    }

    setOpenBottomSheet(false);
    navigate(
      `${BOOKING_PATH.NEW_BOOKING.replace(
        ":accountCode",
        getPathCookie()
      )}${params}`
    );
  };

  if (!unit) return null;

  return (
    <>
      <div
        className="bottomSheet"
        style={{
          maxHeight: "80vh",
          width: "90%",
          maxWidth: "1200px",
          minWidth: "320px",
          position: "fixed",
          bottom: "0px",
          zIndex: 999,
          overflow: "auto",
        }}
      >
        <CardNeu
          className="bottomSheet"
          width={"100%"}
          backgroundColor={cardColor}
          padding={{ all: "12px" }}
          decoration={{ borderRadius: "10px 10px 0px 0px" }}
        >
          <ColumnFlex gap="14px">
            {unit?.floorLayoutUrl && (
              <img src={unit?.floorLayoutUrl} alt="" width="100%" />
            )}
            {unit?.floorLayoutUrl && (
              <span className="fs-12 fw-500 color-text-2">Floor Plan</span>
            )}

            {unit?.unitLayoutUrl && (
              <img src={unit?.unitLayoutUrl} alt="" width="100%" />
            )}
            {unit?.unitLayoutUrl && (
              <span className="fs-12 fw-500 color-text-2">Unit Plan</span>
            )}
            <RowGrid gridTemplateColumns="1fr 1fr">
              <ColumnFlex>
                <span className="fw-700 fs-12 color-text">
                  {unit?.project?.name}
                </span>
                <span className="fs-10 fw-500 color-text-2">
                  {unit?.project?.company?.name}
                </span>
                {unit?.unitStatus === "BOOK" && (
                  <span className="fs-10 fw-500 color-text-2">
                    In Queue: {unit?.waitingListCount || 0}
                  </span>
                )}
              </ColumnFlex>
              <RowFlex
                gap="12px"
                mainAxisAlignment="flex-end"
                height="fit-content"
                crossAxisAlignment="center"
              >
                {unit?.unitViewCount > 0 && (
                  <ContainerX
                    backgroundColor="#fad7a9"
                    padding={{
                      symmetric: { vertical: "2px", horizontal: "10px" },
                    }}
                    decoration={{ borderRadius: "5px" }}
                  >
                    <RowFlex
                      crossAxisAlignment="center"
                      mainAxisAlignment="flex-end"
                      gap="4px"
                      className="fs-14 fw-600 "
                    >
                      <VisibilityRounded
                        fontSize="medium"
                        style={{ color: "#fc9c20" }}
                      />
                      <span style={{ color: "#fc9c20" }}>
                        {unit?.unitViewCount}
                      </span>
                    </RowFlex>
                  </ContainerX>
                )}
                {unit?.unitLayoutVitualLink?.length > 0 && (
                  <div
                    className="click-able"
                    onClick={() => setOpen3DTourDialog(true)}
                  >
                    <RowFlex
                      crossAxisAlignment="center"
                      mainAxisAlignment="flex-end"
                      gap="2px"
                    >
                      <div className="vr-font">VR</div>
                      <span className="fs-14 fw-500 color-black">3D Tour</span>
                    </RowFlex>
                  </div>
                )}
              </RowFlex>
            </RowGrid>

            <RowGrid gridTemplateColumns="1fr 1fr">
              <ColumnFlex className="text-overflow">
                <span className="fs-10 fw-500 color-text-2">Unit</span>
                <RowFlex gap="4px" crossAxisAlignment="center">
                  <span className="fs-12 fw-600">{unit?.unitNo || "-"}</span>
                  {unit?.isBumi && (
                    <img src={BumiIcon} alt="" width={18} height={18} />
                  )}
                </RowFlex>
              </ColumnFlex>
              <ColumnFlex className="text-overflow">
                <span className="fs-10 fw-500 color-text-2">Unit Price</span>
                <RowFlex className="fs-12 fw-600" gap="2px">
                  {currencyData?.getProjectById?.currency && (
                    <span>{currencyData?.getProjectById?.currency}</span>
                  )}
                  <TextX
                    data={unit?.price}
                    dataType="Number"
                    className="fs-12 fw-600"
                  />
                </RowFlex>
              </ColumnFlex>
            </RowGrid>

            <RowGrid gridTemplateColumns="1fr 1fr">
              <ColumnFlex className="text-overflow">
                <span className="fs-10 fw-500 color-text-2">Type</span>
                <TextX
                  data={unit?.unitType?.name || "-"}
                  dataType="Text"
                  className="fs-12 fw-600"
                />
              </ColumnFlex>
              <ColumnFlex className="text-overflow">
                <span className="fs-10 fw-500 color-text-2">Built-up</span>
                <span className="fs-12 fw-600">
                  {unit?.builtUp.toLocaleString("en-US") || "-"}
                </span>
              </ColumnFlex>
            </RowGrid>

            <RowGrid gridTemplateColumns="1fr 1fr">
              <ColumnFlex className="text-overflow">
                <span className="fs-10 fw-500 color-text-2">Direction</span>
                <TextX
                  data={`${unit?.facingDirection?.name || "-"}${
                    unit?.facingView ? ` | ${unit?.facingView}` : ""
                  }`}
                  dataType="Text"
                  className="fs-12 fw-600"
                />
              </ColumnFlex>
              <ColumnFlex className="text-overflow">
                <span className="fs-10 fw-500 color-text-2">Land Area</span>
                {Number(unit?.landArea) <= 0 ? (
                  <span className="fs-12 fw-600">-</span>
                ) : (
                  <TextX
                    data={unit?.landArea}
                    dataType="Number"
                    className="fs-12 fw-600"
                    alt="-"
                  />
                )}
              </ColumnFlex>
            </RowGrid>

            <RowGrid gridTemplateColumns="1fr 1fr">
              {unit?.extraLandArea && (
                <ColumnFlex>
                  <span className="fs-10 fw-500 color-text-2">
                    Extra Land Area
                  </span>
                  <RowFlex className="fs-12 fw-600" gap="2px">
                    <TextX
                      data={unit?.extraLandArea || "-"}
                      dataType="Number"
                    />
                    <span>{unit?.project?.uomDesc || ""}</span>
                  </RowFlex>
                </ColumnFlex>
              )}
              {unit?.getIntegratedProjectCarParkNo && (
                <ColumnFlex>
                  <span className="fs-10 fw-500 color-text-2">Car Park</span>
                  <TextX
                    data={unit?.getIntegratedProjectCarParkNo || "-"}
                    dataType="Text"
                    className="fs-12 fw-600"
                  />
                </ColumnFlex>
              )}
            </RowGrid>

            {isAvailable && (
              <Button
                rounded
                style={{
                  width: "90%",
                  margin: "0 auto",
                  marginTop: "12px",
                  marginBottom: "12px",
                  background: buttonColor,
                }}
                className={"disable-full-uppercase"}
                onClick={handleJumpToNewBooking}
              >
                Book Now
              </Button>
            )}
          </ColumnFlex>
        </CardNeu>
      </div>

      <SimpleDialog
        visible={open3DTourDialog}
        onClose={() => setOpen3DTourDialog(false)}
      >
        <ColumnFlex gap="14px">
          <span className="fw-700 fs-16 color-text text-with-shadow">
            Select 3D Tour
          </span>
          {unit?.unitLayoutVitualLink?.map((item) => (
            <a
              key={item}
              onClick={() => window.open(item)}
              className="click-able"
            >
              <CardNeu>
                <ContainerX
                  padding={{
                    symmetric: { vertical: "10px", horizontal: "14px" },
                  }}
                >
                  <RowFlex
                    crossAxisAlignment="center"
                    mainAxisAlignment="space-between"
                  >
                    <span className="fw-700 fs-12 color-text">{item}</span>

                    <NavigateNextRounded />
                  </RowFlex>
                </ContainerX>
              </CardNeu>
            </a>
          ))}
        </ColumnFlex>
      </SimpleDialog>

      {/* close bottom sheet */}
      <div
        onClick={() => setOpenBottomSheet(false)}
        style={{
          position: "fixed",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: "100%",
          height: "100%",
          top: "0px",
          zIndex: 77,
        }}
      ></div>
    </>
  );
};

export default BottomSheet;
