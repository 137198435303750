type StackProps = {
  children?: React.ReactNode;
  width?: string;
  height?: string;
};

export const StackX = ({ children, height, width }: StackProps) => {
  return (
    <div
      style={{
        position: "relative",
        height,
        width,
      }}
    >
      {children}
    </div>
  );
};

type StackItemProps = {
  children: React.ReactNode;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  width?: string;
  height?: string;
  horizontalCenter?: boolean;
  verticalCenter?: boolean;
};

export const StackItem = ({
  children,
  top,
  left,
  right,
  bottom,
  width,
  height,
  horizontalCenter,
  verticalCenter,
}: StackItemProps) => {
  return (
    <div
      style={{
        position: "absolute",
        top,
        left,
        right,
        bottom,
        width,
        height,
        ...(horizontalCenter &&
          !verticalCenter && { left: "50%", transform: "translateX(-50%)" }),
        ...(verticalCenter &&
          !horizontalCenter && { top: "50%", transform: "translateY(-50%)" }),
        ...(verticalCenter &&
          horizontalCenter && {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }),
      }}
    >
      {children}
    </div>
  );
};
