import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Avatar, colors } from "@mui/material";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import { RowGrid } from "../../../components/LayoutX/Grid/RowGrid";
import CardNeu from "../../../components/Card/Card";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useProfileQuery } from "../../../generated/graphql";
import { Navigate, redirect, useNavigate, useParams } from "react-router";
import { useNavigate as Jump } from "react-router-dom";
import { AUTH_PATH } from "../Authentication/AuthRoutes";
import { Link } from "react-router-dom";
import { OTHERS_PATH } from "./OthersRoutes";
import GeneralHeader from "../../../components/Header/GeneralHeader";
import ContainerX from "../../../components/general/ContainerX";
import TextX from "../../../components/general/TextX";
import { useState } from "react";
import ZoomedImageDialog from "../../../components/Dialog/ZoomedImageDialog";
import { DASHBOARDS_PATH } from "../Dashboards/DashboardRoutes";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import DefaultAvatar from "../../../assets/images/gallery/blank-profile-picture.jpg";
import { headerChangeColor } from "../../context/Context";
import { cardChangeColor } from "../../context/CardContext";
import { buttonChangeColor } from "../../context/ButttonContext";

import { useContext } from "react";

const Profile = () => {
  const {
    data: profileData,
    loading: getProfileLoading,
    error: getProfileError,
  } = useProfileQuery({
    fetchPolicy: "no-cache",
  });
  const navigation = useNavigate();
  const { accountCode } = useParams();
  const [isZoomedProfilePic, setIsZoomedProfilePic] = useState(false);
  const { headerColor } = headerChangeColor();
  const { cardColor } = cardChangeColor();
  const { buttonColor } = buttonChangeColor();

  const handleEditProfile = () => {
    const path = OTHERS_PATH.EDIT_PROFILE.replace(":accountCode", accountCode);
    navigation(path);
  };

  if (getProfileLoading) {
    return <></>;
  }

  if (profileData) {
    return (
      <>
        <div style={{ width: "100%" }}>
          <CardNeu elavation={2} style={{ background: cardColor }}>
            <ContainerX
              padding={{
                symmetric: { vertical: "20px", horizontal: "40px" },
              }}
              alignment="center"
            >
              <ColumnFlex fullWidth crossAxisAlignment="center" gap="30px">
                <GeneralHeader
                  title="Profile"
                  backTo={DASHBOARDS_PATH.DASHBOARD.replace(
                    ":accountCode",
                    getPathCookie()
                  )}
                  style={{ background: headerColor }}
                />
                <ColumnFlex crossAxisAlignment="center" gap="8px">
                  <div
                    className="click-able"
                    onClick={() => setIsZoomedProfilePic(true)}
                  >
                    <Avatar
                      src={profileData?.profile?.ProfilePic || DefaultAvatar}
                      sx={{ height: "120px", width: "120px" }}
                    />
                  </div>
                  <span className="fw-700 fs-16 text-with-shadow">
                    {profileData?.profile?.Name}
                  </span>
                  <RowFlex className="fs-12 fw-600 p-b-10" gap="5px">
                    <RowFlex gap="3px">
                      <PhoneAndroidIcon fontSize="inherit" />
                      <span>{profileData?.profile?.PhoneNo}</span>
                    </RowFlex>
                    <span>|</span>
                    <RowFlex gap="3px">
                      <EmailIcon fontSize="inherit" />
                      <span>{profileData?.profile?.Email}</span>
                    </RowFlex>
                  </RowFlex>
                  <Button
                    className={
                      "disable-full-uppercase font-family-strict color-text-light-blue fw-500-strict"
                    }
                    onClick={handleEditProfile}
                    bgColor={buttonColor}
                  >
                    Edit Profile
                  </Button>
                </ColumnFlex>
                <div style={{ width: "100%" }}>
                  <CardNeu style={{ padding: "12px", background: cardColor }}>
                    <ColumnFlex gap="20px">
                      <RowGrid
                        fullWidth
                        gridTemplateColumns="1fr 1fr"
                        className="color-text"
                      >
                        <ColumnFlex gap="0px">
                          <span className="fs-10 fw-500 color-text-2">
                            NRIC No.
                          </span>
                          <TextX
                            data={profileData?.profile?.IdentityNo}
                            dataType="Text"
                            className="fs-12 fw-600"
                          />
                        </ColumnFlex>
                        <ColumnFlex gap="0px">
                          <span className="fs-10 fw-500 color-text-2">
                            Military / Police IC
                          </span>
                          <TextX
                            data={"-"}
                            dataType="Text"
                            className="fs-12 fw-600"
                          />
                        </ColumnFlex>
                      </RowGrid>
                      <RowGrid
                        fullWidth
                        gridTemplateColumns="1fr 1fr"
                        className="color-text"
                      >
                        <ColumnFlex gap="0px">
                          <span className="fs-10 fw-500 color-text-2">
                            Nationality
                          </span>
                          <TextX
                            data={profileData?.profile?.Nationality}
                            dataType="Text"
                            className="fs-12 fw-600"
                          />
                        </ColumnFlex>
                        <ColumnFlex gap="0px">
                          <span className="fs-10 fw-500 color-text-2">
                            Race
                          </span>
                          <TextX
                            data={profileData?.profile?.Race}
                            dataType="Text"
                            className="fs-12 fw-600"
                          />
                        </ColumnFlex>
                      </RowGrid>
                      {profileData?.profile?.Address && (
                        <RowFlex gap="5px" crossAxisAlignment="center">
                          <LocationOnIcon fontSize="inherit" />
                          <TextX
                            data={profileData?.profile?.Address}
                            dataType="Text"
                            className="fs-12 fw-600 color-text"
                            textStyle={{ textTransform: "uppercase" }}
                          />
                        </RowFlex>
                      )}
                    </ColumnFlex>
                  </CardNeu>
                </div>
              </ColumnFlex>

              <ZoomedImageDialog
                visible={isZoomedProfilePic}
                onClose={() => setIsZoomedProfilePic(false)}
                src={profileData?.profile?.ProfilePic || DefaultAvatar}
              />
            </ContainerX>
          </CardNeu>
        </div>
      </>
    );
  }
};

export default Profile;
