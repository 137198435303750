import { RouteObject } from "react-router-dom";
import SalesChart from "./Unit/SalesChart";
import NewBooking from "./NewBooking/NewBooking";

export const BOOKING_PATH = {
  BOOKING_UNIT: `/:accountCode/booking/unit`,
  NEW_BOOKING: `/:accountCode/booking/new-booking`,
} as const;

const BookingRoutes: RouteObject[] = [
  {
    path: BOOKING_PATH.BOOKING_UNIT,
    element: <SalesChart />,
  },
  {
    path: BOOKING_PATH.NEW_BOOKING,
    element: <NewBooking />,
  },
];

export default BookingRoutes;
